<template>
  <div>
    <TheNavbar />

    <main class="produit pt-32">
      <div
        v-if="
          !store.state.loadingCoffretBySlug &&
            store.state.coffretBySlug &&
            !loading
        "
        class="grid grid-grid-cols-1 md:grid-cols-2"
      >
        <div
          class="images pt-2 pl-10 pr-10 md:pl-10 md:pr-10 lg:pl-20 lg:pr-20"
        >
          <img
            :src="store.state.coffretBySlug.coffret_image"
            :alt="store.state.coffretBySlug.coffret_name"
          />
        </div>
        <div class="description ml-10 lg:ml-0 pt-10 lg:pt-0">
          <h1 class="titre-produit">
            {{ store.state.coffretBySlug.coffret_name }}
          </h1>

          <div class="separator-produit"></div>
          <div class="w-10/12" v-if="store.state.coffretBySlug">
            <div
              v-for="(cardName, index) in store.state.coffretBySlug
                .coffret_cardsName"
              :key="index"
              class="grid grid-cols-1 md:grid-cols-2"
            >
              <span>{{ cardName }}</span>
              <span class="text-sm pl-2 mb-4 lg:mb-2"
                >- {{ store.state.coffretBySlug.coffret_cardsFormat[index] }}
              </span>
            </div>
          </div>
          <div class="options grid grid-cols-1">
            <div class="prix-produit pt-10">{{ showPrice() }} $</div>

            <div class="choixQuantiteRapide pt-5">
              <div class="num-block skin-5">
                <div class="num-in">
                  <span class="minus dis" @click="decrement()">-</span>
                  <input
                    class="in-num"
                    type="number"
                    v-model.number="quantity"
                    @keyup.enter="
                      addToCart();
                      quantity = 0;
                    "
                  />
                  <span class="plus" @click="increment()">+</span>
                </div>
              </div>
            </div>
            <div
              class="pt-10"
              v-if="
                !store.state.loading &&
                  !store.state.loadingCoffretBySlug &&
                  store.state.coffretBySlug
              "
            >
              {{
                store.methods.getInventoryCoffret(store.state.coffretBySlug)
              }}
              en stock
            </div>

            <div class="pt-10">
              <button
                class="btnValider"
                @click="addToCart()"
                v-if="
                  store.methods.getInventoryCoffret(store.state.coffretBySlug) >
                    0
                "
              >
                AJOUTER AU PANIER
              </button>
              <button class="btnValiderDisabled" v-else>
                AJOUTER AU PANIER
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>

    <TheFooter />
  </div>
</template>

<script>
import TheNavbar from "../../components/TheNavbar";
import TheFooter from "../../components/TheFooter";

import { inject, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "ProduitCoffret",
  components: { TheNavbar, TheFooter },
  setup() {
    const store = inject("store");
    const route = useRoute();

    const loading = ref(true);
    const visible = ref(false);
    // const indexImage = ref(0);

    const quantity = ref(1);
    const price = ref(null);
    const image = ref(null);
    // const stockAvailable = ref(null);

    function increment() {
      if (
        quantity.value >=
        store.methods.getInventoryCoffret(store.state.coffretBySlug)
      ) {
        alert(
          "Vous ne pouvez ajouter plus d'articles que l'inventaire disponible"
        );
        return;
      }
      quantity.value++;
    }

    function decrement() {
      if (quantity.value > 0) {
        quantity.value--;
      } else {
        return;
      }
    }

    function addToCart() {
      if (
        quantity.value >
        store.methods.getInventoryCoffret(store.state.coffretBySlug)
      ) {
        alert(
          "Vous ne pouvez ajouter plus d'articles que l'inventaire disponible"
        );
        return;
      }

      let productToAdd = {
        variation: store.state.coffretBySlug.coffret_varId,
        parentId: 999999,
        price: store.state.coffretBySlug.coffret_price,
        imageVariation: store.state.coffretBySlug.coffret_image,
        format: "none",
        coffret_varId: store.state.coffretBySlug.coffret_varId,
      };

      // POUSSER LE PRODUIT DANS LE PANIER
      store.methods.addToCart([
        productToAdd,
        quantity.value,
        store.state.coffretBySlug.coffret_name,
        [true, true],
        false,
        null,
      ]); // isCoffret, IsMelimelo, isPresentoire( last input )
    }

    function showPrice() {
      price.value = store.state.coffretBySlug.coffret_price.toFixed(2);

      return price.value;
    }

    onMounted(() => {
      window.scrollTo(0, 0);

      loading.value = true;

      // SI CAT NE SONT PAS CRES
      if (!store.state.categories) {
        if (!store.state.data) {
          (async () => {
            await store.methods.fetchAllProducts();
            store.methods.fetchCoffretBySlug(route.params.coffret);
            store.methods.checkIfCart();
            loading.value = false;

            (async () => {
              await store.methods.showCategory();
              // store.methods.checkIfCart();
              store.methods.calculTaxes();
            })();
          })();
        } else {
          // SI DATA PAS DOWNLOADÉ
          (async () => {
            await store.methods.showCategory();
            store.methods.checkIfCart();
            store.methods.calculTaxes();
          })();
        }
      } else {
        (async () => {
          await store.methods.fetchCoffretBySlug(route.params.coffret);
          store.methods.showCategory();
          store.methods.checkIfCart();
          loading.value = false;
        })();
      }

      if (!store.state.presentoirs) {
        store.methods.fetchPresentoirs();
      }
      if (!store.state.coffretsMeliMelo) {
        store.methods.fetchCoffretMeliMelo();
      }

      (async () => {
        await store.methods.fetchCoffretBySlug(route.params.coffret);
        loading.value = false;
      })();
    });

    return {
      store,
      loading,
      quantity,
      increment,
      decrement,
      showPrice,
      price,
      image,
      visible,
      addToCart,
    };
  },
};
</script>
<style lang="scss">
$borderGray: #dedede;
$goldColor: #b7a36f;

@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap");

.slide-enter-from {
  opacity: 0;
  transform: scale(0.95);
}
.slide-enter-to {
  transform: scale(1);
  opacity: 1;
}
.slide-enter-active {
  transition: all 0.4s ease;
}
.slide-leave-from {
  opacity: 1;
  transform: scale(1);
}
.slide-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

#formats {
  outline: none;
}

.description-longue {
  line-height: 26px;
  letter-spacing: 1px;

  p {
    padding-bottom: 25px;
  }

  h3 {
    font-family: "Lora", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 1px;
    color: #bba267;
    padding-bottom: 15px;
  }

  ul li {
    list-style: disc;
    margin-left: 19px;
    padding-bottom: 12px;
  }
}

.titre-description {
  color: #bba267 !important;
  font-size: 12px;
  padding-bottom: 8px;
}

.description-under {
  width: min-content;
  border-bottom: 2px solid #bba267;
}

.choixQuantiteRapide {
  z-index: 100;
  /* skin 5 */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input:focus {
    outline: none !important;
  }

  .formatsList {
    color: #222222;
  }

  .skin-5 .num-in {
    width: 88px;
    float: left;
    vertical-align: middle;
    position: relative;
    border: 1px solid #eeeff1;
    border-radius: 3px;
  }

  .skin-5 .num-in:hover {
    border: 1px solid #eeeff7;
    box-shadow: 0px 0px 7px 0px #f3f3f3;
  }

  .skin-5 .num-in span {
    cursor: pointer;
    font-size: 16px;
    width: 20px;
    display: block;
    line-height: 41px;
  }

  .skin-5 .num-in span.minus {
    float: left;
    text-align: right;
    font-size: 18px;
  }

  .skin-5 .num-in input {
    border: none;
    height: 41px;
    width: 46px;
    float: left;
    text-align: center;
    font-size: 16px;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }

  .skin-5 .num-in span.plus {
    cursor: pointer;
    float: right;
    text-align: left;
    font-size: 18px;
  }

  /* / skin 5 */
}

.effacer {
  margin-left: 75px;
  cursor: pointer;
}

.separator-produit {
  width: 90%;
  border: 2px solid rgb(235, 235, 235);
  margin-bottom: 30px;
  margin-top: 30px;
}

.titre-produit {
  font-size: 25px;
  letter-spacing: 1px;
}

.prix-produit {
  font-size: 25px;
  letter-spacing: 1px;
}

.s1 {
  color: #bba267;
  font-size: 18px;
  font-style: italic;
  font-family: "Lora", serif;
}

.petite-photos-wrapper {
  .petite-photo {
    height: 110px;
  }
}

p.p1 {
  margin-top: 20px;
  letter-spacing: 0.5px;
}

.description {
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  // CACHE SUGESTION DE COFFRET
  h6 {
    display: none;
  }
  .p1:nth-child(4) {
    display: none;
  }
}
</style>
