<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8">
        <div class="col-pan-3">
          <div class="text-3xl uppercase">
            GESTION DES MOCKUPS
          </div>
        </div>
        <div class="w-full mt-10 grid grid-cols-1 md:grid-cols-3">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="
              mode = 'ajouter';
              newMockup = {};
            "
          >
            Ajouter un mockup
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="
              mode = 'modifier';
              currentMockup = null;
            "
          >
            Modifier un mockup
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 col-span-3 lg:col-span-1 uppercase"
            @click="
              mode = 'supprimer';
              currentMockup = null;
            "
          >
            Supprimer un mockup
          </button>
        </div>
        <div v-if="mode === 'ajouter'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Ajout d'un mockup</p>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-4 my-10 gap-y-2">
            <p class="col-span-1 md:col-span-4  flex items-center">
              Nom du fichier ( Catégorie - Format - Type ) :
            </p>
            <input
              type="text"
              v-model="nameNewMockup"
              class="py-2 px-2 my-6 text-sm border border-blue-lighter rounded-r col-span-3"
            />
            <label class="cursor-pointer col-span-1 md:col-span-4 ">
              <div class="flex justify-start">
                <span
                  v-if="!uploadingFile"
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded uppercase w-72 flex justify-center"
                  >Téléverser le fichier zip</span
                >
                <span
                  v-else
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded uppercase flex justify-center w-72"
                  ><img
                    id="loadingLogo"
                    src="../../assets/images/spinner_button.gif"
                /></span>
                <input
                  type="file"
                  class="hidden"
                  @change="onFileSelected"
                  id="imageUrl1"
                  required
                />
              </div>
            </label>
          </div>

          <p v-if="uploadSuccess" class="text-green-500 my-6">
            L'opération a été effectuée avec succès.
          </p>
          <p v-if="uploadError" class="text-red-500 my-6">
            Un problème est survenu...
          </p>
          <button
            v-if="fileUrl"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mr-10 uppercase"
            @click="confirmMockup()"
          >
            CONFIRMER L'ENREGISTREMENT
          </button>

          <div></div>
        </div>
        <div v-if="mode === 'modifier'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Modification d'un mockup</p>

            <div class="relative" v-if="mockups && !loadingMockups">
              <select
                v-model="currentMockup"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option disabled selected value="null"
                  >Veuillez sélectionner un mockup à modifier</option
                >
                <option
                  v-for="(mockup, indexMockup) in mockups"
                  :key="indexMockup"
                  :value="indexMockup"
                  >{{ mockup.mockupName }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            class="grid grid-cols-1 md:grid-cols-4 my-10 gap-y-2"
            v-if="currentMockup !== null"
          >
            <p class="col-span-1 md:col-span-4 flex items-center">
              Nom du fichier ( Catégorie - Format - Type ) :
            </p>
            <input
              type="text"
              v-model="mockups[currentMockup].mockupName"
              class="py-2 px-2 text-sm border border-blue-lighter rounded-r col-span-3"
            />
          </div>

          <p v-if="modifSuccess" class="text-green-500 my-6">
            L'opération a été effectuée avec succès.
          </p>
          <p v-if="modifError" class="text-red-500 my-6">
            Un problème est survenu...
          </p>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mr-10 uppercase"
            @click="updateMockup()"
          >
            CONFIRMER LES MODIFICATIONS
          </button>
        </div>
        <div v-if="mode === 'supprimer'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Suppression d'un mockup</p>

            <div class="relative" v-if="mockups && !loadingMockups">
              <select
                v-model="currentMockupToDelete"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option disabled selected value="null"
                  >Veuillez sélectionner un mockup à supprimer</option
                >
                <option
                  v-for="(mockup, indexMockup) in mockups"
                  :key="indexMockup"
                  :value="indexMockup"
                  >{{ mockup.mockupName }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            class="grid grid-cols-1 md:grid-cols-4 my-10 gap-y-2"
            v-if="currentMockup !== null"
          >
            <p class="font-semibold">{{ mockups[currentMockup].mockupName }}</p>
          </div>

          <p v-if="deleteSuccess" class="text-green-500 my-6">
            L'opération a été effectuée avec succès.
          </p>
          <p v-if="deleteError" class="text-red-500 my-6">
            Un problème est survenu...
          </p>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mr-10 uppercase"
            @click="deleteMockup()"
          >
            SUPPRIMER
          </button>
        </div>
      </div>
    </div>

    <!-- </div> -->
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { onMounted, inject, ref } from "vue";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import {
  ref as refDb,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useRouter } from "vue-router";

export default {
  name: "GestionMockups",
  components: { AdminSideBar },
  setup() {
    const firebaseConfig = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: "extranet-fb58d.firebaseapp.com",
      projectId: "extranet-fb58d",
      storageBucket: "extranet-fb58d.appspot.com",
      messagingSenderId: "54012934511",
      appId: "1:54012934511:web:3fa26c12add158e1a2109a",
      measurementId: "G-Z5ELBPXFRY",
    };
    const firebaseApp = initializeApp(firebaseConfig);
    const storage = getStorage(firebaseApp);
    const router = useRouter();
    const store = inject("store");
    const nameNewMockup = ref(null);
    const currentMockup = ref(null);
    const mode = ref(null);
    const uploadSuccess = ref(null);
    const uploadError = ref(null);
    const fileUrlName = ref(null);
    const fileUrl = ref(null);
    const selectedFile = ref(null);
    const uploadingFile = ref(false);
    const mockups = ref(null);
    const currentMockupToDelete = ref(null);
    const loadingMockups = ref(null);
    const modifSuccess = ref(false);
    const modifError = ref(false);
    const deleteSuccess = ref(false);
    const deleteError = ref(false);

    onMounted(() => {
      getMockups();

      if (!store.state.data) {
        store.methods.fetchAllProducts();
      }
    });

    function onFileSelected(event) {
      // VALIDATION DU FORMAT ET DE LA GRANDEUR

      const allowedExtensions = ["zip"],
        sizeLimit = 50000000;
      const fileExtension = event.target.files[0].name.split(".").pop();
      const fileSize = event.target.files[0].fileSize;

      if (!allowedExtensions.includes(fileExtension)) {
        alert("Ce type de fichier n'est pas accepté !");
        return;
      } else if (fileSize > sizeLimit) {
        alert("Le fichier est trop volumineux.");
        return;
      }

      selectedFile.value = event.target.files[0];

      fileUrlName.value = [
        event.target.files[0].name.slice(
          0,
          event.target.files[0].name.length - 4
        ) +
          "_" +
          randomString(8),
      ].join("");
      fileUrlName.value += `.${fileExtension}`;
      onUploadFile(fileUrlName.value);
    }

    const onUploadFile = (fileName) => {
      fileUrl.value = null;

      if (!uploadingFile.value) {
        uploadingFile.value = true;

        const storageRef = refDb(storage, `/mockups/${fileName}`);
        uploadBytes(storageRef, selectedFile.value).then((snapshot) => {
          console.log(
            "Uploaded",
            snapshot,
            getDownloadURL(storageRef).then((url) => {
              fileUrl.value = url;
              uploadingFile.value = false;
            })
          );
        });
      }
    };

    const randomString = (length) => {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    };

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    function confirmMockup() {
      if (!nameNewMockup.value || !fileUrl.value) {
        alert("Veuiller remplir tous les champs.");
        return;
      }

      uploadSuccess.value = false;
      uploadError.value = false;

      const data = {
        mockupName: nameNewMockup.value,
        mockupUrl: fileUrl.value,
      };

      fetch(`${store.state.createMockups}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success === 1) {
            uploadSuccess.value = true;
            router.go();
          } else {
            uploadError.value = true;
          }
        })
        .catch((err) => {
          console.log(err);
          uploadError.value = true;
        });
    }

    async function getMockups() {
      loadingMockups.value = true;
      try {
        const res = await fetch(`${store.state.getMockups}`, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });

        if (!res.ok) {
          console.log("un problème est survenu.");
          loadingMockups.value = false;
        } else {
          const data = await res.json();

          if (data.success === 1) {
            mockups.value = data.data.sort((a, b) =>
              a.mockupName.localeCompare(b.mockupName)
            );
          }
          loadingMockups.value = false;
        }
      } catch (err) {
        console.log(err);
      }
    }

    function getFileName(url) {
      const afterDirectory = url.split("%2F")[1];
      const beforeExtra = afterDirectory.split("?")[0];
      return beforeExtra;
    }

    async function updateMockup() {
      modifSuccess.value = false;

      modifError.value = false;

      if (
        currentMockup.value === null ||
        currentMockup.value == undefined ||
        !mockups.value[currentMockup.value].mockupId ||
        !mockups.value[currentMockup.value].mockupName ||
        mockups.value[currentMockup.value].mockupName === ""
      ) {
        alert("Erreur, veuillez remplir les champs nécessaires...");
        return;
      }

      const data = {
        mockupName: mockups.value[currentMockup.value].mockupName,
        mockupId: mockups.value[currentMockup.value].mockupId,
      };

      try {
        const res = await fetch(`${store.state.updateMockupUrl}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
          credentials: "include",
        });

        if (!res.ok) {
          console.log("un problème est survenu.");

          modifError.value = true;
        } else {
          const data = await res.json();

          if (data.success === 1) {
            modifSuccess.value = true;
            getMockups();
          } else {
            modifError.value = true;
          }
        }
      } catch (err) {
        console.log(err);
        modifError.value = ref(true);
      }
    }

    async function deleteMockup() {
      deleteSuccess.value = false;
      deleteError.value = false;

      const mockupId = mockups.value[currentMockupToDelete.value].mockupId;
      const mockupFile = mockups.value[currentMockupToDelete.value].mockupUrl;

      let proceed = confirm(
        `Confirmer la suppression de -  ${
          mockups.value[currentMockupToDelete.value].mockupName
        }  ? `
      );

      if (proceed) {
        const toDeleteRef = refDb(
          storage,
          `/mockups/${getFileName(mockupFile)}`
        );

        deleteObject(toDeleteRef)
          .then(() => {
            deleteOnDb(mockupId);
          })
          .catch((error) => {
            deleteError.value = true;
            console.log(error);
          });
      }
    }

    async function deleteOnDb(mockupIdToDelete) {
      const data = {
        mockupId: mockupIdToDelete,
      };

      try {
        const res = await fetch(`${store.state.deleteMockupUrl}`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
          credentials: "include",
        });

        if (!res.ok) {
          console.log("un problème est survenu.");
          deleteError.value = true;
        } else {
          const data = await res.json();

          if (data.success === 1) {
            currentMockupToDelete.value = null;
            deleteSuccess.value = true;

            getMockups();
          } else {
            deleteError.value = true;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    return {
      store,
      currentMockup,
      uploadError,
      uploadSuccess,
      toggleMenu,
      mode,
      fileUrlName,
      fileUrl,
      selectedFile,
      randomString,
      onUploadFile,
      onFileSelected,
      uploadingFile,
      confirmMockup,
      nameNewMockup,
      mockups,
      loadingMockups,
      getMockups,
      modifSuccess,
      modifError,
      updateMockup,
      deleteSuccess,
      deleteError,
      deleteMockup,
      currentMockupToDelete,
      getFileName,
      deleteOnDb,
    };
  },
};
</script>
<style lang="scss"></style>
