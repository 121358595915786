<template>
  <nav class="navbar">
    <div id="mobile-navbar">
      <div class="container-icons-mobile w-full flex justify-between">
        <div class="h-full flex items-center pl-4">
          <router-link to="/categorie/cartes-de-souhaits">
            <img
              src="@/assets/images/Mimosa-design-logo-small.png"
              alt="Mimosa Design"
              class="logo_mimosa-navbar w-28"
            />
          </router-link>
        </div>

        <div class="flex justify-end">
          <div class="panier">
            <router-link to="/panier">
              <img
                src="@/assets/images/icon_bag_alt.svg"
                alt="Bag"
                class="bag-cart-mobile"
              />
              <div v-if="store.state.cartQty > 0" class="number-in-cart">
                {{ store.state.cartQty }}
              </div>
            </router-link>
          </div>

          <div class="menu" @click="showMenuMobile()">
            <img src="../assets/images/hamburger-menu.svg" id="hamburger-nav" />
          </div>
        </div>
      </div>

      <div class="layerMenu">
        <ul>
          <template v-for="cat in store.state.catsToHandle" :key="cat.id">
            <li v-if="cat.id !== 473">
              <!-- ID DE COFFRETS -->
              <p
                @click="pushLink('categorie/' + cat.slug)"
                class="nav-links-mobile"
              >
                {{ cat.name }}
              </p>
            </li>
          </template>
          <li v-if="catsToHandleContainsCoffret()">
            <p @click="pushLink('categorie/coffrets')" class="nav-links-mobile">
              Coffrets
            </p>
          </li>
          <li>
            <p
              @click="pushLink('categorie/presentoirs')"
              class="nav-links-mobile"
            >
              Présentoirs
            </p>
          </li>

          <li>
            <div class="relative">
              <p @click="pushLink('promotions')" class="nav-links-mobile">
                Promotions
              </p>
              <div
                class="badgePromo absolute left-28 top-0"
                v-if="store.state.activesPromotions.length > 0"
              ></div>
            </div>
          </li>
          <li>
            <p @click="pushLink('profil')" class="nav-links-mobile">
              Mon profil
            </p>
          </li>
          <li>
            <p @click="logout()" class="nav-links-mobile">Se déconnecter</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="nav-container" id="navbar-desktop">
      <div>
        <img
          src="@/assets/images/Mimosa-design-logo-small.png"
          alt="Mimosa Design"
          width="274"
          height="65"
          class="logo_mimosa-navbar"
        />
      </div>

      <div class="links-nav mt-4" v-if="store.state.catsToHandle.length">
        <ul id="container-desktop-nav">
          <template v-for="cat in store.state.catsToHandle" :key="cat.id">
            <li v-if="cat.id !== 473">
              <!-- ID DE COFFRETS -->
              <router-link
                :to="{
                  name: 'Categorie',
                  params: { categorie: cat.slug },
                }"
                class="nav-links"
              >
                {{ cat.name }}
              </router-link>
            </li>
          </template>
          <li v-if="catsToHandleContainsCoffret()">
            <router-link to="/categorie/coffrets" class="nav-links"
              >Coffrets</router-link
            >
          </li>

          <li>
            <router-link to="/categorie/presentoirs" class="nav-links"
              >Présentoirs</router-link
            >
          </li>
          <li>
            <div class="relative">
              <router-link to="/promotions" class="nav-links"
                >Promotions</router-link
              >
              <div
                class="badgePromo absolute top-0 -right-1"
                v-if="store.state.activesPromotions.length > 0"
              ></div>
            </div>
          </li>
          <li>
            <router-link to="/profil" class="nav-links">Mon profil</router-link>
          </li>
        </ul>

        <div
          class="flex cartContainer"
          @click="openCart()"
          @mouseover="previewCart()"
          @mouseleave="closePreviewCart()"
        >
          <img
            src="@/assets/images/icon_bag_alt.svg"
            alt="Bag"
            class="bag-cart"
          />
          <div class="store_quantity" v-if="store.state.cartQty > 0">
            <p>{{ store.state.cartQty }}</p>
          </div>
        </div>
      </div>
    </div>

    <transition name="slide">
      <PreviewCart
        v-if="ifOnOverPreview > 0"
        @mouseover="isStillOnPreview()"
        @mouseleave="closePreviewCartInside()"
      />
    </transition>
  </nav>
</template>

<script>
import PreviewCart from "../components/PreviewCart";

import { inject, ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "TheNavbar",
  components: { PreviewCart },

  setup() {
    const store = inject("store");
    const router = useRouter();
    const route = useRoute();
    const ifOnOverPreview = ref(0);
    const stillOnPreview = ref(false);
    const toggleMenu = ref(false);

    // GESTION DE l'OUVERTURE DU CART
    function openCart() {
      router.push({ path: "/panier" });
    }

    // computed fonction  to see if cat is 473
    const isCoffret = computed((catId) => {
      return catId === 473;
    });

    function closePreviewCart() {
      setTimeout(function () {
        if (ifOnOverPreview.value >= 1 && !stillOnPreview.value) {
          ifOnOverPreview.value--;
        }
        if (ifOnOverPreview.value > 0) {
          closePreviewCart();
        }
      }, 550);
    }
    function previewCart() {
      if (store.state.cartQty > 0 && route.fullPath !== "/panier") {
        // ACTIVE SI PANIER N'EST PAS VIDE ET QUE USER N'EST PAS DEJA DANS LE PANIER
        ifOnOverPreview.value++;
      }
    }
    function closePreviewCartInside() {
      setTimeout(function () {
        stillOnPreview.value = false;
      }, 250);
    }
    function isStillOnPreview() {
      stillOnPreview.value = true;
    }

    async function logout() {
      try {
        const res = await fetch(store.state.logoutUrl, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });

        if (!res.ok) {
          console.log("ERREUR");
        }

        const json = await res.json();
        // set the response data

        if (json.success === 1) {
          router.push({ path: "/login" });
        }
      } catch (err) {
        console.error(err);
      }
    }

    // DECLANCHE LE MENU MOBILE
    function showMenuMobile() {
      if (!toggleMenu.value) {
        document.querySelector(".layerMenu").style.display = "flex";
        document.body.style.overflow = "hidden";
        toggleMenu.value = true;
      } else {
        document.querySelector(".layerMenu").style.display = "none";
        document.body.style.overflow = "visible";
        toggleMenu.value = false;
      }
    }

    function pushLink(route) {
      document.body.style.overflow = "visible";
      document.querySelector(".layerMenu").style.display = "none";
      toggleMenu.value = false;
      router.push({ path: `/${route}` });
    }

    function checkIfPromoActive() {
      // VERIFIER SI DES PROMOTIONS SONT EN VIGUEURS

      if (store.state.activesPromotions.length === 0) {
        store.methods.getActivesPromotions();
      }
    }

    // listen to the window resize event
    window.addEventListener("resize", handleMenuWidth);

    async function handleMenuWidth() {
      // Attendre que la variable soit définie
      while (
        !store.state.catsToHandle ||
        store.state.catsToHandle.length === 0
      ) {
        await new Promise((resolve) => setTimeout(resolve, 50)); // Attendre 50 ms avant de vérifier à nouveau
      }

      // Une fois que la variable est définie, exécuter le reste du code
      const linksNbr = document.querySelectorAll(".nav-links");

      if (linksNbr.length > 7) {
        // SI LE NOMBRE DE LIENS EST SUPERIEUR A 7 MENU MOBILE ACTIF
        document.querySelector("#navbar-desktop").style.display = "none";
        document.querySelector("#mobile-navbar").style.display = "flex";
      }
    }

    function catsToHandleContainsCoffret() {
      return store.state.catsToHandle.some((cat) => cat.id === 473);
    }

    onMounted(() => {
      handleMenuWidth();

      if (!store.state.catsToHandle || store.state.catsToHandle.length === 0) {
        store.methods.getAllCatsToHandle();
      }

      if (!store.state.gotSettingsDb) {
        store.methods.fetchSettingsExtranet();
      }
      checkIfPromoActive();
      store.methods.fetchInfosCustomer();
    });

    return {
      store,
      showMenuMobile,
      logout,
      ifOnOverPreview,
      stillOnPreview,
      isStillOnPreview,
      toggleMenu,
      pushLink,
      openCart,
      previewCart,
      closePreviewCart,
      closePreviewCartInside,
      checkIfPromoActive,
      handleMenuWidth,
      catsToHandleContainsCoffret,
      isCoffret,
    };
  },
};
</script>

<style lang="scss">
.slide-enter-from {
  opacity: 0;
  transform: scale(0.95);
}
.slide-enter-to {
  transform: scale(1);
  opacity: 1;
}
.slide-enter-active {
  transition: all 0.4s ease;
}
.slide-leave-from {
  opacity: 1;
  transform: scale(1);
}
.slide-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

#mobile-navbar {
  display: none;
}

nav {
  position: relative;
  margin-top: 26px;

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1445px;
    padding-left: 30px;
    padding-right: 30px;
    margin: auto;

    .links-nav {
      display: flex;
      justify-content: flex-end;

      ul {
        list-style-type: none;
        margin-right: 40px;

        li {
          display: table-cell;
          position: relative;

          .nav-links {
            position: relative;
            text-transform: uppercase;
            letter-spacing: 1px;
            color: black;
            font-size: 14px;
            display: inline-block;
            margin-left: 30px;
            padding-bottom: 10px;
            display: table;
            white-space: nowrap;
          }

          .nav-links:after {
            background: none repeat scroll 0 0 transparent;
            bottom: 0;
            content: "";
            display: block;
            height: 2px;
            left: 50%;
            position: absolute;
            background: #000;
            transition: width 0.5s ease 0s, left 0.5s ease 0s;
            width: 0;
          }
          .nav-links:hover:after {
            width: 100%;
            left: 0;
          }
        }
      }

      .cartContainer {
        min-width: 50px;
        cursor: pointer;
        .bag-cart {
          transform: translateY(-7px);
          height: 23px;
          padding-right: 5px;
        }

        .store_quantity {
          background-color: #646464;
          padding: 0 7px;
          min-width: 25px;
          max-height: 23px;
          margin: 6px 0 5px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateY(-9px);
          border-radius: 3px;
          p {
            transform: translate(-1px, -2px);
            color: white;
            font-weight: bold;
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  // nav {
  //   margin-top: 0px;
  // }

  #navbar-desktop {
    display: none;
  }

  #mobile-navbar {
    display: flex;
  }
}

#mobile-navbar {
  max-width: 1445px;
  padding-left: 30px;
  padding-right: 30px;
  margin: auto;
  position: relative;
  justify-content: flex-end;
  padding-bottom: 26px;
  background-color: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(128, 128, 128, 0.522);
  height: 60px;
  width: 100%;

  .container-icons-mobile {
    .panier {
      position: relative;

      .bag-cart-mobile {
        height: 25px;
        margin-bottom: 2px;
        cursor: pointer;
      }

      .number-in-cart {
        position: absolute;
        top: -4px;
        right: -10px;
        font-size: 10px;
        background-color: black;
        border-radius: 50%;
        padding: 2px 1px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        color: white;
      }
    }
    .menu {
      #hamburger-nav {
        margin: 20px;
        height: 20px;
        cursor: pointer;
        transform: translate(0, -13px);
      }
    }
  }

  .layerMenu {
    position: absolute;
    display: none;
    max-width: 1445px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100vw;
    top: 60px;
    height: calc(100vh - 60px);
    background-color: rgba(255, 255, 255, 1);
    z-index: 500;

    li {
      max-width: 1445px;
      width: 100vw;
      margin-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(128, 128, 128, 0.522);
      .nav-links-mobile {
        cursor: pointer;
        text-transform: uppercase;
        font-size: 20px;
        margin-left: 24px;
        font-size: 12px;
        letter-spacing: 1px;
        color: rgb(136, 136, 136);
        font-weight: 700;
      }
    }
  }
}

.badgePromo {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: none;
  background-color: rgb(182, 0, 0);
}

#container-desktop-nav {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
