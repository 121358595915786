<template>
  <div class="home hauteurminadmin relative">
    <img
      src="@/assets/images/Mimosa-design-logo-small.png"
      alt="Mimosa Design"
      width="274"
      height="65"
      id="logo-mimosa"
    />
    <p class="admin-title">SECTION ADMINISTRATEUR</p>
    <input
      class="mt-10 h-10 px-3 mb-2"
      type="text"
      placeholder="Courriel"
      v-model="loginInfos.email"
    />
    <input
      class="mt-1 h-10 px-3 mb-2"
      type="password"
      placeholder="Mot de passe"
      v-model="loginInfos.password"
    />
    <button
      class="h-10 px-5 m-2 text-white text-sm transition-colors duration-150 bg-black focus:shadow-outline uppercase"
      @click="login()"
    >
      Se connecter
    </button>
    <div class="home-links mt-5 pb-10">
      <router-link to="/resetpassword" class="uppercase underline text-xs link"
        >Mot de passe oublié</router-link
      >
    </div>
    <div>
      <p id="badLogin" v-if="badAuth" class="text-red-400">
        Courriel ou mot de passe invalide.
      </p>
    </div>

    <div class="absolute bottom-10 text-sm">
      © {{ store.methods.getCurrentYear() }}. Mimosa Design - Extranet. Tous
      droits réservés.
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { gsap } from "gsap";
import { reactive, onMounted, inject, ref } from "vue";
import { useRouter } from "vue-router";
import jwt_decode from "jwt-decode";
import axios from "axios";

export default {
  name: "LoginAdmin",
  setup() {
    const store = inject("store");
    const loginInfos = reactive({});
    const router = useRouter();
    const badAuth = ref(false);

    function login() {
      const API_URL = store.state.postLogin;

      axios
        .post(
          API_URL,
          {
            email: loginInfos.email,
            password: loginInfos.password,
          },
          { withCredentials: true }
        )

        .then(function (response) {
          if (response.data.success === 1) {
            // SI LOGIN EST SUCCESSFULL
            let { isAdmin } = jwt_decode(response.data.token).result;
            store.state.customerId = jwt_decode(response.data.token).result;
            store.state.isAuth = true;

            if (isAdmin) {
              store.state.isAdmin = true;
              router.push({ path: "/admin" });
            } else {
              router.push({ path: "/" });
            }
          } else {
            console.log("bad login");
            badAuth.value = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    const onLoad = onMounted(() => {
      let logo = document.querySelector("#logo-mimosa");
      gsap.fromTo(logo, { opacity: 0 }, { opacity: 1, duration: 2 });
    });

    return {
      login,
      badAuth,
      onLoad,
      loginInfos,
    };
  },
};
</script>
<style lang="scss">
.hauteurminadmin {
  min-height: 600px;
}

.home {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .admin-title {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    font-size: 14px;
    padding-top: 20px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: rgba(255, 255, 255, 1);
    transition: background-color 5000s ease-in-out 0s;
  }
  input::-webkit-input-placeholder,
  input::-ms-input-placeholder,
  input:-ms-input-placeholder {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }

  .home-links {
    width: min(90%, 400px);
    display: flex;
    justify-content: center;
  }

  input {
    color: #7a7a7a;
    border: 1px solid #dedede;
    outline-width: 0;
    max-width: min(90%, 400px);
  }

  button:hover {
    background-color: #bba267;
  }
}
</style>
