<template>
  <footer>
    <div class="footer-wrapper">
      <div class="footer-inner mx-auto mt-16 pt-10">
        <div class="grid grid-cols-4 lg:grid-cols-12 pb-14">
          <div class="col-span-4 flex justify-center items-center">
            <img
              src="../../src/assets/images/mimosa_footer.svg"
              alt="Mimosa Design Extranet"
              class="mimosa_footer_logo"
            />
          </div>

          <div
            class="col-span-4 flex justify-center items-center pt-10 lg:pt-0"
          >
            <div class="liens-holder">
              <p class="font-semibold titres">CONTACT</p>
              <div class="flex justify-start pt-5">
                <img
                  src="../../src/assets/images/icon_house_alt.svg"
                  alt="Home"
                  class="icons-footer icon-maison"
                />
                <div class="pl-10">
                  295, rue Notre-Dame,<br />
                  Boischatel (Québec) G0A 1H0
                </div>
              </div>

              <div class="flex justify-start pt-5">
                <img
                  src="../../src/assets/images/icon_phone.svg"
                  alt="Telephone"
                  class="icons-footer icon-tel"
                />
                <a href="tel:4188091449" class="pl-10">(418) 809-1449</a>
              </div>

              <div class="flex justify-start pt-5">
                <img
                  src="../../src/assets/images/icon_mail_alt.svg"
                  alt="Mail"
                  class="icons-footer icon-courriel"
                />
                <a href="mailto:info@mimosadesign.ca" class="pl-10"
                  >info@mimosadesign.ca</a
                >
              </div>
            </div>
          </div>
          <div class="col-span-4 flex items-center flex-col pt-12 lg:pt-10">
            <div class="class liens-wrapper" v-if="store.state.catsToHandle">
              <div>
                <p class="font-semibold titres mt-5">LIENS</p>
              </div>
              <div class="pt-5"></div>
              <template v-for="cat in store.state.catsToHandle" :key="cat.id">
                <div class="pt-3" v-if="cat.id !== 473">
                  <!-- ID DE COFRRET-->
                  <router-link
                    :to="{
                      name: 'Categorie',
                      params: { categorie: cat.slug },
                    }"
                  >
                    <span class="flex justify-start">
                      <img
                        src="@/assets/images/chevron-droit.png"
                        class="chevron"
                      />
                      <span class="liens"> {{ cat.name }} </span>
                    </span>
                  </router-link>
                </div>
              </template>

              <div class="pt-3" v-if="catsToHandleContainsCoffret()">
                <router-link to="/categorie/coffrets">
                  <span class="flex justify-start">
                    <img
                      src="@/assets/images/chevron-droit.png"
                      class="chevron"
                    />
                    <span class="liens"> Coffrets </span>
                  </span>
                </router-link>
              </div>
              <div class="pt-3">
                <router-link to="/categorie/presentoirs">
                  <span class="flex justify-start">
                    <img
                      src="@/assets/images/chevron-droit.png"
                      class="chevron"
                    />
                    <span class="liens"> Présentoirs </span>
                  </span>
                </router-link>
              </div>
              <div class="pt-3">
                <router-link to="/promotions">
                  <span class="flex justify-start">
                    <img
                      src="@/assets/images/chevron-droit.png"
                      class="chevron"
                    />
                    <span class="liens"> Promotions </span>
                  </span>
                </router-link>
              </div>

              <div class="pt-3">
                <router-link to="/profil">
                  <span class="flex justify-start">
                    <img
                      src="@/assets/images/chevron-droit.png"
                      class="chevron"
                    />
                    <span class="liens"> Mon profil </span>
                  </span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright-footer mx-auto">
      <div class="text-sm">
        © {{ store.methods.getCurrentYear() }}. Mimosa Design - Extranet. Tous
        droits réservés.
      </div>
    </div>
  </footer>
</template>

<script>
import { inject, onMounted } from "vue";

export default {
  name: "TheFooter",

  setup() {
    const store = inject("store");
    onMounted(() => {
      if (!store.state.catsToHandle || store.state.catsToHandle.length === 0) {
        store.methods.getAllCatsToHandle();
      }
    });

    function catsToHandleContainsCoffret() {
      return store.state.catsToHandle.some((cat) => cat.id === 473);
    }

    return {
      store,
      catsToHandleContainsCoffret,
    };
  },
};
</script>

<style lang="scss">
footer {
  .footer-wrapper {
    color: rgb(136, 136, 136);
    background-color: #efefef7a;
    font-size: 13px;
    letter-spacing: 0.6px;

    .footer-inner {
      max-width: 1445px;

      .icon-maison {
        margin-top: 7px;
      }

      .icon-tel {
        margin-top: 5px;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }

      .icon-courriel {
        margin-top: 3px;
      }

      .mimosa_footer_logo {
        height: 200px;
      }

      .icons-footer {
        height: 15px;
      }

      .liens-holder {
        width: 250px;
      }

      .liens {
        transition: all 0.3s ease-in;
      }

      .liens:hover {
        padding-left: 11px;
        color: black;
      }

      .chevron {
        height: 7px;
        margin-right: 10px;
        margin-top: 5px;
      }

      .titres {
        color: rgb(136, 136, 136);
        letter-spacing: 1px;
      }

      .liens-wrapper {
        width: 250px;
      }
    }
  }
  .copyright-footer {
    max-width: 1445px;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
