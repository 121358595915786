<template>
  <main>
    <TheNavbar />

    <div class="cartes grid grid-cols-1 lg:grid-cols-4 px-4 md:px-10 pt-16">
      <div class="col-span-3 row-start-1 px-5 min-height-top">
        <h1 class="titleSection uppercase pb-2" v-if="currentSelectedCat">
          {{ currentSelectedCat.name }}
        </h1>
        <div class="grid grid-cols-3 mt-4">
          <div class="mr-4 col-span-3 flex items-center">
            <p>Mode Visualisation :</p>
          </div>

          <div class="col-span-3 lg:col-span-2 flex items-center mt-4">
            <button
              @click="switchMode('boutique')"
              class="mr-4 w-20"
              id="modeBoutique"
            >
              Boutique
            </button>
            <button @click="switchMode('rapide')" class="w-20" id="modeRapide">
              Rapide
            </button>
          </div>
        </div>
      </div>

      <ShowProducts
        class="bloc-show-products row-start-2 md:row-start-auto col-span-1 lg:col-span-3 mt-20"
      />

      <div
        class="col-span-1 row-start-3 md:row-start-auto mx-auto pt-20 lg:pt-0 lg:mx-0"
      >
        <Search />
        <SelectStock class="mb-5" />
        <SelectFormat v-if="store.state.modeVisualisation === 'rapide'" />
        <SelectCategories />
        <SelectTag />
      </div>
    </div>

    <TheFooter />
  </main>
</template>

<script>
import TheNavbar from "../../components/TheNavbar";
import TheFooter from "../../components/TheFooter.vue";
import ShowProducts from "../../components/ShowProducts";
import SelectCategories from "../../components/SelectCategories";
import Search from "../../components/Search";
import SelectTag from "../../components/SelectTag";
import SelectStock from "../../components/SelectStock";
import SelectFormat from "../../components/SelectFormat";

import { inject, onMounted, ref, watch } from "vue";

import { useRoute, useRouter } from "vue-router";

export default {
  name: "Cartes",
  components: {
    TheNavbar,
    TheFooter,
    ShowProducts,
    SelectCategories,
    Search,
    SelectTag,
    SelectStock,
    SelectFormat,
  },
  setup() {
    const store = inject("store");
    const route = useRoute();
    const router = useRouter();
    const currentSelectedCat = ref(null);

    function switchMode(mode) {
      store.state.modeVisualisation = mode;
      if (mode === "rapide") {
        document.querySelector("#modeRapide").style.border =
          "1px solid #B7A36F";
        document.querySelector("#modeBoutique").style.border = "1px solid #000";
      } else {
        document.querySelector("#modeBoutique").style.border =
          "1px solid #B7A36F";
        document.querySelector("#modeRapide").style.border = "1px solid #000";
      }
    }

    // watch if route changes
    watch(
      () => route.params.categorie,
      () => {
        handleSelectedCat();
      }
    );

    function handleSelectedCat() {
      const currentCat = route.params.categorie;

      (async () => {
        await store.methods.getAllCatsToHandle();
        const cat = store.state.catsToHandle.find(
          (cat) => cat.slug === currentCat
        );
        if (cat) {
          currentSelectedCat.value = cat;
          store.state.currentTag = cat.id;
        } else {
          alert(
            "La catégorie que vous avez demandé n'existe pas. Vous serez redirigé vers la page d'accueil."
          );
          router.push("/categorie/cartes-de-souhaits");
        }
      })();
    }

    onMounted(() => {
      // GET CATEGORIE
      handleSelectedCat();

      window.scrollTo(0, 0);
      store.methods.tagsEnsList();
      store.methods.tagsRegList();
      store.methods.tagsToucheDoreList();

      store.state.currentFilterSearch = "none"; // RESET FILTRE RECHERCHE
      // RESET CATEGORIES_AFFICHES
      store.state.currentFilterCategories = "all";
      // CAT DE CARTE DE SOUHAITS
      // VERIFIE SI LE CLIENT A DEJA UN CART

      // SI CAT NE SONT PAS CRES
      if (!store.state.categories) {
        if (!store.state.data) {
          (async () => {
            await store.methods.fetchAllProducts();
            store.methods.checkIfCart();

            (async () => {
              await store.methods.showCategory();
              // store.methods.checkIfCart();
              store.methods.calculTaxes();
            })();
          })();
        } else {
          // SI DATA PAS DOWNLOADÉ
          (async () => {
            await store.methods.showCategory();
            store.methods.checkIfCart();
            store.methods.calculTaxes();
          })();
        }
      } else {
        store.methods.showCategory();
        store.methods.checkIfCart();
      }

      if (!store.state.presentoirs) {
        store.methods.fetchPresentoirs();
      }
      if (!store.state.coffretsMeliMelo) {
        store.methods.fetchCoffretMeliMelo();
      }

      // BOLD SELECRTION DE MODE DE VISIONNEMENT
      switchMode(store.state.modeVisualisation);
    });

    return {
      store,
      switchMode,
      handleSelectedCat,
      currentSelectedCat,
    };
  },
};
</script>
<style lang="scss">
.cartes {
  max-width: 1445px;
  // min-height: 70vh;
  margin: auto;

  .min-height-top {
    min-height: 140px;
  }

  #modeBoutique,
  #modeRapide {
    cursor: pointer;
    padding: 2px 5px;
    transition: all 0.2s;
    border: 1px solid #000;
  }

  #modeBoutique:hover,
  #modeRapide:hover {
    transform: scale(1.01);
  }
}
</style>
