<template>
  <div class="container-preview">
    <div class="previewCart">
      <div class="showItemPrev">
        <div v-if="!store.state.loadingInfosCustomer" class="compagny_name">
          <div
            v-if="store.state.infosCustomer.customer_compagnyName"
            class="mb-2"
          >
            {{ store.state.infosCustomer.customer_compagnyName }}
          </div>
          <div v-else>
            {{ store.state.infosCustomer.customer_contact }}
          </div>
        </div>
        <div class="separator-cart-preview pt-6"></div>
        <transition-group name="slide">
          <div
            v-for="(item, index) in store.state.cart"
            :key="index"
            class="grid grid-cols-1"
          >
            <div class="grid grid-cols-10 prev_item_wrapper  mb-12">
              <div class="col-span-9 flex justify-between">
                <div
                  v-if="
                    !item.isCoffret &&
                      item.parentId !== 777777 &&
                      item.variantId !== 777777
                  "
                >
                  <p class="description-preview-cart">
                    {{ item.name }}
                    <span v-if="item.format.length">- {{ item.format }}</span>
                  </p>
                </div>
                <div v-else-if="item.isCoffret">
                  <p class="description-preview-cart">
                    Coffret de 6 - {{ item.name }}
                  </p>
                </div>
                <div v-else>
                  <p class="description-preview-cart">{{ item.name }}</p>
                </div>

                <img
                  :src="item.image"
                  alt="item.nom"
                  class="image_preview"
                  v-if="
                    item.parentId !== 777777 &&
                      item.variantId !== 777777 &&
                      item.parentId !== 999998
                  "
                />
              </div>
              <div class="col-s`pan`-1 x-holder-preview-cart">
                <img
                  src="../../src/assets/images/cancel.svg"
                  class="prev-x"
                  @click="
                    store.methods.removeFromCart(
                      index,
                      item.parentId,
                      item.variationId,
                      item.isCoffret,
                      item.isMeliMelo,
                      item.isPresentoir,
                      item.quantity
                    )
                  "
                />
              </div>
              <div class="col-span-8">
                <p class="priceQuantity-preview-cart">
                  {{ item.quantity }}
                  <span style="padding: 0 3px; fontSize:10px, fontWeight: bold"
                    >x</span
                  >
                  {{ item.price.toFixed(2) }} $
                </p>
              </div>
            </div>
          </div>
        </transition-group>
        <div class="separator-cart-preview"></div>
        <div class="pt-3 pb-5">
          <p class="sous_total_cart_preview">
            Sous-total : {{ store.state.sousTotalCart }} $
          </p>
        </div>

        <button class="btn_cart_preview" @click="goToCart()">
          VOIR LE PANIER
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "PreviewCart",

  setup() {
    const store = inject("store");
    const router = useRouter();

    function goToCart() {
      router.push({ path: "/panier" });
    }

    return {
      store,
      goToCart,
    };
  },
};
</script>
<style lang="scss">
.slide-enter-from {
  opacity: 0;
  transform: scale(0.95);
}
.slide-enter-to {
  transform: scale(1);
  opacity: 1;
}
.slide-enter-active {
  transition: all 0.4s ease;
}
.slide-leave-from {
  opacity: 1;
  transform: scale(1);
}
.slide-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.container-preview {
  max-width: 1445px;
  margin: auto;
  position: relative;
}

.previewCart {
  z-index: 200;
  position: absolute;
  padding: 25px 5px 40px 25px;
  top: 20px;
  right: 20px;
  width: 250px;
  min-height: 315px;
  background-color: white;
  border-top: 1px solid rgb(0 0 0 / 3%);
  box-shadow: 0px 3px 3px rgb(0 0 0 / 23%);

  .prev_item_wrapper {
    .description-preview-cart {
      font-size: 12px;
    }
    height: 50px;
    .image_preview {
      height: 100%;
      max-width: 45px;
    }
    .prev-x {
      cursor: pointer;
      transform: scale(0.3);
      margin-top: 11px;
    }
    .priceQuantity-preview-cart {
      font-weight: 500;
    }
  }

  .separator-cart-preview {
    margin-right: 25px;
    border-top: 1px solid rgb(0 0 0 / 15%);
  }

  .sous_total_cart_preview {
    font-size: 18px;
    font-weight: 600;
  }

  .btn_cart_preview {
    border: 2px solid black;
    margin: 2px;
    transform: translateX(-2px);
    font-size: 12px;
    letter-spacing: 1.1px;
    font-weight: 500;
    padding: 15px 20px;
    transition: all 0.3s;
  }

  .deuxiemeBtnPrev {
    transform: translate(-2px, 10px);
  }
  .btn_cart_preview:hover {
    background-color: black;
    color: #b7a36f;
    outline: none !important;
  }

  button:focus {
    outline: 0 !important;
  }

  .compagny_name {
    font-size: 0.75rem;
  }
}
</style>
