<template>
  <div class="min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10 ">
      <div class="col-span-5 p-8">
        <div>
          <p class="text-3xl uppercase">
            Gestion des coffets Méli Mélos
          </p>
        </div>
        <div class="w-full mt-10 grid grid-cols-1 lg:grid-cols-3">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="mode = 'ajouter'"
          >
            Ajouter un nouveau coffret
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="mode = 'modifier'"
          >
            Modifier un coffret existant
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="mode = 'supprimer'"
          >
            Supprimer un coffret
          </button>
        </div>

        <!-- AJOUTER UN NOUVEAU COFFRET  -->
        <div class="ajouter_coffrets_mm mt-20" v-if="mode === 'ajouter'">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div class="h-full flex items-center pt-20">
              <label class="cursor-pointer">
                <span
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded uppercase"
                  >Téléverser UNE IMAGE</span
                >
                <input
                  type="file"
                  class="hidden"
                  @change="onFileSelected"
                  id="imageUrl1"
                  required
                />
              </label>
            </div>
            <div>
              <img
                :src="coffretToAdd.coffret_image"
                class="image-edit-admin"
                v-if="coffretToAdd.coffret_image"
              />
            </div>

            <div class="mb-3 pt-6">
              <p class="pb-2">Nom du coffret:</p>
              <input
                required
                type="text"
                v-model="coffretToAdd.coffret_name"
                class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600  bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
              />
            </div>
            <div class="mb-3 pt-6">
              <p class="pb-2">Lien du produit:</p>
              <input
                required
                type="text"
                v-model="coffretToAdd.coffret_slug"
                class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600  bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
              />
            </div>
            <div class="mb-3 pt-0">
              <p class="pb-2">Prix:</p>
              <input
                type="number"
                step="0.01"
                v-model="coffretToAdd.coffret_price"
                class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600  bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
              />
            </div>
            <div class="mb-2 pt-0">
              <p class="pb-2">Publier le coffret:</p>
              <select
                required
                v-model="coffretToAdd.coffret_isPublish"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option value="1">Oui</option>
                <option value="0">Non</option>
              </select>
            </div>

            <!-- input de carte -->
            <div class="mb-3 pt-0">
              <p class="pb-3">Carte #1</p>
              <select
                v-model="coffretToAdd.coffret_varId[0]"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <template v-for="typeCarte in store.state.data">
                  <option
                    required
                    :value="format.variation"
                    v-for="(format, index) in typeCarte.product_priceVariations"
                    :key="index"
                    >{{ typeCarte.product_name }}
                    <span v-if="format.format.length"
                      >- {{ format.format }}</span
                    ></option
                  >
                </template>
              </select>
            </div>
            <!-- fin input de carte -->

            <!-- input de carte -->
            <div class="mb-3 pt-0">
              <p class="pb-3">Carte #2</p>
              <select
                v-model="coffretToAdd.coffret_varId[1]"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <template v-for="typeCarte in store.state.data">
                  <option
                    required
                    :value="format.variation"
                    v-for="(format, index) in typeCarte.product_priceVariations"
                    :key="index"
                    >{{ typeCarte.product_name }}
                    <span v-if="format.format.length"
                      >- {{ format.format }}</span
                    ></option
                  >
                </template>
              </select>
            </div>
            <!-- fin input de carte -->
            <!-- input de carte -->
            <div class="mb-3 pt-0">
              <p class="pb-3">Carte #3</p>
              <select
                v-model="coffretToAdd.coffret_varId[2]"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <template v-for="typeCarte in store.state.data">
                  <option
                    required
                    :value="format.variation"
                    v-for="(format, index) in typeCarte.product_priceVariations"
                    :key="index"
                    >{{ typeCarte.product_name }}
                    <span v-if="format.format.length"
                      >- {{ format.format }}</span
                    ></option
                  >
                </template>
              </select>
            </div>
            <!-- fin input de carte -->
            <!-- input de carte -->
            <div class="mb-3 pt-0">
              <p class="pb-3">Carte #4</p>
              <select
                v-model="coffretToAdd.coffret_varId[3]"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <template v-for="typeCarte in store.state.data">
                  <option
                    required
                    :value="format.variation"
                    v-for="(format, index) in typeCarte.product_priceVariations"
                    :key="index"
                    >{{ typeCarte.product_name }}
                    <span v-if="format.format.length"
                      >- {{ format.format }}</span
                    ></option
                  >
                </template>
              </select>
            </div>
            <!-- fin input de carte -->
            <!-- input de carte -->
            <div class="mb-3 pt-0 ">
              <p class="pb-3">Carte #5</p>
              <select
                v-model="coffretToAdd.coffret_varId[4]"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <template v-for="typeCarte in store.state.data">
                  <option
                    required
                    :value="format.variation"
                    v-for="(format, index) in typeCarte.product_priceVariations"
                    :key="index"
                    >{{ typeCarte.product_name }}
                    <span v-if="format.format.length"
                      >- {{ format.format }}</span
                    ></option
                  >
                </template>
              </select>
            </div>
            <!-- fin input de carte -->
            <!-- input de carte -->
            <div class="mb-3 pt-0 ">
              <p class="pb-3">Carte #6</p>
              <select
                v-model="coffretToAdd.coffret_varId[5]"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <template v-for="typeCarte in store.state.data">
                  <option
                    required
                    :value="format.variation"
                    v-for="(format, index) in typeCarte.product_priceVariations"
                    :key="index"
                    >{{ typeCarte.product_name }}
                    <span v-if="format.format.length"
                      >- {{ format.format }}</span
                    ></option
                  >
                </template>
              </select>
            </div>
            <!-- fin input de carte -->
          </div>

          <div
            class="flex justify-center items-center my-3 font-medium py-3 px-2 rounded-md text-red-700 bg-red-100 border border-red-300 "
            v-if="error"
          >
            <div class="text-xl font-normal  max-w-full flex-initial">
              {{ errorMessage }}
            </div>
            <div class="flex flex-auto flex-row-reverse">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x cursor-pointer hover:text-red-400 rounded-full w-5 h-5 ml-2"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
            </div>
          </div>

          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mr-10"
            @click="uploadNewCoffret()"
          >
            AJOUTER LE COFFRET
          </button>
        </div>

        <div class="" v-if="mode === 'modifier'">
          <div class="mb-4 mt-14">
            <div>
              <select
                v-model="coffretToEdit"
                @change="showCoffret()"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option disabled value="null"
                  >Sélectionnez un coffret à modifier</option
                >
                <option
                  v-for="(coffret, index) in store.state.coffretsMeliMelo"
                  :key="index"
                  :value="index"
                  >{{ coffret.coffret_name }}</option
                >
              </select>
            </div>
          </div>
          <div
            v-if="currentCoffret !== null && store.state.data !== null"
            class="grid grid-cols-1 lg:grid-cols-2 gap-6"
          >
            <div class="h-full flex items-center justify-center">
              <label class="cursor-pointer">
                <span
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded uppercase"
                  >Modifier l'image du coffret</span
                >
                <input
                  type="file"
                  class="hidden"
                  @change="onFileSelected"
                  id="imageUrl1"
                  required
                />
              </label>
            </div>
            <div>
              <img
                :src="currentCoffret.coffret_image"
                class="image-edit-admin"
                v-if="currentCoffret.coffret_image"
              />
            </div>

            <div class="mb-3">
              <p class="pb-2">Nom du produit:</p>
              <input
                required
                type="text"
                v-model="currentCoffret.coffret_name"
                class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600  bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
              />
            </div>
            <div class="mb-3">
              <p class="pb-2">Lien du produit:</p>
              <input
                required
                type="text"
                v-model="currentCoffret.coffret_slug"
                class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600  bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
              />
            </div>
            <div class="mb-3">
              <p class="pb-2">Prix:</p>
              <input
                type="number"
                step="0.01"
                v-model="currentCoffret.coffret_price"
                class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600  bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
              />
            </div>
            <div class="mb-2">
              <p class="pb-2">Publier le coffret:</p>
              <select
                required
                v-model="currentCoffret.coffret_isPublish"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option value="1">Oui</option>
                <option value="0">Non</option>
              </select>
            </div>
            <!-- input de carte -->
            <div class="mb-3">
              <p class="pb-3">Carte #1</p>
              <select
                v-model="currentCoffret.coffret_varId[0]"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <template v-for="typeCarte in store.state.data">
                  <option
                    required
                    :value="format.variation"
                    v-for="(format, index) in typeCarte.product_priceVariations"
                    :key="index"
                    >{{ typeCarte.product_name }}
                    <span v-if="format.format.length"
                      >- {{ format.format }}</span
                    ></option
                  >
                </template>
              </select>
            </div>
            <!-- fin input de carte -->

            <!-- input de carte -->
            <div class="mb-3">
              <p class="pb-3">Carte #2</p>
              <select
                v-model="currentCoffret.coffret_varId[1]"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <template v-for="typeCarte in store.state.data">
                  <option
                    required
                    :value="format.variation"
                    v-for="(format, index) in typeCarte.product_priceVariations"
                    :key="index"
                    >{{ typeCarte.product_name }}
                    <span v-if="format.format.length"
                      >- {{ format.format }}</span
                    ></option
                  >
                </template>
              </select>
            </div>
            <!-- fin input de carte -->
            <!-- input de carte -->
            <div class="mb-3">
              <p class="pb-3">Carte #3</p>
              <select
                v-model="currentCoffret.coffret_varId[2]"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <template v-for="typeCarte in store.state.data">
                  <option
                    required
                    :value="format.variation"
                    v-for="(format, index) in typeCarte.product_priceVariations"
                    :key="index"
                    >{{ typeCarte.product_name }}
                    <span v-if="format.format.length"
                      >- {{ format.format }}</span
                    ></option
                  >
                </template>
              </select>
            </div>
            <!-- fin input de carte -->
            <!-- input de carte -->
            <div class="mb-3">
              <p class="pb-3">Carte #4</p>
              <select
                v-model="currentCoffret.coffret_varId[3]"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <template v-for="typeCarte in store.state.data">
                  <option
                    required
                    :value="format.variation"
                    v-for="(format, index) in typeCarte.product_priceVariations"
                    :key="index"
                    >{{ typeCarte.product_name }}
                    <span v-if="format.format.length"
                      >- {{ format.format }}</span
                    ></option
                  >
                </template>
              </select>
            </div>
            <!-- fin input de carte -->
            <!-- input de carte -->
            <div class="mb-3">
              <p class="pb-3">Carte #5</p>
              <select
                v-model="currentCoffret.coffret_varId[4]"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <template v-for="typeCarte in store.state.data">
                  <option
                    required
                    :value="format.variation"
                    v-for="(format, index) in typeCarte.product_priceVariations"
                    :key="index"
                    >{{ typeCarte.product_name }}
                    <span v-if="format.format.length"
                      >- {{ format.format }}</span
                    ></option
                  >
                </template>
              </select>
            </div>
            <!-- fin input de carte -->
            <!-- input de carte -->
            <div class="mb-3">
              <p class="pb-3">Carte #6</p>
              <select
                v-model="currentCoffret.coffret_varId[5]"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <template v-for="typeCarte in store.state.data">
                  <option
                    required
                    :value="format.variation"
                    v-for="(format, index) in typeCarte.product_priceVariations"
                    :key="index"
                    >{{ typeCarte.product_name }}
                    <span v-if="format.format.length"
                      >- {{ format.format }}</span
                    ></option
                  >
                </template>
              </select>
            </div>
            <!-- fin input de carte -->
          </div>

          <div
            class="flex justify-center items-center my-3 font-medium py-3 px-2 rounded-md text-red-700 bg-red-100 border border-red-300 "
            v-if="error"
          >
            <div class="text-xl font-normal  max-w-full flex-initial">
              {{ errorMessage }}
            </div>
            <div class="flex flex-auto flex-row-reverse">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x cursor-pointer hover:text-red-400 rounded-full w-5 h-5 ml-2"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
            </div>
          </div>

          <div class="w-full flex justify-center py-10">
            <div
              v-if="successAjout || successDelete || success"
              class="w-11/12 md:w-3/5 my-2 rounded-r-md px-6 border-l-4 -ml-4 border-gray-100 bg-green-500 cursor-pointer flex"
              @click="
                (successAjout = false),
                  (success = false),
                  (successDelete = false)
              "
            >
              <div class="flex items-center py-4">
                <div class="ml-5">
                  <h1 class="text-lg font-bold text-gray-200">Félicitation!</h1>
                  <p class="text-gray-300 my-0">
                    Opération effectuée avec succès
                  </p>
                </div>
              </div>
            </div>
          </div>

          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded mr-10"
            @click="saveEdit()"
          >
            ENREGISTRER LES MODIFICATIONS
          </button>
        </div>

        <div
          class="ajouter_coffrets_mm"
          v-if="mode === 'supprimer' && store.state.coffretsMeliMelo"
        >
          <div class="pt-10">
            <input
              v-model="selectedForDelete"
              @change="checkDelete()"
              placeholder="Sélectionnez un coffret a supprimer"
              type="text"
              list="coffrets"
              class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            />
            <datalist id="coffrets">
              <option
                v-for="(coffret, index) in store.state.coffretsMeliMelo"
                :key="index"
                >{{ coffret.coffret_name }}</option
              >
            </datalist>
          </div>

          <div
            class="flex justify-center items-center my-3 font-medium py-3 px-2 rounded-md text-red-700 bg-red-100 border border-red-300 "
            v-if="errorDelete"
          >
            <div class="text-xl font-normal  max-w-full flex-initial">
              Une erreur est survenue pendant la suppression du coffret.
            </div>
            <div class="flex flex-auto flex-row-reverse">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x cursor-pointer hover:text-red-400 rounded-full w-5 h-5 ml-2"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </div>
            </div>
          </div>

          <div v-if="indexToDelete !== null" class="grid grid-cols-5">
            <div class="mt-10">
              <img
                :src="store.state.coffretsMeliMelo[indexToDelete].coffret_image"
              />
            </div>
            <div class="pl-10 mt-10">
              <p>
                Coffret ID :
                {{ store.state.coffretsMeliMelo[indexToDelete].coffret_id }}
              </p>
              <br />
              Prix :
              {{
                store.state.coffretsMeliMelo[
                  indexToDelete
                ].coffret_price.toFixed(2)
              }}
              $
            </div>
            <div class="col-span-3 mt-10">
              Nom du coffret :
              {{ store.state.coffretsMeliMelo[indexToDelete].coffret_name }}
              <br />
              <ul class="pt-3">
                <ul
                  v-for="(variation, index) in store.state.coffretsMeliMelo[
                    indexToDelete
                  ].coffret_varId"
                  :key="index"
                >
                  <li
                    class="pb-1"
                    v-if="store.state.data[getCarteInfos(variation)[0]]"
                  >
                    Carte #{{ index + 1 }} -
                    {{
                      store.state.data[getCarteInfos(variation)[0]].product_name
                    }}
                    -
                    {{
                      store.state.data[getCarteInfos(variation)[0]]
                        .product_priceVariations[getCarteInfos(variation)[1]]
                        .format
                    }}
                    - #{{ variation }}
                  </li>
                </ul>
              </ul>
            </div>

            <div class="w-full flex justify-center col-span-5">
              <button
                class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 border border-red-700 rounded"
                @click="
                  deleteCoffret(
                    store.state.coffretsMeliMelo[indexToDelete].coffret_name,
                    store.state.coffretsMeliMelo[indexToDelete].coffret_id
                  )
                "
              >
                Supprimer un coffret
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { ref, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { ref as refDb, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
  name: "CoffretsMeliMeloAdmin",
  components: { AdminSideBar },
  setup() {
    const firebaseConfig = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: "extranet-fb58d.firebaseapp.com",
      projectId: "extranet-fb58d",
      storageBucket: "extranet-fb58d.appspot.com",
      messagingSenderId: "54012934511",
      appId: "1:54012934511:web:3fa26c12add158e1a2109a",
      measurementId: "G-Z5ELBPXFRY",
    };
    const firebaseApp = initializeApp(firebaseConfig);
    const storage = getStorage(firebaseApp);
    const store = inject("store");
    const router = useRouter();
    const mode = ref(null);
    const currentCoffret = ref(null);
    const coffretToAdd = ref({
      coffret_name: "",
      coffret_price: "",
      coffret_slug: "",
      coffret_isPublish: "Oui",
      coffret_varId: ["", "", "", "", "", ""],
    });
    const detailsToAdd = ref(null);
    const newCoffretImage = ref(null);
    const currentVarId = ref({});
    const coffretToEdit = ref(null);
    const newImageUrl = ref(null);
    const errorFile = ref(false);
    const newUploadedImage = ref(null);
    const varIdInclude = ref({});
    const error = ref(false);
    const errorDelete = ref(false);
    const success = ref(false);
    const successAjout = ref(null);
    const successDelete = ref(false);
    const errorMessage = ref("");
    const errorAjout = ref("");
    const errorAjoutMessage = ref("");
    const selectedForDelete = ref("");
    const indexToDelete = ref(null);
    const imageUrlName = ref(null);
    const selectedFile = ref(null);

    onMounted(() => {
      if (store.state.data === null) {
        store.methods.fetchAllProducts();
      }
      if (store.state.coffretsMeliMelo === null) {
        store.methods.fetchCoffretMeliMelo();
      }
    });

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    function uploadNewCoffret() {
      successAjout.value = false;
      errorAjout.value = false;

      if (
        coffretToAdd.value.coffret_isPublish === "" ||
        !coffretToAdd.value.coffret_isPublish ||
        coffretToAdd.value.coffret_price === "" ||
        !coffretToAdd.value.coffret_price ||
        coffretToAdd.value.coffret_slug === "" ||
        !coffretToAdd.value.coffret_slug ||
        coffretToAdd.value.coffret_name === "" ||
        !coffretToAdd.value.coffret_name ||
        coffretToAdd.value.coffret_varId[0] === "" ||
        !coffretToAdd.value.coffret_varId[0] ||
        coffretToAdd.value.coffret_varId[1] === "" ||
        !coffretToAdd.value.coffret_varId[1] ||
        coffretToAdd.value.coffret_varId[2] === "" ||
        !coffretToAdd.value.coffret_varId[2] ||
        coffretToAdd.value.coffret_varId[3] === "" ||
        !coffretToAdd.value.coffret_varId[3] ||
        coffretToAdd.value.coffret_varId[4] === "" ||
        !coffretToAdd.value.coffret_varId[5] ||
        !coffretToAdd.value.coffret_image
      ) {
        error.value = true;
        errorMessage.value =
          "Veuillez vérifier que tous les champs du formulaires sont bien remplis.";
        return;
      }

      coffretToAdd.value.coffret_price = parseFloat(
        coffretToAdd.value.coffret_price
      ).toFixed(2);
      coffretToAdd.value.coffret_isPublish = parseInt(
        coffretToAdd.value.coffret_isPublish
      );
      if (coffretToAdd.value.coffret_isPublish === 1) {
        coffretToAdd.value.coffret_isPublish = true;
      } else {
        coffretToAdd.value.coffret_isPublish = false;
      }

      let cardName = [];
      let cardFormat = [];

      coffretToAdd.value.coffret_varId.forEach((card) => {
        store.state.data.forEach((product, indexProduct) => {
          product.product_priceVariations.forEach(
            (variation, indexVariation) => {
              if (variation.variation === card) {
                cardName.push(store.state.data[indexProduct].product_name);
                if (
                  store.state.data[indexProduct].product_priceVariations[
                    indexVariation
                  ].format.length >= 2
                ) {
                  cardFormat.push(
                    store.state.data[indexProduct].product_priceVariations[
                      indexVariation
                    ].format
                  );
                } else {
                  cardFormat.push("Non disponible");
                }
              }
            }
          );
        });
      });

      coffretToAdd.value.cardFormat = cardFormat;
      coffretToAdd.value.cardName = cardName;

      fetch(store.state.addCoffretMeliMelo, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(coffretToAdd.value),
        credentials: "include",
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success === 1) {
            router.go();
          } else {
            error.value = true;
          }
        })
        .catch((err) => {
          console.log(err);
          error.value = true;
        });
    }

    function maxDecimal(self, count) {
      if (self.indexOf(".") == -1) {
        return;
      }
      if (self.length - self.indexOf(".") > count) {
        self = parseFloat(self).toFixed(count);
      }
    }

    function onFileSelected(event) {
      // VALIDATION DU FORMAT ET DE LA GRANDEUR

      const allowedExtensions = ["jpg", "png", "gif"],
        sizeLimit = 5000000;
      const fileExtension = event.target.files[0].name.split(".").pop();
      const fileSize = event.target.files[0].fileSize;

      if (!allowedExtensions.includes(fileExtension)) {
        alert("Ce type de fichier n'est pas accepté !");
        return;
      } else if (fileSize > sizeLimit) {
        alert("Le fichier est trop volumineux.");
        return;
      }

      selectedFile.value = event.target.files[0];

      imageUrlName.value = [
        event.target.files[0].name.slice(
          0,
          event.target.files[0].name.length - 4
        ) +
          "_" +
          randomString(8),
      ].join("");
      imageUrlName.value += `.${fileExtension}`;
      onUploadImage(imageUrlName.value);
    }

    const onUploadImage = (fileName) => {
      const storageRef = refDb(storage, `/coffrets/${fileName}`);
      uploadBytes(storageRef, selectedFile.value).then((snapshot) => {
        console.log(
          "Uploaded",
          snapshot,
          getDownloadURL(storageRef).then((url) => {
            if (mode.value === "modifier") {
              currentCoffret.value.coffret_image = url;
            } else if (mode.value === "ajouter") {
              coffretToAdd.value.coffret_image = url;
            }
          })
        );
      });
    };

    const randomString = (length) => {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    };

    function showCoffret() {
      currentCoffret.value = store.state.coffretsMeliMelo[coffretToEdit.value];
    }

    function checkDelete() {
      let countCoffret = 0;
      store.state.coffretsMeliMelo.forEach((coffret, index) => {
        if (coffret.coffret_name.includes(selectedForDelete.value)) {
          indexToDelete.value = index;
          countCoffret++;
        }
      });
      if (countCoffret === 0) {
        // SI AUCUNE RESULTATS CORRESPONDANT RETOURNER NULL
        indexToDelete.value = null;
      }
    }

    function getCarteInfos(variationInput) {
      let indexVariation = null;
      let indexCarte = null;

      store.state.data.forEach((item, indexCar) => {
        item.product_priceVariations.forEach((variation, indexVar) => {
          if (variation.variation === variationInput) {
            indexCarte = indexCar;
            indexVariation = indexVar;
          }
        });
      });

      return [indexCarte, indexVariation];
    }

    function deleteCoffret(coffretName, coffretId) {
      let proceed = confirm(
        `Voulez-vous vraiment effacer le coffret ${coffretName} ?`
      );
      if (proceed) {
        fetch(`${store.state.deleteCoffretBySlugUrl}/${coffretId}`, {
          credentials: "include",
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success === 1) {
              successDelete.value = true;
              indexToDelete.value = null;
              selectedForDelete.value = "";
              store.methods.fetchCoffretMeliMelo(); // MET A JOUR LES COFFETS
            } else {
              console.log("no success");
              error.value = true;
            }
          })
          .catch((err) => {
            console.log(err);
            error.value = true;
          });
      }
    }

    function saveEdit() {
      success.value = false;
      error.value = false;

      currentCoffret.value.coffret_isPublish = parseInt(
        currentCoffret.value.coffret_isPublish
      );

      if (
        currentCoffret.value.coffret_isPublish === "" ||
        currentCoffret.value.coffret_price === "" ||
        !currentCoffret.value.coffret_price ||
        currentCoffret.value.coffret_slug === "" ||
        !currentCoffret.value.coffret_slug ||
        currentCoffret.value.coffret_name === "" ||
        !currentCoffret.value.coffret_name ||
        currentCoffret.value.coffret_varId[0] === "" ||
        !currentCoffret.value.coffret_varId[0] ||
        currentCoffret.value.coffret_varId[1] === "" ||
        !currentCoffret.value.coffret_varId[1] ||
        currentCoffret.value.coffret_varId[2] === "" ||
        !currentCoffret.value.coffret_varId[2] ||
        currentCoffret.value.coffret_varId[3] === "" ||
        !currentCoffret.value.coffret_varId[3] ||
        currentCoffret.value.coffret_varId[4] === "" ||
        !currentCoffret.value.coffret_varId[5]
      ) {
        error.value = true;
        errorMessage.value =
          "Veuillez vérifier que tous les champs du formulaires sont bien remplis.";
        return;
      }

      let cardName = [];
      let cardFormat = [];

      currentCoffret.value.coffret_varId.forEach((card) => {
        store.state.data.forEach((product, indexProduct) => {
          product.product_priceVariations.forEach(
            (variation, indexVariation) => {
              if (variation.variation === card) {
                cardName.push(store.state.data[indexProduct].product_name);
                if (
                  store.state.data[indexProduct].product_priceVariations[
                    indexVariation
                  ].format.length >= 2
                ) {
                  cardFormat.push(
                    store.state.data[indexProduct].product_priceVariations[
                      indexVariation
                    ].format
                  );
                } else {
                  cardFormat.push("Non disponible");
                }
              }
            }
          );
        });
      });

      currentCoffret.value.cardFormat = cardFormat;
      currentCoffret.value.cardName = cardName;

      fetch(
        `${store.state.updateCoffretUrl}/${currentCoffret.value.coffret_id}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(currentCoffret.value),
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.success === 1) {
            success.value = true;
          } else {
            error.value = true;
          }
        })
        .catch((err) => {
          console.log(err);
          error.value = true;
        });
    }

    function fullProductListWithVariations() {
      let productList = [];
      store.state.data.forEach((item) => {
        item.product_priceVariations.forEach((variation) => {
          productList.push({
            product_name: item.product_name,
            product_isVariable: item.product_isVariable,
            product_id: item.product_id,
            variation_id: variation.variation,
            product_format: variation.format,
          });
        });
      });
      return productList;
    }

    return {
      store,
      currentCoffret,
      newImageUrl,
      varIdInclude,
      error,
      success,
      checkDelete,
      indexToDelete,
      coffretToAdd,
      errorAjout,
      detailsToAdd,
      onFileSelected,
      onUploadImage,
      mode,
      showCoffret,
      errorFile,
      newUploadedImage,
      errorMessage,
      selectedForDelete,
      errorDelete,
      newCoffretImage,
      toggleMenu,
      fullProductListWithVariations,
      randomString,
      coffretToEdit,
      currentVarId,
      saveEdit,
      getCarteInfos,
      deleteCoffret,
      successDelete,
      successAjout,
      uploadNewCoffret,
      errorAjoutMessage,
      maxDecimal,
      imageUrlName,
      selectedFile,
    };
  },
};
</script>
<style lang="scss">
.image-edit-admin {
  max-width: 200px;
  padding: 20px;
}
</style>
