<template>
  <main>
    <TheNavbar />

    <div
      class="cartes grid grid-cols-1 lg:grid-cols-4 px-4 md:px-10 pt-16 min-height-top"
    >
      <div class="col-span-3 row-start-1 px-5 margin-botto-coffret">
        <h1 class="titleSection uppercase pb-2">Coffrets de cartes</h1>
        <div class="hidden md:block">
          Types de coffrets :
          <span class="text-lg" v-if="store.state.typeCoffrets === 'regulier'"
            >Cartes régulieres</span
          >
          <span
            class="text-lg"
            v-else-if="store.state.typeCoffrets === 'melimelo'"
            >Méli-mélo</span
          >
          <span
            class="text-lg"
            v-else-if="store.state.typeCoffrets === 'touchedore'"
            >Une touche de doré</span
          >
          <span class="text-lg" v-else>Cartes ensemencées</span>
        </div>
        <SelectCoffretType class="block md:hidden mt-4" />
      </div>

      <ShowCoffrets
        class="bloc-show-products  row-start-2 md:row-start-auto col-span-1 lg:col-span-3  mt-20"
      />
      <div
        class="col-span-1 row-start-3 md:row-start-auto mx-auto pt-20 lg:pt-0 lg:mx-0 md:min-h-full"
      >
        <!-- <Search v-if="store.state.typeCoffrets !== 'melimelo'" />
              <SearchCoffret v-if="store.state.typeCoffrets === 'melimelo'"/> -->
        <SelectStock class="mb-5" />
        <SelectCoffretType class="hidden md:block" />
        <SelectCategories v-if="store.state.typeCoffrets === 'autres'" />
        <SelectTagCoffret v-if="store.state.typeCoffrets !== 'melimelo'" />
      </div>
    </div>

    <TheFooter />
  </main>
</template>

<script>
import TheNavbar from "../../components/TheNavbar";
import TheFooter from "../../components/TheFooter.vue";
import ShowCoffrets from "../../components/ShowCoffrets.vue";
// import SelectCoffretType from '../../components/SelectCoffretsType.vue';
import SelectCategories from "../../components/SelectCategories";
// import Search from '../../components/Search';
import SelectStock from "../../components/SelectStock.vue";
import SelectTagCoffret from "../../components/SelectTagCoffret";
// import SearchCoffret from '../../components/SearchCoffret';

import { inject, onMounted } from "vue";

export default {
  name: "Coffrets",
  components: {
    TheNavbar,
    TheFooter,
    ShowCoffrets,
    SelectCategories,
    SelectTagCoffret,
    SelectStock,
  },
  setup() {
    const store = inject("store");

    onMounted(() => {
      window.scrollTo(0, 0);
      store.state.currentTag = 446; // CAT DE CARTE DE SOUHAITS
      store.methods.tagsEnsList();
      store.methods.tagsRegList();
      store.methods.tagsToucheDoreList();

      store.state.currentFilterSearch = "none"; // RESET FILTRE RECHERCHE
      // RESET CATEGORIES_AFFICHES
      store.state.currentFilterCategories = "all";
      // CAT DE CARTE DE SOUHAITS

      // VERIFIE SI LE CLIENT A DEJA UN CART

      // SI CAT NE SONT PAS CRES
      if (!store.state.categories) {
        if (!store.state.data) {
          (async () => {
            await store.methods.fetchAllProducts();
            store.methods.checkIfCart();

            (async () => {
              await store.methods.showCategory();
              store.methods.calculTaxes();
            })();
          })();
        } else {
          // SI DATA PAS DOWNLOADÉ
          (async () => {
            await store.methods.showCategory();
            store.methods.checkIfCart();
            store.methods.calculTaxes();
          })();
        }
      } else {
        store.methods.showCategory();
        store.methods.checkIfCart();
      }

      if (!store.state.presentoirs) {
        store.methods.fetchPresentoirs();
      }
      if (!store.state.coffretsMeliMelo) {
        store.methods.fetchCoffretMeliMelo();
      }
    });

    return {
      store,
    };
  },
};
</script>
<style lang="scss">
.cartes {
  max-width: 1445px;
  margin: auto;

  .margin-botto-coffret {
    margin-bottom: 67px;
  }

  #modeBoutique,
  #modeRapide {
    cursor: pointer;
  }
}
</style>
