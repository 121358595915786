<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8">
        <div class="col-pan-3">
          <div class="text-3xl uppercase">
            DÉSACTIVER / RÉACTIVER UN CLIENT
          </div>
        </div>
        <div class="w-full mt-10 grid grid-cols-1 lg:grid-cols-3">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="mode = 'reactiver'"
          >
            Réactiver un client
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded lg:mr-10 mt-2 lg:mt-0 col-span-3 lg:col-span-1 uppercase"
            @click="mode = 'desactiver'"
          >
            Désactiver un client
          </button>
        </div>

        <div v-if="mode === 'desactiver'">
          <p class="-mb-14 mt-10">
            Veuillez sélectionner un compte client à désactiver
          </p>
          <div class="relative w-6/12 mt-20" v-if="clientsListToDesactivate">
            <select
              v-model="currentClient"
              class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
            >
              <option disabled selected value="null"
                >Veuillez sélectionner un client...</option
              >
              <option
                v-for="(client, index) in clientsListToDesactivate"
                :key="index"
                :value="client.user_id"
                >{{ client.firstName }} {{ client.lastName }} (
                {{ client.email }} )</option
              >
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>

          <p v-if="desactiverSuccess" class="text-green-500 my-4">
            Le client / usager à été désactivé avec succès.
          </p>
          <p v-if="desactiverError" class="text-red-500 my-4">
            Une erreur est survenue.
          </p>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-10"
            @click="handleActivation('desactiver')"
          >
            Désactiver le client
          </button>
        </div>

        <div v-if="mode === 'reactiver'">
          <p class="text mt-10">Sélectionnez un compte client à réactiver</p>
          <div class="relative w-6/12 mt-6" v-if="clientsListToActivate">
            <select
              v-model="currentClient"
              class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
            >
              <option disabled selected value="null"
                >Veuillez sélectionner un client...</option
              >
              <option
                v-for="(client, index) in clientsListToActivate"
                :key="index"
                :value="client.user_id"
                >{{ client.firstName }} {{ client.lastName }} (
                {{ client.email }} )</option
              >
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>

          <p v-if="activerSuccess" class="text-green-500 my-4">
            Le client / usager à été réactivé avec succès.
          </p>
          <p v-if="activerError" class="text-red-500 my-4">
            Une erreur est survenue.
          </p>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-10"
            @click="handleActivation('activer')"
          >
            Réactiver le client
          </button>
        </div>
      </div>

      <div></div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { inject, onMounted, ref } from "vue";

export default {
  name: "DesactiverClient",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");

    const mode = ref(null);

    const error = ref(true);
    const currentClient = ref(null);

    const clientsListToActivate = ref(null);
    const clientsListToDesactivate = ref(null);
    const selectClient = ref(null);
    const activerSuccess = ref(false);
    const activerError = ref(false);
    const desactiverSuccess = ref(false);
    const desactiverError = ref(false);
    const loadingUsers = ref(false);

    onMounted(() => {
      getAllUsers();
    });

    async function getAllUsers() {
      loadingUsers.value = true;

      try {
        const res = await fetch(`${store.state.getAllUsersUrl}`, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });

        if (!res.ok) {
          console.log("un problème est survenu.");
          loadingUsers.value = false;
          return;
        } else {
          clientsListToActivate.value = [];
          clientsListToDesactivate.value = [];
          const data = await res.json();

          data.data.forEach((client) => {
            if (!client.isDesactivated) {
              clientsListToDesactivate.value.push(client);
            } else {
              clientsListToActivate.value.push(client);
            }
          });

          loadingUsers.value = false;
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function handleActivation(action) {
      let actionMot;

      if (action === "activer") {
        actionMot = "réactiver";
      } else {
        actionMot = "désactiver";
      }

      let proceed = confirm(`Voulez-vous vraiment ${actionMot} ce client ?`);

      if (proceed) {
        try {
          activerSuccess.value = false;
          activerError.value = false;
          desactiverSuccess.value = false;
          desactiverError.value = false;

          const data = {
            action: action,
            user_id: currentClient.value,
          };

          const res = await fetch(`${store.state.editUserUrl}`, {
            method: "post",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(data),
            credentials: "include",
          });

          if (!res.ok) {
            console.log("un problème est survenu.");
            if (action == "activer") {
              desactiverError.value = true;
            } else {
              activerError.value = true;
            }
          } else {
            const data = await res.json();

            if (data.success === 1) {
              if (action === "activer") {
                activerSuccess.value = true;
                currentClient.value = null;
                getAllUsers();
              } else {
                desactiverSuccess.value = true;
                currentClient.value = null;
                getAllUsers();
              }
            } else {
              if (action === "activer") {
                activerError.value = true;
              } else {
                desactiverError.value = true;
              }
            }
          }
        } catch (err) {
          console.log(err);
          desactiverError.value = true;
          activerError.value = true;
        }
      } else {
        return;
      }
    }

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    return {
      store,
      toggleMenu,
      desactiverSuccess,
      currentClient,
      handleActivation,
      activerSuccess,
      clientsListToDesactivate,
      clientsListToActivate,
      getAllUsers,

      mode,
      error,
      selectClient,
      loadingUsers,
    };
  },
};
</script>
<style scoped lang="scss">
.enregistrementClient {
  display: block;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: rgba(255, 255, 255, 1);
    transition: background-color 5000s ease-in-out 0s;
  }
  input::-webkit-input-placeholder,
  input::-ms-input-placeholder,
  input:-ms-input-placeholder {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }

  input {
    color: #7a7a7a;
    border: 1px solid #dedede;
    outline-width: 0;
  }

  button:hover {
    background-color: #bba267;
  }
}

.wrapperInput {
  max-width: 400px;
  min-width: 250px;
}

#checkifShipping {
  transform: translateY(-8px);
  cursor: pointer;
}

.selectClient {
  margin-top: -20px;

  .check {
    cursor: pointer;
    position: relative;
    margin: auto;
    width: 18px;
    height: 18px;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
    p {
      width: 400px;
      transform: translate(28px, 21px);
    }
  }

  .check svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #c8ccd4;
    stroke-width: 1.5;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
  }
  .check svg path {
    stroke-dasharray: 60;
    stroke-dashoffset: 0;
  }
  .check svg polyline {
    stroke-dasharray: 22;
    stroke-dashoffset: 66;
  }
  .check:hover:before {
    opacity: 1;
  }
  .check:hover svg {
    stroke: #222;
  }
  #cbx:checked + .check svg {
    stroke: #222;
  }
  #cbx:checked + .check svg path {
    stroke-dashoffset: 60;
    transition: all 0.3s linear;
  }
  #cbx:checked + .check svg polyline {
    stroke-dashoffset: 42;
    transition: all 0.2s linear;
    transition-delay: 0.15s;
  }
}
</style>
