<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="/admin" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10 homecommande">
      <!-- DEBUT DU MODAL -->

      <div
        class="hidden outline-none focus:outline-none justify-center items-center modal-commande"
        id="modal-id"
      >
        <div
          class="relative my-6 mx-auto max-w-6xl w-11/12"
          v-if="selectForUpdate !== null"
        >
          <!--content-->
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            <!--header-->
            <div
              class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
            >
              <div class="flex flex-col md:flex-row md:justify-between w-10/12">
                <h3 class="md:text-2xl font-semibold">
                  Commande #{{
                    store.state.commandesList[selectForUpdate].order_orderNum
                  }}
                </h3>
                <h3 class="md:text-2xl font-semibold">
                  {{
                    store.state.commandesList[selectForUpdate]
                      .order_customer_compagnyName
                  }}
                </h3>
                <h3 class="md:text-2xl font-semibold">
                  {{
                    convertDate(
                      store.state.commandesList[selectForUpdate]
                        .order_dateCreated
                    )
                  }}
                </h3>
              </div>

              <button
                class="p-1 ml-auto border-0 text-black opacity-6 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                @click="toggleModal('modal-id')"
              >
                <span
                  class="text-black h-6 w-6 text-2xl block outline-none focus:outline-none"
                >
                  x
                </span>
              </button>
            </div>
            <!--body-->
            <div class="relative p-6" v-if="!store.state.loadingCommande">
              <div class="container-table">
                <table class="commandemodal">
                  <thead>
                    <tr class="bg-white text-black text-xs">
                      <th colspan="2">
                        <div>
                          <div class="mt-4 mb-4 text-xs text-gray-300 h-full">
                            ADRESSE DE FACTURATION
                          </div>
                          <div>
                            {{
                              store.state.commandesList[selectForUpdate]
                                .order_customer_compagnyName
                            }}
                          </div>
                          <div>
                            {{
                              store.state.commandesList[selectForUpdate]
                                .order_customer_billingAdressNumber
                            }},
                            {{
                              store.state.commandesList[selectForUpdate]
                                .order_customer_billingStreet
                            }}
                          </div>
                          <div>
                            {{
                              store.state.commandesList[selectForUpdate]
                                .order_customer_billingCity
                            }}
                          </div>
                          <div>
                            {{
                              store.state.commandesList[selectForUpdate]
                                .order_customer_billingPostalCode
                            }}
                          </div>
                          <div>
                            {{
                              store.state.commandesList[selectForUpdate]
                                .order_customer_billingProvince
                            }},
                            {{
                              store.state.commandesList[selectForUpdate]
                                .order_customer_billingPays
                            }}
                          </div>
                          <div class="lowercase pt-4 text-sm mb-4">
                            {{
                              store.state.commandesList[selectForUpdate]
                                .order_customer_email
                            }}
                            -
                            {{
                              formatPhoneNumber(
                                store.state.commandesList[selectForUpdate]
                                  .order_customer_phone
                              )
                            }}
                          </div>
                        </div>
                      </th>
                      <th colspan="2" class="ralative">
                        <div class="absolute" id="blocklivraisoncommande">
                          <div class="mb-4 text-xs text-gray-300 text-left">
                            ADRESSE DE LIVRAISON
                          </div>

                          <div
                            v-if="
                              store.state.commandesList[selectForUpdate]
                                .order_customer_shippingIsBilling
                            "
                            class="text-left"
                          >
                            ( MEME )
                          </div>
                          <div v-else class="flex justify-start">
                            <div class="text-left">
                              <div>
                                {{
                                  store.state.commandesList[selectForUpdate]
                                    .order_customer_shippingAdressNumber
                                }},
                                {{
                                  store.state.commandesList[selectForUpdate]
                                    .order_customer_shippingStreet
                                }}
                              </div>
                              <div>
                                {{
                                  store.state.commandesList[selectForUpdate]
                                    .order_customer_shippingCity
                                }}
                              </div>
                              <div>
                                {{
                                  store.state.commandesList[selectForUpdate]
                                    .order_customer_shippingPostalCode
                                }}
                              </div>
                              <div>
                                {{
                                  store.state.commandesList[selectForUpdate]
                                    .order_customer_shippingProvince
                                }}
                              </div>
                              <div>
                                {{
                                  store.state.commandesList[selectForUpdate]
                                    .order_customer_shippingPays
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </th>
                    </tr>

                    <tr>
                      <th scope="col" colspan="2">Produits</th>
                      <th scope="col">Quantité</th>
                      <th scope="col">Prix</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in store.state.currentCommande"
                      :key="index"
                    >
                      <td colspan="2">
                        <strong class="product-title"
                          >{{ item.cart_productName
                          }}<span
                            class="text-offset font-normal ml-10"
                            v-if="
                              item.cart_format && !item.cart_coffretVarName1
                            "
                            >- {{ item.cart_format }}</span
                          ></strong
                        >

                        <ul class="pt-2" v-if="item.cart_coffretVarName1">
                          <li class="pl-4">
                            - {{ item.cart_coffretVarName1 }}
                          </li>
                          <li class="pl-4">
                            - {{ item.cart_coffretVarName2 }}
                          </li>
                          <li class="pl-4">
                            - {{ item.cart_coffretVarName3 }}
                          </li>
                          <li class="pl-4">
                            - {{ item.cart_coffretVarName4 }}
                          </li>
                          <li class="pl-4">
                            - {{ item.cart_coffretVarName5 }}
                          </li>
                          <li class="pl-4">
                            - {{ item.cart_coffretVarName6 }}
                          </li>
                        </ul>
                      </td>

                      <td class="item-qty">{{ item.cart_productQuantity }}</td>
                      <td class="item-price">
                        {{
                          (
                            item.cart_productPrice * item.cart_productQuantity
                          ).toFixed(2)
                        }}$
                        <span
                          v-if="item.cart_productQuantity > 1"
                          class="text-offset item-multiple"
                          >( {{ item.cart_productPrice.toFixed(2) }}$ &#215;
                          {{ item.cart_productQuantity }} )</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td colspan="7"></td>
                    </tr>
                  </tbody>

                  <tfoot>
                    <tr class="text-offset">
                      <td></td>

                      <td colspan="1"></td>
                      <td colspan="1">Sous-total</td>
                      <td>
                        {{
                          store.state.commandesList[
                            selectForUpdate
                          ].order_sousTotal.toFixed(2)
                        }}$
                      </td>
                    </tr>
                    <tr class="text-offset">
                      <td colspan="1">Numéro de suivi de l'expédition</td>
                      <td
                        colspan="1"
                        v-if="
                          store.state.commandesList[selectForUpdate]
                            .order_shippingNumber1
                        "
                      >
                        {{
                          store.state.commandesList[selectForUpdate]
                            .order_shippingNumber1
                        }}
                      </td>
                      <td colspan="1" v-else>
                        <input
                          v-model="suiviNum1"
                          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          type="text"
                          placeholder="# de suivi"
                        />
                      </td>
                      <td colspan="1">TPS</td>
                      <td>
                        {{
                          store.state.commandesList[
                            selectForUpdate
                          ].order_tps.toFixed(2)
                        }}$
                      </td>
                    </tr>
                    <tr class="text-offset">
                      <td colspan="1"></td>

                      <td colspan="1"></td>
                      <td colspan="1">TVQ</td>
                      <td>
                        {{
                          store.state.commandesList[
                            selectForUpdate
                          ].order_tvq.toFixed(2)
                        }}$
                      </td>
                    </tr>
                    <tr class="text-offset">
                      <td colspan="1"></td>
                      <td colspan="1" class="text-center">
                        <div
                          v-if="
                            !store.state.commandesList[selectForUpdate]
                              .order_commandeTotalIsShipped
                          "
                        >
                          <button
                            class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                            @click="isCompleted = true"
                          >
                            <div class="flex justify-center items-center">
                              Indiquer la commande comme complétée
                              <svg
                                width="24"
                                height="24"
                                fill="green"
                                xmlns="http://www.w3.org/2000/svg"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                class="ml-3 scale-50"
                                v-if="isCompleted"
                              >
                                <path
                                  d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm7 7.457l-9.005 9.565-4.995-5.865.761-.649 4.271 5.016 8.24-8.752.728.685z"
                                />
                              </svg>
                            </div>
                          </button>
                        </div>
                        <div v-else>Complétée</div>
                      </td>
                      <td colspan="1">Total</td>
                      <td>
                        {{
                          store.state.commandesList[
                            selectForUpdate
                          ].order_totalPrice.toFixed(2)
                        }}$
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <!--footer-->
            <div
              class="flex items-center justify-between p-6 border-t border-solid border-blueGray-200 rounded-b"
            >
              <div>
                <a
                  :href="`https://${harvestPrefix}.harvestapp.com/client/invoices/${store.state.commandesList[selectForUpdate].order_harvestOrderId}`"
                  target="_blank"
                >
                  <img
                    class="h-8 cursor-pointer"
                    src="../../assets/images/download-pdf.png"
                /></a>
              </div>
              <div>
                <button
                  class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="toggleModal('modal-id')"
                >
                  Fermer
                </button>
                <button
                  class="bg-emerald-500 text-green-400 active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="saveCommande()"
                >
                  Sauvegarder
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- FIN DU MODAL -->

      <div class="col-span-5 p-8 w-full">
        <p class="text-3xl mb-10 uppercase">Gestion des commandes</p>

        <div class="w-full">
          <input
            v-model="filter"
            @keyup.esc="quit()"
            @keyup.enter="search()"
            class="mr-4 mt-4 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full lg:w-8/12 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="inline-full-name"
            type="text"
            placeholder="RECHERCHE @ NOM / COURRIEL / TELEPHONE / #COMMANDE"
          />

          <button
            class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded mt-2 lg:mt-0"
            @click="search()"
          >
            RECHERCHER
          </button>

          <button
            class="ml-2 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            @click="quit()"
          >
            X
          </button>
        </div>

        <div class="totalResults mt-4" v-if="showFilter">
          <span v-if="resultsToShow.length === 0" class="font-bold">Aucun</span>
          <span v-else class="font-bold">{{ resultsToShow.length }}</span>
          résultat<span v-if="resultsToShow.length > 1">s</span> trouvé<span
            v-if="resultsToShow.length > 1"
            >s</span
          >
        </div>

        <div v-if="resultsToShow">
          <div
            v-for="(commande, index) in resultsToShow"
            :key="index"
            class="grid grid-cols-6 xl:grid-cols-12 py-6 px-4 mt-4 shadow-md rounded-sm overflow-hidden items_gestion_commande"
          >
            <div
              class="font-bold"
              @click="
                toggleModal('modal-id', commande.order_orderNum);
                scrollTop();
              "
            >
              # {{ commande.order_orderNum }}
            </div>
            <div
              @click="
                toggleModal('modal-id', commande.order_orderNum);
                scrollTop();
              "
            >
              {{ convertDate(commande.order_dateCreated) }}
            </div>
            <div
              class="col-span-3 pl-4"
              @click="
                toggleModal('modal-id', commande.order_orderNum);
                scrollTop();
              "
            >
              {{ commande.order_customer_compagnyName }}
            </div>
            <div
              class="flex justify-end col-span-2"
              @click="
                toggleModal('modal-id', commande.order_orderNum);
                scrollTop();
              "
            >
              <span class="pr-10"
                >{{ commande.order_totalPrice.toFixed(2) }} $</span
              >
            </div>
            <div
              class="col-span-2"
              @click="
                toggleModal('modal-id', commande.order_orderNum);
                scrollTop();
              "
            >
              <div class="infoContainer flex justify-center">
                <div
                  class="order_attente flex justify-center items-center"
                  v-if="
                    !commande.order_shippingNumber1 &&
                    !commande.order_shippingNumber2 &&
                    !commande.order_commandeTotalIsShipped
                  "
                >
                  En attente
                </div>
                <div
                  class="order_1shipped flex justify-center items-center"
                  v-if="
                    commande.order_shippingNumber1 &&
                    !commande.order_commandeTotalIsShipped &&
                    !commande.order_shippingNumber2
                  "
                >
                  1er Envoi
                </div>
                <div
                  class="order_2shipped flex justify-center items-center"
                  v-if="
                    commande.order_shippingNumber1 &&
                    commande.order_shippingNumber2 &&
                    !commande.order_commandeTotalIsShipped
                  "
                >
                  2ème Envoi
                </div>
                <div
                  class="order_complete flex justify-center items-center"
                  v-if="commande.order_commandeTotalIsShipped"
                >
                  Complétée
                </div>
              </div>
            </div>

            <div>
              <button
                class="bg-red-500 hover:bg-red-700 text-white font-bold px-2 py-1 border border-red-700 rounded"
                @click="deleteCommande(commande.order_orderNum)"
              >
                <div class="flex justify-between items-center text-sm mx-1">
                  X
                  <span
                    class="ml-2"
                    v-if="
                      deletingCommande &&
                      orderNumNow === commande.order_orderNum
                    "
                    ><img
                      id="loadingLogo"
                      src="../../assets/images/spinner_button.gif"
                  /></span>
                </div>
              </button>
            </div>
            <div class="col-span-1 flex justify-center items-center">
              <a
                :href="`https://${harvestPrefix}.harvestapp.com/client/invoices/${commande.order_harvestOrderId}`"
                target="_blank"
                ><img
                  class="downloadicon"
                  src="../../assets/images/arrow_down.png"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { onMounted, ref, inject } from "vue";
import axios from "axios";

export default {
  name: "GestionCommandesAmdin",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");
    const filter = ref(null);
    const showFilter = ref(false);
    const modalIsOpen = ref(false);
    const selectForUpdate = ref(null);
    const suiviNum1 = ref(null);
    const suiviNum2 = ref(null);
    const isCompleted = ref(null);
    const resultsToShow = ref([]);
    const ifHaveTracking = ref(false);
    const deletingCommande = ref(false);
    const orderNumNow = ref(null);
    const harvestPrefix = process.env.VUE_APP_PREFIX_HARVEST;

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    // CONVERTISSEUR DE DATE
    const convertDate = (inputDate) => {
      return inputDate.substring(0, inputDate.length - 14);
    };

    // FORMAT PHONE NUMBER
    function formatPhoneNumber(phoneNumberString) {
      let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    }

    function scrollTop() {
      window.scrollTo(0, 0);
    }

    function quit() {
      !showFilter.value;
      filter.value = null;
      getCommandes();
    }

    // CONTROL DU MODAL
    function toggleModal(modalID, orderNum) {
      modalIsOpen.value = !modalIsOpen.value;
      if (modalIsOpen.value) {
        // TROUVER L'INDEX DANS LE TOTAL DES COMMANDE DE LA COMMANDE SELECTIONER POUR LE POUSSER DASNS SELECTEDFORUPDATE
        selectForUpdate.value = store.state.commandesList.findIndex(
          (x) => x.order_orderNum === orderNum
        );
        // FETCHER LE CONTRAT
        store.methods.getCommande(
          store.state.commandesList[selectForUpdate.value].order_orderNum
        );
        // RESET LES VALEUR DE # TRACKING ET COMPLETE
        suiviNum1.value =
          store.state.commandesList[
            selectForUpdate.value
          ].order_shippingNumber1;
        suiviNum2.value =
          store.state.commandesList[
            selectForUpdate.value
          ].order_shippingNumber2;
        // GARDER LA VIELLE VALEUR DE SAVOIR SI UN TRACKING EXISTE POUR POUSSER LE COURRIEL  SI UPDATE
        if (suiviNum1.value) {
          ifHaveTracking.value = true;
        } else {
          ifHaveTracking.value = false;
        }

        isCompleted.value =
          store.state.commandesList[
            selectForUpdate.value
          ].order_commandeTotalIsShipped;
      } else {
        selectForUpdate.value = null;
      }
      document.getElementById(modalID).classList.toggle("hidden");
      // document.getElementById(modalID + "-backdrop").classList.toggle("hidden");
      document.getElementById(modalID).classList.toggle("flex");
      // document.getElementById(modalID + "-backdrop").classList.toggle("flex");
    }

    // SAUVEGARDE DES DONNE DES NUMERO DE TRACKING
    function saveCommande() {
      // UPDATE LES NUMERO DE TRACKING
      if (
        store.state.commandesList[selectForUpdate.value].order_shippingNumber1
      ) {
        suiviNum1.value =
          store.state.commandesList[
            selectForUpdate.value
          ].order_shippingNumber1;
      }

      // ENVOIE COURRIEL POUR UPDATE NUMERO DE SUIVI
      // VERIFI SI IL Y A EU CHANGEMENT DANS LES TRACKING
      let trackingIsUpdated = false;

      if (suiviNum1.value && !ifHaveTracking.value) {
        trackingIsUpdated = true;
      }

      store.methods.updateCommande({
        orderNum:
          store.state.commandesList[selectForUpdate.value].order_orderNum,
        order_shippingNumber1: suiviNum1.value,
        order_shippingNumber2: suiviNum2.value,
        order_commandeTotalIsShipped: isCompleted.value,
        order_trackingIsUpdated: trackingIsUpdated,
      });

      store.state.commandesList[selectForUpdate.value].order_shippingNumber1 =
        suiviNum1.value;
      store.state.commandesList[selectForUpdate.value].order_shippingNumber2 =
        suiviNum2.value;
      store.state.commandesList[
        selectForUpdate.value
      ].order_commandeTotalIsShipped = isCompleted.value;
    }

    function search() {
      if (filter.value) {
        showFilter.value = true;
        let urls = [
          `${store.state.searchCommandesUrl}/${filter.value}/order_orderNum`,
          `${store.state.searchCommandesUrl}/${filter.value}/order_customer_email`,
          `${store.state.searchCommandesUrl}/${filter.value}/order_customer_phone`,
          `${store.state.searchCommandesUrl}/${filter.value}/order_customer_compagnyName`,
        ];

        const order = axios.get(urls[0], { withCredentials: true });
        const email = axios.get(urls[1], { withCredentials: true });
        const phone = axios.get(urls[2], { withCredentials: true });
        const compagnyName = axios.get(urls[3], { withCredentials: true });

        axios
          .all([order, email, phone, compagnyName])
          .then(
            axios.spread((...responses) => {
              store.state.loadingSearchCommandes = true;

              let tempResults = [];

              const responseOrder = responses[0].data.data;
              const responseEmail = responses[1].data.data;
              const responsePhone = responses[2].data.data;
              const responseCompagnyName = responses[3].data.data;

              if (responseOrder.length) {
                responseOrder.forEach((response) => {
                  tempResults.push(response);
                });
              }
              if (responseEmail.length) {
                responseEmail.forEach((response) => {
                  tempResults.push(response);
                });
              }
              if (responsePhone.length) {
                responsePhone.forEach((response) => {
                  tempResults.push(response);
                });
              }
              if (responseCompagnyName.length) {
                responseCompagnyName.forEach((response) => {
                  tempResults.push(response);
                });
              }

              resultsToShow.value = [
                ...new Map(
                  tempResults.map((item) => [item.order_orderNum, item])
                ).values(),
              ];
            })
          )
          .catch((errors) => {
            // react on errors.
            console.error(errors);
          });
      }
    }

    // RETOURNER LES 20 DERNIERS RESULTATS  DES COMMANDES

    function getCommandes() {
      // GET TOUS LES COMMANDES
      showFilter.value = false;

      let sortedList = [];
      store.state.commandesList.forEach((item, index) => {
        if (index > store.state.commandesList.length - 50) {
          // retourne les 50 derniers contrats
          sortedList.push(item);
        }
      });

      resultsToShow.value = sortedList.sort(
        (a, b) => b.order_orderNum - a.order_orderNum
      ); // RETOURNE CONTRAT LE PLUS RECENT AU PLUS VIEUX
    }

    async function deleteCommande(orderNum) {
      if (deletingCommande.value) {
        return;
      }

      let commandeConfirm = prompt(
        "Veuillez confirmer le numéro de commande " +
          orderNum +
          " dans la case ci-dessous : "
      );

      if (!commandeConfirm) {
        return;
      }

      if (commandeConfirm == orderNum) {
        orderNumNow.value = orderNum;
        deletingCommande.value = true;

        try {
          const res = await fetch(`${store.state.deleteCommandeUrl}`, {
            method: "post",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              orderNum: orderNum,
            }),
            credentials: "include",
          });

          if (!res.ok) {
            console.log("error");
          } else {
            const reponse = await res.json();
            console.log(reponse);
            let indexCommande = resultsToShow.value.findIndex(
              (item) => item.order_orderNum === orderNum
            );
            resultsToShow.value.splice(indexCommande, 1);
            deletingCommande.value = false;
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        alert("Les numéros de commande ne concordent pas.");
        return;
      }
    }

    onMounted(() => {
      if (!store.state.commandesList || !resultsToShow.value) {
        // SI DONNE PAS LOADER ATTENDRE LES COMMANDES
        (async () => {
          await store.methods.getCommandes();
          getCommandes();
        })();
      } else {
        // SINON AFFICHER LES COMMANDES TOUT DE SUITE
        getCommandes();
      }
    });

    return {
      store,
      quit,
      filter,
      ifHaveTracking,
      suiviNum1,
      suiviNum2,
      getCommandes,
      toggleModal,
      isCompleted,
      search,
      saveCommande,
      modalIsOpen,
      selectForUpdate,
      convertDate,
      formatPhoneNumber,
      showFilter,
      resultsToShow,
      toggleMenu,
      harvestPrefix,
      deleteCommande,
      scrollTop,
      deletingCommande,
      orderNumNow,
    };
  },
};
</script>
<style lang="scss">
@media only screen and (max-width: 700) {
  table {
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    white-space: nowrap;
  }
}

.modal-commande {
  position: absolute;
  top: 10px;
  left: 5%;
  right: 5%;
  box-sizing: border-box;
  overflow: auto;
}

table {
  white-space: nowrap;
  overflow-y: auto;
}

.items_gestion_commande {
  cursor: pointer;
}

.order_attente,
.order_1shipped,
.order_2shipped,
.order_complete {
  border-radius: 3px;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  min-width: 110px;
  min-height: 30px;
}
.order_attente {
  background-color: rgb(185, 104, 104);
}
.order_1shipped {
  background-color: rgb(208, 236, 108);
  color: black;
}

.order_2shipped {
  background-color: rgb(91, 138, 174);
}
.order_complete {
  background-color: rgb(6, 95, 70);
}

.infoContainer {
  width: 120px;
}

#blocklivraisoncommande {
  top: 51px;
}

.downloadicon {
  height: 16px;
  transition: all 0.3s;
}

.downloadicon:hover {
  transform: scale(1.15);
}

.totalResults {
  min-height: 30px;
}
</style>
