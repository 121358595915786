<template>
  <div>
    <TheNavbar />
    <vue-easy-lightbox
      :visible="visible"
      :imgs="currentProduct.product_images"
      :index="indexImage"
      @hide="handleHide"
      v-if="currentProduct"
    ></vue-easy-lightbox>
    <main class="produit pt-32">
      <div v-if="!loading" class="grid grid-grid-cols-1 lg:grid-cols-2">
        <div
          class="images pt-2 pl-10 pr-10 md:pl-10 md:pr-10 lg:pl-20 lg:pr-20"
        >
          <img :src="image" :alt="currentProduct.product_name" />
          <div class="petite-photos-wrapper flex justify-start">
            <div
              v-for="(image, index) in currentProduct.product_images"
              :key="index"
            >
              <!-- SELECTIONNE SEULEMENT PREMIERE ET DERNIERE PHOTO DU PRODUIT -->
              <div
                v-if="
                  index === 0 ||
                  index === currentProduct.product_images.length - 1
                "
              >
                <img
                  :src="image.src"
                  :alt="currentProduct.product_name"
                  class="petite-photo mt-2 mr-2 cursor-pointer"
                  @click="() => showImg(index)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="description ml-10 lg:ml-0 pt-10 lg:pt-0">
          <h1 class="titre-produit">{{ currentProduct.product_name }}</h1>
          <span
            class="prix-produit"
            v-for="(variation, index) in store.methods.removeDuplicates(
              currentProduct.product_priceVariations,
              (item) => item.price
            )"
            :key="index"
          >
            <span>{{ variation.price.toFixed(2) }} $ </span>
            <span
              v-if="
                index <
                store.methods.removeDuplicates(
                  currentProduct.product_priceVariations,
                  (item) => item.price
                ).length -
                  1
              "
            >
              -
            </span>
          </span>
          <div class="separator-produit"></div>
          <div class="w-10/12">
            <span v-html="filterDescription()"></span>
          </div>
          <div class="options grid grid-cols-1">
            <div
              v-if="currentProduct.product_isVariable"
              class="choisir-variation pt-10"
            >
              <!-- FILTRE SI PAS PRODUIT VARIABLE -->
              <label for="formats" class="format-tag"
                ><span style="color: #444; fontweight: 600; width: 80px"
                  >Format</span
                ></label
              >
              <select
                name="formats"
                id="formats"
                v-model="currentFormat"
                style="
                  border: 1px solid rgb(235, 235, 235);
                  padding: 7px 10px;
                  margin-left: 20px;
                "
              >
                <option value="Choisir une option" disabled>
                  Choisir une option
                </option>
                <option
                  v-for="(variation, index) in variationDisponible(
                    currentProduct.product_priceVariations
                  )"
                  :key="index"
                  :value="variation.format"
                  class="formatsList"
                >
                  {{ variation.format }}
                </option>
              </select>

              <p
                v-if="currentFormat !== 'Choisir une option'"
                class="pt-2 effacer"
                @click="effacerFormat"
              >
                Effacer
              </p>
            </div>
            <div
              class="prix-produit pt-10"
              v-if="currentFormat !== 'Choisir une option'"
            >
              {{ showPrice() }} $
            </div>

            <div class="choixQuantiteRapide pt-5">
              <div class="num-block skin-5">
                <div class="num-in">
                  <span class="minus dis" @click="decrement()">-</span>
                  <input
                    class="in-num"
                    type="number"
                    v-model.number="quantity"
                    @keyup.enter="
                      addToCart();
                      quantity = 0;
                    "
                  />
                  <span class="plus" @click="increment()">+</span>
                </div>
              </div>
            </div>

            <div
              class="pt-3"
              v-if="
                (currentFormat !== 'Choisir une option' ||
                  !currentProduct.product_isVariable) &&
                !loading
              "
            >
              <p>{{ stockAvailable }} en stock</p>
            </div>

            <div class="pt-10">
              <button
                class="btnValider"
                v-if="
                  (currentFormat !== 'Choisir une option' ||
                    !currentProduct.product_isVariable) & isStock
                "
                @click="addToCart()"
              >
                AJOUTER AU PANIER
              </button>
              <button class="btnValiderDisabled" v-else>
                AJOUTER AU PANIER
              </button>
            </div>
          </div>
        </div>

        <div
          class="col-span-1 lg:col-span-2 pt-16 pl-10 pr-10 md:pl-10 md:pr-10 lg:pl-20 lg:pr-20"
        >
          <div class="description-under mb-10">
            <p class="titre-description">DESCRIPTION</p>
          </div>

          <span
            v-html="currentProduct.product_description"
            class="description-longue"
          ></span>
        </div>
      </div>
    </main>
    <TheFooter />
  </div>
</template>

<script>
import TheNavbar from "../../components/TheNavbar";
import TheFooter from "../../components/TheFooter";
import VueEasyLightbox from "vue-easy-lightbox";

import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "Cartes",
  components: { TheNavbar, TheFooter, VueEasyLightbox },
  setup() {
    const store = inject("store");
    const route = useRoute();
    const router = useRouter();
    const currentProduct = ref(null);
    const loading = ref(true);
    const visible = ref(false);
    const indexImage = ref(0);
    const currentFormat = ref("Choisir une option");
    const currentVariation = ref(null);
    const quantity = ref(1);
    const price = ref(null);
    const image = ref(null);
    const stockAvailable = ref(null);
    const isStock = ref(false);
    const currentSku = ref(null);

    function variationDisponible(variation) {
      let filteredVariation = variation.filter((item) => item.variationShow); // FILTRE SEULEMENT LES VARIATIONS DISPONIBLES
      filteredVariation.sort(store.methods.dynamicSort("format"));
      return filteredVariation;
    }

    function increment() {
      // VERIFICATION DE NE PAS DEFONCER L'INVENTAIRE

      if (quantity.value >= stockAvailable.value) {
        alert(
          "Vous ne pouvez ajouter plus d'articles que l'inventaire disponible"
        );
        return;
      }

      quantity.value++;
    }

    function decrement() {
      if (quantity.value > 0) {
        quantity.value--;
      } else {
        return;
      }
    }

    function filterDescription() {
      let strNew1 = currentProduct.value.product_shortDescription.replace(
        "Psst!",
        ""
      );
      let strNew2 = strNew1.replace("–", "<br/>");

      return strNew2;
    }

    function addToCart() {
      // VERIFICATION DE NE PAS DEFONCER L'INVENTAIRE

      if (quantity.value > stockAvailable.value) {
        alert(
          "Vous ne pouvez ajouter plus d'articles que l'inventaire disponible"
        );
        return;
      }

      if (!store.state.currentTag) {
        router.push("/categorie/cartes-de-souhaits");
        // store.state.currentTag = currentProduct.value.product_categories[0].id;
      }

      let productToAdd = {};
      if (currentProduct.value.product_isVariable) {
        // SI CEST UN PRODUIT VARIABLE

        productToAdd = {
          variation: currentVariation.value,
          parentId: currentProduct.value.product_id,
          price: parseFloat(price.value), // CORRECTION PRIX 14oct
          imageVariation: image.value,
          format: currentFormat.value,
        };
      } else {
        // SI LE PRODUIT NEST PAS VARIABLE

        currentSku.value =
          currentProduct.value.product_priceVariations[0].variation_sku;

        productToAdd = {
          variation: currentProduct.value.product_id,
          parentId: currentProduct.value.product_id,
          price: currentProduct.value.product_priceVariations[0].price,
          imageVariation: currentProduct.value.product_images[0].src,
          format: [],
        };

        stockAvailable.value -= quantity.value; // CORRECTION BUG TEMP

        if (stockAvailable.value === 0) {
          isStock.value = false;
        } else {
          isStock.value = true;
        }
      }

      // POUSSER LE PRODUIT DANS LE PANIER
      store.methods.addToCart([
        productToAdd,
        quantity.value,
        currentProduct.value.product_name,
        [false, false],
        false,
        currentSku.value,
        store.state.currentTag, // AJOUT CATEGORIE NOW 2024
        null,
      ]);
    }

    function showImg(index) {
      indexImage.value = index;
      visible.value = true;
    }

    function handleHide() {
      visible.value = false;
    }

    function effacerFormat() {
      currentFormat.value = "Choisir une option";
    }

    function showPrice() {
      let index = currentProduct.value.product_priceVariations.findIndex(
        (x) => x.format === currentFormat.value
      );
      currentVariation.value =
        currentProduct.value.product_priceVariations[index].variation;
      currentSku.value =
        currentProduct.value.product_priceVariations[index].variation_sku;
      price.value =
        currentProduct.value.product_priceVariations[index].price.toFixed(2);

      if (!currentProduct.value.product_isVariable) {
        stockAvailable.value =
          currentProduct.value.product_priceVariations[0].stockAvailable;
      } else {
        stockAvailable.value =
          currentProduct.value.product_priceVariations[index].stockAvailable;
      }

      if (stockAvailable.value === 0) {
        isStock.value = false;
      } else {
        isStock.value = true;
      }

      image.value =
        currentProduct.value.product_priceVariations[index].imageVariation;

      return price.value;
    }

    onMounted(() => {
      window.scrollTo(0, 0);

      if (!store.state.data) {
        (async () => {
          await store.methods.fetchAllProducts();
          store.methods.checkIfCart();
          store.methods.calculTaxes();
          loading.value = false;
          let index = store.state.allProducts.findIndex(
            (x) => x.product_slug === route.params.produit
          );
          currentProduct.value = store.state.allProducts[index];

          image.value =
            currentProduct.value.product_priceVariations[0].imageVariation;

          if (!currentProduct.value.product_isVariable) {
            stockAvailable.value =
              currentProduct.value.product_priceVariations[0].stockAvailable;
          }
          if (stockAvailable.value > 0) {
            isStock.value = true;
          } else {
            isStock.value = false;
          }
        })();
      } else {
        if (route.params.produit) {
          loading.value = false;
          let index = store.state.allProducts.findIndex(
            (x) => x.product_slug === route.params.produit
          );
          currentProduct.value = store.state.allProducts[index];
          image.value =
            currentProduct.value.product_priceVariations[0].imageVariation;
          if (!currentProduct.value.product_isVariable) {
            stockAvailable.value =
              currentProduct.value.product_priceVariations[0].stockAvailable;
            if (stockAvailable.value > 0) {
              isStock.value = true;
            } else {
              isStock.value = false;
            }
          }
        }
      }
    });

    return {
      store,
      stockAvailable,
      currentProduct,
      loading,
      currentFormat,
      quantity,
      increment,
      decrement,
      effacerFormat,
      showPrice,
      price,
      image,
      currentSku,
      showImg,
      handleHide,
      visible,
      indexImage,
      currentVariation,
      addToCart,
      variationDisponible,
      isStock,
      filterDescription,
    };
  },
};
</script>
<style lang="scss">
$borderGray: #dedede;
$goldColor: #b7a36f;

@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap");

.slide-enter-from {
  opacity: 0;
  transform: scale(0.95);
}
.slide-enter-to {
  transform: scale(1);
  opacity: 1;
}
.slide-enter-active {
  transition: all 0.4s ease;
}
.slide-leave-from {
  opacity: 1;
  transform: scale(1);
}
.slide-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.produit {
  max-width: 1445px;
  margin: auto;
  min-height: 70vh;
}
#formats {
  outline: none;
}

.description-longue {
  line-height: 26px;
  letter-spacing: 1px;

  p {
    padding-bottom: 25px;
  }

  h3 {
    font-family: "Lora", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-style: italic;
    text-transform: none;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 1px;
    color: #bba267;
    padding-bottom: 15px;
  }

  ul li {
    list-style: disc;
    margin-left: 19px;
    padding-bottom: 12px;
  }
}

.titre-description {
  color: #bba267 !important;
  font-size: 12px;
  padding-bottom: 8px;
}

.description-under {
  width: min-content;
  border-bottom: 2px solid #bba267;
}

.choixQuantiteRapide {
  z-index: 100;
  /* skin 5 */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input:focus {
    outline: none !important;
  }

  .formatsList {
    color: #222222;
  }

  .skin-5 .num-in {
    width: 88px;
    float: left;
    vertical-align: middle;
    position: relative;
    border: 1px solid #eeeff1;
    border-radius: 3px;
  }

  .skin-5 .num-in:hover {
    border: 1px solid #eeeff7;
    box-shadow: 0px 0px 7px 0px #f3f3f3;
  }

  .skin-5 .num-in span {
    cursor: pointer;
    font-size: 16px;
    width: 20px;
    display: block;
    line-height: 41px;
  }

  .skin-5 .num-in span.minus {
    float: left;
    text-align: right;
    font-size: 18px;
  }

  .skin-5 .num-in input {
    border: none;
    height: 41px;
    width: 46px;
    float: left;
    text-align: center;
    font-size: 16px;

    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }

  .skin-5 .num-in span.plus {
    cursor: pointer;
    float: right;
    text-align: left;
    font-size: 18px;
  }

  /* / skin 5 */
}

.effacer {
  margin-left: 75px;
  cursor: pointer;
}

.separator-produit {
  width: 90%;
  border: 2px solid rgb(235, 235, 235);
  margin-bottom: 30px;
  margin-top: 30px;
}

.titre-produit {
  font-size: 25px;
  letter-spacing: 1px;
}

.prix-produit {
  font-size: 25px;
  letter-spacing: 1px;
}

.s1 {
  color: #bba267;
  font-size: 18px;
  font-style: italic;
  font-family: "Lora", serif;
}

.petite-photos-wrapper {
  .petite-photo {
    height: 110px;
  }
}

p.p1 {
  margin-top: 20px;
  letter-spacing: 0.5px;
}

.description {
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  // CACHE SUGESTION DE COFFRET
  h6 {
    display: none;
  }
  .p1:nth-child(4) {
    display: none;
  }
}
</style>
