<template>
  <main>
    <div class="enregistrementClient relative">
      <img
        src="@/assets/images/Mimosa-design-logo-small.png"
        class="mt-10"
        alt="Mimosa Design"
        width="274"
        height="65"
        id="logo-mimosa"
      />
      <template v-if="!error">
        <div class="grid grid-cols-1 wrapperInput w-full selectClient">
          <p class="pt-12 pb-4 text-left">
            Veuillez sélectionner le client dans la liste.
          </p>

          <div class="relative" v-if="clientsListOk">
            <input
              :disabled="inputClientDisabled"
              v-model="selectClient"
              @change="getClientsInfos()"
              placeholder="Sélectionnez un client"
              type="text"
              list="produit"
              class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            />
            <datalist id="produit">
              <option
                v-for="(client, index) in clientsListHarvest"
                :key="index"
                >{{ client.name }}</option
              >
            </datalist>
          </div>

          <div>
            <label class="inline-flex items-center wrapperInput mt-6">
              <input
                type="checkbox"
                class="form-checkbox text-black"
                v-model="ifClientExist"
                id="checkifShipping"
                @click="clearSelectedClient()"
              />
              <span class="ml-2 pl-4 mb-4"
                >Le client ne se trouve pas dans la liste.</span
              >
            </label>
          </div>
        </div>
        <div class="grid grid-cols-1 wrapperInput">
          <p class="pt-6 pb-4 text-left">
            Veuillez compléter les champs nécessaires afin de compléter
            l'enregistrement.
          </p>

          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Nom du commerce"
            v-model="infosClient.compagnyName"
            maxlength="70"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Nom de la personne contact"
            v-model="infosClient.contact"
            maxlength="70"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="email"
            placeholder="Courriel"
            v-model="infosClient.email"
            maxlength="70"
          />
          <InputMask
            mask="(999) 999-9999? x99999"
            v-model="infosClient.phone"
            type="text"
            placeholder="(999) 999-9999"
            maxlength="20"
            class="mt-1 h-10 px-3 mb-2"
          />
        </div>
        <div class="grid grid-cols-1 wrapperInput mt-6">
          <p class="pb-4 font-weight-700">ADRESSE DE FACTURATION</p>
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="No civique"
            v-model="infosClient.billingAdressNumber"
            maxlength="70"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Rue"
            v-model="infosClient.billingStreet"
            maxlength="60"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Code postal"
            v-model="infosClient.billingPostalCode"
            maxlength="20"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Ville"
            v-model="infosClient.billingCity"
            maxlength="60"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Province"
            v-model="infosClient.billingProvince"
            maxlength="70"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Pays"
            v-model="infosClient.billingPays"
            maxlength="70"
          />
          <div>
            <label class="inline-flex items-center wrapperInput mt-6">
              <input
                type="checkbox"
                class="form-checkbox text-black"
                v-model="infosClient.shippingIsBilling"
                id="checkifShipping"
              />
              <span class="ml-2 pl-4 mb-4"
                >Utiliser l'adresse de facturation comme adresse de
                livraison</span
              >
            </label>
          </div>

          <div
            class="grid grid-cols-1 wrapperInput my-6"
            v-if="!infosClient.shippingIsBilling"
          >
            <p class="pb-4 font-weight-700">ADRESSE DE LIVRAISON</p>
            <input
              class="mt-1 h-10 px-3 mb-2"
              type="text"
              placeholder="No civique"
              v-model="infosClient.shippingAdressNumber"
              maxlength="70"
            />
            <input
              class="mt-1 h-10 px-3 mb-2"
              type="text"
              placeholder="Rue"
              v-model="infosClient.shippingStreet"
              maxlength="60"
            />
            <input
              class="mt-1 h-10 px-3 mb-2"
              type="text"
              placeholder="Code postal"
              v-model="infosClient.shippingPostalCode"
              maxlength="20"
            />
            <input
              class="mt-1 h-10 px-3 mb-2"
              type="text"
              placeholder="Ville"
              v-model="infosClient.shippingCity"
              maxlength="60"
            />
            <input
              class="mt-1 h-10 px-3 mb-2"
              type="text"
              placeholder="Province"
              v-model="infosClient.shippingProvince"
              maxlength="70"
            />
            <input
              class="mt-1 h-10 px-3 mb-2"
              type="text"
              placeholder="Pays"
              v-model="infosClient.shippingPays"
              maxlength="70"
            />
          </div>
        </div>

        <div class="wrapperInput grid grid-cols-1 w-full">
          <button
            class="h-10 px-5 mt-10 text-white text-sm transition-colors duration-150 bg-black focus:shadow-outline uppercase"
            @click="generatePass()"
          >
            Générer un mot de passe
          </button>
          <input
            class="mt-1 h-10 px-3 mb-2"
            @change="checkPassMatch()"
            id="pass1"
            type="password"
            placeholder="Mot de passe"
            v-model="infosClient.password1"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            @change="checkPassMatch()"
            id="pass2"
            type="password"
            placeholder="Confirmer mot de passe"
            v-model="infosClient.password2"
          />
          <p v-if="!checkPassMatch()" class="text-red-500 text-center">
            Les mots de passes ne concordent pas
          </p>
          <p v-if="needMoreCar" class="text-red-500 text-center">
            Le mot de passe a besoin d'un minimum de 7 caracthères.
          </p>
          <p v-if="errorCreate" class="text-red-500 text-center">
            Une erreur est survenue pendant la création du client.
          </p>
          <p v-if="courrielUtilise" class="text-red-500 text-center">
            Ce courriel est déjà été utilisé par un client, veuillez en inscrire
            un autre.
          </p>
          <p v-if="clientExisteHarvest" class="text-red-500 text-center">
            Ce client existe déja dans les clients existants..
          </p>
          <p v-if="successAddClient" class="text-green-500 text-center">
            L'enregistrement est complété.
          </p>

          <button
            class="h-10 px-5 mt-10 mb-20 text-white text-sm transition-colors duration-150 bg-black focus:shadow-outline uppercase"
            :disabled="needInfos"
            @click="createClient()"
          >
            CONFIRMER L'ENREGISTREMENT
          </button>
        </div>
      </template>
      <template v-else>
        <p class="mt-20 px-24 text-center">
          La clée d'enregistrement est invalide, expirée ou elle a déja été
          utilisée. <br />

          Veuillez communiquer avec l'administrateur pour obtenir une nouvelle
          invitation.
        </p>
      </template>

      <div class="absolute bottom-10 text-sm">
        © {{ store.methods.getCurrentYear() }}. Mimosa Design - Extranet. Tous
        droits réservés.
      </div>
    </div>
  </main>
</template>

<script>
import { onMounted, inject, ref, reactive } from "vue";
import { useRoute } from "vue-router";
import InputMask from "primevue/inputmask";

export default {
  name: "Enregistrement",
  components: { InputMask },
  setup() {
    const route = useRoute();
    const store = inject("store");
    const key = route.params.key;
    const error = ref(true);
    const needMoreCar = ref(false);
    const infosClient = reactive({
      shippingIsBilling: true,
      billingPays: "Canada",
      shippingPays: "Canada",
    });
    const needInfos = ref(false);
    const minCar = 7;
    const clientsListHarvest = ref([null]);
    const clientsListOk = ref(false);
    const selectClient = ref(null);
    const ifClientExist = ref(false);
    const inputClientDisabled = ref(false);
    let clientIdHarvest = null;
    const clientExisteHarvest = ref(false);
    const errorCreate = ref(false);
    const successAddClient = ref(false);
    const courrielUtilise = ref(false);

    function buildPass(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }

    function generatePass() {
      const password = buildPass(7);
      var pass1 = document.getElementById("pass1");
      var pass2 = document.getElementById("pass2");

      pass1.type = "text";
      pass2.type = "text";

      infosClient.password1 = password;
      infosClient.password2 = password;
    }

    function clearSelectedClient() {
      selectClient.value = null;

      if (inputClientDisabled.value) {
        inputClientDisabled.value = false;
      } else {
        inputClientDisabled.value = true;
        infosClient.compagnyName = null;
        clientIdHarvest = null;
      }
    }

    async function getClientsHarvest(pageCount) {
      let numPageToReq;
      if (pageCount === 0) {
        numPageToReq = "";
      } else {
        numPageToReq = `?page=${pageCount}`;
      }

      try {
        const res = await fetch(
          `${process.env.VUE_APP_HARVEST_URL}${numPageToReq}`,
          {
            method: "GET",
            headers: {
              "Harvest-Account-Id": process.env.VUE_APP_HARVEST_ACOUNT_ID,
              Authorization: "Bearer " + process.env.VUE_APP_TOKEN_HARVEST,
            },
          }
        );
        if (!res.ok) {
          console.log("un problème est survenu.");
        } else {
          const data = await res.json();
          let newListingClient;
          if (pageCount === 0) {
            clientsListHarvest.value = data.clients;
          } else {
            newListingClient = clientsListHarvest.value.concat(data.clients);
            clientsListHarvest.value = newListingClient;
          }

          if (data.page === data.total_pages) {
            // SORT BY NAME ASC
            clientsListHarvest.value.sort((a, b) =>
              a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            );
            clientsListOk.value = true;
          } else {
            getClientsHarvest(pageCount + 1);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    function checkPassMinLength() {
      if (infosClient.password1 && infosClient.password2) {
        if (
          infosClient.password1.length >= minCar &&
          infosClient.password2.length >= minCar
        ) {
          needMoreCar.value = false;
        } else {
          needMoreCar.value = true;
        }
      }
    }

    function checkPassMatch() {
      if (infosClient.password1 !== infosClient.password2) {
        return false;
      } else {
        checkPassMinLength();
        return true;
      }
    }

    function getClientsInfos() {
      if (selectClient.value) {
        infosClient.compagnyName = selectClient.value;
        let indexOfSelectClient = clientsListHarvest.value.findIndex(
          (client) => client.name === selectClient.value
        );
        if (indexOfSelectClient !== -1) {
          clientIdHarvest = clientsListHarvest.value[indexOfSelectClient].id;
        } else {
          infosClient.compagnyName = null;
        }
      }
    }

    onMounted(() => {
      window.scrollTo(0, 0);
      checkKey(key);

      getClientsHarvest(0);
      store.methods.getAllClientsInfosForRegistration();
    });

    async function checkKey(key) {
      store.state.loadingCheckKey = true;
      {
        let response = await fetch(`${store.state.getCheckKeyUrl}/${key}`);

        let data = await response.json();

        if (data.success === 0) {
          error.value = true;
        } else {
          error.value = false;
          infosClient.representativeId = data.data.representativeId;
          infosClient.key = key;
        }
        return data;
      }
    }

    async function createClient() {
      successAddClient.value = false;
      let clientExist = false;

      store.state.tempInfosClientEnregistrement = JSON.parse(
        JSON.stringify(infosClient)
      );

      const pass = infosClient.password1;
      infosClient.password = pass;

      if (clientIdHarvest === null && ifClientExist.value === false) {
        alert(
          "Vérifier de sélectionner le client dans la liste ou cocher qu'il n'existe pas."
        );
        return;
      }

      if (
        infosClient.representativeId === null ||
        !infosClient.compagnyName ||
        !infosClient.contact ||
        !infosClient.email ||
        !infosClient.billingAdressNumber ||
        !infosClient.phone ||
        !infosClient.billingStreet ||
        !infosClient.billingPostalCode ||
        !infosClient.billingCity ||
        !infosClient.billingProvince ||
        !infosClient.billingPays
      ) {
        alert("Des informations sont manquantes");
        return;
      }

      if (!infosClient.shippingIsBilling) {
        if (
          !infosClient.shippingAdressNumber ||
          !infosClient.shippingStreet ||
          !infosClient.shippingPostalCode ||
          !infosClient.shippingCity ||
          !infosClient.shippingProvince ||
          !infosClient.shippingPays
        ) {
          alert("Des informations sont manquantes");
          return;
        }
      }

      // VERIFICATION DE L'EXISTANCE DU CLIENT

      store.state.allClientsInfos.forEach((client) => {
        // PAR NOM DE COMPANGNIE
        if (
          client.customer_compagnyName
            .toLowerCase()
            .includes(infosClient.compagnyName.toLowerCase())
        ) {
          let proceed = confirm(
            `Un client avec le nom ${client.customer_compagnyName} exite déja, voulez-vous quand-même faire l'ajout du client ?`
          );
          if (!proceed) {
            clientExist = true;
          }
        }

        // PAR COURRIEL
        if (client.customer_email === infosClient.email) {
          clientExist = true;
          alert(
            `Un client ayant déja le même courriel existe déja, veuillez vérifier que le client n'existe déja... `
          );
          return;
        }

        // PAR ID HARVEST
        if (clientIdHarvest) {
          if (client.customer_harvestId == clientIdHarvest) {
            let proceed = confirm(
              `Un ou plusieurs clients sont associé(s) aux compte client que vous avez sélectionné, voulez-vous quand-même faire l'ajout du client ?`
            );
            if (!proceed) {
              clientExist = true;
            }
          }
        }
      });

      if (!clientExist) {
        if (clientIdHarvest) {
          infosClient.idHarvest = clientIdHarvest;
          pushClientExtranet();
        } else {
          const adresse = `${infosClient.billingAdressNumber}, ${
            infosClient.billingStreet
          }, \r\n${infosClient.billingCity}, ${
            infosClient.billingProvince
          } \r\n${infosClient.billingPostalCode.toUpperCase()}`;

          const infosForHarvest = {
            name: infosClient.compagnyName,
            currency: "CAD",
            is_active: true,
            address: adresse,
          };

          try {
            const res = await fetch(process.env.VUE_APP_HARVEST_URL, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Harvest-Account-Id": process.env.VUE_APP_HARVEST_ACOUNT_ID,
                Authorization: "Bearer " + process.env.VUE_APP_TOKEN_HARVEST,
              },
              body: JSON.stringify(infosForHarvest),
            });

            if (!res.ok) {
              console.log("error");
            } else {
              let data = await res.json();

              infosClient.idHarvest = data.id;

              pushClientExtranet();
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
    }

    async function pushClientExtranet() {
      successAddClient.value = false;
      errorCreate.value = false;
      store.state.loadingCreateClient = true;
      try {
        let response = await fetch(`${store.state.postCreateClientUrl}`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(infosClient),
        });

        if (!response.ok) {
          console.log("Erreur");
        }

        let data = await response.json();

        if (data.success === 1) {
          successAddClient.value = true;
        } else {
          errorCreate.value = true;
        }

        store.state.loadingCreateClient = false;
      } catch (err) {
        console.log(err);
      }
    }

    return {
      key,
      store,
      error,
      checkKey,
      infosClient,
      getClientsHarvest,
      needInfos,
      checkPassMatch,
      checkPassMinLength,
      needMoreCar,
      createClient,
      clientsListHarvest,
      getClientsInfos,
      selectClient,
      clientsListOk,
      ifClientExist,
      clearSelectedClient,
      inputClientDisabled,
      pushClientExtranet,
      clientExisteHarvest,
      errorCreate,
      successAddClient,
      courrielUtilise,
      generatePass,
      buildPass,
    };
  },
};
</script>

<style lang="scss">
.enregistrementClient {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: rgba(255, 255, 255, 1);
    transition: background-color 5000s ease-in-out 0s;
  }
  input::-webkit-input-placeholder,
  input::-ms-input-placeholder,
  input:-ms-input-placeholder {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }

  input {
    color: #7a7a7a;
    border: 1px solid #dedede;
    outline-width: 0;
  }

  button:hover {
    background-color: #bba267;
  }
}

.wrapperInput {
  max-width: 400px;
  min-width: 250px;
}

#checkifShipping {
  transform: translateY(-8px);
  cursor: pointer;
}

.selectClient {
  margin-top: -20px;

  .check {
    cursor: pointer;
    position: relative;
    margin: auto;
    width: 18px;
    height: 18px;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
    p {
      width: 400px;
      transform: translate(28px, 21px);
    }
  }

  .check svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #c8ccd4;
    stroke-width: 1.5;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
  }
  .check svg path {
    stroke-dasharray: 60;
    stroke-dashoffset: 0;
  }
  .check svg polyline {
    stroke-dasharray: 22;
    stroke-dashoffset: 66;
  }
  .check:hover:before {
    opacity: 1;
  }
  .check:hover svg {
    stroke: #222;
  }
  #cbx:checked + .check svg {
    stroke: #222;
  }
  #cbx:checked + .check svg path {
    stroke-dashoffset: 60;
    transition: all 0.3s linear;
  }
  #cbx:checked + .check svg polyline {
    stroke-dashoffset: 42;
    transition: all 0.2s linear;
    transition-delay: 0.15s;
  }
}
</style>
