<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="/admin" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div
      class="flex-1 md:p-10"
      v-if="
        store.state.allFormatsForStats ||
        store.state.categoriesForStats ||
        store.state.tagsForStats
      "
    >
      <div class="col-span-5 p-8">
        <div class="col-pan-3">
          <div class="text-3xl uppercase flex items-center">
            Statistiques de ventes
            <button @click="toggleModal('modal-id')" id="button-stats-params">
              <svg
                width="64px"
                height="64px"
                viewBox="0 0 1024 1024"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fill="#000000"
                    d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357.12 357.12 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a351.616 351.616 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357.12 357.12 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294.113 294.113 0 0 0-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293.12 293.12 0 0 0 0 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294.113 294.113 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288.282 288.282 0 0 0 34.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293.12 293.12 0 0 0 0-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a287.616 287.616 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384 192 192 0 0 1 0-384zm0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"
                  ></path>
                </g>
              </svg>
            </button>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-12 pt-10">
          <div class="flex flex-col">
            <div class="py-2 font-semibold">Type de ventes :</div>
            <label>
              <input type="radio" v-model="typeStats" value="tous" checked />
              Tous
            </label>

            <label>
              <input type="radio" v-model="typeStats" value="extranet" />
              Extranet
            </label>

            <label>
              <input type="radio" v-model="typeStats" value="particuliers" />
              Particuliers
            </label>
          </div>

          <div class="flex flex-col">
            <div class="py-2 font-semibold">Classer par :</div>
            <label>
              <input
                type="radio"
                v-model="typeRapport"
                value="formats"
                @change="clearValues()"
              />
              Formats
            </label>

            <label>
              <input
                type="radio"
                v-model="typeRapport"
                value="categories"
                @change="clearValues()"
              />
              Catégories
            </label>
            <label>
              <input
                type="radio"
                v-model="typeRapport"
                value="tags"
                @change="clearValues()"
              />
              Étiquettes
            </label>
            <label>
              <input
                type="radio"
                v-model="typeRapport"
                value="unique"
                @change="clearValues()"
              />
              Produit(s) spécifique(s)
            </label>
          </div>
          <div
            v-if="typeRapport === 'categories'"
            class="border p-6 mt-6 col-span-2"
          >
            <div class="font-semibold">Catégories :</div>

            <div v-if="store.state.categoriesForStats">
              <div
                class="grid grid-cols-4 gap-y-2 pt-4"
                v-if="filteredCategories.length > 0"
              >
                <div
                  v-for="(cat, indexCat) in filteredCategories"
                  :key="indexCat"
                >
                  <label>
                    <input
                      type="checkbox"
                      :value="categories"
                      @change="updateSelectedCat($event, cat.id)"
                    />
                    {{ firstLetterUppercase(cat.name) }}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div v-if="typeRapport === 'tags'" class="border p-6 mt-6 col-span-2">
            <div class="font-semibold">Étiquettes :</div>
            <div
              class="grid grid-cols-4 gap-y-2 pt-4"
              v-if="store.state.tagsForStats"
            >
              <div v-for="(tag, indexTag) in filteredTags" :key="indexTag">
                <label>
                  <input
                    type="checkbox"
                    :value="tags"
                    @change="updateSelectedTags($event, tag.id)"
                  />
                  {{ firstLetterUppercase(decodeAmpersand(tag.name)) }}
                </label>
              </div>
            </div>
          </div>
          <div
            v-if="typeRapport === 'unique'"
            class="border p-6 mt-6 col-span-2"
          >
            <div class="font-semibold">Produit(s) spécifique(s) :</div>
            <div
              class="grid grid-cols-4 pt-4 gap-4"
              v-if="store.state.tagsForStats"
            >
              <div class="mb-4 col-span-2">
                <p class="my-4">Sélectionner un produit</p>
                <div class="w-80">
                  <div class="relative">
                    <input
                      v-model="selectProductUnique"
                      @keyup="getProductInfos()"
                      placeholder="Sélectionnez un produit à modifier"
                      type="text"
                      list="produit"
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                    <datalist id="produit">
                      <option
                        v-for="(item, index) in store.state.data"
                        :key="index"
                      >
                        {{ item.product_name }}
                      </option>
                    </datalist>
                  </div>
                </div>
              </div>
              <div
                v-if="selectProductUnique && getProductInfos() !== null"
                class="grid grid-cols-2 pt-10 col-span-2"
              >
                <div>
                  <p class="mt-3 text-xl">
                    {{ store.state.data[getProductInfos()].product_name }}
                  </p>

                  <img
                    :src="
                      store.state.data[getProductInfos()]
                        .product_priceVariations[
                        findIndexFormat(selectFormatUpdate)
                      ].imageVariation
                    "
                    :alt="store.state.data[getProductInfos()].product_name"
                    v-if="
                      selectFormatUpdate &&
                      selectFormatUpdate &&
                      store.state.data[getProductInfos()].product_isVariable &&
                      store.state.data[getProductInfos()]
                        .product_priceVariations.length > 1
                    "
                    class="w-8/12 py-4"
                  />
                  <img
                    :src="
                      store.state.data[getProductInfos()]
                        .product_priceVariations[0].imageVariation
                    "
                    :alt="store.state.data[getProductInfos()].product_name"
                    v-else
                    class="w-8/12 py-4"
                  />
                  <!-- CREATE BUTTON CONFIRMER POUR AJOUTER A LA LISTE DES PRODUITS UNIQUES -->
                  <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 border border-blue-700 rounded"
                    @click="addProductUnique()"
                  >
                    <div class="flex justify-between items-center">
                      Ajouter le produit
                    </div>
                  </button>
                </div>
              </div>
              <div
                v-if="selectedUniques.length"
                class="col-span-4 flex flex-wrap gap-2 w-full"
              >
                <button
                  class="bg-blue-400 hover:bg-red-700 text-white py-2 px-1 border-blue-600 hover:border-red-700 border-1 rounded"
                  v-for="(product, indexProduct) in selectedUniques"
                  :key="indexProduct"
                  @click="removeUniqueProduct(indexProduct)"
                >
                  {{ product.product_name }}
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="typeRapport === 'formats'"
            class="border p-6 mt-6 col-span-2"
          >
            <div class="font-semibold">Formats :</div>
            <div
              class="grid grid-cols-4 gap-y-2 pt-4"
              v-if="store.state.allFormatsForStats"
            >
              <div
                v-for="(format, indexFormat) in filteredFormats"
                :key="indexFormat"
              >
                <label>
                  <input
                    type="checkbox"
                    :value="format"
                    @change="updateSelectedFormats($event, format.formatName)"
                  />
                  {{ format.formatName }}
                </label>
              </div>
            </div>
          </div>
          <div class="flex items-center mb-4">
            <label for="maxResults" class="mr-2 font-semibold"
              >Nombre de résultats maximum :<br />
              <p class="text-gray-400 text-xs uppercase">
                ( Vide = AUCUNE LIMITE )
              </p></label
            >
            <input
              id="maxResults"
              type="number"
              v-model="maxResults"
              class="border border-gray-300 rounded-lg px-3 py-2 w-1/2 sm:w-1/4 focus:outline-none focus:border-blue-500"
            />
          </div>

          <div>
            <input
              type="checkbox"
              v-model="combineVariations"
              :disabled="typeRapport === 'formats'"
            />
            <span class="font-semibold pl-2"
              >Combiner les variations
              <span v-if="typeRapport !== 'formats'">( formats, etc...)</span
              ><span v-else>( non disponible )</span></span
            >
          </div>
          <div class="flex flex-col">
            <span class="font-semibold">Ordre des résultats :</span>
            <label>
              <input type="radio" v-model="orderStats" value="asc" />
              Croissant
            </label>

            <label>
              <input type="radio" v-model="orderStats" value="desc" checked />
              Décroissant
            </label>
          </div>
        </div>

        <div class="grid grid-cols-4 py-4">
          <div class="mr-10">
            <p class="pb-6 font-semibold">Date de début :</p>
            <v-date-picker v-model="startDate" :masks="masks">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
          <div>
            <p class="pb-6 font-semibold">Date de fin :</p>
            <v-date-picker v-model="endDate" :masks="masks">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
        </div>
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded my-4"
          @click="getSalesStats()"
        >
          <div class="flex justify-between items-center">
            Générer le rapport<span class="ml-2" v-if="loadingRapport"
              ><img
                id="loadingLogo"
                src="../../assets/images/spinner_button.gif"
            /></span>
          </div>
        </button>
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded my-4 md:ml-4"
          @click="getPdf()"
        >
          <div class="flex justify-between items-center">
            Télécharger le rapport<span class="ml-2" v-if="loadingPdf"
              ><img
                id="loadingLogo"
                src="../../assets/images/spinner_button.gif"
            /></span>
          </div>
        </button>
      </div>

      <div v-if="store.state.salesStats">
        <table id="rapport_createur">
          <thead>
            <tr class="bg-white text-black text-xs">
              <th colspan="9">
                <div class="mt-4 mb-4 text-xs text-gray-300">
                  STATISTIQUES DE VENTES
                </div>
                <div class="text-base">
                  Type de ventes :
                  {{ firstLetterUppercase(stillTypeClassement) }}
                </div>

                <div class="pt-2">
                  CLASSEMENT
                  {{ showFilters() }}
                </div>

                <div class="pt-2">
                  VARIATIONS COMBINÉES :
                  {{ stillCombineVariations ? "OUI" : "NON" }}
                </div>

                <div class="pt-2" v-if="stillMaxResults">
                  Nombre de résultat(s) maximum : {{ stillMaxResults }}
                </div>

                <div class="text-base lowercase pt-2 pb-4">
                  {{ formatDate(stillStartDate) }} au
                  {{ formatDate(stillEndDate) }}
                </div>
              </th>
            </tr>

            <tr>
              <th scope="col" class="text-center" colspan="2">NOM</th>
              <th scope="col">CATÉGORIES</th>
              <th scope="col" colspan="2">FORMAT</th>
              <th scope="col" colspan="2">ÉTIQUETTES</th>
              <th scope="col">Qté vendue</th>
              <th scope="col">%</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(stat, index) in store.state.salesStats.data"
              :key="index"
            >
              <td class="text-left" colspan="2">
                {{ stat.productName }}
              </td>
              <td class="item-qty text-left" colspan="2">
                <div class="container-cats-rapport">
                  <div v-if="showGoodCats(stat.categories).length > 1">
                    <button
                      class="toggle-button-cat"
                      @click="toggleCatsDisplay(index)"
                    >
                      {{
                        showAllCats[index]
                          ? "-"
                          : `+ ${showGoodCats(stat.categories).length - 1}`
                      }}
                    </button>
                  </div>

                  <div>
                    <div
                      v-for="(cat, indexCat) in showGoodCats(stat.categories)"
                      :key="indexCat"
                    >
                      <div
                        v-if="indexCat === 0 || showAllCats[index]"
                        class="capitalize"
                      >
                        {{ decodeAmpersand(cat.name) }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="item-qty text-left">
                {{ correctionCar(stat.variationName) }}
              </td>
              <td class="item-qty text-left" colspan="2">
                <div class="container-tags-rapport">
                  <div v-if="stat.tags.length > 1">
                    <button
                      class="toggle-button"
                      @click="toggleTagsDisplay(index)"
                    >
                      {{
                        showAllTags[index] ? "-" : `+ ${stat.tags.length - 1}`
                      }}
                    </button>
                  </div>

                  <div>
                    <div v-for="(tag, indexTag) in stat.tags" :key="indexTag">
                      <div
                        v-if="indexTag === 0 || showAllTags[index]"
                        class="capitalize"
                      >
                        {{ decodeAmpersand(tag.name) }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>

              <td class="item-qty text-right">
                {{ stat.quantity }}
              </td>
              <td class="item-price text-right">{{ stat.percentage }} %</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- TABLEAU POUR GENERATEUR PDF -->

      <div v-show="false" v-if="store.state.salesStats">
        <table id="rapport-ventes-trim">
          <thead>
            <tr class="bg-white text-black text-xs">
              <th colspan="3" class="uppercase">
                RAPPORT STATISTIQUES DE VENTES
                <br />
                TYPE DE VENTES: {{ uppercaseAll(stillTypeClassement) }}
                <br />
                CLASSEMENT {{ showFilters() }}
                <br />
                VARIATIONS COMBINÉES :
                {{ stillCombineVariations ? "OUI" : "NON" }}
                <div v-if="stillMaxResults">
                  Nombre de résultat(s) maximum : {{ stillMaxResults }}
                </div>
              </th>

              <th colspan="3">
                {{ formatDate(stillStartDate) }} au
                {{ formatDate(stillEndDate) }}
              </th>
            </tr>
            <tr>
              <th>NOM</th>
              <th>CATÉGORIES</th>
              <th>FORMAT</th>
              <th>ÉTIQUETTES</th>
              <th class="text-center">QTÉ VENDUE</th>
              <th class="text-center">%</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(stat, index) in store.state.salesStats.data"
              :key="index"
            >
              <td class="item-qty text-left">
                {{ stat.productName }}
              </td>
              <td class="text-center">
                {{
                  showGoodCats(stat.categories)
                    ? showGoodCats(stat.categories)[0].name
                    : null
                }}
                {{
                  showGoodCats(stat.categories).length > 1
                    ? `+ ${showGoodCats(stat.categories).length - 1}`
                    : ""
                }}
              </td>
              <td class="item-qty text-left">
                {{ correctionCar(stat.variationName) }}
              </td>
              <td class="item-qty text-left">
                <span v-if="stat.tags.length"
                  >{{ decodeAmpersand(stat.tags[0].name) }}
                  <span v-if="stat.tags.length > 1">
                    +{{ stat.tags.length - 1 }}</span
                  >
                </span>
              </td>
              <td class="item-qty text-right">
                {{ stat.quantity }}
              </td>
              <td class="item-qty">{{ stat.percentage }} %</td>
            </tr>
            <tr class="text-offset">
              <td colspan="6">
                Rapport des ventes du
                {{ formatDate(stillStartDate) }} au
                {{ formatDate(stillEndDate) }} inclusivement
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- FIN RAPPORT STATS -->
      <!-- MODAL PARAMETRE -->

      <div class="flex-1 md:p-10">
        <!-- MODAL DE MODIF -->

        <div
          class="hidden overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center"
          id="modal-id"
        >
          <div class="relative my-6 mx-auto max-w-6xl w-11/12">
            <!--content-->
            <div
              class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
            >
              <!--header-->
              <div
                class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
              >
                <h3 class="text-2xl font-semibold pl-4">Paramètres</h3>
                <button
                  class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  @click="toggleModal('modal-id')"
                >
                  <span
                    class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                  >
                    x
                  </span>
                </button>
              </div>

              <!-- body -->

              <div class="relative p-6 flex-auto">
                <div
                  class="flex items-center gap-3 cursor-pointer"
                  @click="showCatsParams()"
                >
                  <div class="py-3">Catégories à dissimuler</div>

                  <div class="chevron-container-stats" id="chevron-cats">
                    <svg
                      fill="#000000"
                      height="12px"
                      width="12px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 407.437 407.437"
                      xml:space="preserve"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <polygon
                          points="386.258,91.567 203.718,273.512 21.179,91.567 0,112.815 203.718,315.87 407.437,112.815 "
                        ></polygon>
                      </g>
                    </svg>
                  </div>
                </div>
                <div
                  class="grid grid-cols-4 gap-y-2 py-3"
                  v-if="store.state.categoriesForStats && showHideCats"
                >
                  <div
                    v-for="(cat, indexCat) in store.state.categoriesForStats"
                    :key="indexCat"
                  >
                    <label>
                      <input
                        type="checkbox"
                        :value="cat.hideCat"
                        :checked="cat.hideCat"
                        @change="updateCatToHide($event, cat.id)"
                      />
                      <span class="pl-3">{{ cat.name }}</span>
                    </label>
                  </div>
                </div>
                <div
                  class="flex items-center gap-3 cursor-pointer"
                  @click="showTagsParams()"
                >
                  <div class="py-3">Étiquettes à dissimuler</div>
                  <div class="chevron-container-stats" id="chevron-tags">
                    <svg
                      fill="#000000"
                      height="12px"
                      width="12px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 407.437 407.437"
                      xml:space="preserve"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <polygon
                          points="386.258,91.567 203.718,273.512 21.179,91.567 0,112.815 203.718,315.87 407.437,112.815 "
                        ></polygon>
                      </g>
                    </svg>
                  </div>
                </div>

                <div
                  class="grid grid-cols-4 gap-y-2 py-3"
                  v-if="store.state.tagsForStats && showHideTags"
                >
                  <div
                    v-for="(tag, indexTag) in store.state.tagsForStats"
                    :key="indexTag"
                  >
                    <label>
                      <input
                        type="checkbox"
                        :value="tag.hideTag"
                        :checked="tag.hideTag"
                        @change="updateTagToHide($event, tag.id)"
                      />
                      <span class="capitalize pl-3">{{
                        decodeAmpersand(tag.name)
                      }}</span>
                    </label>
                  </div>
                </div>
                <div
                  class="flex items-center gap-3 cursor-pointer py-3"
                  @click="showFormatsParams()"
                >
                  Formats à dissimuler

                  <div class="chevron-container-stats" id="chevron-formats">
                    <svg
                      fill="#000000"
                      height="12px"
                      width="12px"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 407.437 407.437"
                      xml:space="preserve"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <polygon
                          points="386.258,91.567 203.718,273.512 21.179,91.567 0,112.815 203.718,315.87 407.437,112.815 "
                        ></polygon>
                      </g>
                    </svg>
                  </div>
                </div>

                <div
                  class="grid grid-cols-4 gap-y-2 py-3"
                  v-if="store.state.allFormatsForStats && showHideFormats"
                >
                  <div
                    v-for="(format, indexFormat) in store.state
                      .allFormatsForStats"
                    :key="indexFormat"
                  >
                    <label>
                      <input
                        type="checkbox"
                        :value="format.hideFormat"
                        :checked="format.hideFormat"
                        @change="updateFormatToHide($event, format.formatName)"
                      />
                      <span class="pl-3">{{ format.formatName }}</span>
                    </label>
                  </div>
                </div>
              </div>

              <!-- footer -->

              <div
                class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
              >
                <button
                  class="text-green-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  @click="toggleModal('modal-id')"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- FIN MODAL PARAMETRES-->

      <div
        class="hidden opacity-25 fixed inset-0 z-40 bg-black"
        id="modal-id-backdrop"
      ></div>
    </div>

    <div v-else class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { inject, onMounted, ref, computed } from "vue";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  name: "GestionClients",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");
    const loadingRapport = ref(false);
    const success = ref(false);
    const erreur = ref(false);
    const modalIsOpen = ref(false);
    const selectForUpdate = ref(null);
    const clientsListHarvest = ref([null]);
    const clientsListOk = ref(false);
    const messageUpdateInfo = ref(null);
    const stillCombineVariations = ref(null);
    const stillStartDate = ref(null);
    const stillEndDate = ref(null);
    const selectedFormats = ref([]);
    const stillSelectedFormats = ref([]);
    const stillSelectedCats = ref([]);
    const stillSelectedTags = ref([]);
    const stillSelectedUniques = ref([]);
    const selectedUniques = ref([]);
    const stillMaxResults = ref(null);
    const selectedCats = ref([]);
    const selectedTags = ref([]);
    const combineVariations = ref(false);
    const typeRapport = ref(null);
    const typeStats = ref("tous");
    const maxResults = ref(null);
    const orderStats = ref("desc");
    const salesStats = ref(null);
    const startDate = ref(null);
    const showAllTags = ref([]);
    const showAllCats = ref([]);
    const showHideTags = ref(false);
    const showHideCats = ref(false);
    const canProducePdf = ref(false);
    const showHideFormats = ref(false);
    const showParametres = ref(false);
    const stillTypeRapport = ref(null);
    const stillTypeClassement = ref(null);
    const selectProductUnique = ref(null);
    const loadingPdf = ref(false);
    const filtersUsed = ref([]);
    const endDate = ref(new Date());
    const masks = {
      input: "DD-MM-YYYY",
    };

    const CATS_TO_HIDE_REPORT = [
      `12"x16"`,
      `16"x20"`,
      `5"x7"`,
      `8"x10"`,
      `9"x9"`,
    ];

    function toggleModal(modalID) {
      clearValues();
      typeRapport.value = null;

      modalIsOpen.value = !modalIsOpen.value;

      document.getElementById(modalID).classList.toggle("hidden");
      document.getElementById(modalID + "-backdrop").classList.toggle("hidden");
      document.getElementById(modalID).classList.toggle("flex");
      document.getElementById(modalID + "-backdrop").classList.toggle("flex");
    }

    function showGoodCats(cats) {
      return cats.filter((cat) => !CATS_TO_HIDE_REPORT.includes(cat.name));
    }

    function correctionCar(texte) {
      if (!texte) return;
      return texte.replace(/″/g, '"');
    }

    function showTagsParams() {
      showHideTags.value = !showHideTags.value;
      document
        .querySelector("#chevron-tags")
        .classList.toggle("chevron-rotate");
    }

    function showCatsParams() {
      showHideCats.value = !showHideCats.value;
      document
        .querySelector("#chevron-cats")
        .classList.toggle("chevron-rotate");
    }

    function showFormatsParams() {
      showHideFormats.value = !showHideFormats.value;
      document
        .querySelector("#chevron-formats")
        .classList.toggle("chevron-rotate");
    }

    function toggleTagsDisplay(index) {
      showAllTags.value[index] = !showAllTags.value[index];
    }

    function toggleCatsDisplay(index) {
      showAllCats.value[index] = !showAllCats.value[index];
    }

    const filteredCategories = computed(() => {
      return store.state.categoriesForStats.filter((cat) => !cat.hideCat);
    });

    const filteredTags = computed(() => {
      return store.state.tagsForStats.filter((tag) => !tag.hideTag);
    });

    const filteredFormats = computed(() => {
      return store.state.allFormatsForStats.filter(
        (format) => !format.hideFormat
      );
    });

    const updateSelectedFormats = (event, format) => {
      if (event.target.checked) {
        selectedFormats.value.push(format);
      } else {
        const index = selectedFormats.value.indexOf(format);
        if (index !== -1) {
          selectedFormats.value.splice(index, 1);
        }
      }
    };

    const updateSelectedCats = (event, cat) => {
      if (event.target.checked) {
        selectedCats.value.push(cat);
      } else {
        const index = selectedCats.value.indexOf(cat);
        if (index !== -1) {
          selectedCats.value.splice(index, 1);
        }
      }
    };

    const updateSelectedTags = (event, cat) => {
      if (event.target.checked) {
        selectedTags.value.push(cat);
      } else {
        const index = selectedTags.value.indexOf(cat);
        if (index !== -1) {
          selectedTags.value.splice(index, 1);
        }
      }
    };

    // Utilisez cette propriété calculée pour obtenir les formats sélectionnés

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    async function getSalesStats() {
      // VALIDATE

      if (!isValidInteger(maxResults.value) && maxResults.value !== null) {
        alert(
          "Veuillez entrez un nombre valide pour la quantité maximum de résultats."
        );
        return;
      }

      if (!startDate.value || !endDate.value) {
        alert("Veuillez indiquer une date de début et de fin.");
        return;
      }

      if (startDate.value > endDate.value) {
        alert("La date de début doit être antérieure à la date de fin.");
        return;
      }

      if (
        typeStats.value !== "tous" &&
        typeStats.value !== "extranet" &&
        typeStats.value !== "particuliers"
      ) {
        alert(
          "Veuillez indiquer un type de rapport ( type de ventes ) à produire"
        );
        return;
      }

      if (!typeRapport.value) {
        alert("Veuillez indiquer un type de classement de produits");
        return;
      }

      if (orderStats.value !== "asc" && orderStats.value !== "desc") {
        alert("Veuillez sélectionner un ordre de tri");
        return;
      }

      if (
        typeRapport.value === "categories" &&
        selectedCats.value.length === 0
      ) {
        alert("Veuillez sélectionner au moins une catégorie");
        return;
      }

      if (typeRapport.value === "tags" && selectedTags.value.length === 0) {
        alert("Veuillez sélectionner au moins une étiquette");
        return;
      }

      if (
        typeRapport.value === "formats" &&
        selectedFormats.value.length === 0
      ) {
        alert("Veuillez sélectionner au moins un format");
        return;
      }

      if (
        typeRapport.value === "unique" &&
        selectedUniques.value.length === 0
      ) {
        alert("Veuillez sélectionner au moins un produit unique");
        return;
      }

      let uniqueProductId = [];
      if (typeRapport.value === "unique") {
        selectedUniques.value.forEach((product) => {
          uniqueProductId.push(product.product_id);
        });
      }

      stillStartDate.value = deepCopy(startDate.value);
      stillEndDate.value = deepCopy(endDate.value);
      stillTypeRapport.value = deepCopy(typeRapport.value);
      stillTypeClassement.value = deepCopy(typeStats.value);
      stillSelectedFormats.value = deepCopy(selectedFormats.value);
      stillSelectedCats.value = deepCopy(selectedCats.value);
      stillSelectedTags.value = deepCopy(selectedTags.value);
      stillCombineVariations.value = deepCopy(combineVariations.value);
      stillMaxResults.value = deepCopy(maxResults.value);
      stillSelectedUniques.value = deepCopy(selectedUniques.value);
      canProducePdf.value = false;
      loadingRapport.value = true;
      store.state.salesStats = null;
      try {
        const response = await fetch(store.state.getSalesStatsUrl, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            type: typeStats.value,
            maxResults: maxResults.value,
            sort: orderStats.value,
            combineVariations: combineVariations.value,
            startDate: startDate.value,
            endDate: endDate.value,
            formatsList: selectedFormats.value,
            uniqueList: uniqueProductId,
            categoriesList: selectedCats.value,
            tagsList: selectedTags.value,
            typeStats: [typeRapport.value],
          }),
        });
        if (!response.ok) {
          console.log(response);
          throw new Error("Failed to fetch sales stats.");
        }

        const data = await response.json();
        store.state.salesStats = data.data;
        loadingRapport.value = false;
        canProducePdf.value = true;
      } catch (error) {
        console.error(error);
        loadingRapport.value = false;

        // Afficher un message d'erreur à l'utilisateur ou gérer l'erreur d'une autre manière.
      }
    }

    function decodeAmpersand(text) {
      return text.replace(/&amp;/g, "&");
    }

    function deepCopy(source) {
      if (source === null || typeof source !== "object") {
        return source;
      }

      if (source instanceof Date) {
        return new Date(source);
      }

      if (Array.isArray(source)) {
        const copy = [];
        for (let i = 0; i < source.length; i++) {
          copy[i] = deepCopy(source[i]);
        }
        return copy;
      }

      if (source instanceof Object) {
        const copy = {};
        for (const key of Object.keys(source)) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            copy[key] = deepCopy(source[key]);
          }
        }
        return copy;
      }
    }

    async function getCategories() {
      try {
        const response = await fetch(store.state.getCategoriesUrl, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch categories.");
        }

        const data = await response.json();

        // if categorie contain one of the statsCatsToHide id, remove it from the array

        let filteredCats = [];

        data.data.forEach((cat) => {
          let hideCat = false; // Par défaut, on suppose que la catégorie ne doit pas être cachée

          store.state.statsCatsToHide.forEach((catToHide) => {
            if (cat.id === catToHide.catId && catToHide.hideCat) {
              hideCat = true; // Si hideCat est vrai dans statsCatsToHide, on indique que la catégorie doit être cachée
            }
          });

          filteredCats.push({ ...cat, hideCat });
        });

        store.state.categoriesForStats = filteredCats;
      } catch (error) {
        console.error(error);

        // Afficher un message d'erreur à l'utilisateur ou gérer l'erreur d'une autre manière.
      }
    }

    async function getCatsToHide() {
      try {
        const response = await fetch(store.state.getCatsToHideUrl, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error("Failed to fetch categories.");
        }

        const data = await response.json();

        store.state.statsCatsToHide = data.data;

        getCategories();
      } catch (error) {
        console.log(error);
      }
    }

    async function getTagsToHide() {
      try {
        const response = await fetch(store.state.getTagsToHideUrl, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error("Failed to fetch categories.");
        }

        const data = await response.json();

        store.state.statsTagsToHide = data.data;

        getTags();
      } catch (error) {
        console.log(error);
      }
    }

    async function getFormatsToHide() {
      try {
        const response = await fetch(store.state.getFormatsToHideUrl, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error("Failed to fetch formats.");
        }

        const data = await response.json();

        store.state.formatsToHide = data.data;

        // ENCLENCHER LA REQUETE DES FORMATS
        getFormats();
      } catch (error) {
        console.log(error);
      }
    }

    async function getFormats() {
      try {
        const response = await fetch(store.state.getAllProductsUrl, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch formats.");
        }

        const data = await response.json();

        let allFormats = [];

        data.data.forEach((product) => {
          if (product.attributes) {
            product.attributes.forEach((element) => {
              if (element.name.toLowerCase() === "format") {
                element.options.forEach((format) => {
                  allFormats.push(format);
                });
              }
            });
          }
        });

        // remove all duplicates
        allFormats = [...new Set(allFormats)];

        let filteredFormats = [];

        allFormats.forEach((formatName) => {
          let hideFormat = false; // Par défaut, on suppose que le format ne doit pas être cachée

          store.state.formatsToHide.forEach((formatToHide) => {
            if (
              formatName === formatToHide.formatName &&
              formatToHide.hideFormat
            ) {
              hideFormat = true;
            }
          });

          filteredFormats.push({ formatName, hideFormat });
        });
        // sort format by formatName
        filteredFormats.sort((a, b) => {
          if (a.formatName < b.formatName) {
            return -1;
          }
          if (a.formatName > b.formatName) {
            return 1;
          }
          return 0;
        });

        store.state.allFormatsForStats = filteredFormats;
      } catch (error) {
        console.error(error);
      }
    }

    async function getTags() {
      try {
        const response = await fetch(store.state.getTagsUrl, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Failed to fetch categories.");
        }

        const data = await response.json();

        let filteredTags = [];

        data.data.forEach((tag) => {
          let hideTag = false; // Par défaut, on suppose que la catégorie ne doit pas être cachée

          store.state.statsTagsToHide.forEach((tagToHide) => {
            if (tag.id === tagToHide.tagId && tagToHide.hideTag) {
              hideTag = true; // Si hideCat est vrai dans statsCatsToHide, on indique que la catégorie doit être cachée
            }
          });

          filteredTags.push({ ...tag, hideTag });
        });

        store.state.tagsForStats = filteredTags;
      } catch (error) {
        console.error(error);

        // Afficher un message d'erreur à l'utilisateur ou gérer l'erreur d'une autre manière.
      }
    }

    function clearValues() {
      if (typeRapport.value === "formats") {
        combineVariations.value = false;
      }

      selectedCats.value = [];
      selectedTags.value = [];
      selectedFormats.value = [];
    }

    async function updateCatToHide(e, catId) {
      const response = await fetch(store.state.updateCatToHideUrl, {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          catId,
          hideCat: e.target.checked,
        }),
      });

      const data = await response.json();

      // if success update categoriesForStats in store
      if (data.success) {
        // find the good cat in store.state.categoriesForStats and update it if exist
        store.state.categoriesForStats.forEach((cat) => {
          if (cat.id === catId) {
            cat.hideCat = e.target.checked;
          }
        });
      }
    }

    async function updateTagToHide(e, tagId) {
      const response = await fetch(store.state.updateTagToHideUrl, {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          tagId,
          hideTag: e.target.checked,
        }),
      });

      const data = await response.json();
      // if success update tagsToHide in store
      if (data.success) {
        // find the good tag in store.state.tagsToHide and update it if exist
        store.state.tagsForStats.forEach((tag) => {
          if (tag.id === tagId) {
            tag.hideTag = e.target.checked;
          }
        });
      }
    }

    async function updateFormatToHide(e, formatName) {
      const response = await fetch(store.state.updateFormatToHideUrl, {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          formatName,
          hideFormat: e.target.checked,
        }),
      });

      const data = await response.json();
      // if success update tagsToHide in store
      if (data.success) {
        // find the good tag in store.state.tagsToHide and update it if exist
        store.state.allFormatsForStats.forEach((format) => {
          if (format.formatName === formatName) {
            format.hideFormat = e.target.checked;
          }
        });
      }
    }

    function firstLetterUppercase(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

    function formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }

    function showFilters() {
      if (stillTypeRapport.value === "categories") {
        let catsName = [];
        if (stillSelectedCats.value.length > 0) {
          // find the name of the cat in the store

          stillSelectedCats.value.forEach((cat) => {
            store.state.categoriesForStats.forEach((catInStore) => {
              if (catInStore.id === cat) {
                catsName.push(catInStore.name);
              }
            });
          });
        }

        return "PAR CATÉGORIE(S) : " + catsName.join(", ");
      }
      if (stillTypeRapport.value === "tags") {
        let tagsName = [];
        if (stillSelectedTags.value.length > 0) {
          // find the name of the cat in the store

          stillSelectedTags.value.forEach((tag) => {
            store.state.tagsForStats.forEach((tagInStore) => {
              if (tagInStore.id === tag) {
                tagsName.push(tagInStore.name);
              }
            });
          });
        }

        return "PAR ÉTIQUETTE(S) : " + tagsName.join(", ");
      }

      if (stillTypeRapport.value === "formats") {
        let formatsName = [];
        if (stillSelectedFormats.value.length > 0) {
          // find the name of the cat in the store

          stillSelectedFormats.value.forEach((format) => {
            store.state.allFormatsForStats.forEach((formatInStore) => {
              if (formatInStore.formatName === format) {
                formatsName.push(formatInStore.formatName);
              }
            });
          });
        }

        return "PAR FORMAT(S) : " + formatsName.join(", ");
      }

      if (stillTypeRapport.value === "unique") {
        let formatsName = [];
        if (stillSelectedUniques.value.length > 0) {
          // find the name of the cat in the store

          stillSelectedUniques.value.forEach((item) => {
            formatsName.push(item.product_name);
          });
        }

        return "PAR PRODUIT(S) SPÉCIFIQUE(S) : " + formatsName.join(", ");
      }
    }

    function isValidInteger(input) {
      const parsedInput = parseInt(input);
      return !isNaN(parsedInput) && Number.isInteger(parsedInput);
    }

    function getProductInfos() {
      let index = store.state.data.findIndex(
        (x) => x.product_name === selectProductUnique.value
      );

      if (index !== -1) {
        return index;
      } else {
        return null;
      }
    }

    function addProductUnique() {
      // add selectedProduct to selectedUniques if not already in

      const productIndex = getProductInfos();
      if (productIndex !== null) {
        const product = store.state.data[productIndex];
        const found = selectedUniques.value.find(
          (x) => x.product_id === product.product_id
        );

        if (!found) {
          selectedUniques.value.push({
            product_name: product.product_name,
            product_id: product.product_id,
          });
        }
      }
    }

    function removeUniqueProduct(indexProduct) {
      // remove cliked product

      // confirm is yes or no delet product
      if (confirm("Voulez-vous vraiment supprimer ce produit de la liste ?")) {
        selectedUniques.value.splice(indexProduct, 1);
      }
    }

    function getPdf() {
      if (!canProducePdf.value) {
        alert("Veuillez générer le rapport avant de le télécharger");
      } else {
        loadingPdf.value = true;
        (async () => {
          await buildPdf();
          loadingPdf.value = false;
        })();
      }
    }

    function buildPdf() {
      const doc = new jsPDF({
        format: "letter",
        orientation: "portrait",
      });
      doc.autoTable({
        html: "#rapport-ventes-trim",
        didParseCell: function (data) {
          var rows = data.table.body;
          if (data.row.index === rows.length - 1) {
            data.cell.styles.fillColor = [44, 44, 44];
            data.cell.styles.halign = "right";
            data.cell.styles.valign = "middle";

            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.minCellHeight = 10;
          }
        },

        headStyles: {
          fillColor: [44, 44, 44],
          textColor: [255, 255, 255],
        },
        columnStyles: {
          0: { cellWidth: 45 }, // DETAILS
          1: { cellWidth: 36 }, // DETAILS
          2: { cellWidth: 36 }, // DETAILS
          3: { cellWidth: 36 }, // DETAILS
          4: { cellWidth: "auto", halign: "right" }, // QTY
          5: { cellWidth: "auto", halign: "right" }, // %
        },
        styles: {
          fontSize: 8,
          overflow: "linebreak",
        },
      });

      doc.save(
        `${formatDate(stillStartDate.value)}_au_${formatDate(
          stillEndDate.value
        )}_${stillTypeClassement.value}_${showFilters()}_${
          stillTypeClassement.value
        }.pdf`
      );
    }

    function uppercaseAll(text) {
      return text.toUpperCase();
    }

    onMounted(() => {
      getTagsToHide();
      getCatsToHide();
      getFormatsToHide();
      if (store.state.data === null) {
        store.methods.fetchAllProducts();
      }
    });

    return {
      formatDate,
      store,
      updateTagToHide,
      toggleMenu,
      success,
      firstLetterUppercase,
      messageUpdateInfo,
      erreur,
      modalIsOpen,
      selectForUpdate,
      clientsListHarvest,
      clientsListOk,
      deepCopy,
      getCategories,
      toggleModal,
      getTags,
      showHideCats,
      showHideTags,
      showHideFormats,
      getFormats,
      filteredFormats,
      showGoodCats,
      showCatsParams,
      showFormatsParams,
      updateFormatToHide,
      selectedFormats,
      updateSelectedFormats,
      selectedCats,
      updateSelectedCat: updateSelectedCats,
      updateSelectedTags,
      selectedTags,
      typeStats,
      maxResults,
      orderStats,
      combineVariations,
      startDate,
      endDate,
      masks,
      typeRapport,
      salesStats,
      getSalesStats,
      decodeAmpersand,
      showAllTags,
      toggleTagsDisplay,
      toggleCatsDisplay,
      showAllCats,
      getCatsToHide,
      showParametres,
      updateCatToHide,
      filteredCategories,
      getTagsToHide,
      filteredTags,
      getFormatsToHide,
      showTagsParams,
      loadingRapport,
      clearValues,
      stillStartDate,
      stillEndDate,
      stillTypeRapport,
      stillTypeClassement,
      stillSelectedTags,
      stillSelectedCats,
      stillSelectedFormats,
      stillCombineVariations,
      stillMaxResults,
      showFilters,
      filtersUsed,
      isValidInteger,
      stillSelectedUniques,
      selectedUniques,
      getProductInfos,
      selectProductUnique,
      addProductUnique,
      removeUniqueProduct,
      correctionCar,
      loadingPdf,
      buildPdf,
      getPdf,
      uppercaseAll,
    };
  },
};
</script>
<style>
.toggle-button,
.toggle-button-cat {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 15px;
  position: absolute;
  top: -17px;
  left: 87%;
  padding: 5px;
}

.toggle-button-cat {
  left: 90%;
}

.toggle-button:first-letter,
.toggle-button-cat:first-letter {
  transform: scale(2);
  font-weight: 400;
  font-size: 20px;
}

.capitalize:first-letter {
  text-transform: capitalize;
}

.container-tags-rapport,
.container-cats-rapport {
  position: relative;
}

.chevron-container-stats {
  margin-top: 4px;
  transition: all 0.3s ease-in;
}

.chevron-rotate {
  transform: rotate(180deg);
}

#button-stats-params {
  transform: scale(0.35);
  transform-origin: center;
  transition: all 0.3s ease-in;
  margin-bottom: 7px;
}

#button-stats-params:hover {
  transform: rotate(90deg) scale(0.4);
}
</style>
