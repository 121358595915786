<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <!-- MODAL DE MODIF -->

      <div
        class="hidden overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center"
        id="modal-id"
      >
        <div
          class="relative my-6 mx-auto max-w-6xl w-11/12"
          v-if="selectForUpdate !== null"
        >
          <!--content-->
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            <!--header-->
            <div
              class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
            >
              <h3 class="text-2xl font-semibold pl-4">
                {{ store.state.newProducts[selectForUpdate].product_name }}
                <span
                  v-if="
                    store.state.newProducts[selectForUpdate].product_isVariable
                  "
                  >-
                  {{ store.state.newProducts[selectForUpdate].product_format }}
                </span>
                - SKU {{ store.state.newProducts[selectForUpdate].product_sku }}
              </h3>
              <button
                class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                @click="toggleModal('modal-id')"
              >
                <span
                  class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                >
                  x
                </span>
              </button>
            </div>
            <!--body-->
            <div class="relative p-6 flex-auto">
              <div class="grid grid-cols-1 md:grid-cols-3">
                <div class="col-span-1">
                  <img
                    :src="
                      store.state.newProducts[selectForUpdate].product_images
                    "
                    v-if="
                      store.state.newProducts[selectForUpdate]
                        .product_isVariable
                    "
                    class="image-modal-principale pb-10 "
                  />
                  <img
                    :src="
                      store.state.newProducts[selectForUpdate].product_images[0]
                        .src
                    "
                    v-else
                    class="image-modal-principale pb-10 "
                  />
                </div>
              </div>

              <div class="col-span-2 py-2">
                <p class="pb-1 pt-2">Prix:</p>
                <input
                  type="number"
                  v-model="carteInfos.prix"
                  class="py-3 px-2 text-md border border-blue-lighter rounded h-10 w-20"
                />
                $
              </div>

              <div class="relative">
                <p class="pb-3">Créateur:</p>
                <select
                  v-model="selectedCreator"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option disabled selected value="null"
                    >Veuillez sélectionner un créateur à associer à ce
                    produit</option
                  >
                  <option
                    v-for="(creator, index) in store.state.creators"
                    :key="index"
                    :value="creator.creator_id"
                    >{{ creator.creator_firstName }}
                    {{ creator.creator_lastName }}</option
                  >
                </select>
                <div
                  class="pointer-events-none absolute inset-y-2 right-0 top-10 flex items-center px-2 text-gray-700"
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>

              <div class="flex justify-center w-full pt-5" v-if="error">
                <p class="text-red-400">
                  Veuillez remplir tous les champs nécessaires.
                </p>
              </div>
            </div>
            <!--footer-->
            <div
              class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
            >
              <button
                class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="toggleModal('modal-id')"
              >
                Fermer
              </button>
              <button
                class="bg-emerald-500 text-green-400 active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="saveCarte()"
              >
                Sauvegarder
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- FIN DU MODAL -->

      <div
        class="hidden opacity-25 fixed inset-0 z-40 bg-black"
        id="modal-id-backdrop"
      ></div>

      <div class="col-span-5 p-8 ">
        <div class="col-span-3">
          <p class="text-3xl uppercase">
            Gestion des produits
          </p>
        </div>
        <div class="w-full mt-10 grid grid-cols-1 lg:grid-cols-3">
          <button
            class="button_admin bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mr-10 uppercase w-11/12"
            @click="
              mode = 'ajouter';
              selectedCreator = null;
              refreshProduct();
            "
          >
            Actualiser les produits
          </button>
          <button
            class="button_admin bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mr-10 uppercase w-11/12 mt-4 lg:mt-0"
            @click="
              mode = 'modifier';
              editCreator = {};
              currentCreatorEdit = 'choose';
            "
          >
            Modifier un produit
          </button>
          <button
            class="button_admin bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mr-10 uppercase w-11/12 mt-4 lg:mt-0"
            @click="
              mode = 'avance';
              editCreator = {};
              currentCreatorEdit = 'choose';
            "
          >
            Gestion avancé
          </button>
        </div>

        <div v-if="mode === 'modifier'">
          <div class="mb-4 mt-18">
            <p class="text-2xl my-10">Modification d'un produit</p>
            <div class="w-full md:w-6/12">
              <div class="relative">
                <input
                  v-model="selectCarteEdit"
                  @keyup="getCarteInfos()"
                  placeholder="Sélectionnez un produit à modifier"
                  type="text"
                  list="produit"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                />
                <datalist id="produit">
                  <option
                    v-for="(item, index) in store.state.data"
                    :key="index"
                    >{{ item.product_name }}</option
                  >
                </datalist>
              </div>
            </div>
          </div>

          <div
            v-if="selectCarteEdit"
            class="grid grid-cols-2 gap-y-2 pt-10"
            @change="getCarteInfos()"
          >
            <div>
              <p class="text-2xl">
                {{ store.state.data[getCarteInfos()].product_name }}
              </p>

              <img
                :src="
                  store.state.data[getCarteInfos()].product_priceVariations[
                    findIndexFormat(selectFormatUpdate)
                  ].imageVariation
                "
                :alt="store.state.data[getCarteInfos()].product_name"
                v-if="
                  selectFormatUpdate &&
                    selectFormatUpdate &&
                    store.state.data[getCarteInfos()].product_isVariable &&
                    store.state.data[getCarteInfos()].product_priceVariations
                      .length > 1
                "
                class="w-8/12 py-4"
              />
              <img
                :src="
                  store.state.data[getCarteInfos()].product_priceVariations[0]
                    .imageVariation
                "
                :alt="store.state.data[getCarteInfos()].product_name"
                v-else
                class="w-8/12 py-4"
              />
            </div>
            <div class="relative col-span-2">
              <p class="pb-3">Créateur:</p>
              <select
                v-model="store.state.data[getCarteInfos()].product_creator"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:w-6/12"
              >
                <option
                  v-for="(creator, index) in store.state.creators"
                  :key="index"
                  :value="creator.creator_id"
                  >{{ creator.creator_firstName }}
                  {{ creator.creator_lastName }}</option
                >
              </select>
              <div
                class="pointer-events-none absolute inset-y-1 right-0 top-10 flex items-center px-2 text-gray-700 lg:w-7/12"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>

            <div class="relative col-span-2">
              <p
                v-if="store.state.data[getCarteInfos()].product_isVariable"
                class="mt-4"
              >
                Format du produit
              </p>

              <select
                v-model="selectFormatUpdate"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mt-3 lg:w-6/12"
                v-if="store.state.data[getCarteInfos()].product_isVariable"
              >
                <option disabled selected value="null"
                  >Veuillez sélectionner un format à modifier</option
                >
                <option
                  v-for="(variation, index) in store.state.data[getCarteInfos()]
                    .product_priceVariations"
                  :key="index"
                  :value="variation.variation"
                  >{{ variation.format }} - {{ variation.variation }}
                </option>
              </select>

              <div
                class="pointer-events-none absolute -inset-y-3 right-0 top-9 flex items-center px-2 text-gray-700 lg:w-7/12"
                v-if="store.state.data[getCarteInfos()].product_isVariable"
              >
                <svg
                  class="fill-current h4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>

            <div
              v-if="
                selectFormatUpdate ||
                  !store.state.data[getCarteInfos()].product_isVariable
              "
            >
              <div>
                <p v-if="store.state.data[getCarteInfos()]" class="mt-4">
                  Publier le produit
                  <input
                    id="codeActif"
                    class="pl-2"
                    type="checkbox"
                    v-model="
                      store.state.data[getCarteInfos()].product_priceVariations[
                        findIndexFormat(selectFormatUpdate)
                      ].variationShow
                    "
                    :checked="
                      store.state.data[getCarteInfos()].product_priceVariations[
                        findIndexFormat(selectFormatUpdate)
                      ].variationShow == 1
                        ? (store.state.data[
                            getCarteInfos()
                          ].product_priceVariations[
                            findIndexFormat(selectFormatUpdate)
                          ].variationShow = true)
                        : (store.state.data[
                            getCarteInfos()
                          ].product_priceVariations[
                            findIndexFormat(selectFormatUpdate)
                          ].variationShow = false)
                    "
                  />
                </p>
              </div>

              <div
                v-if="
                  selectFormatUpdate ||
                    !store.state.data[getCarteInfos()].product_isVariable
                "
                class="mt-3"
              >
                <p>PRIX :</p>
                <input
                  type="number"
                  @change="noNegative()"
                  step="1"
                  v-model="
                    store.state.data[getCarteInfos()].product_priceVariations[
                      findIndexFormat(selectFormatUpdate)
                    ].price
                  "
                  class="py-3 px-2 text-md border border-blue-lighter rounded h-10 text-right w-20 mr-1"
                />$
              </div>
            </div>
          </div>

          <p v-if="successEdit" class="text-green-500 py-6">
            Opération effectuée avec succès.
          </p>
          <p v-if="errorEdit" class="text-red-500 py-6">
            Un problème est survenue.
          </p>

          <button
            class="mt-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase"
            @click="confirmEditCarte()"
          >
            CONFIRMER LES MODIFICATIONS
          </button>
        </div>

        <div v-if="mode === 'ajouter'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">
              Nouveaux produits à confirmer
              <span v-if="store.state.newProducts !== null"
                >( {{ store.state.newProducts.length }} )
              </span>
            </p>

            <div
              v-if="
                store.state.newProducts !== null && !loadingNewProductsRefresh
              "
            >
              <div
                v-for="(newProduct, index) in store.state.newProducts"
                :key="index"
                class="grid grid-cols-5 px-6 py-4 my-2 cursor-pointer  overflow-hidden shadow"
                @click="
                  toggleModal('modal-id', index);
                  carteInfos = { imageUrl: null };
                  changeImage = false;
                "
              >
                <!-- RESET INFO CARTE -->
                <div>
                  <img
                    :src="newProduct.product_images[0].src"
                    class="h-14"
                    v-if="!newProduct.product_isVariable"
                  />
                  <img :src="newProduct.product_images" class="h-14" v-else />
                </div>
                <div class="flex items-center">
                  SKU: {{ newProduct.product_sku }}
                </div>
                <div class="col-span-2 flex items-center">
                  - {{ newProduct.product_name }}
                </div>
                <div
                  class="flex items-center"
                  v-if="newProduct.product_isVariable"
                >
                  {{ newProduct.product_format }}
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>

        <div v-if="mode === 'avance'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Gestion avancé</p>
            <p>Ajouter une nouvelle variation à un produit</p>

            <div class="relative">
              <input
                v-model="productToEdit"
                @keyup="getCarteInfos()"
                placeholder="Sélectionnez un produit à modifier"
                type="text"
                list="produit"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              />
              <datalist id="produit">
                <option
                  v-for="(item, index) in productsVariables()"
                  :key="index"
                  :value="item.product_id"
                  >- {{ item.product_name }}</option
                >
              </datalist>
            </div>

            <div class="flex justify-start py-6">
              <div>
                <button
                  @click="getProductDetails()"
                  class="bg-black  text-white font-bold py-2 px-4 rounded"
                >
                  <span class="flex items-center"
                    >Chercher le produit
                    <span v-if="loadingProductAdvanced"
                      ><img
                        src="../../assets/images/spinner_button.gif"
                        class="pl-3 h-5"/></span
                  ></span>
                </button>
              </div>

              <div></div>
            </div>
            <div v-if="detailsProduit">
              <p class="font-bold pb-10">{{ detailsProduit.name }}</p>
              <div
                v-for="variation in detailsProduit.variations"
                :key="variation"
                class="py-2  border border-gray-200 rounded p-2 mb-2"
              >
                <div class="flex justify-start items-center gap-2">
                  <img :src="variation.image[0].src" class="h-24" />
                  <p>
                    Variation: {{ variation.id }} - SKU : {{ variation.sku }}
                  </p>
                  {{ variation.attributes[0].option }}
                  <div v-if="variationIsExisting(productId, variation.id)">
                    <img
                      src="../../assets/images/checkvert.png"
                      class="h-8 ml-4"
                    />
                  </div>
                </div>

                <div
                  v-if="!variationIsExisting(productId, variation.id)"
                  class="flex mt-4"
                >
                  <div class="flex justify-start items-center">
                    <input
                      type="number"
                      v-model="prixNewVariationToForce"
                      class=" text-md border border-blue-lighter rounded h-10 w-20  text-center mr-2"
                    />
                    $
                  </div>

                  <button
                    @click="
                      addVariation(
                        productToEdit,
                        variation.id,
                        prixNewVariationToForce,
                        variation.image[0].src,
                        variation.attributes[0].option,
                        variation.sku
                      )
                    "
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4"
                  >
                    Ajouter
                  </button>
                </div>
              </div>
            </div>

            <div
              v-else-if="
                !detailsProduit &&
                  !loadingProductAdvanced &&
                  !errorProductNotCreated
              "
            >
              <p>
                Aucun produit trouvé, ou ce produit ne contient pas de
                variation.
              </p>
            </div>

            <div v-if="errorProductNotCreated">
              <p>
                Le produit que vous avez sélectionner doit être déja crée avec
                son créateur associé.
              </p>
            </div>
          </div>
        </div>

        <div class="w-full h-10 mt-10"></div>
      </div>

      <div
        v-if="store.state.loadingNewProducts || loadingNewProductsRefresh"
        class="lds-roller"
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { ref, onMounted, inject } from "vue";

export default {
  name: "GestionProduitsAdmin",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");
    const successEdit = ref(false);
    const errorEdit = ref(false);
    const uploadCarte = ref(false);
    const modalIsOpen = ref(false);
    const selectFormatUpdate = ref(null);
    const selectForUpdate = ref(null);
    const selectCarteEdit = ref(null);
    const selectedCreator = ref(null);
    const skuOfCurrentVariation = ref(null);
    const mode = ref("ajouter");
    const successDelete = ref(false);
    const success = ref(false);
    const error = ref(false);
    const changeImage = ref(false);
    const productToEdit = ref(null);
    const detailsProduit = ref(null);
    const productId = ref(null);
    const loadingProductAdvanced = ref(false);
    const loadingNewProductsRefresh = ref(false);
    const carteInfos = ref({
      inventory: null,
      imageUrl: null,
    });
    const prixNewVariationToForce = ref(null);
    const errorProductNotCreated = ref(false);

    function noNegative() {
      if (carteInfos.value.inventory < 0) {
        carteInfos.value.inventory = 0;
      }
    }

    function toggleModal(modalID, index) {
      modalIsOpen.value = !modalIsOpen.value;

      if (modalIsOpen.value) {
        selectForUpdate.value = index;
        getSku();
      } else {
        selectForUpdate.value = null;
      }
      document.getElementById(modalID).classList.toggle("hidden");
      document.getElementById(modalID + "-backdrop").classList.toggle("hidden");
      document.getElementById(modalID).classList.toggle("flex");
      document.getElementById(modalID + "-backdrop").classList.toggle("flex");
    }

    async function getSku() {
      if (store.state.newProducts[selectForUpdate.value].product_isVariable) {
        let productId =
          store.state.newProducts[selectForUpdate.value].product_id;
        let variationId =
          store.state.newProducts[selectForUpdate.value].product_variation;

        try {
          const res = await fetch(
            `${store.state.getVariationsInfosUrl}/${productId}/${variationId}`,
            {
              method: "GET",
              credentials: "include",
              headers: { "Content-Type": "application/json" },
            }
          );
          if (!res.ok) {
            console.log("un problème est survenu.");
          } else {
            const data = await res.json();

            skuOfCurrentVariation.value = await data.data.sku;
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        skuOfCurrentVariation.value =
          store.state.newProducts[selectForUpdate.value].product_sku;
      }
    }

    function selectImage(image) {
      carteInfos.value.imageUrl = image;
    }

    function findIndexFormat(format) {
      let index;
      if (store.state.data[getCarteInfos()].product_isVariable) {
        // SI C'EST UN PRODUIT VARIABLE
        index = store.state.data[
          getCarteInfos()
        ].product_priceVariations.findIndex((x) => x.variation === format); //
      } else {
        index = 0;
      }

      return index;
    }

    function getCarteInfos() {
      successEdit.value = false;
      errorEdit.value = false;

      let index = store.state.data.findIndex(
        (x) => x.product_name === selectCarteEdit.value
      );

      if (index !== -1) {
        selectedCreator.value = store.state.data[index].product_creator;

        return index;
      } else {
        return 0;
      }
    }

    function confirmEditCarte() {
      // CONFIRMATION DE L'EDITION DE LA CARTE

      successEdit.value = false;
      errorEdit.value = false;

      let proceed = confirm(`Confirmer les modifications du produits`);

      if (proceed) {
        carteInfos.value.prix = parseFloat(carteInfos.value.prix).toFixed(2);

        const updateProductExtranet = new Promise((resolve, reject) => {
          fetch(store.state.postUpdateProductExtranet, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
              product_id: store.state.data[getCarteInfos()].product_id,
              product_creator:
                store.state.data[getCarteInfos()].product_creator,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.success === 1) {
                resolve(1);
              } else {
                reject("ERREUR");
              }
            })
            .catch((err) => {
              console.log(err);
              reject("ERREUR");
            });
        });

        const updateVariationExtranet = new Promise((resolve, reject) => {
          let variation =
            store.state.data[getCarteInfos()].product_priceVariations[
              findIndexFormat(selectFormatUpdate.value)
            ].variation;

          if (!store.state.data[getCarteInfos()].product_isVariable) {
            variation = store.state.data[getCarteInfos()].product_id;
          }

          fetch(store.state.postUpdateVariation, {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },

            body: JSON.stringify({
              variation_id: variation,
              variation_price: parseFloat(
                store.state.data[getCarteInfos()].product_priceVariations[
                  findIndexFormat(selectFormatUpdate.value)
                ].price
              ).toFixed(2),
              variation_show:
                store.state.data[getCarteInfos()].product_priceVariations[
                  findIndexFormat(selectFormatUpdate.value)
                ].variationShow,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.success === 1) {
                resolve(1);
              } else {
                reject("ERREUR");
              }
            })
            .catch((err) => {
              console.log(err);
              reject("ERREUR");
            });
        });

        const allpromises = Promise.all([
          updateProductExtranet,
          updateVariationExtranet,
        ]);

        allpromises.then((success) => {
          if (success[0] === 1 && success[1] === 1) {
            successEdit.value = true;
            selectFormatUpdate.value = null;
            selectForUpdate.value = null;
            selectCarteEdit.value = null;
            selectedCreator.value = null;
            skuOfCurrentVariation.value = null;

            store.methods.fetchNewProducts();
          } else {
            console.log("une errer est survenue pendant l'update des produits");
            errorEdit.value = true;
          }
        });
      }
    }

    function saveCarte() {
      // CREER PRODUIT ET VARIATION DANS DB Extranet
      uploadCarte.value = true;

      if (!selectedCreator.value || !carteInfos.value.prix) {
        error.value = true;
        return;
      } else {
        error.value = false;
      }

      let proceed = confirm(`Confirmer l'ajout du produit ?`);
      if (proceed && skuOfCurrentVariation.value) {
        const uploadProduct = new Promise((resolve, reject) => {
          fetch(store.state.postNewProduct, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
              product_id:
                store.state.newProducts[selectForUpdate.value].product_id,
              product_showIt: true,
              product_regPrice: null,
              product_price: null,
              product_isPromo: false,
              product_promoId: null,
              product_creatorId: selectedCreator.value,
              product_stock: null,
              product_stockReserved: null,
              product_stockAvailable: null,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.success === 1) {
                resolve(1);
              } else {
                reject("ERREUR");
              }
            })
            .catch((err) => {
              console.log(err);
              reject("ERREUR");
            });
        });

        const uploadVariation = new Promise((resolve, reject) => {
          let variation_id =
            store.state.newProducts[selectForUpdate.value].product_variation;

          if (
            !store.state.newProducts[selectForUpdate.value].product_isVariable
          ) {
            // SI PRODUIT EST NON VARIABLE VARIATION = PRODUIT ID
            variation_id =
              store.state.newProducts[selectForUpdate.value].product_id;
          }

          let bonneImage;

          let sku = store.state.newProducts[selectForUpdate.value].product_sku;
          if (
            store.state.newProducts[selectForUpdate.value].product_isVariable
          ) {
            bonneImage =
              store.state.newProducts[selectForUpdate.value].product_images;
          } else {
            bonneImage =
              store.state.newProducts[selectForUpdate.value].product_images[0]
                .src;
          }

          fetch(store.state.confirmVariationUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({
              variation_id: variation_id,
              parent_id:
                store.state.newProducts[selectForUpdate.value].product_id,
              variation_regPrice: null,
              variation_price: carteInfos.value.prix,
              variation_stock: 0,
              variation_stockReserved: 0,
              variation_stockAvailable: 0,
              variation_image: bonneImage,
              variation_sku: sku,
              variation_format:
                store.state.newProducts[selectForUpdate.value].product_format,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.success === 1) {
                resolve(1);
              } else {
                reject("ERREUR");
              }
            })
            .catch((err) => {
              console.log(err);
              reject("ERREUR");
            });
        });

        const allpromises = Promise.all([uploadProduct, uploadVariation]);

        allpromises.then((success) => {
          if (success[0] === 1 && success[1] === 1) {
            uploadCarte.value = false;
            selectForUpdate.value = null;
            store.methods.fetchNewProducts();
            const isLoading = new Promise((resolve, reject) => {
              let timerMax = 20000;
              let beenResolved = false;
              const interval = 250;
              let loopInterval = setInterval(() => {
                timerMax -= interval;
                if (!store.state.loadingNewProducts && !beenResolved) {
                  beenResolved = true;
                  resolve(1);
                  clearInterval(loopInterval);
                }
                if (timerMax === 0 && !beenResolved) {
                  reject(Error("Une erreur est survenue"));
                  clearInterval(loopInterval);
                }
              }, interval);
            });

            isLoading.then(() => {
              toggleModal("modal-id");
            });
          }
        });
      } else {
        console.log("ERREUR CREATION");
      }
    }

    function refreshProduct() {
      loadingNewProductsRefresh.value = true;

      (async () => {
        await store.methods.fetchNewProducts();
        loadingNewProductsRefresh.value = false;
      })();
    }

    async function getProductDetails() {
      loadingProductAdvanced.value = true;
      errorProductNotCreated.value = false;
      detailsProduit.value = null;
      productId.value = productToEdit.value;

      let indexIfProductExist = store.state.data.findIndex(
        (product) => product.product_id == productToEdit.value
      );

      if (indexIfProductExist === -1) {
        errorProductNotCreated.value = true;
        loadingProductAdvanced.value = false;
        return;
      }

      try {
        const res = await fetch(store.state.getDetailsProductUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({
            productId: productToEdit.value,
          }),
        });

        let data = await res.json();
        if (data.success === 1) {
          if (data.data) {
            if (data.data.variations.length > 0) {
              detailsProduit.value = data.data;
            } else {
              detailsProduit.value = null;
            }
          } else {
            detailsProduit.value = null;
          }

          loadingProductAdvanced.value = false;
        } else {
          detailsProduit.value = null;
          loadingProductAdvanced.value = false;
        }
      } catch (err) {
        console.log(err);
        detailsProduit.value = null;
      }
    }

    function variationIsExisting(productId, variationId) {
      let indexProduct = store.state.data.findIndex(
        (product) => product.product_id === parseInt(productId)
      );
      if (indexProduct !== -1) {
        let indexVariation = store.state.data[
          indexProduct
        ].product_priceVariations.findIndex(
          (variation) => variation.variation === parseInt(variationId)
        );

        if (indexVariation !== -1) {
          return true;
        } else {
          return false;
        }
      }
    }

    async function addVariation(
      productId,
      variationId,
      prix,
      imageUrl,
      format,
      sku
    ) {
      try {
        const res = await fetch(store.state.checkIfProductExistUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify({
            productId: productId,
          }),
        });

        let data = await res.json();
        if (data.success === 1) {
          if (data.data) {
            try {
              const res = await fetch(store.state.confirmVariationUrl, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify({
                  variation_id: variationId,
                  parent_id: productId,
                  variation_regPrice: null,
                  variation_price: prix,
                  variation_stock: 0,
                  variation_stockReserved: 0,
                  variation_stockAvailable: 0,
                  variation_image: imageUrl,
                  variation_sku: sku,
                  variation_format: format,
                }),
              });

              if (!res.ok) {
                console.log("Un probleme est survenu");
              } else {
                const data = await res.json();
                if (data.success === 1) {
                  console.log("Variation ajoutée");
                  store.methods.fetchAllProducts();
                  getProductDetails();
                  return true;
                } else {
                  console.log("Un probleme est survenu");
                  return false;
                }
              }
            } catch (err) {
              console.log(err);
            }
          } else {
            console.log("Produit pas cree");
            return false;
          }
        } else {
          console.log("Produit pas cree");
          return false;
        }
      } catch (err) {
        console.log("Produit pas cree");
        return false;
      }
    }

    function listVariablesProduct() {
      let list = [];
      store.state.data.forEach((product) => {
        if (product.product_isVariable) {
          list.push({
            id: product.product_id,
            name: product.product_name,
          });
        }
      });

      return list;
    }

    function productsVariables() {
      let variablesProducts = [];
      store.state.data.forEach((product) => {
        if (product.product_isVariable) {
          variablesProducts.push(product);
        }
      });
      return variablesProducts;
    }

    onMounted(() => {
      if (!store.state.newProducts) {
        store.methods.fetchNewProducts();
      }
      if (!store.state.creators) {
        store.methods.fetchCreators();
      }
      if (store.state.data === null) {
        store.methods.fetchAllProducts();
      }
    });

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    return {
      store,
      selectForUpdate,
      mode,
      toggleModal,
      selectedCreator,
      changeImage,
      carteInfos,
      saveCarte,
      modalIsOpen,
      getCarteInfos,
      selectFormatUpdate,
      findIndexFormat,
      refreshProduct,
      productToEdit,
      getProductDetails,
      variationIsExisting,
      loadingProductAdvanced,
      successDelete,
      error,
      success,
      selectImage,
      uploadCarte,
      selectCarteEdit,
      confirmEditCarte,
      toggleMenu,
      productsVariables,
      skuOfCurrentVariation,
      noNegative,
      successEdit,
      errorEdit,
      loadingNewProductsRefresh,
      detailsProduit,
      errorProductNotCreated,
      addVariation,
      productId,
      prixNewVariationToForce,
      listVariablesProduct,
    };
  },
};
</script>
<style lang="scss">
.button_admin {
  max-width: 500px !important;
}

.image-edit-admin {
  width: 200px;
}

.image-modal-principale {
  height: 200px;
}

.image-modal-select {
  height: 250px;
}
</style>
