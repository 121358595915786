import { reactive } from "vue";

const state = reactive({
  // ALL PRODUCTS
  allProducts: {},
  // VARIABLES FIXES
  tps: null, //DBSETTINGS
  tvq: null, //DBSETTINGS
  prixExpedition: null, //DBSETTINGS
  prixCoffretReg: null, //DBSETTINGS
  prixCoffretEns: null, // DBSETTINGS
  prixCoffretToucheDore: null, // DBSETTINGS
  prixCoffretMeliMelo: null, //DBSETTINGS
  commandeMinimale: null, //DBSETTINGS
  grosseurCoffret: null, //DBSETTINGS
  gotFreePresentoir: false,
  gotSettingsDb: false, // TRUE IF SETTING FROM DB ARE LOADED
  idCatAffiches: 572,
  idCatPapeterie: 548,
  idCatCartes: 446,
  prixRevente: null,
  // UPDATE CATS HANDLE BY ADMIN
  catsToHandle: [],

  // FILTRES + CONFIG
  categoriesToRemove: [], // CATEGORIES A ENLEVER DU MENU //DBSETTINGS  PAPETERIE ET CARTE DE SOUHAITES
  tagsToRemove: [], // TAGS A ENLEVER DU MENU //DBSETTINGS
  formatsToRemove: [], // FORMATS A ENLEVER //DBSETTINGS
  modeVisualisation: "rapide", // MODE DE VISUALISATION
  typeCoffrets: "melimelo",
  // CURRENT
  currentCommande: null,
  currentTag: null,
  currentFilter: null,
  currentFilterSearch: "none",
  currentFilterTags: "all",
  currentFilterCategories: "all",
  currentFilterSize: " ",
  currentModeCoffret: null,
  currentModeProfil: "commandes",
  ifStock: 0,

  // TEMP RESULTS
  tempResults: [],
  myCodePromo: null,
  tempInfosClientEnregistrement: {},
  updatedEns: false,
  updatedReg: false,
  updatedToucheDore: false,
  inventoryBeenUpdated: false,
  numCommandeFinalized: null,
  customerIdFinalized: null,
  // DATA DATABASE
  mockups: null,
  gotNewStockVnl: false,
  gotNewProducts: false,
  newProducts: null,
  coffretBySlug: null,
  coffretsMeliMelo: null,
  allCatDispo: null,
  presentoirs: null,
  formats: null,
  creators: null,
  representants: null,
  codesPromo: null,
  statsCreator: null,
  statsReprensentant: null,
  data: null,
  tags: null,
  tagsCoffReg: null,
  tagsCoffEns: null,
  tagsCoffToucheDore: null,
  tagsPapeterie: null,
  tagsAffiches: null,
  categories: null,
  categoriesPapeterie: null,
  categoriesAffiches: null,
  unfilteredCategories: null,
  nbrResults: null,
  promoPresentoir: null,
  activesPromotions: [],
  promotions: null,
  allTagsDispo: null,
  allFormatsForStats: null,
  statsCatsToHide: null,

  // INFOS CUSTOMERS AFTER LOGGED
  customerInfos: null,
  infosHarvest: null,
  customerId: null,
  infosCustomer: null,
  // HANDLE ACTIONS
  searchCoffretName: null,
  ifConfirmCartCliked: false,
  firstLoad: true,
  loading: false,
  loadingCodesPromo: false,
  loadingCommande: false,
  loadingCommandes: false,
  loadingSearchCommandes: false,
  loadingSettingsExtranet: false,
  loadingNewProducts: false,
  loadingCoffret: false,
  loadingCoffretBySlug: false,
  loadingPresentoirs: false,
  loadingCreators: false,
  loadingInfosCustomer: false,
  loadingRepresentants: false,
  loadingUpdateCommande: false,
  loadingStatsCreator: false,
  loadingStatsRepresentants: false,
  loadingCheckKey: false,
  loadingCreateKey: false,
  loadingCreateClient: false,
  loadActivesPromotions: false,
  errorPresentoirs: false,
  errorCoffretBySlug: false,
  errorCoffret: false,
  error: null,
  errorInventory: [],
  errorInventoryMessage: null,
  // CART
  tempCartQty: {},
  tempQty: 0,
  cart: [],
  cartQty: 0,
  sousTotalCart: 0,
  inventoryCart: [],
  // ORDER

  currentOrder: null,
  currentTaxes: null,

  // RABAIS PROMO
  montantRabais: 0,
  rabais: false,
  montantRabaisExpedition: 0,
  rabaisExpedition: false,
  // CALCUL TAXES ETC...
  sousTotalAvecRabais: 0,
  sousTotalAvecRabaisSansExpedition: 0,
  totalTps: 0,
  totalTvq: 0,
  totalFinal: 0,
  // STOCK COFFRETS
  stockCoffretMelimelo: 0,

  // ADMIN
  commandesList: null,

  // FETCH ADRESSES
  updateFormatToHideUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updateFormatToHide`,
  getFormatsToHideUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getFormatsToHide`,
  getTagsToHideUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getTagsToHide`,
  updateTagToHideUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updateTagToHide`,
  updateCatToHideUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updateCatToHide`,
  getCatsToHideUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getCatsToHide`,
  getSalesStatsUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getSalesStats`,

  // LIST ALL REATORS
  getCreatorsUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getCreators`,
  // LIST INVENTORY
  getInventoryUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory`,
  getCategoriesUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/categories`,
  getTagsUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/tags`,
  getAllProductsUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/getUnfilteredProducts`,

  // LIST PRESENTOIRS
  getPresentoirsUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/getPresentoirs`,
  // LIST NEW PRODUCTS
  getNewProductsUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getNewProducts`,
  // GET SETTINS EXTRANET
  getSettingsExtranetUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/getSettingsExtranet`,
  // LIST COFFRET MELIMELO UNIQUE ET ALL
  getCoffretsMeliMeloUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/getCoffretMeliMelo`,
  // LIST REPRESENTANT
  getRepresentantsUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getRepresentants`,
  // POST A NEW PRODUCT
  postNewProduct: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/confirmNewProduct`,
  // UPDATE PRODUCT
  postUpdateProductExtranet: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updateProductExtranet`,
  // UPDATE VARIATIONS
  postUpdateVariation: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updateVariationExtranet`,
  // POST LOGIN
  postLogin: `${process.env.VUE_APP_BASE_URL}/api/users/login`,
  // GET INFOS CUSTOMERS
  getInfosCustomer: `${process.env.VUE_APP_BASE_URL}/api/users/getUserById`,
  // MAKE ORDER
  postOrder: `${process.env.VUE_APP_BASE_URL}/api/inventory/postOrder`,
  // GET COMMANDES
  getCommandesUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getCommandes`,
  // GET COMMANDE UNIQUE
  getCommandeUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getCommande`,
  // UPDATE COMMANDE
  updateCommandeUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updateCommande`,
  // SEARCH COMMANDES
  searchCommandesUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/searchCommandes`,
  // EDIT COFFRET  CARTES REGULIERES
  editCoffretRegUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/editCoffretReg`,
  // ADD A CREATOR
  addCreatorUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/addCreator`,
  // UPDATE CREATOR
  updateCreatorUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updateCreator`,
  // DELETE CREATOR
  deleteCreatorUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/deleteCreatorById`,
  // UPDATE REPRESENTANT
  addRepresentantUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/addRepresentant`,
  // UPDATE REPRESENTANT
  updateRepresentantUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updateRepresentant`,
  // DELETE REPRESENTANT
  deleteRepresentantUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/deleteRepresentantById/`,
  // getStatsCreatorUrl:
  getStatsCreatorUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getStatsCreateur`,
  // getStatsCreatorUrl:
  getStatsRepresentantUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getStatsRepresentants`,
  // checkKey
  getCheckKeyUrl: `${process.env.VUE_APP_BASE_URL}/api/users/checkKey`,
  // Create KEY
  getCreateKeyUrl: `${process.env.VUE_APP_BASE_URL}/api/users/createKey`,
  // CREATE REP KEY
  getCreateKeyRepUrl: `${process.env.VUE_APP_BASE_URL}/api/users/createKeyRep`,
  // CREATE NEW CLIENT
  postCreateClientUrl: `${process.env.VUE_APP_BASE_URL}/api/users/createClient`,
  // EDIT COFFRET REG PRICE
  editCoffretRegPriceUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/editCoffretRegPrice`,
  // GET CODES PROMO
  getCodesPromosUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getCodesPromo`,
  // DELETE CODE PROMO
  deleteCodePromoUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/deleteCodePromo`,
  // GET CODE PROMO
  getCodePromoUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/getCodePromo`,
  // GET FACTURE PDF
  getPdfCommandeUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getPDfCommande`,
  // GET FACTURE PDF By USER
  getPdfCommandeByUserUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/getPDfCommande`,
  // UPLOAD IMAGE
  uploadImageUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/upload`,
  // POST COFFRET MELIMELO
  addCoffretMeliMelo: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/addCoffretMeliMelo`,
  // DELETE COFFRET
  deleteCoffretBySlugUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/deleteCoffretBySlug`,
  // UPDATE COFFRET
  updateCoffretUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updateCoffret`,
  // UPDATE PRESENTOIR
  addPresentoirUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/addPresentoir`,
  // DELETE PRESENTOIR
  deletePresentoirUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/deletePresentoirById`,
  // CONFIRM VARITION
  confirmVariationUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/confirmVariation`,
  // PROCESS ORDER
  processOrderUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/processOrder`,
  // CREATE PROMOTIONS
  createPromotionUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/createPromotion`,
  // GET PROMOTIONS
  getPromotionsUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getPromotions`,
  // GET ACTIVE PROMOTIONS ( CLIENT )
  getActivesPromotionsUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/getActivesPromotions`,
  // DELETE PROMOTION
  getDeletePromotionByIdUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/deletePromotion`,
  // UPDATEEXTRANETSETTINGS
  updateSettingsExtranet: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updateSettingsExtranet`,
  // CHANGEPASSWORDBYUSER
  changePasswordByUserUrl: `${process.env.VUE_APP_BASE_URL}/api/users/changePasswordByUser`,
  //  CHANGE USER INFO BY USER
  changeInfosUserUrl: `${process.env.VUE_APP_BASE_URL}/api/users/changeInfosUser`,
  //  GET COMMANDE BY USER
  getCommandesByUserUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/getCommandesByUser`,
  // GET COMMANDE UNIQUE PAR CLIENT
  getCommandeByUserUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/getCommandeByUser`,
  // CONFIRM AND POST ORDER
  getConfirmPostOrderUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/confirmPostOrder/`,
  // GET ALL CUSTOMERS
  getAllCustomersUrl: `${process.env.VUE_APP_BASE_URL}/api/users/getAllCustomers`,
  // CHECK IF CODE PROMO EXISTE DEJA
  getCheckIfCodePromoAlereadyExistUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/getPromoCodeAlereadyExist`,
  // CHECK IF CODE PROMO EXISTE DEJA
  createPromoCodeUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/createPromoCode`,
  // UPDATE CODE PROMO
  updateCodePromoUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/updateCodePromo`,
  // LOGOUT
  logoutUrl: `${process.env.VUE_APP_BASE_URL}/api/users/logout`,
  // FORGOT PASSWORD
  forgotPasswordUrl: `${process.env.VUE_APP_BASE_URL}/api/users/forgotPassword`,
  // RESET PASSWORD
  resetPasswordUrl: `${process.env.VUE_APP_BASE_URL}/api/users/resetLostPassword`,
  // GET VARIATIONS INFO DETAILS
  getVariationsInfosUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/variations`,
  // UPDATE COMMMANDE AVEC INFIO HARVEST
  updateCommandeHarvestNumUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/updateCommandeHarvestNum`,
  // UPDATE PROMOTIONS :
  updatePromotionUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updatePromotion`,
  // CREATE CLIENT ADMIN
  createClientAdminUrl: `${process.env.VUE_APP_BASE_URL}/api/users/createClientAdmin`,
  // REVOKE REP URL
  revokeRepLinksUrl: `${process.env.VUE_APP_BASE_URL}/api/users/admin/revokeRep`,
  // POST SEND DEMANDE
  sendDemandeUrl: `${process.env.VUE_APP_BASE_URL}/api/users/sendDemande`,
  // getAllUsers
  getAllUsersUrl: `${process.env.VUE_APP_BASE_URL}/api/users`,
  // edit user:
  editUserUrl: `${process.env.VUE_APP_BASE_URL}/api/users/editUser`,
  // UPDATE PRESENTOIR
  updatePresentoirUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updatePresentoir`,
  // CREATE MOCK
  createMockups: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/createMockup`,
  // GET Mockups
  getMockups: `${process.env.VUE_APP_BASE_URL}/api/inventory/getMockups`,
  // UPDATE MOCKUP
  updateMockupUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/updateMockup`,
  // DELETE MOCKUP
  deleteMockupUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/deleteMockup`,
  // GET ALL CLIENTS VALIDATION KEY
  getAllCustomersForRegistrationUrl: `${process.env.VUE_APP_BASE_URL}/api/users/getAllCustomersForRegistration`,
  // CAHANGE INFO URL
  changeInfosUserAdminUrl: `${process.env.VUE_APP_BASE_URL}/api/users/changeInfosUserAdmin`,
  // GET PRIX DE REVENTES
  getPrixReventeUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/getPrixRevente`,
  // UPDATE PRIX DE REVENTES
  updatePrixReventeUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/updatePrixRevente`,
  // ISIT ADMIN
  isAdminUrl: `${process.env.VUE_APP_BASE_URL}/api/users/isAdmin`,
  // CHANGE USER PASSWORD BY ADMIN
  changePasswordOfUserUrl: `${process.env.VUE_APP_BASE_URL}/api/users/changePasswordOfUser`,
  // DELETECOMMANDE
  deleteCommandeUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/deleteCommande`,
  // GET PRODUCT DETAIL
  getDetailsProductUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getDetailsProduct`,
  // CHECKIFPRODUCT
  checkIfProductExistUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/checkIfProductExist`,
  // GET ALL CATS TO HANDLE
  getAllCatsToHandleUrl: `${process.env.VUE_APP_BASE_URL}/api/inventory/getAllCatsToHandle`,

  // AUTH
  isAuth: false,
  isAdmin: false,
});

const methods = {
  // GET CURRENT YEAR

  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  },

  // FONCTIONS COOKIES

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  },

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },

  // GET USER ID
  getUserId() {
    return this.getCookie("user_id");
  },

  // TOUT EFFACER DU LOCAL STORAGE
  clearInfosClient() {
    localStorage.removeItem(`mimosa-extranet--${this.getUserId()}`);
  },
  // METRE A JOUR LE PANIER SUR LE LOCAL STORAGE
  updateLocalStorage() {
    localStorage.setItem(
      `mimosa-extranet--${this.getUserId()}`,
      JSON.stringify({ cart: state.cart, numCommande: null })
    ); // stringify object and store
  },

  addNumCommandeUpdateLocalStorage(numCommande, customerId) {
    localStorage.setItem(
      `mimosa-extranet--${this.getUserId()}`,
      JSON.stringify({
        cart: state.cart,
        numCommande: numCommande,
        customerId: customerId,
      })
    ); // stringify object and store
  },

  clearCart() {
    // flush cart
    localStorage.removeItem(`mimosa-extranet--${this.getUserId()}`);
    state.cart = [];
    state.cartQty = 0;
  },

  checkUserId() {
    if (!state.customerId) {
      state.customerId = {}; // SI VARIABLE EXISTE PAS CREER L'OBJET
      state.customerId.user_id = this.getCookie("user_id"); // PASSER LA VALEUR
    }
  },

  // LISTING DE TOUS LES PRODUITS
  fetchAllProducts() {
    state.firstLoad = false; // SAVOIR SI DEJA LA PAGE A EUE UN LOADING
    state.loading = true;

    return fetch(state.getInventoryUrl, {
      method: "get",
      headers: {
        "content-type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => {
        if (!res.ok) {
          const error = new Error(res.statusText);
          error.json = res.json() + "errorfetch";
          throw error;
        }

        return res.json();
      })
      .then((json) => {
        // set the response data

        let tempData = json.data.sort(function (a, b) {
          // SORT EN ORDRE ALPHABETIQUE
          return a.product_name.localeCompare(b.product_name);
        });

        state.allProducts = tempData;
        state.data = tempData;

        this.showTags(state.data);
        this.showCategory(state.data);
        this.showFormats(state.data);
        this.calculNbrResults(state.data);
      })
      .catch((err) => {
        state.error = err + "fetcherreur";
        // In case a custom JSON error response was provided
        if (err.json) {
          return err.json.then((json) => {
            // set the JSON response message
            this.error.message = json.message + "icierreur";
          });
        }
      })
      .then(() => {
        state.loading = false;
      });
  }, // FILTRE DE COFFRETS

  filterCoffretsBy(
    data,
    filterTags = "all",
    filterCat = "all",
    filterSearch = "none",
    filterStock = true
  ) {
    if (data.length > 0) {
      // VERIFIER QUE PRODUITS SONT DISPOS

      if (
        filterTags === "all" &&
        filterCat === "all" &&
        filterSearch === "none" &&
        filterStock === false
      ) {
        state.nbrResults = data.length; // RETOURNER NOMBRE DE RESULTATS

        return data;
      } else {
        let filteredData = [];
        let filteredByTags = [];
        let filteredByCats = [];
        let filteredBySearch = [];

        // FILTRE PAR TAGS

        if (filterTags !== "all") {
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].product_tags.length > 0) {
                let totalTagsByProduct = [];
                for (let j = 0; j < data[i].product_tags.length; j++) {
                  totalTagsByProduct.push(data[i].product_tags[j].id);
                }

                if (
                  filterTags.every((tag) => totalTagsByProduct.includes(tag))
                ) {
                  filteredByTags.push(data[i]);
                }
                totalTagsByProduct = [];
              }
            }
          } else {
            filteredByTags = data;
          }
        } else {
          filteredByTags = data;
        }

        // FILTRE PAR CATEGORIE
        if (filterCat !== "all" && filterCat !== "" && filterCat !== null) {
          if (filteredByTags.length > 0) {
            for (let i = 0; i < filteredByTags.length; i++) {
              if (filteredByTags[i].product_categories.length > 0) {
                for (
                  let j = 0;
                  j < filteredByTags[i].product_categories.length;
                  j++
                ) {
                  if (
                    filteredByTags[i].product_categories[j].id === filterCat
                  ) {
                    filteredByCats.push(filteredByTags[i]);
                  }
                }
              }
            }
          } else {
            filteredByCats = filteredByTags;
          }
        } else {
          filteredByCats = filteredByTags;
        }

        // FILTRE PAR RECHERCHE TEXTE
        if (
          filterSearch !== "none" &&
          filterSearch !== "" &&
          filterSearch !== null
        ) {
          filteredBySearch = filteredByCats.filter((product) =>
            product.product_name.toLowerCase().includes(filterSearch)
          );
        } else {
          filteredBySearch = filteredByCats;
        }

        // FILTER DUPLICATES FINAL RESULTS

        filteredData = this.removeDuplicates(
          filteredBySearch,
          (item) => item.product_id
        );

        state.tempResults = filteredData; // RETOURNER FICHIER FILTRER

        return filteredData;
      }
    } else {
      state.nbrResults = 0;

      return [];
    }
  },
  // FILTER BY

  filterBy(
    data,
    filterTags = "all",
    filterCat = "all",
    filterSearch = "none",
    filterStock = true,
    productType
  ) {
    if (data.length > 0 && filterStock) {
      // VERIFIER QUE PRODUITS SONT DISPOS 442

      let filteredData = [];
      let filteredByTags = [];
      let filteredByCats = [];
      let filteredBySearch = [];

      // FILTRE PAR TAGS

      if (filterTags !== "all") {
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].product_tags.length > 0) {
              let totalTagsByProduct = [];
              for (let j = 0; j < data[i].product_tags.length; j++) {
                totalTagsByProduct.push(data[i].product_tags[j].id);
              }

              if (filterTags.every((tag) => totalTagsByProduct.includes(tag))) {
                filteredByTags.push(data[i]);
              }
              totalTagsByProduct = [];
            }
          }
        } else {
          filteredByTags = data;
        }
      } else {
        filteredByTags = data;
      }

      // FILTRE PAR CATEGORIE

      if (filteredByTags.length > 0) {
        if (filterCat !== "all") {
          filteredByTags.forEach((product) => {
            let isGoodCat = false;
            product.product_categories.forEach((category) => {
              if (category.id === productType) {
                isGoodCat = true;
              }
            });
            product.product_categories.forEach((cat) => {
              if (cat.id === filterCat) {
                if (isGoodCat) {
                  filteredByCats.push(product);
                }
              }
            });
          });
        } else {
          for (let i = 0; i < filteredByTags.length; i++) {
            if (filteredByTags[i].product_categories.length > 0) {
              for (
                let j = 0;
                j < filteredByTags[i].product_categories.length;
                j++
              ) {
                if (
                  filteredByTags[i].product_categories[j].id === productType
                ) {
                  filteredByCats.push(filteredByTags[i]);
                }
              }
            }
          }
        }
      } else {
        filteredByCats = filteredByTags;
      }

      // FILTRE PAR RECHERCHE TEXTE
      if (
        filterSearch !== "none" &&
        filterSearch !== "" &&
        filterSearch !== null
      ) {
        for (let i = 0; i < filteredByCats.length; i++) {
          if (
            filteredByCats[i].product_name.toLowerCase().includes(filterSearch)
          ) {
            filteredBySearch.push(filteredByCats[i]);
          }
        }
      } else {
        filteredBySearch = filteredByCats;
      }

      // FILTER DUPLICATES FINAL RESULTS

      filteredData = this.removeDuplicates(
        filteredBySearch,
        (item) => item.product_id
      );

      state.tempResults = filteredData; // RETOURNER FICHIER FILTRER TEMP

      return filteredData;
    } else {
      state.nbrResults = 0;

      return [];
    }
  },

  // SORT ARRAY OF OBJECT BY KEY VALUES
  dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  },

  removeDuplicates(data, key) {
    return [...new Map(data.map((item) => [key(item), item])).values()];
  },

  // COUNT OCCURENCE OF A CATEGORIE
  countCatOccurences(idCat) {
    return state.unfilteredCategories.reduce(
      (acc, cur) => (cur.id === idCat ? ++acc : acc),
      0
    );
  },

  // AFFICHER LES CATEGORIES
  showCategory() {
    //  FILTRE ET LES CATEGORIES

    let categories = [];
    let tempCategory = {};

    if (!state.data) {
      (async () => {
        await this.fetchAllProducts();

        state.data.forEach((product) => {
          if (product.product_categories.length > 0) {
            let isGoodCat = false;
            product.product_categories.forEach((categorie) => {
              if (categorie.id === state.currentTag) {
                isGoodCat = true;
              }
            });

            for (let i = 0; i < product.product_categories.length; i++) {
              if (
                !state.categoriesToRemove.includes(
                  product.product_categories[i].id
                ) &&
                isGoodCat
              ) {
                // REMOVE CATEGORIES LISTED
                tempCategory = {
                  id: product.product_categories[i].id,
                  name: product.product_categories[i].name,
                };
                categories.push(tempCategory);
              }
            }
          }
        });

        // PUSH UNFILTERED CATEGORIES TO VARIABLE FOR A COUNT

        state.unfilteredCategories = JSON.parse(JSON.stringify(categories));

        switch (state.currentTag) {
          case state.idCatCartes:
            state.categories = this.removeDuplicates(
              state.unfilteredCategories,
              (item) => item.id
            );
            break;
          case state.idCatPapeterie:
            state.categoriesPapeterie = this.removeDuplicates(
              state.unfilteredCategories,
              (item) => item.id
            );
            break;
          case state.categoriesAffiches:
            state.categories = this.removeDuplicates(
              state.unfilteredCategories,
              (item) => item.id
            );
            break;
          default:
            return;
        }
      })();
    } else {
      state.data.forEach((product) => {
        if (product.product_categories.length > 0) {
          let isGoodCat = false;
          product.product_categories.forEach((categorie) => {
            if (categorie.id === state.currentTag) {
              isGoodCat = true;
            }
          });

          for (let i = 0; i < product.product_categories.length; i++) {
            if (
              !state.categoriesToRemove.includes(
                product.product_categories[i].id
              ) &&
              isGoodCat
            ) {
              // REMOVE CATEGORIES LISTED
              tempCategory = {
                id: product.product_categories[i].id,
                name: product.product_categories[i].name,
              };
              categories.push(tempCategory);
            }
          }
        }
      });

      // PUSH UNFILTERED CATEGORIES TO VARIABLE FOR A COUNT
      state.unfilteredCategories = categories;

      switch (state.currentTag) {
        case state.idCatCartes:
          state.categories = this.removeDuplicates(
            categories,
            (item) => item.id
          );
          break;
        case state.idCatPapeterie:
          state.categoriesPapeterie = this.removeDuplicates(
            categories,
            (item) => item.id
          );
          break;
        case state.categoriesAffiches:
          state.categories = this.removeDuplicates(
            categories,
            (item) => item.id
          );
          break;
        default:
          return;
      }
    }

    // COUNT NUMBER CARDS IN CATEGORIES

    return state.categories;
  },

  // LISTER TOUTES LES CATEGORIE LES CATEGORIES DES PRODUITS
  showAllTags(data) {
    let tags = [];
    let tempTags = [];

    data.forEach((product) => {
      if (product.product_tags.length > 0) {
        for (let i = 0; i < product.product_tags.length; i++) {
          if (!state.tagsToRemove.includes(product.product_tags[i].id)) {
            // REMOVE CATEGORIES LISTED
            tempTags = {
              id: product.product_tags[i].id,
              name: product.product_tags[i].name,
            };
            tags.push(tempTags);
          }
        }
      }
    });

    state.allTagsDispo = this.removeDuplicates(tags, (item) => item.id);
    state.allTagsDispo.sort(this.dynamicSort("name")); // SORT BY ALPHABETIC ORDER

    return state.allTagsDispo;
  },

  // LISTER LES CATEGORIES DES PRODUITS
  showTags(data) {
    let tags = [];
    let tempTags = [];
    data.forEach((product) => {
      if (product.product_tags.length > 0) {
        for (let i = 0; i < product.product_tags.length; i++) {
          if (!state.tagsToRemove.includes(product.product_tags[i].id)) {
            // REMOVE CATEGORIES LISTED
            tempTags = {
              id: product.product_tags[i].id,
              name: product.product_tags[i].name,
            };
            tags.push(tempTags);
          }
        }
      }
    });

    state.tags = this.removeDuplicates(tags, (item) => item.id);
    state.tags.sort(this.dynamicSort("name")); // SORT BY ALPHABETIC ORDER

    return state.tags;
  },
  // LISTER LES CATEGORIES DES PRODUITS
  showTagsPapeterie() {
    let tags = [];
    let tempTags = [];

    state.data.forEach((product) => {
      let isGoodCat = false;

      if (product.product_categories.length > 0) {
        product.product_categories.forEach((categorie) => {
          if (categorie.id === state.currentTag) {
            // SI CAT EN COURS EST PAPETETIRE
            isGoodCat = true;
          }
        });
      }

      if (product.product_tags.length > 0 && isGoodCat) {
        for (let i = 0; i < product.product_tags.length; i++) {
          if (!state.tagsToRemove.includes(product.product_tags[i].id)) {
            // REMOVE CATEGORIES LISTED
            tempTags = {
              id: product.product_tags[i].id,
              name: product.product_tags[i].name,
            };
            tags.push(tempTags);
          }
        }
      }
    });

    state.tagsPapeterie = this.removeDuplicates(tags, (item) => item.id);
    state.tagsPapeterie.sort(this.dynamicSort("name")); // SORT BY ALPHABETIC ORDER

    return state.tags;
  },

  showTagsAffiches() {
    let tags = [];
    let tempTags = [];

    state.data.forEach((product) => {
      let isGoodCat = false;
      // CAT AFFICHES

      if (product.product_categories.length > 0) {
        product.product_categories.forEach((categorie) => {
          if (categorie.id === state.currentTag) {
            // SI CAT EN COURS EST AFFICHES
            isGoodCat = true;
          }
        });
      }

      if (product.product_tags.length > 0 && isGoodCat) {
        for (let i = 0; i < product.product_tags.length; i++) {
          if (!state.tagsToRemove.includes(product.product_tags[i].id)) {
            // REMOVE CATEGORIES LISTED
            tempTags = {
              id: product.product_tags[i].id,
              name: product.product_tags[i].name,
            };
            tags.push(tempTags);
          }
        }
      }
    });

    state.tagsAffiches = this.removeDuplicates(tags, (item) => item.id);
    state.tagsAffiches.sort(this.dynamicSort("name")); // SORT BY ALPHABETIC ORDER

    return state.tags;
  },

  showFormats(data, cat = null) {
    if (cat === null) {
      let formats = [];
      let tempFormats = [];
      data.forEach((product) => {
        if (product.product_priceVariations.length > 0) {
          for (let i = 0; i < product.product_priceVariations.length; i++) {
            if (
              !state.formatsToRemove.includes(
                product.product_priceVariations[i].format
              ) &&
              product.product_priceVariations[i].format.length !== 0
              // SI FORMAT AFFICHES
              // TODO: A MODIFIER
            ) {
              // REMOVE CATEGORIES LISTED
              tempFormats = {
                name: product.product_priceVariations[i].format,
              };

              formats.push(tempFormats);
            }
          }
        }
      });

      let formatMoinCvba = formats.filter(
        (format) => !format.name.includes("CVBA")
      ); // ENLEVE LE CVBA DES FORMATS DISPONIBLE

      state.formats = this.removeDuplicates(
        formatMoinCvba,
        (item) => item.name
      );
      state.formats.sort(this.dynamicSort("name")); // SORT BY ALPHABETIC ORDER
      return state.formats;
    } else {
      // IF CAT IS SELECTED
      // get all formats for the selected cat
    }
  },

  // FONCTIONS NOMBRE DE RESULTATS

  calculNbrResults(data) {
    let countVariations = 0;
    if (state.modeVisualisation === "rapide") {
      if (state.ifStock === 0 && state.currentFilterSize === " ") {
        data.forEach((product) => {
          countVariations += product.product_priceVariations.length;
        });
      }
    }

    state.nbrResults = countVariations;
  },

  countCartQty() {
    // DECOMPTE DU PANIER

    if (state.cart) {
      state.tempQty = 0;

      state.cart.forEach((item, index) => {
        if (item.variationId !== 777777 && item.parentId !== 777777) {
          // FILTRER SI CE N'EST PAS UNE PROMO
          state.tempQty += state.cart[index].quantity;
        }
      });
      state.cartQty = state.tempQty;
      this.countSousTotal(); // ACTIVE LE CALCUL DU SOUS-TOTAL
    }
  },

  countSousTotal() {
    state.sousTotalCart = 0;
    for (let i = 0; i < state.cart.length; i++) {
      state.sousTotalCart += state.cart[i].quantity * state.cart[i].price;
    }
    state.sousTotalCart = state.sousTotalCart.toFixed(2);
  },

  /// GESTION CART ET AJOUT ITEM
  addToCart(item) {
    if (!isNaN(item[1])) {
      let variationId = item[0].variation;
      let parentId = item[0].parentId;
      let price = item[0].price;
      let image = item[0].imageVariation;
      let format = item[0].format;
      let quantity = item[1];
      let name = item[2];
      let isCoffret = item[3][0];
      let isMeliMelo = item[3][1];
      let isCoffretReg = item[3][2];
      let isCoffretEns = item[3][3];
      let isCoffretToucheDore = item[3][4];
      let isPresentoir = item[4];
      let commandeMinimale = item[0].commandeMinimale;
      let productSku = item[5];
      let productCategorieId = item[6];
      let productFormat = item[7];

      if (!isPresentoir) {
        // SI CE N'EST PAS UN PRESENTOIR
        if (quantity > 0) {
          if (!isMeliMelo) {
            // SI CE N'EST PAS UN COFFRET MELIMELO

            if (
              state.cart.some((el) => {
                return (
                  el.variationId === variationId &&
                  el.isCoffret === isCoffret &&
                  el.isMeliMelo === isMeliMelo &&
                  el.name === name
                );
              })
            ) {
              // VERIFIE LE VARIANT ET SI COFFRET
              let index = state.cart.findIndex(
                (x) =>
                  x.variationId === variationId &&
                  x.isCoffret === isCoffret &&
                  x.isMeliMelo === isMeliMelo &&
                  x.name === name
              ); // TROUVER INDEX DE ITEM DANS LE CART
              let preQty = state.cart[index].quantity; // ALLER CHERCHER QUANTITE AVANT AJOUT

              let indexProduct = state.data.findIndex(
                (product) => product.product_id === parentId
              );
              let indexVariation = state.data[
                indexProduct
              ].product_priceVariations.findIndex(
                (variation) => variation.variation === variationId
              );

              if (!isCoffret) {
                // SI CE N'EST PAS UN COFFRET Y ALLER PAR QUANTITE
                if (
                  state.data[indexProduct].product_priceVariations[
                    indexVariation
                  ].stockAvailable -
                    quantity >=
                  0
                ) {
                  state.data[indexProduct].product_priceVariations[
                    indexVariation
                  ].stockAvailable -= quantity;
                  state.cart[index].quantity = quantity + preQty;
                } else {
                  alert(
                    `Vous ne pouvez ajouter plus d'item que l'inventaire diponible`
                  );
                  return;
                }

                // AJUSTEMENT INVENTAIRE LIVE
              } else {
                // SI C'EST UN COFFRET AJUSTER LA QUANTITE A 6
                if (
                  state.data[indexProduct].product_priceVariations[
                    indexVariation
                  ].stockAvailable -
                    6 * quantity >=
                  0
                ) {
                  state.cart[index].quantity = quantity + preQty;
                  state.data[indexProduct].product_priceVariations[
                    indexVariation
                  ].stockAvailable -= 6 * quantity;
                } else {
                  alert(
                    `Vous ne pouvez ajouter plus d'item que l'inventaire diponible`
                  );
                  return;
                }
              }
            } else {
              // SINON POUSSER LA VALEUR DANS LE CART

              if (!isCoffret) {
                // SI CE N'EST PAS UN COFFRET ( DONC PRODUIT REG )

                if (parentId !== 777777 && parentId !== 999998) {
                  // ELIMINE LA PROMO PRESENTOIR OU CODE PROMO

                  // AJUSTEMENT INVENTAIRE LIVE
                  let indexProduct = state.data.findIndex(
                    (product) => product.product_id === parentId
                  );
                  let indexVariation = state.data[
                    indexProduct
                  ].product_priceVariations.findIndex(
                    (variation) => variation.variation === variationId
                  );

                  if (
                    state.data[indexProduct].product_priceVariations[
                      indexVariation
                    ].stockAvailable -
                      quantity >=
                    0
                  ) {
                    state.data[indexProduct].product_priceVariations[
                      indexVariation
                    ].stockAvailable -= quantity;
                  } else {
                    alert(
                      `Vous ne pouvez ajouter plus d'item que l'inventaire diponible`
                    );
                    return;
                  }
                }

                state.cart.push({
                  parentId: parentId,
                  productSku: productSku,
                  variationId: variationId,
                  price: price,
                  quantity: quantity,
                  image: image,
                  format: format,
                  name: name,
                  isCoffret: isCoffret,
                  isMeliMelo: isMeliMelo,
                  isPresentoir: isPresentoir,
                  isCoffretReg: false,
                  isCoffretEns: false,
                  isCoffretToucheDore: false,
                  categorieId: productCategorieId,
                  itemFormat: productFormat,
                });
              } else if (isCoffretReg) {
                // SINON C'EST UN COFFRET MAIS PAS A PLANTER ( COFFRET REG )

                // AJUSTEMENT INVENTAIRE LIVE
                let indexProduct = state.data.findIndex(
                  (product) => product.product_id === parentId
                );
                let indexVariation = state.data[
                  indexProduct
                ].product_priceVariations.findIndex(
                  (variation) => variation.variation === variationId
                );
                if (
                  state.data[indexProduct].product_priceVariations[
                    indexVariation
                  ].stockAvailable >=
                  6 * quantity
                ) {
                  state.data[indexProduct].product_priceVariations[
                    indexVariation
                  ].stockAvailable -= 6 * quantity;
                } else {
                  alert(
                    `Vous ne pouvez ajouter plus d'item que l'inventaire diponible`
                  );
                  return;
                }

                state.cart.push({
                  parentId: parentId,
                  variationId: variationId,
                  productSku: productSku,
                  price: state.prixCoffretReg, // PRIX STANDARD DE COFFRET
                  quantity: quantity,
                  image: image,
                  format: format,
                  name: name,
                  isCoffret: isCoffret,
                  isMeliMelo: isMeliMelo,
                  isPresentoir: isPresentoir,
                  isCoffretReg: true,
                  isCoffretEns: false,
                  isCoffretToucheDore: false,
                  categorieId: productCategorieId,
                  itemFormat: productFormat,
                });
              } else if (isCoffretEns) {
                // AJUSTEMENT INVENTAIRE LIVE
                let indexProduct = state.data.findIndex(
                  (product) => product.product_id === parentId
                );

                let indexVariation = state.data[
                  indexProduct
                ].product_priceVariations.findIndex(
                  (variation) => variation.variation === variationId
                );
                if (
                  state.data[indexProduct].product_priceVariations[
                    indexVariation
                  ].stockAvailable >=
                  6 * quantity
                ) {
                  state.data[indexProduct].product_priceVariations[
                    indexVariation
                  ].stockAvailable -= 6 * quantity;
                } else {
                  alert(
                    `Vous ne pouvez ajouter plus d'item que l'inventaire diponible`
                  );
                  return;
                }

                state.cart.push({
                  parentId: parentId,
                  variationId: variationId,
                  productSku: productSku,
                  price: state.prixCoffretEns, // PRIX COFFRET ENS
                  quantity: quantity,
                  image: image,
                  format: format,
                  name: name,
                  isCoffret: isCoffret,
                  isMeliMelo: isMeliMelo,
                  isPresentoir: isPresentoir,
                  isCoffretReg: false,
                  isCoffretEns: true,
                  isCoffretToucheDore: false,
                  categorieId: productCategorieId,
                  itemFormat: productFormat,
                });
              } else if (isCoffretToucheDore) {
                // AJUSTEMENT INVENTAIRE LIVE
                let indexProduct = state.data.findIndex(
                  (product) => product.product_id === parentId
                );
                let indexVariation = state.data[
                  indexProduct
                ].product_priceVariations.findIndex(
                  (variation) => variation.variation === variationId
                );
                if (
                  state.data[indexProduct].product_priceVariations[
                    indexVariation
                  ].stockAvailable >=
                  6 * quantity
                ) {
                  state.data[indexProduct].product_priceVariations[
                    indexVariation
                  ].stockAvailable -= 6 * quantity;
                } else {
                  alert(
                    `Vous ne pouvez ajouter plus d'item que l'inventaire diponible`
                  );
                  return;
                }

                state.cart.push({
                  parentId: parentId,
                  variationId: variationId,
                  productSku: productSku,
                  price: state.prixCoffretToucheDore, // PRIX COFFRET ENS
                  quantity: quantity,
                  image: image,
                  format: format,
                  name: name,
                  isCoffret: isCoffret,
                  isMeliMelo: isMeliMelo,
                  isPresentoir: isPresentoir,
                  isCoffretReg: false,
                  isCoffretEns: false,
                  isCoffretToucheDore: true,
                  categorieId: productCategorieId,
                  itemFormat: productFormat,
                });
              }
            }
          } else {
            //  SI COFFRET MELIMELO

            // AJUSTEMENT D'INVENTAIRE POUR COFFRET MELIMELO
            this.updateMeliMeloInventary(variationId, quantity);

            if (
              state.cart.some((el) => {
                return (
                  el.isCoffret === isCoffret &&
                  el.isMeliMelo === isMeliMelo &&
                  el.name === name
                );
              })
            ) {
              // VERIFIE LE VARIANT ET SI COFFRET
              let index = state.cart.findIndex(
                (x) =>
                  x.isCoffret === isCoffret &&
                  x.isMeliMelo === isMeliMelo &&
                  x.name === name
              ); // TROUVER INDEX DE ITEM DANS LE CART
              let preQty = state.cart[index].quantity; // ALLER CHERCHER QUANTITE AVANT AJOUT
              state.cart[index].quantity = quantity + preQty; // ADITIONNER NOUVEL AJOUT AVEC ANCIENNE QUANTITE
            } else {
              // SINON POUSSER LA VALEUR DANS LE CART
              state.cart.push({
                parentId: parentId,
                variationId: variationId,
                productSku: null,
                price: price,
                quantity: quantity,
                image: image,
                format: format,
                name: name,
                isCoffret: isCoffret,
                isMeliMelo: isMeliMelo,
                isPresentoir: isPresentoir,
                categorieId: null,
                itemFormat: null,
              });
            }
          }
        }
      } else {
        // SI C'EST UN PRESENTOIR

        // AJUSTEMENT INVENTAIRE LIVE
        let indexPresentoir = state.presentoirs.findIndex(
          (product) => product.presentoirId === variationId
        );
        state.presentoirs[indexPresentoir].presentoirStockAvailable -= quantity;

        if (
          state.cart.some((el) => {
            return (
              el.variationId === variationId &&
              el.isCoffret === isCoffret &&
              el.isMeliMelo === isMeliMelo &&
              el.name === name
            );
          })
        ) {
          // VERIFIE LE VARIANT ET SI COFFRET
          let index = state.cart.findIndex(
            (x) =>
              x.variationId === variationId &&
              x.isCoffret === isCoffret &&
              x.isMeliMelo === isMeliMelo &&
              x.name === name
          ); // TROUVER INDEX DE ITEM DANS LE CART
          let preQty = state.cart[index].quantity; // ALLER CHERCHER QUANTITE AVANT AJOUT
          state.cart[index].quantity = quantity + preQty; // ADITIONNER NOUVEL AJOUT AVEC ANCIENNE QUANTITE
        } else {
          // SINON POUSSER LA VALEUR DANS LE CART
          state.cart.push({
            parentId: parentId,
            variationId: variationId,
            productSku: null,
            price: price,
            quantity: quantity,
            image: image,
            format: format,
            name: name,
            isCoffret: isCoffret,
            isMeliMelo: isMeliMelo,
            isPresentoir: isPresentoir,
            commandeMinimale: commandeMinimale,
            categorieId: null,
            itemFormat: null,
          });
        }
      }

      // UPDATE NOMBRE DANS CARTE PLUS BACKUP SUR LOCALSTORAGE

      state.tempCartQty["card-" + parentId + "-" + variationId] = 0;
      this.updateLocalStorage(); // UPDATE LOCAL STORAGE
      this.countCartQty(); // FAIRE LE COMPTE DU CART
    }
  },

  updateCart(item) {
    if (item.quantity > 0) {
      let index = state.cart.findIndex(
        (x) =>
          x.variationId === item.variationId && x.isCoffret === item.isCoffret
      );
      state.cart[index].quantity = item.quantity;

      this.countCartQty();
    }

    // ACTIVE FONCTION DE CALCUL
    this.updateLocalStorage();
    this.calculSousTotalCart();
    this.countCartQty();
    this.calculTaxes();
  },

  // REMOVE FROM CART

  removeFromCart(
    index,
    parentId,
    variationId,
    isCoffret,
    isMeliMelo,
    isPrensentoir,
    quantityInCart
  ) {
    // ENLEVER LE CODE PROMO SI UN PRODUIT EST RETIRER DU PANIER
    let listOfIndexPromo = [];
    state.cart.forEach((item, index) => {
      if (item.parentId === 999998 && item.variationId === 999998) {
        listOfIndexPromo.push(index);
      }
    });

    if (listOfIndexPromo.length) {
      listOfIndexPromo.reverse().forEach((indexToRemove) => {
        state.cart.splice(indexToRemove, 1); // ENLEVE LA PROMO
      });
    }

    // CORRIGE L'INVENTAIRE
    if (isPrensentoir) {
      let indexPresentoir = state.presentoirs.findIndex(
        (x) => x.presentoirId === variationId
      );
      state.presentoirs[indexPresentoir].presentoirStockAvailable +=
        quantityInCart;
    } else if (isCoffret && !isMeliMelo) {
      // AJUSTEMENT INVENTAIRE LIVE
      let indexProduct = state.data.findIndex(
        (product) => product.product_id === parentId
      );
      let indexVariation = state.data[
        indexProduct
      ].product_priceVariations.findIndex(
        (variation) => variation.variation === variationId
      );
      state.data[indexProduct].product_priceVariations[
        indexVariation
      ].stockAvailable += quantityInCart * 6;
    } else if (isMeliMelo) {
      // // VERIFIER INVENTAIRE DISPONIBLE DU COFFRET

      this.updateMeliMeloInventary(variationId, -quantityInCart);
    } else {
      // PRODUIT REG

      if (parentId !== 777777 && parentId !== 999998) {
        // ELIMINE LA GESTION DES INVENTAIRE DES CODES PROMO
        let indexProduct = state.data.findIndex(
          (product) => product.product_id === parentId
        );
        let indexVariation = state.data[
          indexProduct
        ].product_priceVariations.findIndex(
          (variation) => variation.variation === variationId
        );
        state.data[indexProduct].product_priceVariations[
          indexVariation
        ].stockAvailable += quantityInCart;
      }
    }

    state.cart.splice(index, 1);
    this.updateLocalStorage();
    this.calculSousTotalCart();
    this.countCartQty();
    this.calculTaxes();

    // REMOVE FROM INVENORY CART
  },

  calculSousTotalCart() {
    let total = 0;
    state.cart.forEach((item) => {
      total += item.price * item.quantity;
    });
    return total.toFixed(2);
  },

  calculTaxes() {
    state.totalTps = 0;
    state.totalTps = 0;
    state.sousTotalAvecRabaisSansExpedition = 0;
    let sousTotal = 0;
    let totalRabais = 0;
    // CALCUL TOTAL DU CART
    state.cart.forEach((item) => {
      sousTotal += item.price * item.quantity;
    });

    state.sousTotalAvecRabaisSansExpedition = (sousTotal - totalRabais).toFixed(
      2
    );

    // CALCUL LE COUT D'EXPEDITION
    sousTotal += state.prixExpedition;

    // CALCUL TOTAL DES RABAIS
    totalRabais = state.montantRabais + state.montantRabaisExpedition;

    // CALCUL VRAI SOUS TOTAL
    state.sousTotalAvecRabais = sousTotal - totalRabais;

    // CALCUL TPS
    state.montantTps = (state.sousTotalAvecRabais * state.tps).toFixed(2);

    // CALCUL TVQ
    state.montantTvq = (state.sousTotalAvecRabais * state.tvq).toFixed(2);

    // CALCUL TOTAL FINAL
    state.totalFinal = (
      state.sousTotalAvecRabais +
      parseFloat(state.montantTvq) +
      parseFloat(state.montantTps)
    ).toFixed(2);

    // ENCLENCHE FONCTION GESTION DE PRESENTOIRE
    this.handleRentPresentoir();
  },

  // FONCTION DE POSSIBILITE DE LOCATION DE PRESENTOIRE DE
  // LOCATION SAPPLIQUE SUR LE PLUS CHER POSSIBLE AVEC LE MINIMUM DE COMMANDE

  handleRentPresentoir() {
    let presentoirs = [];
    let bestDealIndex;
    let bestDeal;
    if (!state.gotFreePresentoir) {
      state.cart.forEach((item, index) => {
        // VERIFIE SI UN PRESENTOIRE DANS LE CART
        if (item.isPresentoir) {
          if (!this.promoPresentoirUnique()) {
            if (
              item.commandeMinimale <=
              state.sousTotalAvecRabaisSansExpedition - state.cart[index].price
            )
              // VERIFIER QUE PRIX DU CART EST BON
              presentoirs.push({
                index: index,
                prixMin: item.commandeMinimale,
              });
          } else {
            if (
              item.commandeMinimale <= state.sousTotalAvecRabaisSansExpedition
            )
              // VERIFIER QUE PRIX DU CART EST BON SANS SOUSTRAIRE LE PRIX DU CART CART DEJA RABAIS DANS SOUSTOTAL
              presentoirs.push({
                index: index,
                prixMin: item.commandeMinimale,
              });
          }
        }
      });

      if (presentoirs.length > 0) {
        bestDeal = Math.max.apply(
          Math,
          presentoirs.map(function (o) {
            return o.prixMin;
          })
        );
        bestDealIndex = state.cart.findIndex(
          (x) => x.commandeMinimale === bestDeal
        );
        state.promoPresentoir = [bestDealIndex];
      } else {
        state.promoPresentoir = null;

        state.cart.forEach((item, index) => {
          // ENLEVE LA PROMO SI UN CLIENT REBAISSE LE TOTAL DU CART PAR LA SUITE
          if (item.parentId === 777777 && item.variationId === 777777) {
            this.removeFromCart(index, 777777);
          }
        });
      }
    }
  },

  addPromoPresentoir() {
    let itemToAdd = {
      parentId: 777777,
      variation: 777777,
      price: -state.cart[state.promoPresentoir].price,
      isPromo: true,
      format: "",
    };

    // PERMET D'AJOUTER UNE SEULE FOIS SEULEMENT
    if (
      !state.cart.some((el) => {
        return el.variationId === 777777 && el.parentId === 777777;
      })
    ) {
      this.addToCart([
        itemToAdd,
        1,
        "Promo location de présentoir sans frais",
        [false, false],
        false,
      ]);
    }
  },

  promoPresentoirUnique() {
    if (
      state.cart.some((el) => {
        return el.variationId === 777777 && el.parentId === 777777;
      })
    ) {
      if (!state.gotFreePresentoir) {
        // SI LE CLIENT A DEJA EUE LA PROMO DU LOCATION DE PRESENTOIR
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },

  async fetchRepresentants() {
    state.loadingRepresentants = true;

    try {
      const res = await fetch(state.getRepresentantsUrl, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
      const json = await res.json();

      state.representants = json.data;
      // sort representants by representative_firstName asc
      state.representants.sort((a, b) =>
        a.representative_firstName > b.representative_firstName
          ? 1
          : b.representative_firstName > a.representative_firstName
          ? -1
          : 0
      );
    } catch (err) {
      state.error = err;

      if (err.json) {
        return err.json.then((json_1) => {
          this.error.message = json_1.message;
        });
      }
    }
    state.loadingRepresentants = false;
  },

  async fetchCreators() {
    state.loadingCreators = true;

    try {
      const res = await fetch(state.getCreatorsUrl, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
      const json = await res.json();

      state.creators = json.data;
    } catch (err) {
      state.error = err;

      if (err.json) {
        return err.json.then((json_1) => {
          this.error.message = json_1.message;
        });
      }
    }
    state.loadingCreators = false;
  },

  async fetchCoffretMeliMelo() {
    state.loadingCoffret = true;

    try {
      const res = await fetch(state.getCoffretsMeliMeloUrl, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
      const json = await res.json();

      state.coffretsMeliMelo = json.data;
    } catch (err) {
      state.error = err;

      if (err.json) {
        return err.json.then((json_1) => {
          this.error.message = json_1.message;
        });
      }
    }
    state.loadingCoffret = false;
  }, // FILTRE DE COFFRETS

  async fetchCoffretBySlug(coffret_slug) {
    state.loadingCoffretBySlug = true;

    try {
      const res = await fetch(
        `${state.getCoffretsMeliMeloUrl}/${coffret_slug}`,
        {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        }
      );

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
      const json = await res.json();
      // ACTIVER FONCTION POUR CALCULER INVENTAIRE DE STOCK

      state.coffretBySlug = json.data;
    } catch (err) {
      state.error = err;

      if (err.json) {
        return err.json.then((json_1) => {
          this.error.message = json_1.message;
        });
      }
    }
    state.loadingCoffretBySlug = false;
  },

  stockCoffretAvailable(coffret, isMeliMelo) {
    let stock = [];
    if (isMeliMelo) {
      if (state.data === null) {
        state.loading = true;

        return fetch(state.getInventoryUrl, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        })
          .then((res) => {
            if (!res.ok) {
              const error = new Error(res.statusText);
              error.json = res.json();
              throw error;
            }

            return res.json();
          })
          .then((json) => {
            // set the response data
            state.allProducts = json.data;
            state.data = json.data;
            this.showCategory(state.data);
            this.showTags(state.data);
            this.showFormats(state.data);
            this.calculNbrResults(state.data);

            // CALCUL INVENTAIRE DU COFFRET

            coffret.coffret_varId.forEach((variant) => {
              state.data.forEach((product) => {
                let index = product.product_priceVariations.findIndex(
                  (x) => x.variation === variant
                );
                if (index !== -1) {
                  stock.push(product.product_priceVariations.stockAvailable);
                }
              });
            });
            if (stock.length > 0) {
              const min = stock.reduce((a, b) => Math.min(a, b));
              state.stockCoffretMelimeli = min;
              return min;
            } else {
              state.stockCoffretMelimeli = 0;
              return 0;
            }
          })
          .catch((err) => {
            state.error = err;
            // In case a custom JSON error response was provided
            if (err.json) {
              return err.json.then((json) => {
                // set the JSON response message
                this.error.message = json.message;
              });
            }
          })
          .then(() => {
            state.loading = false;
          });
      } else {
        coffret.coffret_varId.forEach((variant) => {
          state.data.forEach((product) => {
            let index = product.product_priceVariations.findIndex(
              (x) => x.variation === variant
            );
            if (index !== -1) {
              stock.push(product.product_priceVariations.stockAvailable);
            }
          });
        });
        if (stock.length > 0) {
          const min = stock.reduce((a, b) => Math.min(a, b));
          state.stockCoffretMelimeli = min;
        } else {
          state.stockCoffretMelimeli = 0;
        }
      }
    } else {
      let stockAvailable = 0;
      state.data.forEach((card) => {
        card.product_priceVariations.forEach((variant) => {
          if (variant.variation === coffret.variation) {
            stockAvailable = Math.floor(
              variant.stockAvailable / state.grosseurCoffret
            );
          }
        });
      });

      // SI PAS DE RETURN DANS LE LOOP RETOURNER 0 CAR AUCUN STOCK
      return stockAvailable;
    }
  },

  async fetchPresentoirs() {
    state.loadingPresentoirs = true;

    try {
      const res = await fetch(state.getPresentoirsUrl, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
      const json = await res.json();

      state.presentoirs = json.data;
    } catch (err) {
      state.error = err;
      // In case a custom JSON error response was provided
      if (err.json) {
        return err.json.then((json_1) => {
          // set the JSON response message
          this.error.message = json_1.message;
        });
      }
    }
    state.loadingPresentoirs = false;
  },

  // FETCHER LES NOUVEAUX PRODUITS PAS ENCORE CONFIRMER

  async fetchNewProducts() {
    state.loadingNewProducts = true;

    try {
      const res = await fetch(state.getNewProductsUrl, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });
      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
      const json = await res.json();
      if (json.data.length > 0) {
        state.gotNewProducts = true;
      } else {
        state.gotNewProducts = false;
      }
      // set the response data

      state.newProducts = json.data.sort(function (a, b) {
        // SORT EN ORDRE ALPHABETIQUE
        return a.product_name.localeCompare(b.product_name);
      });
    } catch (err) {
      state.error = err;
      // In case a custom JSON error response was provided
      if (err.json) {
        return err.json.then((json_1) => {
          // set the JSON response message
          this.error.message = json_1.message;
        });
      }
    }
    state.loadingNewProducts = false;
  },

  // FETCH LES SETTINGS DE LA EXTRANET

  async fetchSettingsExtranet() {
    state.loadingSettingsExtranet = true;

    try {
      const res = await fetch(state.getSettingsExtranetUrl, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });
      // a non-200 response code
      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
      const json = await res.json();
      // APPLIQUE LES VALEURS DE LA DB;

      state.tps = json.data.tps;
      state.tvq = json.data.tvq;
      state.prixExpedition = json.data.prixExpedition;
      state.commandeMinimale = json.data.commandeMinimale;
      state.grosseurCoffret = json.data.grosseurCoffret;
      state.categoriesToRemove = json.data.categoriesToRemove;
      state.tagsToRemove = json.data.tagsToRemove;
      state.formatsToRemove = json.data.formatsToRemove;
      state.prixCoffretReg = json.data.prixCoffretReg;
      state.prixCoffretToucheDore = json.data.prixCoffretToucheDore;
      state.prixCoffretEns = json.data.prixCoffretEns;
      state.gotSettingsDb = true;
    } catch (err) {
      state.error = err;
      // In case a custom JSON error response was provided
      if (err.json) {
        return err.json.then((json_1) => {
          // set the JSON response message
          this.error.message = json_1.message;
        });
      }
    }
    state.loadingSettingsExtranet = false;
  },

  async fetchInfosCustomer() {
    state.loadingInfosCustomer = true;

    if (!state.customerId) {
      methods.checkUserId();
    }

    try {
      const res = await fetch(
        `${state.getInfosCustomer}/${state.customerId.user_id}`,
        {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        }
      );

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }

      const json = await res.json();

      // MODIF ADRESS CUSTOMER HARVEST

      // SI AUCUNE DONNE DANS INFOS HARVEST ALLER LES CHERCHER...
      if (!state.infoHarvest) {
        state.infoHarvest = this.getInfosClientHarvest(
          json.data.customer_harvestId
        );
      }

      // END MODIF

      state.infosCustomer = json.data;

      // MODIFIER DATA DU CLIENT POUR CELLE DE HARVEST

      state.infosCustomer.customer_name = state.infoHarvest.name;
      state.infosCustomer.adresse_harvest = state.infoHarvest.adresse;

      state.gotFreePresentoir = state.infosCustomer.customer_gotPromoPresentoir; // RENVOIS SI LE CLIENT A EUE LA PROMO DU PRESENTOIR
      this.setCookie("user_id", state.infosCustomer.customer_userId, 30);
    } catch (err) {
      state.error = err;

      if (err.json) {
        return err.json.then((json_1) => {
          this.error.message = json_1.message;
        });
      }
    }
    state.loadingInfosCustomer = false;
  },

  async getCommandes() {
    state.loadingCommandes = true;

    try {
      const res = await fetch(state.getCommandesUrl, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
      const json = await res.json();

      state.commandesList = json.data;
    } catch (err) {
      state.error = err;

      if (err.json) {
        return err.json.then((json_1) => {
          this.error.message = json_1.message;
        });
      }
    }
    state.loadingCommandes = false;
  },

  async getCommande(orderNum) {
    state.loadingCommande = true;

    try {
      const res = await fetch(`${state.getCommandeUrl}/${orderNum}`, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
      const json = await res.json();
      state.currentCommande = json.data;

      state.loadingCommande = false;
    } catch (err) {
      state.error = err;

      if (err.json) {
        return err.json.then((json_1) => {
          this.error.message = json_1.message;
        });
      }
    }
  },

  async updateCommande(data) {
    state.loadingUpdateCommande = true;

    try {
      const res = await fetch(`${state.updateCommandeUrl}`, {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(data),
      });

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }

      state.loadingUpdateCommande = false;
    } catch (err) {
      state.error = err;
      // In case a custom JSON error response was provided
      if (err.json) {
        return err.json.then((json_1) => {
          // set the JSON response message
          this.error.message = json_1.message;
        });
      }
    }
  },

  async editCoffretReg(variation, isTrue, typeCoffretNum) {
    try {
      const res = await fetch(
        `${state.editCoffretRegUrl}/${variation}/${isTrue}/${typeCoffretNum}`,
        {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        }
      );

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
    } catch (err) {
      state.error = err;
      // In case a custom JSON error response was provided
      if (err.json) {
        return err.json.then((json_1) => {
          // set the JSON response message
          this.error.message = json_1.message;
        });
      }
    }
  },

  async getStatsCreator(creatorId, startDate, endDate, type) {
    state.loadingStatsCreator = true;

    try {
      const res = await fetch(
        `${state.getStatsCreatorUrl}/${creatorId}/${startDate}/${endDate}/${type}`,
        {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        }
      );

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
      const json = await res.json();
      state.statsCreator = json.data;

      state.loadingStatsCreator = false;
    } catch (err) {
      state.error = err;

      if (err.json) {
        return err.json.then((json_1) => {
          this.error.message = json_1.message;
        });
      }
    }
  },

  async getStatsRepresentant(representantId, startDate, endDate) {
    state.loadingStatsRepresentants = true;

    try {
      const res = await fetch(
        `${state.getStatsRepresentantUrl}/${representantId}/${startDate}/${endDate}`,
        {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        }
      );

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
      const json = await res.json();
      state.statsRepresentant = json.data;

      state.loadingStatsRepresentants = false;
    } catch (err) {
      state.error = err;

      if (err.json) {
        return err.json.then((json_1) => {
          this.error.message = json_1.message;
        });
      }
    }
  },

  async editCoffretPrice(coffretType, price) {
    try {
      const res = await fetch(
        `${state.editCoffretRegPriceUrl}/${price}/${coffretType}`,
        {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        }
      );
      // a non-200 response code
      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
      const json = await res.json();

      if (json.success === 1) {
        // ENVOYER RESULT OK AU BON COFFRET

        if (state.currentModeCoffret === "touchedore") {
          state.updatedToucheDore = true;
        } else if (state.currentModeCoffret === "regulieres") {
          state.updatedReg = true;
        } else {
          state.updatedEns = true;
        }
      }
    } catch (err) {
      state.error = err;

      if (err.json) {
        return err.json.then((json_1) => {
          this.error.message = json_1.message;
        });
      }
    }
  },

  // FETCH LES CODES PROMO

  async getCodesPromos() {
    state.loadingCodesPromo = true;

    try {
      const res = await fetch(state.getCodesPromosUrl, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
      const json = await res.json();
      // APPLIQUE LES VALEURS DE LA DB;

      state.codesPromo = json.data;
      state.loadingCodesPromo = false;
    } catch (err) {
      state.error = err;

      if (err.json) {
        return err.json.then((json_1) => {
          this.error.message = json_1.message;
        });
      }
    }
  },

  async getCodePromo(codePromo) {
    try {
      const res = await fetch(
        `${state.getCodePromoUrl}/${codePromo}/${state.infosCustomer.customer_id}`,
        {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        }
      );

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      } else {
        const json = await res.json();
        // APPLIQUE LES VALEURS DE LA DB;

        state.myCodePromo = json.data;
      }
    } catch (err) {
      state.error = err;

      if (err.json) {
        return err.json.then((json_1) => {
          this.error.message = json_1.message;
        });
      }
    }
  },

  async deleteCodePromo(codePromoId) {
    try {
      const res = await fetch(`${state.deleteCodePromoUrl}/${codePromoId}/`, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      }
    } catch (err) {
      state.error = err;

      if (err.json) {
        return err.json.then((json_1) => {
          this.error.message = json_1.message;
        });
      }
    }
  },

  // SORT TAGS FOR COFFRETS

  tagsRegList() {
    if (state.data) {
      let tags = [];
      let tempTags = [];
      state.data.forEach((product) => {
        if (product.product_tags.length > 0) {
          let isGoodFormat = false;
          let isInListing = false;

          product.product_priceVariations.forEach((variation) => {
            if (variation.isCoffretReg === 1) {
              isInListing = true;
            }
          });

          if (isInListing) {
            product.product_priceVariations.forEach((variation) => {
              if (variation.format.includes("5,25")) {
                isGoodFormat = true;
              }
            });
          }

          if (isGoodFormat) {
            for (let i = 0; i < product.product_tags.length; i++) {
              if (!state.tagsToRemove.includes(product.product_tags[i].id)) {
                // REMOVE CATEGORIES LISTED
                tempTags = {
                  id: product.product_tags[i].id,
                  name: product.product_tags[i].name,
                };
                tags.push(tempTags);
              }
            }
          }
        }
      });

      state.tagsCoffReg = this.removeDuplicates(tags, (item) => item.id);
      state.tagsCoffReg.sort(this.dynamicSort("name")); // SORT BY ALPHABETIC ORDER

      return state.tagsCoffReg;
    }
  },

  tagsEnsList() {
    if (state.data) {
      // SCAN TAG POUR COFFRET ENSEMENCÉES
      let tagsEns = [];
      let tempTagsEns = [];
      state.data.forEach((product) => {
        if (product.product_tags.length > 0) {
          let isInListing = false;
          let isGoodCat = false;

          product.product_priceVariations.forEach((variation) => {
            if (variation.isCoffretEns === 1) {
              isInListing = true;
            }
          });
          if (isInListing) {
            product.product_categories.forEach((category) => {
              if (category.id === 461) {
                isGoodCat = true;
              }
            });
          }

          if (isGoodCat) {
            for (let i = 0; i < product.product_tags.length; i++) {
              if (!state.tagsToRemove.includes(product.product_tags[i].id)) {
                // REMOVE CATEGORIES LISTED
                tempTagsEns = {
                  id: product.product_tags[i].id,
                  name: product.product_tags[i].name,
                };
                tagsEns.push(tempTagsEns);
              }
            }
          }
        }
      });

      state.tagsCoffEns = this.removeDuplicates(tagsEns, (item) => item.id);
      state.tagsCoffEns.sort(this.dynamicSort("name")); // SORT BY ALPHABETIC ORDER

      return state.tagsCoffEns;
    }
  },

  tagsToucheDoreList() {
    if (state.data) {
      // SCAN TAG POUR COFFRET ENSEMENCÉES
      let tagsEns = [];
      let tempTagsEns = [];
      state.data.forEach((product) => {
        if (product.product_tags.length > 0) {
          let isInListing = false;
          let isGoodCat = false;

          product.product_priceVariations.forEach((variation) => {
            if (variation.isCoffretToucheDore === 1) {
              isInListing = true;
            }
          });
          if (isInListing) {
            product.product_categories.forEach((category) => {
              if (category.id === 460) {
                isGoodCat = true;
              }
            });
          }

          if (isGoodCat) {
            for (let i = 0; i < product.product_tags.length; i++) {
              if (!state.tagsToRemove.includes(product.product_tags[i].id)) {
                // REMOVE CATEGORIES LISTED
                tempTagsEns = {
                  id: product.product_tags[i].id,
                  name: product.product_tags[i].name,
                };
                tagsEns.push(tempTagsEns);
              }
            }
          }
        }
      });

      state.tagsCoffToucheDore = this.removeDuplicates(
        tagsEns,
        (item) => item.id
      );
      state.tagsCoffToucheDore.sort(this.dynamicSort("name")); // SORT BY ALPHABETIC ORDER

      return state.tagsCoffToucheDore;
    }
  },

  getInventoryCoffret(coffret) {
    // CALCUL LE STOCK D'UN COFFRET

    let inventoryCoffretToScan = [];
    state.data.forEach((product) => {
      product.product_priceVariations.forEach((variation) => {
        inventoryCoffretToScan.push({
          variation_id: variation.variation,
          stock_available: variation.stockAvailable,
        });
      });
    });

    let minimalStock = 0;
    const cardsStock = [];
    coffret.coffret_varId.forEach((varId) => {
      let index = inventoryCoffretToScan.findIndex(
        (x) => x.variation_id === varId
      );
      if (index !== -1) {
        cardsStock.push(inventoryCoffretToScan[index].stock_available);
      }
    });

    if (cardsStock.length) {
      minimalStock = Math.min(...cardsStock);

      return minimalStock;
    } else {
      return 0;
    }
  },

  // AJUSTEMENT D'INVENTAIRE POUR COFFRET MELIMELO
  updateMeliMeloInventary(coffret, quantity) {
    let inventoryCoffretToScan = [];
    state.data.forEach((product, indexProduct) => {
      product.product_priceVariations.forEach((variation, indexVariation) => {
        inventoryCoffretToScan.push({
          variation_id: variation.variation,
          stock_available: variation.stockAvailable,
          indexProduct: indexProduct,
          indexVariation: indexVariation,
        });
      });
    });

    const cardsStock = [];
    coffret.forEach((varId) => {
      let index = inventoryCoffretToScan.findIndex(
        (x) => x.variation_id === varId
      );

      if (index !== -1) {
        cardsStock.push({
          variation_id: inventoryCoffretToScan[index].variation_id,
          indexProduct: inventoryCoffretToScan[index].indexProduct,
          indexVariation: inventoryCoffretToScan[index].indexVariation,
        });
      }
    });

    cardsStock.forEach((product) => {
      state.data[product.indexProduct].product_priceVariations[
        product.indexVariation
      ].stockAvailable -= quantity;
    });
  },

  // CALCUL L'INVENTAIRE SUIVANT LE RELOAD DU PANIER
  calculDifferentielPanierInventaire() {
    if (!state.inventoryBeenUpdated) {
      // ASSURER QUE LA CORRECTION SE FAIT UNE SEULE FOIS

      state.inventoryBeenUpdated = true;

      if (state.cart.length) {
        let presentoirDeleted = false;

        state.cart.forEach((item, indexCart) => {
          if (item.isPresentoir) {
            // SI PRESENTOIR

            let indexPresentoir = state.presentoirs.findIndex(
              (product) => product.presentoirId === item.variationId
            );
            if (
              state.presentoirs[indexPresentoir].presentoirStockAvailable -
                item.quantity <
              0
            ) {
              // SI LA COMMANDE DEFONCE LE STOCK
              state.errorInventory.push(indexCart); // POUSSER L'INDEX PROBLEMATIQUE SI INVENTAIRE A BAISSER ENTRE TEMPS
              presentoirDeleted = true;
            } else {
              state.presentoirs[indexPresentoir].presentoirStockAvailable -=
                item.quantity;
            }
          } else if (item.isCoffret && !item.isMeliMelo) {
            // SI COFFRET REG

            let indexProduct = state.data.findIndex(
              (product) => product.product_id === item.parentId
            );
            let indexVariation = state.data[
              indexProduct
            ].product_priceVariations.findIndex(
              (variation) => variation.variation === item.variationId
            );

            if (
              state.data[indexProduct].product_priceVariations[indexVariation]
                .stockAvailable -
                item.quantity * 6 <
              0
            ) {
              state.errorInventory.push(indexCart); // POUSSER L'INDEX PROBLEMATIQUE SI INVENTAIRE A BAISSER ENTRE TEMPS
            } else {
              state.data[indexProduct].product_priceVariations[
                indexVariation
              ].stockAvailable -= item.quantity * 6;
            }
          } else if (item.isCoffret && item.isMeliMelo) {
            // SI MELIMELO

            let formatedCoffret = {
              coffret_varId: item.variationId,
            };

            if (this.getInventoryCoffret(formatedCoffret) - item.quantity < 0) {
              state.errorInventory.push(indexCart); // POUSSER L'INDEX PROBLEMATIQUE SI INVENTAIRE A BAISSER ENTRE TEMPS
            } else {
              this.updateMeliMeloInventary(item.variationId, item.quantity);
            }
          } else if (item.parentId !== 777777 && item.parentId !== 999998) {
            // SI PRODUIT REG ET PAS PROMO

            let indexProduct = state.data.findIndex(
              (product) => product.product_id === item.parentId
            );
            let indexVariation = state.data[
              indexProduct
            ].product_priceVariations.findIndex(
              (variation) => variation.variation === item.variationId
            );

            if (
              state.data[indexProduct].product_priceVariations[indexVariation]
                .stockAvailable -
                item.quantity <
              0
            ) {
              state.errorInventory.push(indexCart); // POUSSER L'INDEX PROBLEMATIQUE SI INVENTAIRE A BAISSER ENTRE TEMPS
            } else {
              state.data[indexProduct].product_priceVariations[
                indexVariation
              ].stockAvailable -= item.quantity;
            }
          }
        });

        // GESTION DES DIFFENRENCE PROBLEMATIQUE D'INVENTAIRE

        if (state.errorInventory.length) {
          // SI DES ERREURS EFFACER LES ITEMS DU PANIER
          state.errorInventoryMessage =
            "Le(s) item(s) suivant(s) ont été(s) retiré(s) de votre panier du à un changement d'inventaire : ";
          state.errorInventory.forEach((index) => {
            state.errorInventoryMessage += ` ${state.cart[index].name} , `;
            state.cart.splice(index, 1);
          });
        } else {
          state.errorInventoryMessage = null;
        }

        if (presentoirDeleted) {
          // SI UN PRESENTOIR A ETE EFFACER GERER LE RESTE
          methods.handleRentPresentoir();
        }

        this.updateLocalStorage();
        this.calculSousTotalCart();
        this.countCartQty();
        this.calculTaxes();
      }
    }
  },

  delayDiffenrentiel() {
    // DIFFERE LE CALCUL LE TEMPS DE TOUT LOADER
    setTimeout(function () {
      if (
        state.data !== null &&
        state.presentoirs !== null &&
        state.coffretsMeliMelo !== null &&
        !state.loading
      ) {
        methods.calculDifferentielPanierInventaire();
      } else {
        methods.delayDiffenrentiel();
      }
    }, 1000);
  },

  checkIfCart() {
    if (
      localStorage.getItem(`mimosa-extranet--${this.getUserId()}`) &&
      state.cart.length === 0
    ) {
      let ifCartGood = true;
      const cartInLocalStorage = JSON.parse(
        localStorage.getItem(`mimosa-extranet--${this.getUserId()}`)
      ).cart;

      cartInLocalStorage.forEach((item) => {
        // VERIFICATIONS SI CART EST TOUJOURS BON

        if (
          !item.isPresentoir &&
          !item.isMeliMelo &&
          item.parentId !== 777777 &&
          item.parentId !== 999998
        ) {
          let ifProductExist = state.data.findIndex(
            (itemInDb) => itemInDb.product_id === item.parentId
          );
          if (ifProductExist !== -1) {
            let ifVariationExist = state.data[
              ifProductExist
            ].product_priceVariations.findIndex(
              (variation) => variation.variation === item.variationId
            );
            if (ifVariationExist === -1) {
              ifCartGood = false;
            }
          } else {
            ifCartGood = false;
          }
        }
      });

      if (ifCartGood) {
        // SI CART TOUJOURS BON ON LE RESTORE

        state.cart = cartInLocalStorage;
        this.countCartQty();
        // CALCUL LE DIFFENRENCE ENTRE INVENTAIRE REEL ET AVEC PANIER

        methods.delayDiffenrentiel();
      } else {
        // SINON ON EFFACE LE CART
        methods.clearCart();
      }
    }
  },

  checkIfNumCommande() {
    if (localStorage.getItem(`mimosa-extranet--${this.getUserId()}`)) {
      state.numCommandeFinalized = JSON.parse(
        localStorage.getItem(`mimosa-extranet--${this.getUserId()}`)
      ).numCommande;
      state.customerIdFinalized = JSON.parse(
        localStorage.getItem(`mimosa-extranet--${this.getUserId()}`)
      ).customerId;

      return {
        numCommande: state.numCommandeFinalized,
        customerId: state.customerIdFinalized,
      };
    }
  },

  async uploadPromotion(promo) {
    try {
      const res = await fetch(`${state.createPromotionUrl}`, {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(promo),
      });
      const data = res.json();

      if (!res.ok) {
        console.error(data);
        return 0;
      } else {
        return 1;
      }
    } catch (err) {
      console.error(err);
      return 0;
    }
  },

  // GET ACTIVES PROMOTIONS

  async getActivesPromotions() {
    state.loadActivesPromotions = true;
    try {
      const res = await fetch(`${state.getActivesPromotionsUrl}`, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      } else {
        // APPLIQUE LES VALEURS DE LA DB;
        const json = await res.json();
        state.activesPromotions = json.data;

        state.loadActivesPromotions = false;
      }
    } catch (err) {
      console.log(err);
    }
  },

  async getInfosClientHarvest(harvestClientId) {
    try {
      const res = await fetch(
        `${process.env.VUE_APP_HARVEST_URL}/${harvestClientId}`,
        {
          method: "GET",
          headers: {
            "Harvest-Account-Id": process.env.VUE_APP_HARVEST_ACOUNT_ID,
            Authorization: "Bearer " + process.env.VUE_APP_TOKEN_HARVEST,
          },
        }
      );
      if (!res.ok) {
        console.log("un problème est survenu.");
      } else {
        const data = await res.json();

        console.log(data);

        return data;
      }
    } catch (err) {
      console.log(err);
    }
  },

  //  LIST ALL CLIENT NAMES FROM DB EXTRANET

  async getAllClientsInfos() {
    try {
      const res = await fetch(`${state.getAllCustomersUrl}`, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });

      if (!res.ok) {
        console.log("un problème est survenu.");
      } else {
        const data = await res.json();
        state.allClientsInfos = data.data;
        // SORT CLIENT BY COMPANGNY NAME
        state.allClientsInfos.sort((a, b) =>
          a.customer_compagnyName > b.customer_compagnyName ? 1 : -1
        );
      }
    } catch (err) {
      console.log(err);
    }
  },

  //  LIST ALL CLIENT NAMES FROM DB EXTRANET

  async getAllClientsInfosForRegistration() {
    const key = {
      key: process.env.VUE_APP_GETCLIENTS_KEY,
    };
    try {
      const res = await fetch(`${state.getAllCustomersForRegistrationUrl}`, {
        method: "post",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(key),
      });

      if (!res.ok) {
        console.log(res.statusText);
        console.log("un problème est survenu.");
      } else {
        const data = await res.json();
        state.allClientsInfos = data.data;
      }
    } catch (err) {
      console.log(err);
    }
  },

  async getPrixRevente() {
    try {
      const res = await fetch(state.getPrixReventeUrl, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });
      // a non-200 response code
      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();

        throw error;
      }
      const json = await res.json();

      // APPLIQUE LES VALEURS DE LA DB;

      state.prixRevente = json.data;
    } catch (err) {
      console.log(err);
    }
    state.loadingSettingsExtranet = false;
  },

  async getAllCatsToHandle() {
    console.log("fetching cats to handle");
    try {
      const res = await fetch(state.getAllCatsToHandleUrl, {
        method: "get",
        headers: {
          "content-type": "application/json",
        },
        credentials: "include",
      });

      if (!res.ok) {
        console.log(res.statusText);
        console.log("un problème est survenu.");
      } else {
        const data = await res.json();
        state.catsToHandle = data.data;
        console.log(state.catsToHandle);
      }
    } catch (err) {
      console.log(err);
    }
  },

  returnBaseUrl() {
    return state.baseFetchUrl;
  },
};

const getters = {};

export default {
  state,
  methods,
  getters,
};
