<template>
  <div class="home relative hauteurmin">
    <img
      src="@/assets/images/Mimosa-design-logo-small.png"
      alt="Mimosa Design"
      width="274"
      height="65"
      id="logo-mimosa"
    />
    <div class="mt-14 pb-10">
      <p class="uppercase  text-xs link">
        Réinitialisation de votre mot de passe.
      </p>
    </div>
    <input
      class="h-10 px-3 mb-2"
      type="password"
      placeholder="Mot de passe"
      v-model="password.password1"
    />
    <input
      class="h-10 px-3 mb-2"
      type="password"
      placeholder="Confirmer le mot de passe"
      v-model="password.password2"
    />

    <button
      class="h-10 px-5 m-2 mb-7 text-white text-sm transition-colors duration-150 bg-black focus:shadow-outline uppercase"
      @click="resetPass()"
    >
      Réinitialiser
    </button>

    <div v-if="message">
      <p class="text-green-500">Votre mot de passe est réinitialisé.</p>
    </div>
    <div v-if="messageOk">
      <p>{{ messageOk }}</p>
    </div>

    <div class="absolute bottom-10 text-sm">
      © {{ store.methods.getCurrentYear() }}. Mimosa Design - Extranet. Tous
      droits réservés.
    </div>
  </div>
</template>

<script>
import { onMounted, ref, inject } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "Reinitialisation",
  setup() {
    const route = useRoute();
    const store = inject("store");
    const message = ref(null);
    const messageOk = ref(null);
    const resetToken = ref(null);
    const password = ref({});

    async function resetPass() {
      if (!password.value.password1 || !password.value.password2) {
        message.value = "Veuillez remplir les champs.";
        return;
      }

      if (password.value.password1 !== password.value.password2) {
        message.value = "Les mots de passes ne concordent pas.";
        return;
      }
      if (password.value.password1.length < 7) {
        message.value =
          "Veuillez choisir un mot de passe d'un minimum de 7 caractères.";
        return;
      }

      let data = {
        newPass: password.value.password1,
        resetToken: resetToken.value,
      };

      try {
        const res = await fetch(`${store.state.resetPasswordUrl}`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(data),
        });

        // a non-200 response code
        if (!res.ok) {
          message.value = true;
        } else {
          const json = await res.json();

          if (json.success === 1) {
            if (json.data.changedRows === 1) {
              messageOk.value = "Votre mot de passe a été changé avec succès";
              message.value = null;
            } else {
              message.value =
                "Votre lien est expiré, veuillez refaire une demande changement de mot de passe.";
              messageOk.value = null;
            }
          } else {
            messageOk.value = null;
            message.value =
              "Votre lien est expiré, veuillez refaire une demande changement de mot de passe.";
          }
        }
      } catch (err) {
        message.value = true;
      }
    }

    onMounted(() => {
      window.scrollTo(0, 0);

      if (route.params.resetLink) {
        resetToken.value = route.params.resetLink;
      }
    });

    return {
      password,
      message,
      messageOk,
      resetPass,
    };
  },
};
</script>
<style lang="scss">
.cartes {
  max-width: 1445px;
  margin: auto;

  #modeBoutique,
  #modeRapide {
    cursor: pointer;
  }
}
</style>
