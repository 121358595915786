<template>
  <router-view />
</template>
<script>
import store from "./store/index";
import { provide } from "vue";

export default {
  name: "App",

  setup() {
    provide("store", store);

    return {};
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap");

* {
  margin: 0;
  padding: 0;
}

.lineCategorie {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .textCategorie {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.3s ease-in-out;
  }
  img {
    height: 10px;
    padding-right: 15px;
  }
}

.titlesChoices {
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 1px;
  color: rgba(34, 34, 34, 1);
  font-weight: 700;
  font-style: normal;
}

.titleSection {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 25px;
  color: #222;
  letter-spacing: 2px;
}

#app {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
  background-color: rgba(255, 255, 255, 1);
}
</style>
