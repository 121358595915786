<template>
  <section
    class="filterCategories"
    v-if="!store.state.loading && store.state.data && store.state.currentTag"
  >
    <!-- GRANDEUR PRODUITS -->
    <h6 class="titlesChoices" v-if="getCategories().length">
      CATEGORIES DE PRODUITS
    </h6>

    <!-- CATEGORIES POUR CARTES  -->

    <div>
      <ul v-for="(categorie, index) in getCategories()" :key="index">
        <li class="lineCategorie" @click="selectCategorie(categorie.id)">
          <div class="textCategorie">
            <img src="@/assets/images/chevron-droit.png" class="chevron" />
            <p class="categorie_item" :id="`cat${categorie.id}`">
              {{ categorie.name }}
            </p>
          </div>
          <p>({{ categorie.count }})</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { inject, ref, watch, onMounted } from "vue";
export default {
  name: "SelectCategories",

  setup() {
    const store = inject("store");
    const currentCat = ref(null);

    function selectCategorie(categorieId) {
      if (currentCat.value !== null) {
        if (currentCat.value === categorieId) {
          currentCat.value = null;
          store.state.currentFilterCategories = "all";
          document.querySelector(`#cat${categorieId}`).style.fontWeight =
            "normal";
        } else {
          document.querySelector(`#cat${currentCat.value}`).style.fontWeight =
            "normal";
          currentCat.value = categorieId;
          document.querySelector(`#cat${categorieId}`).style.fontWeight = "600";
          store.state.currentFilterCategories = currentCat.value;
        }
      } else {
        currentCat.value = categorieId;
        document.querySelector(`#cat${categorieId}`).style.fontWeight = "600";
        store.state.currentFilterCategories = currentCat.value;
      }
    }

    function getCategories() {
      let categoriesList = [];

      store.state.data.forEach((item) => {
        const hasCurrentCategory = item.product_categories.some(
          (cat) => cat.id === store.state.currentTag
        );

        if (hasCurrentCategory) {
          item.product_categories.forEach((categorie) => {
            if (categorie.id !== store.state.currentTag) {
              categoriesList.push(categorie);
            }
          });
        }
      });

      // remove all duplicates in categoriesList but add key accountace for the number time it repeat befoire remove duplicates
      let categoriesArray = categoriesList.map((categorie) => {
        let count = 0;
        categoriesList.forEach((cat) => {
          if (cat.id === categorie.id) {
            count++;
          }
        });
        categorie.count = count;
        return categorie;
      });

      let filteredCategoriesArray = categoriesArray.filter(
        (categorie, index, self) =>
          index ===
          self.findIndex(
            (t) => t.id === categorie.id && t.name === categorie.name
          )
      );

      return filteredCategoriesArray;
    }

    function resetCatFilters() {
      // Réinitialiser le style de toutes les catégories
      const categoryElements = document.querySelectorAll(".categorie_item");
      categoryElements.forEach((element) => {
        element.style.fontWeight = "normal";
      });

      // Réinitialiser la catégorie courante
      currentCat.value = null;
    }

    onMounted(() => {
      currentCat.value = null;
      store.state.currentFilterCategories = "all";
    });

    watch(
      () => store.state.currentTag,
      () => {
        store.state.currentFilterCategories = "all";
        resetCatFilters();
        // refresh templates
      }
    );

    return {
      store,
      selectCategorie,
      currentCat,
      getCategories,
    };
  },
};
</script>

<style lang="scss">
.filterCategories {
  max-width: 250px;

  h6 {
    padding-bottom: 15px;
  }
}
</style>
