<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet
      </a>

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8">
        <div>
          <div class="text-3xl mb-10 uppercase">
            Gestion des coffrets
          </div>
        </div>
        <div class="w-full mt-10 grid grid-cols-1 lg:grid-cols-3">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="store.state.currentModeCoffret = 'touchedore'"
          >
            Une touche de doré
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="store.state.currentModeCoffret = 'ensemencees'"
          >
            Cartes ensemencées
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 col-span-3 lg:col-span-1 uppercase"
            @click="store.state.currentModeCoffret = 'regulieres'"
          >
            Cartes régulières
          </button>
        </div>

        <div
          class="pt-10"
          v-if="store.state.currentModeCoffret === 'regulieres'"
        >
          <p class="text-2xl mb-12">
            GESTION DES COFFRETS DE CARTES RÉGULIÈRES
          </p>
          <div class="mb-2" v-if="store.state.prixCoffretReg">
            <p>Prix du coffret :</p>
            <div class="flex justify-start items-center">
              <input
                type="number"
                step="0.01"
                class="bg-white border px-2 py-1 rounded mr-1 text-right"
                placeholder="Prix du coffret"
                v-model="prixCoffretReg"
                @change="store.state.updatedReg = false"
              />$

              <svg
                width="24"
                height="24"
                fill="green"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
                v-if="store.state.updatedReg"
              >
                <path
                  d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm7 7.457l-9.005 9.565-4.995-5.865.761-.649 4.271 5.016 8.24-8.752.728.685z"
                />
              </svg>
            </div>
          </div>
          <button
            type="button"
            class="mb-10 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            @click="submitPrice()"
          >
            Modifier le prix<span> </span>
          </button>

          <div v-for="(item, index) in store.state.data" :key="index">
            <div
              v-for="(variation, index) in item.product_priceVariations"
              :key="index"
            >
              <div
                v-if="variation.format.includes('5,25')"
                class="grid grid-cols-5 px-6 py-4 my-2 cursor-pointer overflow-hidden shadow"
              >
                <div class="flex items-center">
                  <img
                    :src="variation.imageVariation"
                    :alt="item.product_name"
                    class="h-14"
                  />
                </div>

                <div class="flex items-center col-span-2">
                  {{ item.product_name }}
                </div>
                <div class="flex items-center">
                  {{ variation.format }}
                </div>
                <div class="flex items-center">
                  <label class="inline-flex items-center">
                    <input
                      type="checkbox"
                      class="form-checkbox text-black-600 p-4"
                      v-model="variation.isCoffretReg"
                      @change="
                        addVariation(
                          variation.variation,
                          variation.isCoffretReg
                        )
                      "
                      :checked="
                        variation.isCoffretReg == 1
                          ? (variation.isCoffretReg = true)
                          : (variation.isCoffretReg = false)
                      "
                    />
                    <span class="ml-2 cursor-pointer">Offrir en coffret</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="pt-10"
          v-if="store.state.currentModeCoffret === 'touchedore'"
        >
          <p class="text-2xl mb-12">
            GESTION DES COFFRETS DE CARTES UNE TOUCHE DE DORÉ
          </p>
          <div class="mb-2" v-if="store.state.prixCoffretToucheDore">
            <p>Prix du coffret :</p>
            <div class="flex justify-start items-center">
              <input
                type="number"
                step="0.01"
                class="bg-white border px-2 py-1 rounded text-right mr-1"
                placeholder="Prix du coffret"
                v-model="prixCoffretToucheDore"
                @change="store.state.updatedToucheDore = false"
              />$

              <svg
                width="24"
                height="24"
                fill="green"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
                v-if="store.state.updatedToucheDore"
              >
                <path
                  d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm7 7.457l-9.005 9.565-4.995-5.865.761-.649 4.271 5.016 8.24-8.752.728.685z"
                />
              </svg>
            </div>
          </div>
          <button
            type="button"
            class="mb-10 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            @click="submitPrice()"
          >
            Modifier le prix<span> </span>
          </button>

          <div v-for="(item, index) in store.state.data" :key="index">
            <div
              v-for="(variation, index) in item.product_priceVariations"
              :key="index"
            >
              <div
                v-if="variation.format.includes('875')"
                class="grid grid-cols-5 px-6 py-4 my-2 cursor-pointer overflow-hidden shadow"
              >
                <div class="flex items-center">
                  <img
                    :src="variation.imageVariation"
                    :alt="item.product_name"
                    class="h-14"
                  />
                </div>

                <div class="flex items-center col-span-2">
                  {{ item.product_name }}
                </div>
                <div class="flex items-center">
                  {{ variation.format }}
                </div>
                <div class="flex items-center">
                  <label class="inline-flex items-center">
                    <input
                      type="checkbox"
                      class="form-checkbox text-black-600 p-4"
                      v-model="variation.isCoffretToucheDore"
                      @change="
                        addVariation(
                          variation.variation,
                          variation.isCoffretToucheDore
                        )
                      "
                      :checked="
                        variation.isCoffretToucheDore == 1
                          ? (variation.isCoffretToucheDore = true)
                          : (variation.isCoffretToucheDore = false)
                      "
                    />
                    <span class="ml-2 cursor-pointer">Offrir en coffret</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="pt-10"
          v-if="store.state.currentModeCoffret === 'ensemencees'"
        >
          <p class="text-2xl mb-12">
            GESTION DES COFFRETS DE CARTES ENSEMENCÉES
          </p>
          <div class="mb-2" v-if="store.state.prixCoffretEns">
            <p>Prix du coffret</p>
            <div class="flex justify-start items-center">
              <input
                type="number"
                step="0.01"
                class="bg-white border px-2 py-1 rounded mr-1 text-right"
                placeholder="Prix du coffret"
                v-model="prixCoffretEns"
                @change="store.state.updatedEns = false"
              />$
              <svg
                width="24"
                height="24"
                fill="green"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
                v-if="store.state.updatedEns"
              >
                <path
                  d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm7 7.457l-9.005 9.565-4.995-5.865.761-.649 4.271 5.016 8.24-8.752.728.685z"
                />
              </svg>
            </div>
          </div>
          <button
            type="button"
            class="mb-10 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
            @click="submitPrice()"
          >
            Modifier le prix
          </button>

          <div v-for="(item, index) in store.state.data" :key="index">
            <div
              v-for="(variation, index) in item.product_priceVariations"
              :key="index"
            >
              <div
                v-if="variation.format.includes('5,5')"
                class="grid grid-cols-5 px-6 py-4 my-2 cursor-pointer overflow-hidden shadow"
              >
                <div class="flex items-center">
                  <img
                    :src="variation.imageVariation"
                    :alt="item.product_name"
                    class="h-14"
                  />
                </div>

                <div class="flex items-center col-span-2">
                  {{ item.product_name }}
                </div>
                <div class="flex items-center">
                  {{ variation.format }}
                </div>
                <div class="flex items-center">
                  <label class="inline-flex items-center">
                    <input
                      type="checkbox"
                      class="form-checkbox text-black-600"
                      v-model="variation.isCoffretEns"
                      @change="
                        addVariation(
                          variation.variation,
                          variation.isCoffretEns
                        )
                      "
                      :checked="
                        variation.isCoffretEns == 1
                          ? (variation.isCoffretEns = true)
                          : (variation.isCoffretEns = false)
                      "
                    />
                    <span class="ml-2 cursor-pointer">Offrir en coffret</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { ref, onMounted, inject } from "vue";
export default {
  name: "CoffretRegAdmin",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");

    let prixCoffretReg = ref(null);
    let prixCoffretEns = ref(null);
    let prixCoffretToucheDore = ref(null);

    function addVariation(variation, isCoffretTrue) {
      let isTrueFalse = 0;

      if (isCoffretTrue) {
        isTrueFalse = "true";
      }

      let coffretTypeNum;

      if (store.state.currentModeCoffret === "regulieres") {
        // SI EN MODE REGULER
        coffretTypeNum = 1;

        // SI MODE TOUCHE DORE
      } else if (store.state.currentModeCoffret === "touchedore") {
        coffretTypeNum = 3;
      } else {
        // SINON EN MODE ENSEMENCEES
        coffretTypeNum = 2;
      }
      store.methods.editCoffretReg(variation, isTrueFalse, coffretTypeNum);
    }

    function submitPrice() {
      if (store.state.currentModeCoffret === "regulieres") {
        // TYPE COFFRET 1

        if (prixCoffretReg.value) {
          store.methods.editCoffretPrice(1, prixCoffretReg.value);
        }
      } else if (store.state.currentModeCoffret === "touchedore") {
        // TYPE COFFRET 3

        if (prixCoffretToucheDore.value) {
          store.methods.editCoffretPrice(3, prixCoffretToucheDore.value);
        }
      } else {
        // TYPE COFFRET 2

        if (prixCoffretEns.value) {
          store.methods.editCoffretPrice(2, prixCoffretEns.value);
        }
      }
    }

    onMounted(() => {
      if (store.state.data === null) {
        store.methods.fetchAllProducts();
      }
      if (
        !store.state.prixCoffretReg ||
        !store.state.prixCoffretEns ||
        !store.state.prixCoffretToucheDore
      ) {
        (async () => {
          await store.methods.fetchSettingsExtranet();
          prixCoffretEns.value = store.state.prixCoffretEns.toFixed(2);
          prixCoffretReg.value = store.state.prixCoffretReg.toFixed(2);
          prixCoffretToucheDore.value = store.state.prixCoffretToucheDore.toFixed(
            2
          );
        })();
      } else {
        prixCoffretEns.value = store.state.prixCoffretEns.toFixed(2);
        prixCoffretReg.value = store.state.prixCoffretReg.toFixed(2);
        prixCoffretToucheDore.value = store.state.prixCoffretToucheDore.toFixed(
          2
        );
      }

      (async () => {
        await store.methods.fetchAllProducts();
        store.methods.checkIfCart();
        store.methods.calculTaxes();
      })();
    });

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    return {
      toggleMenu,
      store,
      addVariation,
      prixCoffretReg,
      prixCoffretEns,
      submitPrice,
      prixCoffretToucheDore,
    };
  },
};
</script>
<style lang="scss"></style>
