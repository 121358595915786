<template>
  <main>
    <div class="enregistrementClient relative">
      <img
        src="@/assets/images/Mimosa-design-logo-small.png"
        class="mt-10"
        alt="Mimosa Design"
        width="274"
        height="65"
        id="logo-mimosa"
      />

      <template v-if="!successSendDemande">
        <div class="grid grid-cols-1 wrapperInput mt-10">
          <h1 class="uppercase font-bold text-lg text-center py-4">
            Devenir revendeur
          </h1>
          <p class="pt-6 pb-4 text-left">
            Veuillez remplir les champs nécessaires afin de compléter votre
            demande.
          </p>

          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Nom du commerce"
            v-model="infosClient.compagnyName"
            maxlength="70"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Nom de la personne contact"
            v-model="infosClient.contact"
            maxlength="70"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="email"
            placeholder="Courriel"
            v-model="infosClient.email"
            maxlength="70"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="No de téléphone"
            v-model="infosClient.phone"
            maxlength="20"
          />
        </div>
        <div class="grid grid-cols-1 wrapperInput mt-6">
          <p class="pb-4 font-weight-700">ADRESSE DE FACTURATION</p>
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="No civique"
            v-model="infosClient.billingAdressNumber"
            maxlength="70"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Rue"
            v-model="infosClient.billingStreet"
            maxlength="60"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Code postal"
            v-model="infosClient.billingPostalCode"
            maxlength="20"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Ville"
            v-model="infosClient.billingCity"
            maxlength="60"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Province"
            v-model="infosClient.billingProvince"
            maxlength="70"
          />
          <input
            class="mt-1 h-10 px-3 mb-2"
            type="text"
            placeholder="Pays"
            v-model="infosClient.billingPays"
            maxlength="70"
          />
          <div>
            <label class="inline-flex items-center wrapperInput mt-6">
              <input
                type="checkbox"
                class="form-checkbox text-black"
                v-model="infosClient.shippingIsBilling"
                id="checkifShipping"
              />
              <span class="ml-2 pl-4 mb-4"
                >Utiliser l'adresse de facturation comme adresse de
                livraison</span
              >
            </label>
          </div>

          <div
            class="grid grid-cols-1 wrapperInput my-6"
            v-if="!infosClient.shippingIsBilling"
          >
            <p class="pb-4 font-weight-700">ADRESSE DE LIVRAISON</p>
            <input
              class="mt-1 h-10 px-3 mb-2"
              type="text"
              placeholder="No civique"
              v-model="infosClient.shippingAdressNumber"
              maxlength="70"
            />
            <input
              class="mt-1 h-10 px-3 mb-2"
              type="text"
              placeholder="Rue"
              v-model="infosClient.shippingStreet"
              maxlength="60"
            />
            <input
              class="mt-1 h-10 px-3 mb-2"
              type="text"
              placeholder="Code postal"
              v-model="infosClient.shippingPostalCode"
              maxlength="20"
            />
            <input
              class="mt-1 h-10 px-3 mb-2"
              type="text"
              placeholder="Ville"
              v-model="infosClient.shippingCity"
              maxlength="60"
            />
            <input
              class="mt-1 h-10 px-3 mb-2"
              type="text"
              placeholder="Province"
              v-model="infosClient.shippingProvince"
              maxlength="70"
            />
            <input
              class="mt-1 h-10 px-3 mb-2"
              type="text"
              placeholder="Pays"
              v-model="infosClient.shippingPays"
              maxlength="70"
            />
          </div>
        </div>

        <div class="wrapperInput grid grid-cols-1 w-full">
          <p v-if="errorSendDemande" class="text-red-500 text-center">
            Une erreur est survenue pendant l'envoi de votre demande, veuillez
            essayer de nouveau. Si l'erreur persiste contactez directement
            l'équipe au
            <a href="mailto:info@mimosadesign.ca">info@mimosadesign.ca</a>
          </p>

          <button
            class="h-10 px-5 mt-10 mb-20 text-white text-sm transition-colors duration-150 bg-black focus:shadow-outline uppercase"
            :disabled="needInfos"
            @click="createClient()"
          >
            SOUMETTRE LA DEMANDE
          </button>
        </div>
      </template>
      <template v-else>
        <div
          class="col-span-4 row-start-1 px-5 flex justify-center items-center mt-10"
        >
          <div
            class="w-11/12 h-5/6 md:w-9/12 md:h-3/6 border flex justify-center items-center p-5 min-height-merci"
          >
            <div class="flex flex-col items-center">
              <h1 class="text-3xl mb-4">Merci !</h1>
              <p>
                Notre équipe vous contactera pour finaliser les détails de votre
                demande.
              </p>
            </div>
          </div>
        </div>
      </template>

      <div class="absolute bottom-10 text-sm">
        © {{ store.methods.getCurrentYear() }}. Mimosa Design - Extranet. Tous
        droits réservés.
      </div>
    </div>
  </main>
</template>

<script>
import { onMounted, inject, ref, reactive } from "vue";

export default {
  name: "Demande",
  setup() {
    const store = inject("store");

    const needMoreCar = ref(false);
    const infosClient = reactive({
      billingPays: "Canada",
      shippingPays: "Canada",
    });
    const needInfos = ref(false);

    const clientsListOk = ref(false);
    const selectClient = ref(null);

    const inputClientDisabled = ref(false);

    const successSendDemande = ref(false);
    const errorSendDemande = ref(false);

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    function createClient() {
      store.state.tempInfosClientEnregistrement = JSON.parse(
        JSON.stringify(infosClient)
      );

      const pass = infosClient.password1;
      infosClient.password = pass;

      if (
        !infosClient.compagnyName ||
        !infosClient.email ||
        !infosClient.phone
      ) {
        alert("Des informations sont manquantes");
        return;
      }

      sendDemande();
    }

    async function sendDemande() {
      errorSendDemande.value = false;
      successSendDemande.value = false;

      try {
        let response = await fetch(`${store.state.sendDemandeUrl}`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(infosClient),
        });

        if (!response.ok) {
          errorSendDemande.value = true;
        }

        successSendDemande.value = true;
      } catch (err) {
        console.log(err);
        errorSendDemande.value = true;
      }
    }

    return {
      store,

      infosClient,
      needInfos,
      needMoreCar,
      errorSendDemande,
      selectClient,
      clientsListOk,
      createClient,
      successSendDemande,
      inputClientDisabled,
      sendDemande,
    };
  },
};
</script>

<style lang="scss">
.enregistrementClient {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: rgba(255, 255, 255, 1);
    transition: background-color 5000s ease-in-out 0s;
  }
  input::-webkit-input-placeholder,
  input::-ms-input-placeholder,
  input:-ms-input-placeholder {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }

  input {
    color: #7a7a7a;
    border: 1px solid #dedede;
    outline-width: 0;
  }

  button:hover {
    background-color: #bba267;
  }
}

.wrapperInput {
  max-width: 400px;
  min-width: 250px;
}

#checkifShipping {
  transform: translateY(-8px);
  cursor: pointer;
}

.selectClient {
  margin-top: -20px;

  .check {
    cursor: pointer;
    position: relative;
    margin: auto;
    width: 18px;
    height: 18px;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
    p {
      width: 400px;
      transform: translate(28px, 21px);
    }
  }

  .check svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #c8ccd4;
    stroke-width: 1.5;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
  }
  .check svg path {
    stroke-dasharray: 60;
    stroke-dashoffset: 0;
  }
  .check svg polyline {
    stroke-dasharray: 22;
    stroke-dashoffset: 66;
  }
  .check:hover:before {
    opacity: 1;
  }
  .check:hover svg {
    stroke: #222;
  }
  #cbx:checked + .check svg {
    stroke: #222;
  }
  #cbx:checked + .check svg path {
    stroke-dashoffset: 60;
    transition: all 0.3s linear;
  }
  #cbx:checked + .check svg polyline {
    stroke-dashoffset: 42;
    transition: all 0.2s linear;
    transition-delay: 0.15s;
  }
}
</style>
