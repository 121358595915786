<template>
  <main>
    <TheNavbar />

    <div class="presentoirs grid grid-cols-1   px-4 lg:px-10 pt-16">
      <div class="px-5">
        <h1 class="titleSection uppercase pb-2">Présentoirs</h1>
      </div>
      <ShowPresentoirs />
    </div>

    <TheFooter />
  </main>
</template>

<script>
import TheNavbar from "../../components/TheNavbar";
import TheFooter from "../../components/TheFooter.vue";
import ShowPresentoirs from "../../components/ShowPresentoirs.vue";

import { inject, onMounted } from "vue";

export default {
  name: "Presentoirs",
  components: { TheNavbar, TheFooter, ShowPresentoirs },
  setup() {
    const store = inject("store");

    onMounted(() => {
      window.scrollTo(0, 0);
      // VERIFIE SI LE CLIENT A DEJA UN CART
      store.methods.tagsEnsList();
      store.methods.tagsRegList();

      if (!store.state.presentoirs) {
        store.methods.fetchPresentoirs();
      }

      if (!store.state.coffretsMeliMelo) {
        store.methods.fetchCoffretMeliMelo();
      }

      if (!store.state.categories) {
        if (!store.state.data) {
          (async () => {
            await store.methods.fetchAllProducts();
            store.methods.checkIfCart();

            (async () => {
              await store.methods.showCategory();
              // store.methods.checkIfCart();
              store.methods.calculTaxes();
            })();
          })();
        } else {
          // SI DATA PAS DOWNLOADÉ
          (async () => {
            await store.methods.showCategory();
            store.methods.checkIfCart();
            store.methods.calculTaxes();
          })();
        }
      } else {
        store.methods.showCategory();
        store.methods.checkIfCart();
      }
    });

    function switchMode(mode) {
      store.state.modeVisualisation = mode;
      if (mode === "rapide") {
        document.querySelector("#modeRapide").style.fontWeight = "500";
        document.querySelector("#modeBoutique").style.fontWeight = "normal";
      } else {
        document.querySelector("#modeBoutique").style.fontWeight = "500";
        document.querySelector("#modeRapide").style.fontWeight = "normal";
      }
    }

    return {
      store,
      switchMode,
    };
  },
};
</script>
<style lang="scss">
.presentoirs {
  max-width: 1445px;
  margin: auto;
  min-height: 70vh;

  #modeBoutique,
  #modeRapide {
    cursor: pointer;
  }
}
</style>
