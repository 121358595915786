<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 h-full flex justify-center items-center">
        <div
          class="w-11/12 h-5/6 md:w-6/12 md:h-2/6 border flex justify-center items-center p-3 mb-32"
        >
          <div class="flex flex-col items-center">
            <h1 class="text-3xl mb-4">Oups !</h1>
            <h2 class="text-2xl">
              La page n'est pas disponible.
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";

import "jspdf-autotable";

export default {
  name: "OupsAdmin",
  components: { AdminSideBar },
  setup() {
    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    return {
      toggleMenu,
    };
  },
};
</script>
<style lang="scss"></style>
