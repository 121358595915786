<template>
  <div class="`r`elative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8">
        <div class="col-pan-3">
          <div class="text-3xl uppercase">RAPPORTS DES REPRÉSENTANTS</div>
        </div>

        <p class="text-2xl my-10">Sélectionnez un représentant</p>
        <div class="relative w-6/12" v-if="store.state.representants">
          <select
            v-model="currentRep"
            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option disabled selected value="null">
              Veuillez sélectionner un représentant...
            </option>
            <option
              v-for="(rep, index) in store.state.representants"
              :key="index"
              :value="rep.representative_id"
            >
              {{ rep.representative_firstName }}
              {{ rep.representative_lastName }}
            </option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>

        <div class="flex justify-start mt-10">
          <div class="mr-10">
            <p class="pb-6">Date de début</p>
            <v-date-picker v-model="startDate" :masks="masks">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
          <div>
            <p class="pb-6">Date de fin</p>
            <v-date-picker v-model="endDate" :masks="masks">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
        </div>

        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded my-4"
          @click="buildRapport()"
        >
          <div class="flex justify-between">
            Générer le rapport<span class="ml-2" v-if="loadingRapport"
              ><img
                id="loadingLogo"
                src="../../assets/images/spinner_button.gif"
            /></span>
          </div>
        </button>
        <button
          v-if="store.state.statsRepresentant"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded my-4 md:ml-8"
          @click="getPdf()"
        >
          Télécharger le rapport
        </button>

        <div v-if="store.state.statsRepresentant">
          <table>
            <thead>
              <tr class="bg-white text-black text-xs">
                <th colspan="8">
                  <div class="mt-4 mb-4 text-xs text-gray-300">
                    ( RAPPORT REPRÉSENTANT )
                  </div>
                  <div class="text-base">
                    {{
                      store.state.statsRepresentant[1].representative_firstName
                    }}
                    {{
                      store.state.statsRepresentant[1].representative_lastName
                    }}
                  </div>
                  <div class="pt-2">
                    {{
                      store.state.statsRepresentant[1]
                        .representrative_commission
                    }}
                    % redevance
                  </div>
                  <div class="text-base lowercase pt-2 pb-4">
                    {{ store.state.statsRepresentant[1].dateDebut }} au
                    {{ store.state.statsRepresentant[1].dateFin }}
                  </div>
                </th>
              </tr>

              <tr>
                <th scope="col" col-span="3">CLIENTS</th>

                <th scope="col" colspan="1">#FACTURE</th>
                <th scope="col">TOTAL + TAXES</th>
                <th scope="col">AVANT TAXES</th>
                <th scope="col">LIVRAISON</th>
                <th scope="col">
                  SOUS-TOTAL
                  <!-- {{store.state.statsCreator[0].createur_commission}}% redev. -->
                </th>
                <th scope="col">
                  {{
                    store.state.statsRepresentant[1].representrative_commission
                  }}% redev.
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="stat in store.state.statsRepresentant[0]">
                <tr v-for="(vente, index) in stat.ventes" :key="index">
                  <td class="item-qty text-left">
                    {{ stat.customerName }}
                  </td>

                  <td class="item-qty text-center">{{ vente.orderNum }}</td>
                  <td class="item-qty text-right">
                    {{ vente.order_totalPrice.toFixed(2) }} $
                  </td>
                  <td class="item-qty text-right">
                    {{ vente.order_sousTotal.toFixed(2) }} $
                  </td>
                  <td class="item-qty text-right">
                    - {{ vente.order_shippingCost.toFixed(2) }} $
                  </td>
                  <td class="item-qty text-right">
                    {{
                      (
                        vente.order_sousTotal - vente.order_shippingCost
                      ).toFixed(2)
                    }}
                    $
                  </td>
                  <td class="item-qty text-right">
                    {{ vente.montantCommission.toFixed(2) }} $
                  </td>
                </tr>
              </template>
            </tbody>

            <tfoot>
              <tr class="text-offset">
                <td colspan="8" class="font-bold"></td>
              </tr>

              <tr class="text-offset">
                <td colspan="6">
                  Total des redevances entre le
                  {{ store.state.statsRepresentant[1].dateDebut }} et
                  {{ store.state.statsRepresentant[1].dateFin }} inclusivement
                </td>
                <td colspan="2" class="text-right">
                  {{
                    store.state.statsRepresentant[1].totalCommission.toFixed(2)
                  }}
                  $
                </td>
              </tr>
            </tfoot>
          </table>

          <!-- TABLEAU SLIM POUR GENERATION DE PDF -->

          <div v-show="false">
            <table id="rapportRepSlim">
              <thead>
                <tr class="bg-white text-black text-xs">
                  <th colspan="1">RAPPORT REPRÉSENTANT</th>
                  <th colspan="3">
                    {{
                      store.state.statsRepresentant[1].representative_firstName
                    }}
                    {{
                      store.state.statsRepresentant[1].representative_lastName
                    }}
                  </th>
                  <th colspan="3">
                    Période du
                    {{ store.state.statsRepresentant[1].dateDebut }} au
                    {{ store.state.statsRepresentant[1].dateFin }}
                  </th>
                </tr>

                <tr>
                  <th scope="col" col-span="3">CLIENTS</th>

                  <th scope="col" colspan="1">#FACTURE</th>
                  <th scope="col">TOTAL + TX</th>
                  <th scope="col">AVANT TX</th>
                  <th scope="col">LIVRAISON</th>
                  <th scope="col">
                    SOUS-TOTAL
                    <!-- {{store.state.statsCreator[0].createur_commission}}% redev. -->
                  </th>
                  <th scope="col">
                    {{
                      store.state.statsRepresentant[1]
                        .representrative_commission
                    }}% redev.
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="stat in store.state.statsRepresentant[0]">
                  <tr v-for="(vente, index) in stat.ventes" :key="index">
                    <td class="item-qty text-left">
                      {{ stat.customerName }}
                    </td>

                    <td class="item-qty text-center">{{ vente.orderNum }}</td>
                    <td class="item-qty text-right">
                      {{ vente.order_totalPrice.toFixed(2) }} $
                    </td>
                    <td class="item-qty text-right">
                      {{ vente.order_sousTotal.toFixed(2) }} $
                    </td>
                    <td class="item-qty text-right">
                      - {{ vente.order_shippingCost.toFixed(2) }} $
                    </td>
                    <td class="item-qty text-right">
                      {{
                        (
                          vente.order_sousTotal - vente.order_shippingCost
                        ).toFixed(2)
                      }}
                      $
                    </td>
                    <td class="item-qty text-right">
                      {{ vente.montantCommission.toFixed(2) }} $
                    </td>
                  </tr>
                </template>
                <tr>
                  <td colspan="5">
                    Total des redevances entre le
                    {{ store.state.statsRepresentant[1].dateDebut }} et le
                    {{ store.state.statsRepresentant[1].dateFin }} inclusivement
                  </td>
                  <td colspan="2" class="text-right">
                    {{
                      store.state.statsRepresentant[1].totalCommission.toFixed(
                        2
                      )
                    }}
                    $
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { onMounted, inject, ref } from "vue";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  name: "RapportsRepresentantAdmin",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");
    const currentRep = ref(null);
    const loadingPdf = ref(false);
    const loadingRapport = ref(false);
    const startDate = ref(null);
    const endDate = ref(new Date());

    const masks = {
      input: "DD-MM-YYYY",
    };

    onMounted(() => {
      if (!store.state.representants) {
        store.methods.fetchRepresentants();
      }
    });

    function buildRapport() {
      if (!endDate.value || !startDate.value || !currentRep.value) {
        alert(
          "Des données sont manquantes pour effectuer la requête du rapport."
        );
        return;
      }

      if (endDate.value <= startDate.value) {
        alert("La date de début doit précéder la date de fin du rapport.");
        return;
      } else {
        loadingRapport.value = true;
        store.state.statsRepresentant = null;

        let ddS = String(startDate.value.getDate()).padStart(2, "0");
        let mmS = String(startDate.value.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyyS = startDate.value.getFullYear();

        let goodStartDate = yyyyS + "-" + mmS + "-" + ddS;

        let ddE = String(endDate.value.getDate()).padStart(2, "0");
        let mmE = String(endDate.value.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyyE = endDate.value.getFullYear();

        let goodEndDate = yyyyE + "-" + mmE + "-" + ddE;

        (async () => {
          await store.methods.getStatsRepresentant(
            currentRep.value,
            goodStartDate,
            goodEndDate
          );
          loadingRapport.value = false;
        })();
      }
    }

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    function getPdf() {
      loadingPdf.value = true;

      const doc = new jsPDF({
        format: "letter",
        orientation: "portrait",
      });

      doc.autoTable({
        html: "#rapportRepSlim",
        didParseCell: function (data) {
          var rows = data.table.body;
          if (data.row.index === rows.length - 1) {
            data.cell.styles.fillColor = [44, 44, 44];
            data.cell.styles.halign = "right";
            data.cell.styles.valign = "middle";
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.minCellHeight = 10;
          }
        },

        headStyles: {
          fillColor: [44, 44, 44],
          textColor: [255, 255, 255],
        },
        columnStyles: {
          0: { cellWidth: 48 }, // REP
          1: { cellWidth: 18, halign: "center" }, // #FACTURE
          2: { cellWidth: "auto", halign: "right" }, // TAXES VENTES
          3: { cellWidth: "auto", halign: "right" }, // AVANT TAXES
          4: { cellWidth: "auto", halign: "center", valign: "top" }, // LIVRAISON
          5: { cellWidth: "auto", halign: "right", valign: "top" }, // SOUS-TOTAL
          6: { cellWidth: "auto", halign: "right", valign: "top" }, // REDEV
        },
        styles: {
          fontSize: 8,
          overflow: "linebreak",
        },
      });
      loadingPdf.value = false;
      doc.save(
        `rapport_rep_${store.state.statsRepresentant[1].representative_firstName}${store.state.statsRepresentant[1].representative_lastName}_${store.state.statsRepresentant[1].dateDebut}_au_${store.state.statsRepresentant[1].dateFin}.pdf`
      );
    }

    return {
      store,
      toggleMenu,
      getPdf,
      currentRep,
      startDate,
      loadingPdf,
      endDate,
      buildRapport,
      masks,
      loadingRapport,
    };
  },
};
</script>
<style lang="scss">
#loadingLogo {
  max-height: 22px;
}
</style>
