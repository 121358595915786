import { createApp } from "vue";
import VueEasyLightbox from "vue-easy-lightbox";
import VCalendar from "v-calendar";
import PrimeVue from "primevue/config";
import Card from "primevue/card";
import Editor from "primevue/editor";
import Badge from "primevue/badge";
import App from "./App.vue";
import router from "./router";
import "./assets/tailwind.css";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
import "v-calendar/style.css";
const app = createApp(App);

app.use(router);
app.use(VueEasyLightbox);
app.use(VCalendar, {});
app.use(PrimeVue);
app.component("Card", Card);
app.component("Editor", Editor);
app.component("Badge", Badge);
app.mount("#app");
