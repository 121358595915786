<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8">
        <div class="col-pan-3">
          <div class="text-3xl uppercase">RAPPORTS DES CRÉATEURS</div>
        </div>

        <p class="text-2xl my-10">Sélectionnez un créateur</p>
        <div
          class="relative w-6/12"
          v-if="store.state.creators && !store.state.loadingCreators"
        >
          <select
            v-model="currentCreator"
            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option disabled selected value="null">
              Veuillez sélectionner un créateur...
            </option>
            <option
              v-for="(creator, index) in store.state.creators"
              :key="index"
              :value="creator.creator_id"
            >
              {{ creator.creator_firstName }} {{ creator.creator_lastName }}
            </option>
          </select>

          <div class="pt-10 flex flex-col">
            <div class="py-2">Type de rapport:</div>
            <label>
              <input type="radio" v-model="type" value="tous" />
              Tous
            </label>

            <label>
              <input type="radio" v-model="type" value="extranet" />
              Extranet
            </label>

            <label>
              <input type="radio" v-model="type" value="particuliers" />
              Particuliers
            </label>
          </div>
          <div
            class="pointer-events-none absolute right-0 flex items-center px-2 text-gray-700 chevron-down"
          >
            <svg
              class="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>

        <div class="flex justify-start mt-10">
          <div class="mr-10">
            <p class="pb-6">Date de début :</p>
            <v-date-picker v-model="startDate" :masks="masks">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
          <div>
            <p class="pb-6">Date de fin :</p>
            <v-date-picker v-model="endDate" :masks="masks">
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>
        </div>

        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded my-4"
          @click="buildRapport()"
        >
          <div class="flex justify-between items-center">
            Générer le rapport<span class="ml-2" v-if="loadingRapport"
              ><img
                id="loadingLogo"
                src="../../assets/images/spinner_button.gif"
            /></span>
          </div>
        </button>
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded my-4 md:ml-4"
          @click="getPdf()"
        >
          <div class="flex justify-between items-center">
            Télécharger le rapport<span class="ml-2" v-if="loadingPdf"
              ><img
                id="loadingLogo"
                src="../../assets/images/spinner_button.gif"
            /></span>
          </div>
        </button>

        <div
          v-if="!store.state.loadingStatsCreator && store.state.statsCreator"
        >
          <table id="rapport_createur">
            <thead>
              <tr class="bg-white text-black text-xs">
                <th colspan="9">
                  <div class="mt-4 mb-4 text-xs text-gray-300">
                    ( RAPPORT CRÉATEUR : {{ typeOfRapport.toUpperCase() }} )
                  </div>
                  <div class="text-base">
                    {{ store.state.statsCreator[0].createur_firstName }}
                    {{ store.state.statsCreator[0].createur_lastName }}
                  </div>
                  <div class="pt-2">
                    {{ store.state.statsCreator[0].createur_commission }}%
                    redevance
                  </div>
                  <div class="text-base lowercase pt-2 pb-4">
                    {{ store.state.statsCreator[0].dateDebut }} au
                    {{ store.state.statsCreator[0].dateFin }}
                  </div>
                </th>
              </tr>

              <tr>
                <th scope="col" class="text-center">TYPE</th>
                <th scope="col">#COMMANDE</th>
                <th scope="col" colspan="2">NOM</th>
                <th scope="col">DÉTAILS</th>
                <th scope="col">Prix unitaire</th>
                <th scope="col">Quantité</th>
                <th scope="col">Total</th>
                <th scope="col">
                  {{ store.state.statsCreator[0].createur_commission }}% redev.
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(stat, index) in store.state.statsCreator[1]"
                :key="index"
              >
                <td class="text-center font-bold">
                  {{ stat.orderType }}
                </td>
                <td class="text-center font-bold">
                  {{ stat.orderNum }}
                </td>

                <td class="item-qty text-left" colspan="2">
                  {{ stat.product_name }}
                </td>
                <td class="item-qty text-left">
                  {{ correctionCar(stat.product_format) }}
                </td>
                <td class="item-qty text-right">
                  {{ stat.product_price.toFixed(2) }} $
                </td>
                <td class="item-qty">{{ stat.product_quantity }}</td>
                <td class="item-qty text-right">
                  {{ stat.total_price.toFixed(2) }} $
                </td>
                <td class="item-price text-right">
                  {{ stat.commission_item.toFixed(2) }} $
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr class="text-offset">
                <td colspan="9" class="font-bold"></td>
              </tr>

              <tr class="text-offset">
                <td colspan="7">
                  Total des redevances ( {{ typeOfRapport.toUpperCase() }} )
                  entre le {{ store.state.statsCreator[0].dateDebut }} et
                  {{ store.state.statsCreator[0].dateFin }} inclusivement
                </td>
                <td colspan="2" class="text-right">
                  {{
                    store.state.statsCreator[0].createur_totalCommission.toFixed(
                      2
                    )
                  }}
                  $
                </td>
              </tr>
            </tfoot>
          </table>

          <!-- TABLEAU POUR GENERATEUR PDF -->

          <div v-show="false">
            <table id="rapport-createur-trim">
              <thead>
                <tr class="bg-white text-black text-xs">
                  <th colspan="3">
                    RAPPORT CRÉATEUR
                    <br />
                    TYPE DE VENTES : {{ typeOfRapport.toUpperCase() }}
                  </th>
                  <th colspan="2">
                    {{ store.state.statsCreator[0].createur_firstName }}
                    {{ store.state.statsCreator[0].createur_lastName }}
                  </th>
                  <th colspan="3">
                    {{ store.state.statsCreator[0].dateDebut }} au
                    {{ store.state.statsCreator[0].dateFin }}
                  </th>
                </tr>
                <tr>
                  <th scope="col">TYPE</th>
                  <th scope="col">#COM.</th>
                  <th scope="col">NOM</th>
                  <th scope="col">DETAILS</th>
                  <th scope="col">PRIX</th>
                  <th scope="col">QTÉE.</th>
                  <th scope="col">TOTAL</th>
                  <th scope="col">
                    {{ store.state.statsCreator[0].createur_commission }}%
                    REDEV.
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(stat, index) in store.state.statsCreator[1]"
                  :key="index"
                >
                  <td class="text-center font-bold">{{ stat.orderType }}</td>
                  <td class="text-center font-bold">{{ stat.orderNum }}</td>
                  <td class="item-qty text-left">{{ stat.product_name }}</td>
                  <td class="item-qty text-left">
                    {{ correctionCar(stat.product_format) }}
                  </td>
                  <td class="item-qty text-right">
                    {{ stat.product_price.toFixed(2) }} $
                  </td>
                  <td class="item-qty">{{ stat.product_quantity }}</td>
                  <td class="item-qty text-right">
                    {{ stat.total_price.toFixed(2) }} $
                  </td>
                  <td class="item-price text-right">
                    {{ stat.commission_item.toFixed(2) }} $
                  </td>
                </tr>
                <tr class="text-offset">
                  <td colspan="5">
                    Total des redevances :
                    {{ typeOfRapport.toUpperCase() }} entre le
                    {{ store.state.statsCreator[0].dateDebut }} et
                    {{ store.state.statsCreator[0].dateFin }} inclusivement
                  </td>
                  <td colspan="3">
                    {{
                      store.state.statsCreator[0].createur_totalCommission.toFixed(
                        2
                      )
                    }}
                    $
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { onMounted, inject, ref } from "vue";

export default {
  name: "RapportsCreateursAdmin",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");
    const currentCreator = ref(null);
    const loadingPdf = ref(false);
    const loadingRapport = ref(false);
    const type = ref(null);
    const typeOfRapport = ref(null);
    const canProducePdf = ref(false);
    const startDate = ref(null);
    const endDate = ref(new Date());

    const masks = {
      input: "DD-MM-YYYY",
    };

    onMounted(() => {
      if (!store.state.creators) {
        store.methods.fetchCreators();
      }
    });

    function buildRapport() {
      if (
        !endDate.value ||
        !startDate.value ||
        !currentCreator.value ||
        !type.value
      ) {
        alert(
          "Des données sont manquantes pour effectuer la requête du rapport."
        );
        return;
      }

      if (endDate.value <= startDate.value) {
        alert("La date de début doit précéder la date de fin du rapport.");
        return;
      } else {
        loadingRapport.value = true;
        typeOfRapport.value = type.value;

        store.state.statsCreator = null;
        let ddS = String(startDate.value.getDate()).padStart(2, "0");
        let mmS = String(startDate.value.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyyS = startDate.value.getFullYear();

        let goodStartDate = yyyyS + "-" + mmS + "-" + ddS;

        let ddE = String(endDate.value.getDate()).padStart(2, "0");
        let mmE = String(endDate.value.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyyE = endDate.value.getFullYear();

        let goodEndDate = yyyyE + "-" + mmE + "-" + ddE;

        (async () => {
          await store.methods.getStatsCreator(
            currentCreator.value,
            goodStartDate,
            goodEndDate,
            type.value
          );
          loadingRapport.value = false;
          canProducePdf.value = true;
        })();
      }
    }

    function correctionCar(texte) {
      if (!texte) return;
      return texte.replace(/″/g, '"');
    }

    function buildPdf() {
      const doc = new jsPDF({
        format: "letter",
        orientation: "portrait",
      });
      doc.autoTable({
        html: "#rapport-createur-trim",
        didParseCell: function (data) {
          var rows = data.table.body;
          if (data.row.index === rows.length - 1) {
            data.cell.styles.fillColor = [44, 44, 44];
            data.cell.styles.halign = "right";
            data.cell.styles.valign = "middle";

            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.minCellHeight = 10;
          }
        },

        headStyles: {
          fillColor: [44, 44, 44],
          textColor: [255, 255, 255],
        },
        columnStyles: {
          0: { cellWidth: 11, halign: "center" }, // ORDER TYPE
          1: { cellWidth: 13 }, // NUM FACTURE
          2: { cellWidth: "auto" }, // NOM
          3: { cellWidth: "auto" }, // DETAILS
          4: { cellWidth: 15, halign: "right" }, // PRIX
          5: { cellWidth: 13, halign: "center", valign: "top" }, // QUANTITE
          6: { cellWidth: 18, halign: "right", valign: "top" }, // TOTAL
          7: { cellWidth: 20, halign: "right", valign: "top" }, // REDEV
        },
        styles: {
          fontSize: 8,
          overflow: "linebreak",
        },
      });

      doc.save(
        `rapport_createur_${store.state.statsCreator[0].createur_firstName}${
          store.state.statsCreator[0].createur_lastName
        }_${store.state.statsCreator[0].dateDebut}_au_${
          store.state.statsCreator[0].dateFin
        }_${typeOfRapport.value.toUpperCase()}.pdf`
      );
    }

    function getPdf() {
      if (!canProducePdf.value) {
        alert("Veuillez générer le rapport avant de le télécharger");
      } else {
        loadingPdf.value = true;
        (async () => {
          await buildPdf();
          loadingPdf.value = false;
        })();
      }
    }

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    return {
      store,
      toggleMenu,
      loadingPdf,
      currentCreator,
      startDate,
      getPdf,
      endDate,
      buildRapport,
      masks,
      buildPdf,
      loadingRapport,
      correctionCar,
      type,
      canProducePdf,
      typeOfRapport,
    };
  },
};
</script>
<style lang="scss">
.chevron-down {
  top: 13px;
}
</style>
