<template>
  <section class="md:mb-4 ml-6 pb-4">
    <div v-if="prixRevente">
      <div v-for="(prix, index) in prixRevente.products" :key="index">
        <div class="my-2 flex flex-wrap">
          <div class="sizeCatPrixCartes font-semibold">
            <p>{{ prix.name }} :</p>
          </div>
          <div>
            Votre prix {{ prix.votre_prix }} | Prix suggéré
            {{ prix.prix_suggere }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, inject, ref, watch } from "vue";
const store = inject("store");

const prixRevente = ref(null);

// watch if the store.state.currentTag change and update the prixRevente
watch(
  () => store.state.currentTag,
  (newVal) => {
    const currentCat = store.state.catsToHandle.find((cat) => cat.id === 473);
    prixRevente.value = JSON.parse(currentCat.prixSugg);
    console.log(newVal);
  }
);

onMounted(() => {
  if (!store.state.catsToHandle || store.state.catsToHandle.length === 0) {
    (async () => {
      store.methods.getAllCatsToHandle();
      // get the props currentCatId from store.state.catsToHandle
      const currentCat = store.state.catsToHandle.find((cat) => cat.id === 473);

      prixRevente.value = currentCat.prixSugg;
    })();
  } else {
    const currentCat = store.state.catsToHandle.find((cat) => cat.id === 473);

    prixRevente.value = JSON.parse(currentCat.prixSugg);
  }
});
</script>

<style lang="scss">
.sizeCatPrixCartes {
  min-width: 400px !important;
}

.filterCategories {
  max-width: 250px;
  h6 {
    padding-bottom: 15px;
  }
}
</style>
