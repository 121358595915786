<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="/admin" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <!-- DEBUT DU MODAL -->

      <div
        class="hidden overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center"
        id="modal-id"
      >
        <div
          class="relative my-6 mx-auto max-w-6xl w-11/12"
          v-if="selectForUpdate"
        >
          <!--content-->
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            <!--header-->
            <div
              class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
            >
              <div class="flex flex-col md:flex-row md:justify-between w-10/12">
                <h3 class="font-semibold text-lg">DOSSIER CLIENT :</h3>
              </div>
              <div class="flex flex-col md:flex-row md:justify-between w-10/12">
                <h4 class="font-semibold text-lg">
                  {{ selectForUpdate.customer_compagnyName }}
                </h4>
              </div>

              <button
                class="p-1 ml-auto border-0 text-black opacity-6 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                @click="toggleModal('modal-id')"
              >
                <span
                  class="text-black h-6 w-6 text-2xl block outline-none focus:outline-none"
                >
                  x
                </span>
              </button>
            </div>
            <!--body-->
            <div class="relative p-6 grid md:grid-cols-2">
              <div class="col-span-1">
                <div class="font-semibold mr-10 my-1">
                  <input
                    type="text"
                    v-model="selectForUpdate.customer_compagnyName"
                    class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    placeholder="Nom de la société"
                  />
                </div>

                <div class="mr-10 my-1">
                  <input
                    type="text"
                    v-model="selectForUpdate.customer_contact"
                    class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    placeholder="Nom du contact"
                  />
                </div>
                <div class="my-1 mr-10">
                  <input
                    type="text"
                    v-model="selectForUpdate.customer_phone"
                    class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    placeholder="Téléphone"
                  />
                </div>
                <div class="my-1 mr-10">
                  <input
                    type="text"
                    v-model="selectForUpdate.customer_email"
                    class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                    placeholder="Courriel"
                  />
                </div>
                <div class="my-2 mr-10">Adresse de facturation</div>
                <div class="mr-10">
                  <div class="flex justify-center gap-1">
                    <input
                      type="text"
                      v-model="selectForUpdate.customer_billingAdressNumber"
                      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-4/12 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder="Numéro civique"
                    />
                    <input
                      type="text"
                      v-model="selectForUpdate.customer_billingStreet"
                      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder="Rue"
                    />
                  </div>

                  <div class="flex justify-center gap-1 my-1">
                    <input
                      type="text"
                      v-model="selectForUpdate.customer_billingCity"
                      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder="Ville"
                    />
                    <input
                      type="text"
                      v-model="selectForUpdate.customer_billingProvince"
                      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder="Province"
                    />
                  </div>
                  <div class="flex justify-center gap-1 my-1">
                    <input
                      type="text"
                      v-model="selectForUpdate.customer_billingPostalCode"
                      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder="Code postal"
                    />
                    <input
                      type="text"
                      v-model="selectForUpdate.customer_billingPays"
                      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder="Pays"
                    />
                  </div>
                </div>

                <div class="py-6">Représentant lié au compte client :</div>
                <div class="relative mr-10" v-if="store.state.representants">
                  <select
                    v-model="currentRepToLink"
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    @change="checktherep(currentRepToLink)"
                  >
                    <option
                      v-for="(rep, index) in store.state.representants"
                      :key="index"
                      :value="rep.representative_id"
                    >
                      {{ rep.representative_firstName }}
                      {{ rep.representative_lastName }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div>
                <div>Adresse de livraison :</div>

                <label class="inline-flex items-center wrapperInput mt-6">
                  <input
                    type="checkbox"
                    class="form-checkbox text-black"
                    v-model="selectForUpdate.customer_shippingIsBilling"
                    id="checkifShipping"
                    @click="clearSelectedClient()"
                  />
                  <span class="ml-2 pl-4 mb-4"
                    >( Même que l'adresse de facturation )</span
                  >
                </label>
                <div
                  v-if="selectForUpdate.customer_shippingIsBilling == 0"
                  class="mr-10"
                >
                  <div class="flex justify-center gap-1">
                    <input
                      type="text"
                      v-model="selectForUpdate.customer_shippingAdressNumber"
                      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-4/12 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder="Numéro civique"
                    />
                    <input
                      type="text"
                      v-model="selectForUpdate.customer_shippingStreet"
                      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder="Rue"
                    />
                  </div>

                  <div class="flex justify-center gap-1 my-1">
                    <input
                      type="text"
                      v-model="selectForUpdate.customer_shippingCity"
                      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder="Ville"
                    />
                    <input
                      type="text"
                      v-model="selectForUpdate.customer_shippingProvince"
                      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder="Province"
                    />
                  </div>
                  <div class="flex justify-center gap-1 my-1">
                    <input
                      type="text"
                      v-model="selectForUpdate.customer_shippingPostalCode"
                      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder="Code postal"
                    />
                    <input
                      type="text"
                      v-model="selectForUpdate.customer_shippingPays"
                      class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
                      placeholder="Pays"
                    />
                  </div>
                </div>
              </div>
              <div class="py-6">
                Compte client harvest lié au client.
                <div class="relative mr-10 mt-6" v-if="clientsListHarvest">
                  <select
                    v-model="selectForUpdate.customer_harvestId"
                    class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(harvestAccount, index) in clientsListHarvest"
                      :key="index"
                      :value="harvestAccount.id"
                    >
                      {{ harvestAccount.name }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <p v-if="messageUpdateInfo" class="text-red-500">
                {{ messageUpdateInfo.message }}
              </p>
            </div>
            <!--footer-->
            <div
              class="flex justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
            >
              <button
                class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="toggleModal('modal-id')"
              >
                Fermer
              </button>
              <button
                class="bg-emerald-500 text-green-400 active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="confirmChangementsProfil()"
              >
                Sauvegarder
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- FIN DU MODAL -->
      <div class="col-span-5 p-8">
        <div class="col-pan-3">
          <div class="text-3xl uppercase">Gestion / Aperçu des clients</div>
        </div>

        <div
          class="grid grid-cols-10 gap-y-2 pt-10"
          v-if="store.state.allClientsInfos"
        >
          <input
            v-model="filterClients"
            class="mr-4 mt-4 bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full lg:w-8/12 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 col-span-10"
            id="inline-full-name"
            type="text"
            placeholder="RECHERCHE PAR NOM DE COMPAGNIE / COURRIEL / TEL"
          />
          <p class="my-6 text-semibold" v-if="nbrResultatsRecherche !== null">
            Résultat<span v-if="nbrResultatsRecherche > 1">s</span>
            {{ nbrResultatsRecherche }}
          </p>

          <div
            class="col-span-10 grid grid-cols-6 py-2 px-4 mt-4 shadow rounded-sm overflow-hidden items_gestion_commande"
            v-for="(client, index) in searchClients()"
            :key="index"
          >
            <div
              class="col-span-2 font-semibold"
              @click="toggleModal('modal-id', client.customer_id)"
            >
              {{ client.customer_compagnyName }}
            </div>
            <div class="col-span-2">
              {{ client.customer_email }}
            </div>
            <div class="col-span-2">
              {{ client.customer_phone }}
            </div>
          </div>
        </div>
        <div>
          <p class="text-green-700 py-4 text-lg font-bold" v-if="success">
            Les modifications ont étés effectuées avec succès.
          </p>
          <p class="text-red-400 py-4 text-lg font-bold" v-if="erreur">
            Une erreur est survenue pendant les modifications.
          </p>
        </div>
      </div>
    </div>
    <div v-if="updatingInfo" class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { inject, onMounted, ref } from "vue";

export default {
  name: "GestionClients",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");
    const filterClients = ref(null);
    const success = ref(false);
    const erreur = ref(false);
    const modalIsOpen = ref(false);
    const selectForUpdate = ref(null);
    const clientsListHarvest = ref([null]);
    const clientsListOk = ref(false);
    const messageUpdateInfo = ref(null);
    const updatingInfo = ref(false);
    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }
    const currentRepToLink = ref(null);
    let nbrResultatsRecherche = ref(null);

    function getRepId(rep) {
      selectForUpdate.value.customer_representativeId = rep;
    }

    function searchClients() {
      // SEARCH BY COMPAGNY NAME
      let filteredClients = [];
      if (filterClients.value) {
        if (filterClients.value.length > 2) {
          store.state.allClientsInfos.forEach((client) => {
            const filter = filterClients.value.toLowerCase();
            const compagnyName = client.customer_compagnyName.toLowerCase();
            const email = client.customer_email.toLowerCase();
            const tel = client.customer_phone.toLowerCase();
            if (
              compagnyName.includes(filter) ||
              email.includes(filter) ||
              tel.includes(filter)
            ) {
              filteredClients.push(client);
            }
          });
        } else {
          filteredClients = [];
        }
      } else {
        filteredClients = [];
      }

      // COUNT NUMBER OF RESULTS
      if (filterClients.value) {
        if (filterClients.value.length < 3) {
          nbrResultatsRecherche.value = null;
        } else {
          nbrResultatsRecherche.value = filteredClients.length;
        }
      }

      return filteredClients;
    }

    // CONTROL DU MODAL
    function toggleModal(modalID, customerId) {
      modalIsOpen.value = !modalIsOpen.value;

      if (modalIsOpen.value) {
        let indexClient = store.state.allClientsInfos.findIndex(
          (client) => client.customer_id == customerId
        );

        if (indexClient !== -1) {
          selectForUpdate.value = store.state.allClientsInfos[indexClient];
        }

        currentRepToLink.value =
          store.state.allClientsInfos[indexClient].customer_representativeId;

        selectForUpdate.value.customer_representativeId =
          store.state.allClientsInfos[indexClient].customer_representativeId;
      } else {
        selectForUpdate.value = null;
      }

      document.getElementById(modalID).classList.toggle("flex");
      document.getElementById(modalID).classList.toggle("hidden");
    }

    function saveCommande() {
      console.log("save commande");
    }

    async function getClientsHarvest(pageCount) {
      let numPageToReq;
      if (pageCount === 0) {
        numPageToReq = "";
      } else {
        numPageToReq = `?page=${pageCount}`;
      }

      try {
        const res = await fetch(
          `${process.env.VUE_APP_HARVEST_URL}${numPageToReq}`,
          {
            method: "GET",
            headers: {
              "Harvest-Account-Id": process.env.VUE_APP_HARVEST_ACOUNT_ID,
              Authorization: "Bearer " + process.env.VUE_APP_TOKEN_HARVEST,
            },
          }
        );
        if (!res.ok) {
          console.log("un problème est survenu.");
        } else {
          const data = await res.json();
          let newListingClient;
          if (pageCount === 0) {
            clientsListHarvest.value = data.clients;
          } else {
            newListingClient = clientsListHarvest.value.concat(data.clients);
            clientsListHarvest.value = newListingClient;
          }

          if (data.page === data.total_pages) {
            // SORT BY NAME ASC
            clientsListHarvest.value.sort((a, b) =>
              a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            );
            clientsListOk.value = true;
          } else {
            getClientsHarvest(pageCount + 1);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function confirmChangementsProfil() {
      let proceed = confirm(`Voulez-vous confirmer les modifications ?`);

      if (proceed) {
        let error = false;

        if (!selectForUpdate.value.customer_billingAdressNumber) {
          error = true;
        }
        if (!selectForUpdate.value.customer_billingCity) {
          error = true;
        }
        if (!selectForUpdate.value.customer_billingPays) {
          error = true;
        }
        if (!selectForUpdate.value.customer_billingPostalCode) {
          error = true;
        }
        if (!selectForUpdate.value.customer_billingProvince) {
          error = true;
        }
        if (!selectForUpdate.value.customer_billingStreet) {
          error = true;
        }
        if (!selectForUpdate.value.customer_compagnyName) {
          error = true;
        }
        if (!selectForUpdate.value.customer_contact) {
          error = true;
        }
        if (!selectForUpdate.value.customer_email) {
          error = true;
        }
        if (!selectForUpdate.value.customer_phone) {
          error = true;
        }

        if (!selectForUpdate.value.customer_shippingIsBilling) {
          if (!selectForUpdate.value.customer_shippingAdressNumber) {
            error = true;
          }
          if (!selectForUpdate.value.customer_shippingCity) {
            error = true;
          }
          if (!selectForUpdate.value.customer_shippingPays) {
            error = true;
          }
          if (!selectForUpdate.value.customer_shippingPostalCode) {
            error = true;
          }
          if (!selectForUpdate.value.customer_shippingProvince) {
            error = true;
          }
          if (!selectForUpdate.value.customer_shippingStreet) {
            error = true;
          }
        }

        if (error) {
          alert("Veuillez vérifier que tous les champs sont bien remplis.");
          return;
        }

        updatingInfo.value = true;

        try {
          const res = await fetch(
            `${store.state.changeInfosUserAdminUrl}/${selectForUpdate.value.customer_userId}`,
            {
              method: "post",
              headers: {
                "content-type": "application/json",
              },
              credentials: "include",
              body: JSON.stringify(selectForUpdate.value),
            }
          );

          // a non-200 response code
          if (!res.ok) {
            const error = new Error(res);
            error.json = res.json();
            throw error;
          } else {
            updatingInfo.value = false;
            toggleModal("modal-id", null);

            messageUpdateInfo.value = {
              status: 200,
              message: "Les modifications ont été effectuées avec succès.",
            };
          }
        } catch (err) {
          updatingInfo.value = false;

          messageUpdateInfo.value = {
            status: 400,
            message:
              "Vérifiez que le courriel n'existe pas déjà et que les champs sont bien renseignés.",
          };
        }
      }
    }

    onMounted(() => {
      if (!store.state.allClientsInfos) {
        store.methods.getAllClientsInfos();
      }

      if (!store.state.representants) {
        store.methods.fetchRepresentants();
      }

      getClientsHarvest(0);
    });

    return {
      store,
      toggleMenu,
      searchClients,
      filterClients,
      saveCommande,
      success,
      messageUpdateInfo,
      erreur,
      toggleModal,
      modalIsOpen,
      selectForUpdate,
      confirmChangementsProfil,
      updatingInfo,
      currentRepToLink,
      nbrResultatsRecherche,
      clientsListHarvest,
      getClientsHarvest,
      clientsListOk,
      checktherep: getRepId,
    };
  },
};
</script>
<style lang="scss"></style>
