<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8">
        <div class="col-pan-3">
          <h2 class="text-3xl uppercase">
            GESTION DES PROMOTIONS
          </h2>
        </div>

        <div>
          <div class="w-full mt-10 grid grid-cols-1 md:grid-cols-3">
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded lg:mr-10 col-span-3 lg:col-span-1 uppercase"
              @click="mode = 'ajouter'"
            >
              Ajouter une promotion
            </button>
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 lg:mr-10 col-span-3 lg:col-span-1 uppercase"
              @click="
                mode = 'modifier';
                editCreator = {};
                currentCreatorEdit = 'choose';
              "
            >
              Modifier une promotion
            </button>
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 col-span-3 lg:col-span-1 uppercase"
              @click="mode = 'supprimer'"
            >
              Supprimer une promotion
            </button>
          </div>

          <div v-if="mode === 'modifier'">
            <div class="mb-4 mt-14">
              <p class="text-2xl my-10">MODIFIER UNE PROMOTION</p>

              <div class="relative" v-if="store.state.promotions !== null">
                <select
                  v-model="currentPromotionIndex"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  @change="clearAlert()"
                >
                  <option disabled selected value="null"
                    >Veuillez sélectionner une promotion à modifier</option
                  >
                  <option
                    v-for="(promotion, index) in store.state.promotions"
                    :key="index"
                    :value="index"
                    >{{ promotion.promotion_name }}</option
                  >
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                >
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>

              <div
                class="grid grid-cols-6 gap-y-2 pt-10 mb-4"
                v-if="currentPromotionIndex !== null"
              >
                <div class="col-span-6">
                  <p class="flex items-center">Nom de la promotion:</p>
                </div>
                <div class="col-span-6 ">
                  <input
                    type="text"
                    v-model="
                      store.state.promotions[currentPromotionIndex]
                        .promotion_name
                    "
                    id="nompromotion"
                    class="py-2 px-2 text-sm border border-blue-lighter rounded w-9/12"
                  />
                </div>

                <div class="col-span-6 mt-4">
                  <div>
                    <span class="text-gray-700">Promotion active :</span>
                    <div class="mt-2">
                      <label class="inline-flex items-center">
                        <input
                          type="radio"
                          class="form-radio cursor-pointer"
                          name="isActive"
                          value="1"
                          v-model="
                            store.state.promotions[currentPromotionIndex]
                              .promotion_isActive
                          "
                          @click="
                            store.state.promotions[
                              currentPromotionIndex
                            ].promotion_isActive = 1
                          "
                        />
                        <span class="ml-2">Oui</span>
                      </label>
                      <label class="inline-flex items-center ml-6">
                        <input
                          type="radio"
                          class="form-radio cursor-pointer"
                          name="isActive"
                          value="0"
                          @click="
                            store.state.promotions[
                              currentPromotionIndex
                            ].promotion_isActive = 0
                          "
                          v-model="
                            store.state.promotions[currentPromotionIndex]
                              .promotion_isActive
                          "
                        />
                        <span class="ml-2">Non</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-span-6">
                  <Editor
                    v-model="
                      store.state.promotions[currentPromotionIndex]
                        .promotion_content
                    "
                    editorStyle="height: 800px"
                    class="mt-4"
                  />
                  <p
                    class="text-green-700 py-4 text-lg font-bold"
                    v-if="modifSucces"
                  >
                    Les modifications ont étés effectuées avec succès.
                  </p>
                  <p
                    class="text-red-400 py-4 text-lg font-bold"
                    v-if="modifErreur"
                  >
                    Une erreur est survenue pendant la modification de la
                    promotion.
                  </p>
                  <button
                    class="bg-blue-500 mt-4 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase"
                    @click="editPromo()"
                  >
                    CONFIRMER
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="mode === 'ajouter'">
            <div class="mb-4 mt-14">
              <p class="text-2xl my-10">AJOUTER UNE PROMOTION</p>

              <div class="grid grid-cols-6 gap-y-2 pt-10 mb-4">
                <div class="col-span-6">
                  <p class="flex items-center">Nom de la promotion:</p>
                </div>
                <div class="col-span-6 ">
                  <input
                    type="text"
                    v-model="promotion.promotion_name"
                    id="nompromotion"
                    class="py-2 px-2 text-sm border border-blue-lighter rounded w-9/12"
                  />
                </div>

                <div class="col-span-6 mt-4">
                  <div>
                    <span class="text-gray-700">Promotion active :</span>
                    <div class="mt-2">
                      <label class="inline-flex items-center">
                        <input
                          type="radio"
                          class="form-radio cursor-pointer"
                          name="isActive"
                          value="1"
                          v-model="promotion.promotion_isActive"
                        />
                        <span class="ml-2">Oui</span>
                      </label>
                      <label class="inline-flex items-center ml-6">
                        <input
                          type="radio"
                          class="form-radi cursor-pointer"
                          name="isActive"
                          value="0"
                          v-model="promotion.promotion_isActive"
                        />
                        <span class="ml-2">Non</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-span-6">
                  <Editor
                    v-model="promotion.promotion_content"
                    editorStyle="height: 555px"
                    class="mt-4"
                  />
                  <div v-if="erreurAdd">
                    <p class="text-red-400 py-4 text-lg font-bold">
                      Une erreur est survenue pendant l'ajout de la promotion.
                    </p>
                  </div>
                  <div v-if="successAdd">
                    <p class="text-green-500 py-4 text-lg font-bold">
                      La promotion a été ajoutée.
                    </p>
                  </div>
                  <button
                    class="bg-blue-500 mt-4 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase"
                    @click="uploadPromo()"
                  >
                    CONFIRMER
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="mode === 'supprimer'">
            <div class="mb-4 mt-14">
              <p class="text-2xl my-10">SUPPRIMER UNE PROMOTION</p>

              <div class="grid grid-cols-6 gap-y-2 pt-10 mb-4">
                <div class="col-span-6">
                  <p class="flex items-center">Nom de la promotion:</p>
                </div>

                <div class="col-span-6">
                  <div class="relative" v-if="store.state.promotions !== null">
                    <select
                      v-model="currentPromotionToDelete"
                      class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option disabled selected value="null"
                        >Veuillez sélectionner une promotion à supprimer</option
                      >
                      <option
                        v-for="(promotion, index) in store.state.promotions"
                        :key="index"
                        :value="promotion.promotion_id"
                        >{{ promotion.promotion_name }}</option
                      >
                    </select>

                    <div v-if="deleteErreur">
                      <p class="text-red-400 py-4 text-lg font-bold">
                        Une erreur est survenue pendant la suppression de la
                        promotion.
                      </p>
                    </div>
                    <div v-if="deleteSucces">
                      <p class="text-green-500 py-4 text-lg font-bold">
                        La promotion a été supprimé avec succès.
                      </p>
                    </div>
                  </div>

                  <button
                    class="bg-blue-500 mt-4 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase"
                    @click="deletePromotion()"
                  >
                    CONFIRMER LA SUPPRESION
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { onMounted, inject, ref } from "vue";

export default {
  name: "RapportsCreateursAdmin",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");
    const inputtext = ref();
    const mode = ref();
    const promotion = ref({});
    const currentPromotionIndex = ref(null);
    const currentPromotionToDelete = ref(null);
    const modifSucces = ref(false);
    const modifErreur = ref(false);
    const deleteSucces = ref(false);
    const deleteErreur = ref(false);
    const startDate = ref(null);
    const endDate = ref(new Date());
    const erreurAdd = ref(false);
    const successAdd = ref(false);
    const masks = {
      input: "DD-MM-YYYY",
    };

    async function uploadPromo() {
      erreurAdd.value = false;
      successAdd.value = false;
      if ((await store.methods.uploadPromotion(promotion.value)) === 1) {
        successAdd.value = true;
        getPromotions();
        promotion.value = {};
      } else {
        erreurAdd.value = true;
        console.log("Failed");
      }
    }

    function editPromo() {
      updatePromotion(store.state.promotions[currentPromotionIndex.value]);
    }

    async function getPromotions() {
      try {
        const res = await fetch(`${store.state.getPromotionsUrl}`, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });
        // a non-200 response code
        if (!res.ok) {
          // create error instance with HTTP status text
          const error = new Error(res.statusText);
          error.json = res.json();
          throw error;
        } else {
          const json = await res.json();
          // APPLIQUE LES VALEURS DE LA DB;

          store.state.promotions = json.data;
        }
      } catch (err) {
        console.log(err);
      }
    }

    function clearAlert() {
      // CLEAR MESSAGE
      modifSucces.value = false;
      modifErreur.value = false;
    }

    async function deletePromotion() {
      if (currentPromotionToDelete.value === null) {
        return;
      }

      let proceed = confirm(`Voulez-vous vraiment supprimer la promotion ?`);

      if (proceed) {
        deleteErreur.value = false;
        deleteSucces.value = false;

        try {
          const res = await fetch(
            `${store.state.getDeletePromotionByIdUrl}/${currentPromotionToDelete.value}`,
            {
              method: "get",
              headers: {
                "content-type": "application/json",
              },
              credentials: "include",
            }
          );

          if (!res.ok) {
            deleteErreur.value = true;
          } else {
            const json = await res.json();
            if (json.success === 1) {
              // APPLIQUE LES VALEURS DE LA DB;
              currentPromotionToDelete.value = null;
              deleteSucces.value = true;
              getPromotions();
            } else {
              console.error(json);
              deleteErreur.value = true;
            }
          }
        } catch (err) {
          console.error(err);
          deleteErreur.value = true;
        }
      }
    }

    async function updatePromotion(promo) {
      modifSucces.value = false;
      modifErreur.value = false;

      try {
        const res = await fetch(`${store.state.updatePromotionUrl}`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(promo),
        });
        const json = await res.json();
        if (!res.ok) {
          console.error(json);
          modifErreur.value = true;
        } else {
          if (json.success === 1) {
            modifSucces.value = true;
          }
        }
      } catch (err) {
        console.error(err);
      }
    }

    onMounted(() => {
      getPromotions();
    });

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    return {
      store,
      toggleMenu,
      inputtext,
      uploadPromo,
      deletePromotion,
      editPromo,
      getPromotions,
      currentPromotionIndex,
      currentPromotionToDelete,
      mode,
      promotion,
      startDate,
      masks,
      endDate,
      updatePromotion,
      modifSucces,
      modifErreur,
      deleteSucces,
      deleteErreur,
      erreurAdd,
      successAdd,
      clearAlert,
    };
  },
};
</script>
<style lang="scss"></style>
