<template>
  <section
    class="filterTags pt-10"
    v-if="!store.state.loading && store.state.data && store.state.currentTag"
  >
    <h6 class="titlesChoices" v-if="getTags().length">ETIQUETTES PRODUITS</h6>

    <div>
      <div v-for="(tag, index) in getTags()" :key="index" class="mx-5">
        <button
          class="btnFilter"
          :id="`tag${tag.id}`"
          @click="handleTags(tag.id)"
        >
          <span v-html="tag.name"></span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { inject, ref, watch, onMounted } from "vue";

export default {
  name: "SelectTag",

  setup() {
    const store = inject("store");
    const tagsList = ref([]);

    function handleTags(tag) {
      if (tagsList.value.length > 0) {
        if (tagsList.value.includes(tag)) {
          // SI TAG EST DEJA DANS LA LISTE
          let tagIndex = tagsList.value.indexOf(tag);
          tagsList.value.splice(tagIndex, 1);
          document.querySelector(`#tag${tag}`).classList.remove("tag_selected");
        } else {
          tagsList.value.push(tag);
          document.querySelector(`#tag${tag}`).classList.add("tag_selected");
        }
      } else {
        tagsList.value.push(tag);
        document.querySelector(`#tag${tag}`).classList.add("tag_selected");
      }

      if (tagsList.value.length > 0) {
        store.state.currentFilterTags = tagsList.value;
      } else {
        store.state.currentFilterTags = "all";
      }
    }

    function getTags() {
      const tagsMap = new Map();

      store.state.data.forEach((item) => {
        const hasCurrentCategory = item.product_categories.some(
          (cat) => cat.id === store.state.currentTag
        );

        if (hasCurrentCategory) {
          item.product_tags.forEach((tag) => {
            // Utiliser l'ID comme clé pour s'assurer que chaque tag est unique
            if (!tagsMap.has(tag.id)) {
              tagsMap.set(tag.id, tag);
            }
          });
        }
      });

      // Convertir les valeurs de la map en un tableau
      return Array.from(tagsMap.values());
    }

    function resetTagStyles() {
      // Supprime la classe 'tag_selected' de tous les boutons de tag
      const selectedTags = document.querySelectorAll(".tag_selected");
      selectedTags.forEach((btn) => {
        btn.classList.remove("tag_selected");
      });
    }

    onMounted(() => {
      tagsList.value = [];
      store.state.currentFilterTags = "all";
    });

    watch(
      () => store.state.currentTag,
      () => {
        tagsList.value = [];
        store.state.currentFilterTags = "all";
        resetTagStyles();
      }
    );

    return {
      store,
      handleTags,
      tagsList,
      getTags,
      resetTagStyles,
    };
  },
};
</script>

<style lang="scss">
.filterTags {
  h6 {
    padding-bottom: 25px;
  }

  .btnFilter {
    font-size: 14px !important;
    color: black;
    border-radius: 0px;
    border: 1px solid black;
    padding: 7px 15px;
    display: block;
    margin: 0 15px 15px 0;
    transition: all 0.3s;
    width: 150px;
    outline: none;
  }
  .tag_selected {
    color: white;
    background-color: black;
  }
  .btnFilter:hover {
    color: white;
    background-color: black;
  }
}
</style>
