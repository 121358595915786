<template>
  <main>
    <TheNavbar />

    <div class="cartes grid grid-cols-1 lg:grid-cols-4 px-4 md:px-10 pt-16">
      <div class="col-span-3 row-start-1 px-5">
        <h1 class="titleSection uppercase pb-2 ">MON PROFIL</h1>
        <div class="grid grid-cols-3  mt-4">
          <div class="mr-4 col-span-3  flex items-center mt-8">
            <p v-if="store.state.currentModeProfil === 'infos'" class="text-xl">
              Mes infos
            </p>
            <p
              v-if="store.state.currentModeProfil === 'commandes'"
              class="text-xl"
            >
              Mes commandes
            </p>
            <p
              v-if="store.state.currentModeProfil === 'telechargement'"
              class="text-xl"
            >
              Images des produits
            </p>
            <p
              v-if="store.state.currentModeProfil === 'password'"
              class="text-xl"
            >
              Modifier mon mot de passe
            </p>
          </div>

          <!-- INFOS CONTENT-->
          <div
            v-if="store.state.currentModeProfil === 'infos'"
            class="col-span-3"
          >
            <div
              class="md:mt-16 grid grid-cols-1 md:grid-cols-2 "
              v-if="gotInfosClient"
            >
              <div class="wrapperInput col-span-1 md:col-span-2 mt-2">
                <p class="pb-4 font-weight-700">INFORMATIONS</p>
                <p class="mt-4">Nom du commerce :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2  w-11/12 border"
                  type="text"
                  placeholder="Nom du commerce"
                  v-model="infosClient.customer_compagnyName"
                />
                <p class="mt-4">Nom de la personne contact :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 w-11/12 border"
                  type="text"
                  placeholder="Nom de la personne contact"
                  v-model="infosClient.customer_contact"
                />
                <p class="mt-4">Courriel :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2  w-11/12 border"
                  type="email"
                  placeholder="Courriel"
                  v-model="infosClient.customer_email"
                />
                <p class="mt-4">No de téléphone :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 w-11/12 border"
                  type="text"
                  placeholder="No de téléphone"
                  v-model="infosClient.customer_phone"
                />
              </div>
              <div class="wrapperInput mt-6">
                <p class="pb-4 font-weight-700">ADRESSE DE FACTURATION</p>
                <p class="mt-4">No civique :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 border  w-11/12"
                  type="text"
                  placeholder="No civique"
                  v-model="infosClient.customer_billingAdressNumber"
                />
                <p class="mt-4">Rue :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 border  w-11/12"
                  type="text"
                  placeholder="Rue"
                  v-model="infosClient.customer_billingStreet"
                />
                <p class="mt-4">Code postal :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 border  w-11/12"
                  type="text"
                  placeholder="Code postal"
                  v-model="infosClient.customer_billingPostalCode"
                />
                <p class="mt-4">Ville :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 border  w-11/12"
                  type="text"
                  placeholder="Ville"
                  v-model="infosClient.customer_billingCity"
                />
                <p class="mt-4">Province :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 border  w-11/12"
                  type="text"
                  placeholder="Province"
                  v-model="infosClient.customer_billingProvince"
                />
                <p class="mt-4">Pays :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 border  w-11/12"
                  type="text"
                  placeholder="Pays"
                  v-model="infosClient.customer_billingPays"
                />
                <div>
                  <label class="inline-flex items-center wrapperInput mt-6">
                    <input
                      type="checkbox"
                      class="form-checkbox text-black"
                      v-model="infosClient.customer_shippingIsBilling"
                      id="checkifShipping"
                      @click="handleChecked()"
                      :checked="
                        infosClient.customer_shippingIsBilling == 1
                          ? (infosClient.customer_shippingIsBilling = true)
                          : (infosClient.customer_shippingIsBilling = false)
                      "
                    />
                    <span class="ml-2 pl-4 mb-4 w-9/12"
                      >Utiliser l'adresse de facturation comme adresse de
                      livraison</span
                    >
                  </label>
                </div>
              </div>
              <div
                class="wrapperInput mt-6"
                v-if="!infosClient.customer_shippingIsBilling"
              >
                <p class="pb-4 font-weight-700">ADRESSE DE LIVRAISON</p>
                <p class="mt-4">No civique :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 border w-11/12"
                  type="text"
                  placeholder="No civique"
                  v-model="infosClient.customer_shippingAdressNumber"
                />
                <p class="mt-4">Rue :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 border w-11/12"
                  type="text"
                  placeholder="Rue"
                  v-model="infosClient.customer_shippingStreet"
                />
                <p class="mt-4">Code postal :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 border w-11/12"
                  type="text"
                  placeholder="Code postal"
                  v-model="infosClient.customer_shippingPostalCode"
                />
                <p class="mt-4">Ville :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 border w-11/12"
                  type="text"
                  placeholder="Ville"
                  v-model="infosClient.customer_shippingCity"
                />
                <p class="mt-4">Province :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 border w-11/12"
                  type="text"
                  placeholder="Province"
                  v-model="infosClient.customer_shippingProvince"
                />
                <p class="mt-4">Pays :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2  border w-11/12"
                  type="text"
                  placeholder="Pays"
                  v-model="infosClient.customer_shippingPays"
                />
              </div>
            </div>
            <div v-if="messageUpdateInfo !== null">
              <p class="text-green-500" v-if="messageUpdateInfo.status === 200">
                {{ messageUpdateInfo.message }}
              </p>
              <p v-else class="text-red-500">{{ messageUpdateInfo.message }}</p>
            </div>
            <div class="w-full flex justify-center">
              <button
                class="boutonappliquer w-10/12 mt-4 lg:w-56"
                @click="confirmChangementsProfil()"
              >
                Confirmer
              </button>
            </div>
          </div>

          <!-- INFOS CONTENT END HERE -->

          <!-- GESTION MOT DE PASSE START HERE -->
          <div
            v-if="store.state.currentModeProfil === 'password'"
            class="col-span-3"
          >
            <div class="md:mt-16 grid grid-cols-1 md:grid-cols-2 ">
              <div>
                <p class="mt-4">Ancien mot de passe :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2 border w-11/12"
                  type="text"
                  placeholder="Ancien mot de passe"
                  v-model="password.oldPassword"
                />
                <p class="mt-4">Nouveau mot de passe :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2  border w-11/12"
                  type="text"
                  placeholder="Nouveau mot de passe"
                  v-model="password.newPassword1"
                />
                <p class="mt-4">Confirmer le nouveau mot de passe :</p>
                <input
                  class="mt-2 h-10 px-3 mb-2  border w-11/12"
                  type="text"
                  placeholder="Confirmer le nouveau mot de passe"
                  v-model="password.newPassword2"
                />
                <div v-if="messagePasswordStatus !== null">
                  <p
                    class="text-green-300"
                    v-if="messagePasswordStatus.status === 200"
                  >
                    {{ messagePasswordStatus.message }}
                  </p>
                  <p v-else class="text-red-400">
                    {{ messagePasswordStatus.message }}
                  </p>
                </div>
              </div>
            </div>
            <div class=" col-span-1 w-full flex justify-start">
              <button
                class="boutonappliquer w-10/12 mt-8 lg:w-56"
                @click="confirmPasswordChange(password)"
              >
                Confirmer
              </button>
            </div>
          </div>

          <!-- GESTION MOT DE PASSE END HERE -->

          <!-- GESTION FACTURES START HERE -->
          <div
            v-if="store.state.currentModeProfil === 'commandes'"
            class="col-span-3"
          >
            <div
              class="md:mt-16 grid grid-cols-1 md:grid-cols-2 "
              v-if="!loadingCommandes"
            >
              <div
                v-if="commandesClient !== null"
                class="col-span-1 md:col-span-2"
              >
                ( {{ commandesClient.length }} dernière<span
                  v-if="commandesClient.length > 1"
                  >s</span
                >
                commande<span v-if="commandesClient.length > 1">s</span> )

                <div
                  v-for="(commande, index) in commandesClient"
                  :key="index"
                  class="w-full"
                >
                  <a
                    :href="
                      `https://${prefixHarvest}.harvestapp.com/client/invoices/${commande.order_harvestOrderId}`
                    "
                    target="_blank"
                  >
                    <div
                      class="grid grid-cols-5 lg:grid-cols-10 py-6 px-4  mt-4 shadow-md rounded-sm overflow-hidden items_gestion_commande"
                    >
                      <div class="font-bold">
                        # {{ commande.order_orderNum }}
                      </div>

                      <div>
                        {{ convertDate(commande.order_dateCreated) }}
                      </div>
                      <div class="col-span-3 pl-4">
                        {{ commande.order_customer_compagnyName }}
                      </div>
                      <div class="flex justify-end col-span-3">
                        <span class="pr-10"
                          >Montant initial (
                          {{ commande.order_totalPrice.toFixed(2) }} $ )</span
                        >
                      </div>
                      <div class="col-span-1 flex justify-center items-center">
                        <a
                          :href="
                            `https://${prefixHarvest}.harvestapp.com/client/invoices/${commande.order_harvestOrderId}`
                          "
                          target="_blank"
                          ><img
                            class="downloadicon"
                            src="../../assets/images/arrow_down.png"
                        /></a>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div v-else>
                <p>Aucune commande dans votre compte client</p>
              </div>
            </div>
          </div>

          <!-- GESTION FACTURES END HERE -->

          <!-- GESTION TELECHARGEMENTS START HERE -->
          <div
            class="col-span-3"
            v-if="store.state.currentModeProfil === 'telechargement'"
          >
            <div
              class="md:mt-16 grid grid-cols-1 md:grid-cols-2 pr-10"
              v-if="!loadingMockups"
            >
              <div
                v-for="(mockup, index) in store.state.mockups"
                :key="index"
                class="w-full  col-span-2"
              >
                <a :href="`${mockup.mockupUrl}`">
                  <div
                    class="grid grid-cols-5 lg:grid-cols-10 py-2 px-4  mt-4 shadow-md rounded-sm overflow-hidden items_gestion_commande"
                  >
                    <div class="font-bold col-span-8">
                      {{ mockup.mockupName }}
                    </div>
                    <div class="col-span-2 flex justify-center items-center">
                      <a :href="`${mockup.mockupUrl}`"
                        ><img
                          class="downloadicon"
                          src="../../assets/images/arrow_down.png"
                      /></a>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div v-else>
              Loading
            </div>
          </div>
          <!-- GESTION TELECHARGEMENTS END HERE -->
        </div>
      </div>
      <div
        class="col-span-1 row-start-3 md:row-start-auto mx-auto pt-20 lg:pt-0 lg:mx-0"
      >
        <MenuProfil v-if="store.state.infosCustomer" />
      </div>
    </div>

    <TheFooter />
  </main>
</template>

<script>
import TheNavbar from "../../components/TheNavbar";
import TheFooter from "../../components/TheFooter.vue";
import MenuProfil from "../../components/MenuProfil.vue";
import { inject, onMounted, ref } from "vue";

export default {
  name: "Profil",
  components: { TheNavbar, TheFooter, MenuProfil },
  setup() {
    const store = inject("store");
    const infosClient = ref({});
    const gotInfosClient = ref(false);
    const currentCommande = ref(null);
    const currentCommandeNum = ref(null);
    const selectForUpdate = ref(null);
    const suiviNum1 = ref(null);
    const suiviNum2 = ref(null);
    const isCompleted = ref(null);
    const loadingCommande = ref(false);
    const messageUpdateInfo = ref(null);
    const messagePasswordStatus = ref(null);
    const loadingCommandes = ref(false);
    const prefixHarvest = process.env.VUE_APP_PREFIX_HARVEST;
    const loadingMockups = ref(false);
    const commandesClient = ref(null);
    const commandeClient = ref(null);
    const password = ref({});

    function handleChecked() {
      !infosClient.value.customer_shippingIsBilling;
    }

    async function confirmChangementsProfil() {
      let proceed = confirm(`Voulez-vous confirmer les modifications ?`);

      if (proceed) {
        let error = false;

        if (!infosClient.value.customer_billingAdressNumber) {
          error = true;
        }
        if (!infosClient.value.customer_billingCity) {
          error = true;
        }
        if (!infosClient.value.customer_billingPays) {
          error = true;
        }
        if (!infosClient.value.customer_billingPostalCode) {
          error = true;
        }
        if (!infosClient.value.customer_billingProvince) {
          error = true;
        }
        if (!infosClient.value.customer_billingStreet) {
          error = true;
        }
        if (!infosClient.value.customer_compagnyName) {
          error = true;
        }
        if (!infosClient.value.customer_contact) {
          error = true;
        }
        if (!infosClient.value.customer_email) {
          error = true;
        }
        if (!infosClient.value.customer_phone) {
          error = true;
        }
        if (!infosClient.value.customer_phone) {
          error = true;
        }

        if (!infosClient.value.customer_shippingIsBilling) {
          if (!infosClient.value.customer_shippingAdressNumber) {
            error = true;
          }
          if (!infosClient.value.customer_shippingCity) {
            error = true;
          }
          if (!infosClient.value.customer_shippingPays) {
            error = true;
          }
          if (!infosClient.value.customer_shippingPostalCode) {
            error = true;
          }
          if (!infosClient.value.customer_shippingProvince) {
            error = true;
          }
          if (!infosClient.value.customer_shippingStreet) {
            error = true;
          }
        }

        if (error) {
          alert("Veuillez vérifier que tous les champs sont bien remplis.");
          return;
        }

        try {
          const res = await fetch(
            `${store.state.changeInfosUserUrl}/${infosClient.value.customer_userId}`,
            {
              method: "post",
              headers: {
                "content-type": "application/json",
              },
              credentials: "include",
              body: JSON.stringify(infosClient.value),
            }
          );

          // a non-200 response code
          if (!res.ok) {
            const error = new Error(res);
            error.json = res.json();
            throw error;
          } else {
            messageUpdateInfo.value = {
              status: 200,
              message: "Les modifications ont été effectuées avec succès.",
            };
          }
        } catch (err) {
          messageUpdateInfo.value = {
            status: 400,
            message: "Le courriel existe déja ou un champ est manquant.",
          };
        }
      }
    }
    async function confirmPasswordChange() {
      if (password.value.newPassword1 !== password.value.newPassword2) {
        alert("Vos mot de passes ne concordent pas.");
        return;
      }
      if (password.value.newPassword1.length < 6) {
        alert(
          "Veuillez chosir un mot de passe avec un minimum de 6 caracthères"
        );
        return;
      }

      let proceed = confirm(`Voulez-vous confirmer les modifications ?`);

      if (proceed) {
        password.value.email = store.state.infosCustomer.customer_email;

        try {
          const res = await fetch(`${store.state.changePasswordByUserUrl}`, {
            method: "post",
            headers: {
              "content-type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(password.value),
          });

          if (!res.ok) {
            const error = new Error(res);
            error.json = res.json();
            throw error;
          } else {
            const json = await res.json();

            if (json.success === 1) {
              messagePasswordStatus.value = {
                status: 200,
                message: "Les modifications ont été effectuées avec succès.",
              };
            } else {
              messagePasswordStatus.value = {
                status: 400,
                message:
                  "Une erreur est survenue pendant les modifications. Votre ancien mot de passe est invalide.",
              };
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    }

    async function getCommandesByUser() {
      loadingCommandes.value = true;

      try {
        const res = await fetch(
          `${store.state.getCommandesByUserUrl}/${infosClient.value.customer_userId}`,
          {
            method: "get",
            headers: {
              "content-type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!res.ok) {
          const error = new Error(res);
          error.json = res.json();
          throw error;
        } else {
          const json = await res.json();

          commandesClient.value = json.data
            .sort((a, b) => b.order_orderNum - a.order_orderNum)
            .slice(0, 52); // RETOURNE CONTRAT LE PLUS RECENT AU PLUS VIEUX
          loadingCommandes.value = false;
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function getCommandeByUser() {
      const orderNum = currentCommandeNum.value;

      try {
        const res = await fetch(
          `${store.state.getCommandeByUserUrl}/${infosClient.value.customer_userId}/${orderNum}`,
          {
            method: "get",
            headers: {
              "content-type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!res.ok) {
          const error = new Error(res);
          error.json = res.json();
          throw error;
        } else {
          const json = await res.json();

          commandeClient.value = json.data;

          return json;
        }
      } catch (err) {
        console.log(err);
      }
    }

    onMounted(() => {
      getMockups();
      window.scrollTo(0, 0);

      if (!store.state.data) {
        (async () => {
          await store.methods.fetchAllProducts();
          store.methods.checkIfCart();
          store.methods.calculTaxes();
        })();
      }

      if (!store.state.infosCustomer) {
        (async () => {
          await store.methods.fetchInfosCustomer();
          infosClient.value = store.state.infosCustomer;
          gotInfosClient.value = true;
          getCommandesByUser();
        })();
      } else {
        infosClient.value = store.state.infosCustomer;

        gotInfosClient.value = true;

        getCommandesByUser();
      }

      // VERIFIE SI LE CLIENT A DEJA UN CART

      if (!store.state.coffretsMeliMelo) {
        store.methods.fetchCoffretMeliMelo();
      }
      if (!store.state.presentoirs) {
        store.methods.fetchPresentoirs();
      }
    });

    // CONVERTISSEUR DE DATE
    const convertDate = (inputDate) => {
      return inputDate.substring(0, inputDate.length - 14);
    };

    // FORMAT PHONE NUMBER
    function formatPhoneNumber(phoneNumberString) {
      let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    }

    async function getMockups() {
      loadingMockups.value = true;
      try {
        const res = await fetch(`${store.state.getMockups}`, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });

        if (!res.ok) {
          console.log("un problème est survenu.");
          loadingMockups.value = false;
        } else {
          const data = await res.json();
          store.state.mockups = data.data.sort((a, b) =>
            a.mockupName.localeCompare(b.mockupName)
          );
          loadingMockups.value = false;
        }
      } catch (err) {
        console.log(err);
      }
    }

    return {
      store,
      infosClient,
      gotInfosClient,
      confirmChangementsProfil,
      password,
      confirmPasswordChange,
      handleChecked,
      getCommandesByUser,
      commandesClient,
      convertDate,
      formatPhoneNumber,
      selectForUpdate,
      isCompleted,
      suiviNum1,
      suiviNum2,
      loadingCommande,
      currentCommande,
      getCommandeByUser,
      commandeClient,
      currentCommandeNum,
      messageUpdateInfo,
      messagePasswordStatus,
      loadingCommandes,
      prefixHarvest,
      getMockups,
      loadingMockups,
    };
  },
};
</script>
<style lang="scss">
.cartes {
  max-width: 1445px;
  margin: auto;

  #modeBoutique,
  #modeRapide {
    cursor: pointer;
  }
}
</style>
