<template>
  <section class="showProducts">
    <div
      v-if="
        !store.state.loadingCoffret &&
        !store.state.loading &&
        store.state.data &&
        store.state.coffretsMeliMelo
      "
    >
      <ShowPrixReventeCoffrets />
      <!-- SI COFFRETS MELIMELO     -->
      <div
        v-if="
          store.state.typeCoffrets === 'melimelo' && !store.state.loadingCoffret
        "
        class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3"
      >
        <transition-group name="slide">
          <div
            v-for="(product, index) in stockCoffretMeliMelo()"
            :key="index"
            :id="`product${index}`"
            class="mb-5"
          >
            <div
              class="product-wrapper-coffret mx-auto md:mx-0 md:ml-5 md:mr-5"
              @mouseover="overSquare(true, index)"
              @mouseleave="overSquare(false, index)"
            >
              <router-link :to="`/produit/coffret/${product.coffret_slug}`">
                <div class="image-product-wrapper">
                  <img
                    :src="product.coffret_image"
                    :alt="product.coffret_name"
                    class="image_coffret"
                  />
                  <div class="over-square" :id="`square${index}`">
                    <img
                      src="@/assets/images/hamburger_icon_square.svg"
                      class="hamburger-square"
                    />
                  </div>
                </div>
                <div class="description-product-wrapper">
                  <p>{{ product.coffret_name }}</p>
                  <p class="price">{{ product.coffret_price.toFixed(2) }} $</p>
                </div>
              </router-link>
            </div>
          </div>
        </transition-group>
      </div>

      <!-- SI COFFRET REGULIERS -->
      <div
        v-else-if="store.state.typeCoffrets === 'regulier'"
        class="col-span-3 w-full grid grid-cols-1 ml-5"
      >
        <transition-group name="slide">
          <div
            v-for="(product, index) in store.methods.filterBy(
              store.state.data,
              store.state.currentFilterTags,
              store.state.currentFilterCategories,
              store.state.currentFilterSearch,
              true,
              store.state.currentTag
            )"
            :key="index"
            class="rapide-product-holder"
            :id="`product${index}`"
            @mouseover="overSquare(true, index)"
            @mouseleave="overSquare(false, index)"
          >
            <div
              v-for="(
                variation, indexVariation
              ) in product.product_priceVariations"
              :key="indexVariation"
              class="col-span-4 grid grid-cols-1 gap-0 rapide-produit w-11/12"
            >
              <!--  VERIFIE LE BON FORMAT ET SI VARIATION EST SHOW -->
              <div
                v-if="
                  variation.isCoffretReg &&
                  variation.variationShow &&
                  (store.methods.stockCoffretAvailable(variation, false) > 0 ||
                    store.state.ifStock === 0)
                "
              >
                <div
                  class="rapide-product-wrapper grid grid-cols-5 lg:grid-cols-10 gap-0 mb-5"
                >
                  <div class="rapide-image-holder col-span-1">
                    <img
                      :src="variation.imageVariation"
                      alt="product.product_images[0].alt"
                      class="image-rapide w-20"
                    />
                  </div>
                  <div class="rapide-name-holder col-span-4">
                    <p class="px-10 w-full">
                      {{ product.product_name }}<br />
                      <span class="format-rapide">- Coffret de 6 cartes </span>
                      <span class="format-rapide"
                        >- {{ variation.format }} </span
                      ><br />
                      <span
                        class="format-rapide"
                        v-if="
                          store.methods.stockCoffretAvailable(
                            variation,
                            false
                          ) === 0
                        "
                        >( Rupture d'inventaire )</span
                      ><span class="format-rapide" v-else
                        >(
                        {{
                          store.methods.stockCoffretAvailable(variation, false)
                        }}
                        en stock )</span
                      >
                    </p>
                  </div>

                  <div
                    class="prixQuantiteRapide col-span-1 flex flex-wrap content-center justify-start lg:justify-end mt-2 lg:mt-0 -mr-20 lg:mr-0"
                  >
                    <p>{{ store.state.prixCoffretReg.toFixed(2) }} $</p>
                  </div>
                  <div
                    class="choixQuantiteRapide col-span-3 lg:col-span-2 flex flex-wrap content-center justify-center mt-2 lg:mt-0"
                  >
                    <div class="num-block skin-5">
                      <div class="num-in">
                        <span class="minus dis" @click="decrement(variation)"
                          >-</span
                        >
                        <input
                          class="in-num"
                          type="number"
                          @keyup.enter="
                            store.methods.addToCart([
                              variation,
                              store.state.tempCartQty[
                                'card-' +
                                  variation.parentId +
                                  '-' +
                                  variation.variation
                              ],
                              product.product_name,
                              [true, false, true, false, false],
                              false,
                              product.product_sku,
                            ])
                          "
                          v-model.number="
                            store.state.tempCartQty[
                              'card-' +
                                variation.parentId +
                                '-' +
                                variation.variation
                            ]
                          "
                        />
                        <span class="plus" @click="increment(variation)"
                          >+</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="totalQuantiteRapide col-span-1 flex items-center mt-2 lg:mt-0 -ml-10 lg:ml-0"
                  >
                    <span
                      v-if="
                        store.state.tempCartQty[
                          'card-' +
                            variation.parentId +
                            '-' +
                            variation.variation
                        ]
                      "
                    >
                      {{
                        (
                          store.state.prixCoffretReg *
                          store.state.tempCartQty[
                            "card-" +
                              variation.parentId +
                              "-" +
                              variation.variation
                          ]
                        ).toFixed(2)
                      }}
                      $
                    </span>
                  </div>
                  <div
                    class="choixAjouterRapide col-span-5 lg:col-span-1 flex flex-wrap content-center mt-2"
                  >
                    <button
                      class="btnFilterCoffret w-full"
                      v-if="
                        isStock(
                          store.methods.stockCoffretAvailable(variation, false)
                        )
                      "
                      @click="
                        store.methods.addToCart([
                          variation,
                          store.state.tempCartQty[
                            'card-' +
                              variation.parentId +
                              '-' +
                              variation.variation
                          ],
                          product.product_name,
                          [true, false, true, false, false],
                          false,
                          product.product_sku,
                        ])
                      "
                    >
                      Ajouter
                    </button>
                    <button
                      class="btnFilterCoffretDisable w-full"
                      v-else
                      disabled
                    >
                      Ajouter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>

      <!-- SI COFFRET CARTE TOUCHE DE DORE -->
      <div
        v-else-if="store.state.typeCoffrets === 'touchedore'"
        class="col-span-3 w-full grid grid-cols-1 ml-5"
      >
        <transition-group name="slide">
          <div
            v-for="(product, index) in store.methods.filterBy(
              store.state.data,
              store.state.currentFilterTags,
              store.state.currentFilterCategories,
              store.state.currentFilterSearch,
              true,
              store.state.currentTag
            )"
            :key="index"
            class="rapide-product-holder w-full"
            :id="`product${index}`"
            @mouseover="overSquare(true, index)"
            @mouseleave="overSquare(false, index)"
          >
            <div
              v-for="(
                variation, indexVariation
              ) in product.product_priceVariations"
              :key="indexVariation"
              class="col-span-4 grid grid-cols-1 gap-0 rapide-produit w-11/12"
            >
              <div
                v-if="
                  variation.isCoffretToucheDore &&
                  variation.variationShow &&
                  (store.methods.stockCoffretAvailable(variation, false) > 0 ||
                    store.state.ifStock === 0)
                "
              >
                <!--  VERIFIE LE BON FORMAT ET SI VARIATION EST SHOW -->
                <div
                  class="rapide-product-wrapper grid grid-cols-5 lg:grid-cols-10 gap-0 mb-5"
                >
                  <div class="rapide-image-holder col-span-1">
                    <img
                      :src="variation.imageVariation"
                      alt="product.product_images[0].alt"
                      class="image-rapide w-20"
                    />
                  </div>

                  <div class="rapide-name-holder col-span-4">
                    <p class="px-10 w-full">
                      {{ product.product_name }}<br />
                      <span class="format-rapide">- Coffret de 6 cartes </span>
                      <span class="format-rapide"
                        >- {{ variation.format }} </span
                      ><br />
                      <span
                        class="format-rapide"
                        v-if="
                          store.methods.stockCoffretAvailable(
                            variation,
                            false
                          ) === 0
                        "
                        >( Rupture d'inventaire )</span
                      ><span class="format-rapide" v-else
                        >(
                        {{
                          store.methods.stockCoffretAvailable(variation, false)
                        }}
                        en stock )</span
                      >
                    </p>
                  </div>

                  <div
                    class="prixQuantiteRapide col-span-1 flex flex-wrap content-center justify-start lg:justify-end mt-2 lg:mt-0 -mr-20 lg:mr-0"
                  >
                    <p>{{ store.state.prixCoffretEns.toFixed(2) }} $</p>
                  </div>
                  <div
                    class="choixQuantiteRapide col-span-3 lg:col-span-2 flex flex-wrap content-center justify-center mt-2 lg:mt-0"
                  >
                    <div class="num-block skin-5">
                      <div class="num-in">
                        <span class="minus dis" @click="decrement(variation)"
                          >-</span
                        >
                        <input
                          class="in-num"
                          type="number"
                          @keyup.enter="
                            store.methods.addToCart([
                              variation,
                              store.state.tempCartQty[
                                'card-' +
                                  variation.parentId +
                                  '-' +
                                  variation.variation
                              ],
                              product.product_name,
                              [true, false, false, false, true],
                              false,
                              product.product_sku,
                            ])
                          "
                          v-model.number="
                            store.state.tempCartQty[
                              'card-' +
                                variation.parentId +
                                '-' +
                                variation.variation
                            ]
                          "
                        />
                        <span class="plus" @click="increment(variation)"
                          >+</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="totalQuantiteRapide col-span-1 flex items-center mt-2 lg:mt-0 -ml-10 lg:ml-0"
                  >
                    <span
                      v-if="
                        store.state.tempCartQty[
                          'card-' +
                            variation.parentId +
                            '-' +
                            variation.variation
                        ]
                      "
                    >
                      {{
                        (
                          store.state.prixCoffretEns *
                          store.state.tempCartQty[
                            "card-" +
                              variation.parentId +
                              "-" +
                              variation.variation
                          ]
                        ).toFixed(2)
                      }}
                      $
                    </span>
                  </div>

                  <div
                    class="choixAjouterRapide col-span-5 lg:col-span-1 flex flex-wrap content-center mt-2"
                  >
                    <button
                      class="btnFilterCoffret w-full"
                      v-if="
                        isStock(
                          store.methods.stockCoffretAvailable(variation, false)
                        )
                      "
                      @click="
                        store.methods.addToCart([
                          variation,
                          store.state.tempCartQty[
                            'card-' +
                              variation.parentId +
                              '-' +
                              variation.variation
                          ],
                          product.product_name,
                          [true, false, false, false, true],
                          false,
                          product.product_sku,
                        ])
                      "
                    >
                      Ajouter
                    </button>
                    <button
                      class="btnFilterCoffretDisable w-full"
                      v-else
                      disabled
                    >
                      Ajouter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>

      <!-- SI COFFRET ENSEMENCE -->
      <div v-else class="col-span-3 w-full grid grid-cols-1 ml-5">
        <transition-group name="slide">
          <div
            v-for="(product, index) in store.methods.filterBy(
              store.state.data,
              store.state.currentFilterTags,
              store.state.currentFilterCategories,
              store.state.currentFilterSearch,
              true,
              store.state.currentTag
            )"
            :key="index"
            class="rapide-product-holder w-full"
            :id="`product${index}`"
            @mouseover="overSquare(true, index)"
            @mouseleave="overSquare(false, index)"
          >
            <div
              v-for="(
                variation, indexVariation
              ) in product.product_priceVariations"
              :key="indexVariation"
              class="col-span-4 grid grid-cols-1 gap-0 rapide-produit w-11/12"
            >
              <div
                v-if="
                  variation.isCoffretEns &&
                  variation.variationShow &&
                  (store.methods.stockCoffretAvailable(variation, false) > 0 ||
                    store.state.ifStock === 0)
                "
              >
                <!--  VERIFIE LE BON FORMAT ET SI VARIATION EST SHOW -->
                <div
                  class="rapide-product-wrapper grid grid-cols-5 lg:grid-cols-10 gap-0 mb-5"
                >
                  <div class="rapide-image-holder col-span-1">
                    <img
                      :src="variation.imageVariation"
                      alt="product.product_images[0].alt"
                      class="image-rapide w-20"
                    />
                  </div>

                  <div class="rapide-name-holder col-span-4">
                    <p class="px-10 w-full">
                      {{ product.product_name }}<br />
                      <span class="format-rapide">- Coffret de 6 cartes </span>
                      <span class="format-rapide"
                        >- {{ variation.format }} </span
                      ><br />
                      <span
                        class="format-rapide"
                        v-if="
                          store.methods.stockCoffretAvailable(
                            variation,
                            false
                          ) === 0
                        "
                        >( Rupture d'inventaire )</span
                      ><span class="format-rapide" v-else
                        >(
                        {{
                          store.methods.stockCoffretAvailable(variation, false)
                        }}
                        en stock )</span
                      >
                    </p>
                  </div>

                  <div
                    class="prixQuantiteRapide col-span-1 flex flex-wrap content-center justify-start lg:justify-end mt-2 lg:mt-0 -mr-20 lg:mr-0"
                  >
                    <p>{{ store.state.prixCoffretEns.toFixed(2) }} $</p>
                  </div>
                  <div
                    class="choixQuantiteRapide col-span-3 lg:col-span-2 flex flex-wrap content-center justify-center mt-2 lg:mt-0"
                  >
                    <div class="num-block skin-5">
                      <div class="num-in">
                        <span class="minus dis" @click="decrement(variation)"
                          >-</span
                        >
                        <input
                          class="in-num"
                          type="number"
                          v-model.number="
                            store.state.tempCartQty[
                              'card-' +
                                variation.parentId +
                                '-' +
                                variation.variation
                            ]
                          "
                          @keyup.enter="
                            store.methods.addToCart([
                              variation,
                              store.state.tempCartQty[
                                'card-' +
                                  variation.parentId +
                                  '-' +
                                  variation.variation
                              ],
                              product.product_name,
                              [true, false, false, true, false],
                              false,
                              product.product_sku,
                            ])
                          "
                        />
                        <span class="plus" @click="increment(variation)"
                          >+</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="totalQuantiteRapide col-span-1 flex items-center mt-2 lg:mt-0 -ml-10 lg:ml-0"
                  >
                    <span
                      v-if="
                        store.state.tempCartQty[
                          'card-' +
                            variation.parentId +
                            '-' +
                            variation.variation
                        ]
                      "
                    >
                      {{
                        (
                          store.state.prixCoffretEns *
                          store.state.tempCartQty[
                            "card-" +
                              variation.parentId +
                              "-" +
                              variation.variation
                          ]
                        ).toFixed(2)
                      }}
                      $
                    </span>
                  </div>

                  <div
                    class="choixAjouterRapide col-span-5 lg:col-span-1 flex flex-wrap content-center mt-2"
                  >
                    <button
                      class="btnFilterCoffret w-full"
                      v-if="
                        isStock(
                          store.methods.stockCoffretAvailable(variation, false)
                        )
                      "
                      @click="
                        store.methods.addToCart([
                          variation,
                          store.state.tempCartQty[
                            'card-' +
                              variation.parentId +
                              '-' +
                              variation.variation
                          ],
                          product.product_name,
                          [true, false, false, true, false],
                          false,
                          product.product_sku,
                        ])
                      "
                    >
                      Ajouter
                    </button>
                    <button
                      class="btnFilterCoffretDisable w-full"
                      v-else
                      disabled
                    >
                      Ajouter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>

    <div v-if="store.state.loading" class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    <p v-if="store.state.error">
      {{ store.state.error }}
    </p>
  </section>
</template>
<script>
import { onMounted, ref, inject, reactive } from "vue";
import ShowPrixReventeCoffrets from "../components/ShowPrixReventeCoffrets.vue";

export default {
  name: "ShowProducts",
  components: { ShowPrixReventeCoffrets },

  setup() {
    const store = inject("store");
    const filter = ref("all");
    const tempCart = reactive([]);

    function increment(variation) {
      // VERIFICATION SI LA DEMANDE EST PLUS GRANDE QUE L'INVENTAIRE
      if (
        store.state.tempCartQty[
          `card-${variation.parentId}-${variation.variation}`
        ] >= store.methods.stockCoffretAvailable(variation, false) ||
        store.methods.stockCoffretAvailable(variation, false) === 0
      ) {
        alert(
          "Vous ne pouvez ajouter plus d'articles que l'inventaire disponible"
        );
        return;
      }

      if (
        !isNaN(
          store.state.tempCartQty[
            `card-${variation.parentId}-${variation.variation}`
          ]
        )
      ) {
        store.state.tempCartQty[
          `card-${variation.parentId}-${variation.variation}`
        ]++;
      } else {
        store.state.tempCartQty[
          `card-${variation.parentId}-${variation.variation}`
        ] = 1;
      }
    }

    function decrement(variation) {
      if (
        !isNaN(
          store.state.tempCartQty[
            `card-${variation.parentId}-${variation.variation}`
          ]
        )
      ) {
        if (
          store.state.tempCartQty[
            `card-${variation.parentId}-${variation.variation}`
          ] > 0
        ) {
          store.state.tempCartQty[
            `card-${variation.parentId}-${variation.variation}`
          ]--;
        }
      }
    }

    onMounted(() => {
      store.state.currentTag = 446; // FILTER ALL CARDS

      if (store.state.data === null) {
        // SI LIENS DIRECT ET CARTE PAS PRELOADÉ
        store.methods.fetchAllProducts();
      }
      if (store.state.coffretsMeliMelo === null) {
        // SI LIENS DIRECT ET CARTE PAS PRELOADÉ
        store.methods.fetchCoffretMeliMelo();
      }

      if (!store.state.presentoirs) {
        store.methods.fetchPresentoirs();
      }
    });

    function overSquare(handleOver, product) {
      const square = document.querySelector(`#square${product}`);
      if (!square) return; // CORRECTION BUG SAFARI

      if (store.state.typeCoffrets === "melimelo") {
        if (handleOver) {
          square.classList.add("hover");
        } else {
          square.classList.remove("hover");
        }
      }
    }

    function isStock(item) {
      if (item && item > 0) {
        return true;
      } else {
        return false;
      }
    }

    // RETOURE INVENTAIRE AVEC MODIF SI DEMANDE DE STOCK SEULEMENT
    function stockCoffretMeliMelo() {
      const inventoryCoffret = store.state.coffretsMeliMelo;

      let filteredStockCoffret = [];

      if (store.state.ifStock === 1) {
        let inventoryCoffretToScan = [];
        store.state.data.forEach((product) => {
          product.product_priceVariations.forEach((variation) => {
            inventoryCoffretToScan.push({
              variation_id: variation.variation,
              stock_available: variation.stockAvailable,
            });
          });
        });

        inventoryCoffret.forEach((coffret) => {
          let minimalStock = 0;
          const cardsStock = [];
          coffret.coffret_varId.forEach((varId) => {
            let index = inventoryCoffretToScan.findIndex(
              (x) => x.variation_id === varId
            );
            if (index !== -1) {
              cardsStock.push(inventoryCoffretToScan[index].stock_available);
            }
          });
          if (cardsStock.length) {
            minimalStock = Math.min(...cardsStock);
          }
          if (minimalStock > 0) {
            filteredStockCoffret.push(coffret);
          }
        });

        return filteredStockCoffret;
      } else {
        return inventoryCoffret;
      }
    }

    return {
      store,
      filter,
      isStock,
      tempCart,
      overSquare,
      increment,
      decrement,
      stockCoffretMeliMelo,
    };
  },
};
</script>

<style lang="scss">
.image_coffret {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btnFilterCoffret {
  font-size: 14px !important;
  color: white;
  border-radius: 0px;
  padding: 7px 15px;
  display: block;
  background-color: black;
  width: 100%;
  transition: all 0.3s;
  width: 150px;
  outline: none;

  &:hover {
    color: white;
    background-color: #b7a36f;
  }

  &:active {
    outline: none !important;
  }
}

.btnFilterCoffretDisable {
  font-size: 14px !important;
  border-radius: 0px;
  color: #a2a2a2 !important;
  width: 100%;
  cursor: not-allowed;
  padding: 7px 15px;
  display: block;
  background-color: rgb(236, 236, 236);
  transition: all 0.3s;
  width: 150px;
  outline: none;
}

@media only screen and (min-width: 1100px) {
  .container_coffret {
    transform: translateY(-160px);
  }
}

.format-rapide {
  font-size: 12px;
}

// VISUEL BOUTIQUE
.product-wrapper-coffret {
  max-height: 400px;
  max-width: 250px;

  .image-product-wrapper {
    position: relative;
    z-index: 0;
    width: 250px;
    height: 251px;
    transform: translateY(1px);

    .over-square {
      z-index: 10;
      position: absolute;
      bottom: -50px;
      right: 0;
      width: 50px;
      height: 50px;
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s all;

      .hamburger-square {
        transform: scale(0.5);
      }

      &.hover {
        bottom: 0px;
      }
    }
  }

  .description-product-wrapper {
    position: relative;
    z-index: 100;
    width: calc(100% + 1px);
    min-height: 120px;
    text-align: center;
    padding: 20px 20px;
    background-color: rgb(247, 247, 247);
    font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 19px;
    line-height: 27px;
    letter-spacing: 1px;
    color: rgba(34, 34, 34, 1);

    .price {
      color: #000;
      font-size: 14px;
      font-weight: bold;
    }
  }
}

// LOADER

.lds-roller {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
