<template>
  <section class="showProducts">
    <div
      v-if="!store.state.loading && store.state.data"
      class="product-holder pr-0 lg:pr-5"
    >
      <ShowPrixRevente />

      <div
        v-if="store.state.modeVisualisation === 'boutique'"
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
      >
        <transition-group name="slide">
          <div
            v-for="(product, index) in filterStock()"
            :key="returnIndex(index)"
            class="product-wrapper mx-auto md:mx-0 md:ml-3 md:mr-5"
            :id="`product${index}`"
            @mouseover="overSquare(true, index)"
            @mouseleave="overSquare(false, index)"
          >
            <div class="mb-7 px-2" v-if="product">
              <router-link :to="`/produit/${product.product_slug}`">
                <div class="image-product-wrapper">
                  <img
                    v-if="product.product_images.length > 0"
                    :src="product.product_images[0].src"
                    alt="Image Produit"
                    height="251"
                    width="250"
                  />
                  <div class="over-square" :id="`square${index}`">
                    <img
                      src="@/assets/images/hamburger_icon_square.svg"
                      class="hamburger-square"
                    />
                  </div>
                </div>
                <div class="description-product-wrapper">
                  <p>{{ product.product_name }}</p>
                  <p
                    class="price"
                    v-if="
                      !product.product_isVariable &&
                      product.product_priceVariations.length
                    "
                  >
                    {{ product.product_priceVariations[0].price.toFixed(2) }} $
                  </p>
                  <p class="price" v-else>
                    <span
                      v-for="(
                        variation, index
                      ) in store.methods.removeDuplicates(
                        product.product_priceVariations,
                        (item) => item.price
                      )"
                      :key="index"
                    >
                      <span v-if="variation.variationShow"
                        >{{ variation.price.toFixed(2) }} $</span
                      >
                      <span
                        v-if="
                          index <
                          store.methods.removeDuplicates(
                            product.product_priceVariations,
                            (item) => item.price
                          ).length -
                            1
                        "
                      >
                        -
                      </span>
                    </span>
                  </p>
                </div>
              </router-link>
            </div>
          </div>
        </transition-group>
      </div>
      <!-- SI CARTES AVEC FORMATS -->

      <div
        v-if="store.state.modeVisualisation === 'rapide'"
        class="col-span-3 w-full grid grid-cols-1"
      >
        <transition-group name="slide">
          <div
            v-for="(product, index) in store.methods.filterBy(
              store.state.data,
              store.state.currentFilterTags,
              store.state.currentFilterCategories,
              store.state.currentFilterSearch,
              true,
              store.state.currentTag
            )"
            :key="index"
            class="rapide-product-holder w-full"
            :id="`product${index}`"
            @mouseover="overSquare(true, index)"
            @mouseleave="overSquare(false, index)"
          >
            <div
              v-for="(
                variation, indexVariation
              ) in product.product_priceVariations"
              :key="indexVariation"
              class="col-span-4 grid grid-cols-1 gap-0 rapide-produit mx-auto ml-5 mr-5 md:mr-12"
            >
              <div
                v-if="
                  variation.stockAvailable >= store.state.ifStock &&
                  variation.variationShow &&
                  needFilterSize(variation)
                "
              >
                <div
                  class="rapide-product-wrapper grid grid-cols-5 lg:grid-cols-10 gap-0 mb-5"
                >
                  <div class="rapide-image-holder col-span-1">
                    <router-link :to="`/produit/${product.product_slug}`">
                      <img
                        :src="variation.imageVariation"
                        alt="product.product_images[0].alt"
                        class="image-rapide w-20"
                      />
                    </router-link>
                  </div>

                  <div class="rapide-name-holder col-span-4">
                    <router-link :to="`/produit/${product.product_slug}`">
                      <p class="px-10 w-full">
                        {{ product.product_name }}<br />
                        <span class="format-rapide"
                          ><span v-if="variation.format.length"
                            >- {{ variation.format }}</span
                          ><br v-if="variation.format.length" />(
                          <span v-if="variation.stockAvailable === 0"
                            >Rupture d'inventaire</span
                          ><span v-else>
                            {{ variation.stockAvailable }} en stock</span
                          >
                          )</span
                        >
                      </p>
                    </router-link>
                  </div>

                  <div
                    class="prixQuantiteRapide col-span-1 flex flex-wrap content-center justify-start lg:justify-end mt-2 lg:mt-0 -mr-20 lg:mr-0"
                  >
                    <p>{{ variation.price.toFixed(2) }} $</p>
                  </div>
                  <div
                    class="choixQuantiteRapide col-span-3 lg:col-span-2 flex flex-wrap content-center justify-center mt-2 lg:mt-0"
                  >
                    <div class="num-block skin-5">
                      <div class="num-in">
                        <span class="minus dis" @click="decrement(variation)"
                          >-</span
                        >
                        <input
                          class="in-num"
                          type="number"
                          @keyup.enter="
                            store.methods.addToCart([
                              variation,
                              store.state.tempCartQty[
                                'card-' +
                                  variation.parentId +
                                  '-' +
                                  variation.variation
                              ],
                              product.product_name,
                              [false, false],
                              false,
                              variation.variation_sku,
                              store.state.currentTag, // AJOUT CATEGORIE NOW 2024
                              variation.format || null,
                            ])
                          "
                          v-model.number="
                            store.state.tempCartQty[
                              'card-' +
                                variation.parentId +
                                '-' +
                                variation.variation
                            ]
                          "
                        />
                        <span class="plus" @click="increment(variation)"
                          >+</span
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="totalQuantiteRapide col-span-1 flex items-center mt-2 lg:mt-0 -ml-10 lg:ml-0"
                  >
                    <span
                      v-if="
                        store.state.tempCartQty[
                          'card-' +
                            variation.parentId +
                            '-' +
                            variation.variation
                        ]
                      "
                      >{{
                        (
                          variation.price *
                          store.state.tempCartQty[
                            "card-" +
                              variation.parentId +
                              "-" +
                              variation.variation
                          ]
                        ).toFixed(2)
                      }}
                      $</span
                    >
                  </div>

                  <div
                    class="choixAjouterRapide col-span-5 lg:col-span-1 flex flex-wrap content-center mt-2"
                    @click="
                      store.methods.addToCart([
                        variation,
                        store.state.tempCartQty[
                          'card-' +
                            variation.parentId +
                            '-' +
                            variation.variation
                        ],
                        product.product_name,
                        [false, false],
                        false,
                        variation.variation_sku,
                        store.state.currentTag, // AJOUT CATEGORIE NOW 2024
                        variation.format || null,
                      ])
                    "
                  >
                    <button
                      class="btnFilterbuy w-full"
                      v-if="variation.stockAvailable > 0"
                    >
                      Ajouter
                    </button>
                    <button class="btnFilterbuyDisabled w-full" v-else disabled>
                      Ajouter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>

    <div v-if="store.state.loading" class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    <p v-if="store.state.error">
      {{ store.state.error }}
    </p>
  </section>
</template>
<script>
import { ref, inject, reactive, onMounted, watch } from "vue";
import { useRoute } from "vue-router";

import ShowPrixRevente from "../components/ShowPrixRevente.vue";

export default {
  name: "ShowProducts",
  components: {
    ShowPrixRevente,
  },

  setup() {
    const store = inject("store");
    const filter = ref("all");
    const tempCart = reactive([]);
    const route = useRoute();

    function increment(variation) {
      // NE PAS AUGMENTER AU DELA DE LA QUANTITE STOCK

      if (
        store.state.tempCartQty[
          `card-${variation.parentId}-${variation.variation}`
        ] === variation.stockAvailable ||
        variation.stockAvailable === 0
      ) {
        alert("vous ne pouvez ajouter plus que linventaire disponible");
        return;
      }

      if (
        !isNaN(
          store.state.tempCartQty[
            `card-${variation.parentId}-${variation.variation}`
          ]
        )
      ) {
        store.state.tempCartQty[
          `card-${variation.parentId}-${variation.variation}`
        ]++;
      } else {
        store.state.tempCartQty[
          `card-${variation.parentId}-${variation.variation}`
        ] = 1;
      }
    }

    function decrement(variation) {
      if (
        !isNaN(
          store.state.tempCartQty[
            `card-${variation.parentId}-${variation.variation}`
          ]
        )
      ) {
        if (
          store.state.tempCartQty[
            `card-${variation.parentId}-${variation.variation}`
          ] > 0
        ) {
          store.state.tempCartQty[
            `card-${variation.parentId}-${variation.variation}`
          ]--;
        }
      }
    }

    function needFilterSize(variation) {
      // return true;
      if (store.state.currentFilterSize === " ") {
        return true;
      } else if (!variation.format || variation.format.length === 0) {
        return true;
      } else {
        return variation.format.includes(store.state.currentFilterSize);
      }
    }

    function overSquare(handleOver, product) {
      if (store.state.modeVisualisation !== "rapide") {
        const square = document.querySelector(`#square${product}`);

        if (!square) {
          return;
        }

        if (handleOver) {
          square.classList.add("hover");
        } else {
          square.classList.remove("hover");
        }
      }
    }

    function filterStock() {
      const inventory = store.methods.filterBy(
        store.state.data,
        store.state.currentFilterTags,
        store.state.currentFilterCategories,
        store.state.currentFilterSearch,
        true,
        store.state.currentTag
      );
      if (store.state.ifStock === 1) {
        // SI FITRE DE STOCK

        let filteredInventory = [];

        inventory.forEach((product) => {
          let ifAnyStock = false;
          product.product_priceVariations.forEach((variation) => {
            if (variation.stockAvailable > 0) {
              ifAnyStock = true;
            }
          });
          if (ifAnyStock) {
            // SI UN EST STOCK ENVOYER LE PRODUIT AU COMPLET
            filteredInventory.push(product);
          }
        });

        return filteredInventory;
      } else {
        // SINON DATA COMPLETE

        return inventory;
      }
    }

    function returnIndex(index) {
      return index;
    }

    // watch route change to reset currentFilterSize
    watch(
      () => route.params.categorie,
      () => {
        store.state.currentFilterSize = " ";
      }
    );

    onMounted(() => {
      store.state.currentFilterSize = " ";
    });

    return {
      store,
      overSquare,
      filter,
      tempCart,
      returnIndex,
      filterStock,
      increment,
      decrement,
      needFilterSize,
    };
  },
};
</script>

<style lang="scss" scoped>
.showProducts {
  height: auto;
  // TRANSITION DE PRODUITS

  .slide-enter-from {
    opacity: 0;
    transform: scale(0.95);
  }
  .slide-enter-to {
    transform: scale(1);
    opacity: 1;
  }
  .slide-enter-active {
    transition: all 0.4s ease;
  }
  .slide-leave-from {
    opacity: 1;
    transform: scale(1);
  }
  .slide-leave-to {
    opacity: 0;
    transform: scale(0.95);
  }

  .rapide-product-holder {
    // VISUEL RAPIDE

    .rapide-product-wrapper {
      // GRID 1
      .rapide-image-holder {
        .image-rapide {
          max-width: 90px;
          transition: 0.3s ease;
          transition-delay: 0.4s;
        }
        .image-rapide:hover {
          transform: scale(2);
        }
      }

      // GRID 2

      .rapide-name-holder {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .format-rapide {
          font-size: 12px;
        }
      }

      // GRID

      .btnFilterbuy {
        font-size: 14px !important;
        color: white;
        border-radius: 0px;
        width: 100% !important;
        padding: 7px 15px;
        display: block;
        background-color: black;
        transition: all 0.3s;
        min-width: 75px;
        outline: none;
      }

      .btnFilterbuyDisabled {
        font-size: 14px !important;
        color: #a2a2a2 !important;
        border-radius: 0px;
        width: 100% !important;
        padding: 7px 15px;
        display: block;
        background-color: rgb(236, 236, 236);
        cursor: not-allowed;
        transition: all 0.3s;
        min-width: 75px;
        outline: none;
      }

      .btnFilterbuy:hover {
        color: white;
        background-color: #b7a36f;
      }

      .choixQuantiteRapide {
        z-index: 100;
        /* skin 5 */
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type="number"] {
          -moz-appearance: textfield;
        }

        input:focus {
          outline: none !important;
        }

        .skin-5 .num-in {
          width: 88px;
          float: left;
          vertical-align: middle;
          position: relative;
          border: 1px solid #eeeff1;
          border-radius: 3px;
        }

        .skin-5 .num-in:hover {
          border: 1px solid #eeeff7;
          box-shadow: 0px 0px 7px 0px #f3f3f3;
        }

        .skin-5 .num-in span {
          cursor: pointer;
          font-size: 16px;
          width: 20px;
          display: block;
          line-height: 41px;
        }

        .skin-5 .num-in span.minus {
          float: left;
          text-align: right;
          font-size: 18px;
        }

        .skin-5 .num-in input {
          border: none;
          height: 41px;
          width: 46px;
          float: left;
          text-align: center;
          font-size: 16px;

          -webkit-box-shadow: none !important;
          -moz-box-shadow: none !important;
          box-shadow: none !important;
        }

        .skin-5 .num-in span.plus {
          cursor: pointer;
          float: right;
          text-align: left;
          font-size: 18px;
        }

        /* / skin 5 */
      }
    }
  }

  .product-holder {
    width: 100%;
    // padding: 0 20px;

    // VISUEL BOUTIQUE
    .product-wrapper {
      max-width: 250px;
      .image-product-wrapper {
        .over-square {
          z-index: 10;
          position: absolute;
          bottom: -50px;
          right: 0;
          width: 50px;
          height: 50px;
          background-color: black;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.3s all;
          .hamburger-square {
            transform: scale(0.5);
          }
        }
        .over-square.hover {
          bottom: 0px;
        }
        margin-top: auto;
        height: 100%;
        z-index: 0;
        position: relative;
        width: 100%;
        transform: translateY(1px);
      }
      .description-product-wrapper {
        position: relative;
        z-index: 100;
        max-width: 100%;
        min-height: 80px;
        text-align: center;
        padding: 20px 20px;
        background-color: rgb(247, 247, 247);
        font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI",
          Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 19px;
        line-height: 27px;
        letter-spacing: 1px;
        color: rgba(34, 34, 34, 1);

        .price {
          color: #000;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }

  .noproduct-bloc {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50vw;
    height: 20vh;
    border: 1px solid black;
  }

  #noproducttext {
    padding: 0 20px;
  }

  // LOADER

  .lds-roller {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #000;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
