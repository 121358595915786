<template>
  <main>
    <TheNavbar />

    <div class="cartes grid grid-cols-1  lg:grid-cols-4  px-10 pt-16">
      <div class="col-span-4 row-start-1 px-5 flex justify-center items-center">
        <div
          class="w-11/12 h-5/6 md:w-6/12 md:h-3/6 border flex justify-center items-center p-3"
        >
          <div class="flex flex-col items-center">
            <h1 class="text-3xl mb-4 text-center">
              Un problème est survenu pendant le paiement de votre commande.
            </h1>
            <h2 class="text-2xl mb-4 text-center">
              Veuillez contacter Mimosa design.
            </h2>
          </div>
        </div>
      </div>
    </div>

    <TheFooter />
  </main>
</template>

<script>
import TheNavbar from "../../components/TheNavbar";
import TheFooter from "../../components/TheFooter.vue";

import { onMounted } from "vue";

export default {
  name: "Erreur",
  components: { TheNavbar, TheFooter },
  setup() {
    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {};
  },
};
</script>
<style lang="scss">
.cartes {
  max-width: 1445px;
  margin: auto;

  #modeBoutique,
  #modeRapide {
    cursor: pointer;
  }
}
</style>
