<template>
  <section
    class="filterFormats pb-5"
    v-if="store.state.currentTag && store.state.data"
  >
    <!-- GRANDEUR PRODUITS -->
    <h6 class="titlesChoices" v-if="getFormats().length">
      FORMATS DE PRODUITS
    </h6>
    <ul v-for="(format, index) in getFormats()" :key="index">
      <li class="lineCategorie" @click="handleFormats([index, format])">
        <div class="textCategorie">
          <img src="@/assets/images/chevron-droit.png" class="chevron" />
          <p class="categorie_item" :id="`format${index}`">{{ format }}</p>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { inject, ref, watch, onMounted } from "vue";

export default {
  name: "SelectFormat",

  setup() {
    const store = inject("store");
    const currentFormat = ref([999, " "]);

    function getFormats() {
      const formatsSet = new Set();

      store.state.data.forEach((item) => {
        const hasCurrentCategory = item.product_categories.some(
          (cat) => cat.id === store.state.currentTag
        );

        if (hasCurrentCategory) {
          item.product_priceVariations.forEach((variation) => {
            if ("format" in variation && variation.format) {
              formatsSet.add(variation.format);
            }
          });
        }
      });

      const formatsArray = Array.from(formatsSet).filter(
        (format) => format && format.length > 0
      );
      return formatsArray;
    }

    function handleFormats(formatName) {
      const currentElement = document.querySelector(
        `#format${currentFormat.value[0]}`
      );
      const newElement = document.querySelector(`#format${formatName[0]}`);

      if (currentFormat.value[0] === formatName[0]) {
        newElement.style.fontWeight = "normal";
        currentFormat.value = [999, " "];
      } else {
        if (currentElement) {
          currentElement.style.fontWeight = "normal";
        }
        newElement.style.fontWeight = "600";
        currentFormat.value = formatName;
      }
      store.state.currentFilterSize = currentFormat.value[1];
    }

    function resetStyles() {
      const elements = document.querySelectorAll(".categorie_item");
      elements.forEach((element) => {
        element.style.fontWeight = "normal";
      });
    }

    // watch if route changes if yes reset the currentFilterSize
    watch(
      () => store.state.currentTag,
      () => {
        store.state.currentFilterSize = " ";
        currentFormat.value = [999, " "];
        resetStyles(); // Reset CSS to default when route changes
      }
    );

    onMounted(() => {
      store.state.formats = [];
    });

    return {
      store,
      handleFormats,
      getFormats,
      resetStyles,
    };
  },
};
</script>

<style lang="scss">
h6 {
  padding-bottom: 15px;
}
</style>
