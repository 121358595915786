<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8 ">
        <div class="col-span-3">
          <p class="text-3xl uppercase">
            Gestion des présentoirs
          </p>
        </div>
        <div class="w-full mt-10 grid grid-cols-1 md:grid-cols-3">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="
              mode = 'ajouter';
              newPresentoir = {};
              imageUrl1 = null;
              (imageUrl2 = null), (imageUrl3 = null);
            "
          >
            Ajouter un présentoir
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="
              mode = 'modifier';
              currentPresentoir = 'choose';
              newPresentoir = {};
              imageUrl1 = null;
              (imageUrl2 = null), (imageUrl3 = null);
            "
          >
            Modifier un présentoir
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 col-span-3 lg:col-span-1 uppercase"
            @click="
              mode = 'supprimer';
              currentPresentoir = 'choose';
              newPresentoir = {};
              imageUrl1 = null;
              (imageUrl2 = null), (imageUrl3 = null);
            "
          >
            Supprimer un présentoir
          </button>
        </div>
        <div v-if="mode === 'ajouter'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Ajout d'un présentoir</p>

            <label class="cursor-pointer">
              <span
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded uppercase"
                >Téléverser image #1</span
              >
              <input
                type="file"
                class="hidden"
                @change="onFileSelected"
                id="imageUrl1"
                required
              />
            </label>
            <div class="col-span-1" v-if="imageUrl1">
              <img :src="imageUrl1" alt="image1" class="imageUploaded" />
            </div>

            <label class="cursor-pointer">
              <span
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded uppercase"
                >Téléverser image #2</span
              >
              <input
                type="file"
                class="hidden"
                @change="onFileSelected"
                id="imageUrl2"
              />
            </label>
            <div class="col-span-1" v-if="imageUrl2">
              <img :src="imageUrl2" alt="image2" class="imageUploaded" />
            </div>
            <label class="cursor-pointer">
              <span
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded uppercase"
                >Téléverser image #3</span
              >
              <input
                type="file"
                class="hidden"
                @change="onFileSelected"
                id="imageUrl3"
              />
            </label>
            <div class="col-span-1" v-if="imageUrl3">
              <img :src="imageUrl3" alt="image3" class="imageUploaded" />
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-4 my-10">
            <div class="col-span-1 md:col-span-3 pl-10 text-xl">
              <p>Type:</p>
              <input
                type="text"
                v-model="newPresentoir.presentoirType"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Matériel:</p>
              <input
                type="text"
                v-model="newPresentoir.presentoirMateriel"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Format:</p>
              <input
                type="text"
                v-model="newPresentoir.presentoirFormat"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Pour type de cartes:</p>
              <input
                type="text"
                v-model="newPresentoir.presentoirPourFormat"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Capacité:</p>
              <input
                type="text"
                v-model="newPresentoir.presentoirCapacite"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Prix de vente:</p>
              <input
                type="number"
                v-model="newPresentoir.presentoirPrix"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Prix minimum location:</p>
              <input
                type="number"
                v-model="newPresentoir.presentoirCommandeMinimale"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Note:</p>
              <input
                type="text"
                v-model="newPresentoir.presentoirNote"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Nombre en inventaire:</p>
              <input
                type="number"
                v-model="newPresentoir.presentoirStockAvailable"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
            </div>
          </div>
          <div v-if="successAjout" class="mb-10">
            <p class="text-green-500">
              Le nouveau presentoir a été ajouté avec succès.
            </p>
          </div>
          <div v-if="errorAjout" class="mb-10">
            <p class="text-red-500">Une erreur est survenue.</p>
          </div>

          <div v-if="manqueInfos">
            <p class="text-red-500 pb-4">{{ errorMessage }}</p>
          </div>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="uploadNewPresentoir()"
          >
            AJOUTER LE PRESENTOIR
          </button>
        </div>

        <div v-if="mode === 'modifier'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Modification de présentoir</p>
            <div class="relative">
              <select
                v-model="currentPresentoir"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option disabled selected value="choose"
                  >Veuillez sélectionner un présentoir à modifier</option
                >
                <option
                  v-for="(presentoir, index) in store.state.presentoirs"
                  :key="index"
                  :value="presentoir.presentoirId"
                  >{{ presentoir.presentoirType }} -
                  {{
                    presentoir.presentoirPourFormat.charAt(0).toUpperCase() +
                      presentoir.presentoirPourFormat.slice(1)
                  }}
                  - {{ presentoir.presentoirFormat }}</option
                >
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            class="grid grid-cols-1 md:grid-cols-4 my-10"
            v-if="currentPresentoir !== 'choose'"
          >
            <div class="col-span-1 md:col-span-4">
              <img
                :src="newPresentoir.presentoirImage"
                class="mb-10 imageUploaded"
              />

              <label class="cursor-pointer">
                <span
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded uppercase my-10"
                  >Téléverser image #1</span
                >
                <input
                  type="file"
                  class="hidden"
                  @change="onFileSelected"
                  id="imageUrl1"
                />
              </label>
            </div>

            <div class="col-span-1 md:col-span-4">
              <img
                :src="newPresentoir.presentoirImage2"
                class="imageUploaded my-10"
                v-if="
                  store.state.presentoirs[getIndex(currentPresentoir)]
                    .presentoirImage2
                "
              />
              <p v-else class="my-10 ml-2">[ Aucune deuxième image ]</p>

              <label class="cursor-pointer">
                <span
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded uppercase mt-10"
                  >Téléverser image #2</span
                >
                <input
                  type="file"
                  class="hidden"
                  @change="onFileSelected"
                  id="imageUrl2"
                />
              </label>
            </div>

            <div class="col-span-1 md:col-span-4">
              <img
                :src="newPresentoir.presentoirImage3"
                class="imageUploaded my-10"
                v-if="
                  store.state.presentoirs[getIndex(currentPresentoir)]
                    .presentoirImage2
                "
              />
              <p v-else class="my-10 ml-2">[ Aucune troisième image ]</p>

              <label class="cursor-pointer">
                <span
                  class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded uppercase mt-10"
                  >Téléverser image #3</span
                >
                <input
                  type="file"
                  class="hidden"
                  @change="onFileSelected"
                  id="imageUrl3"
                />
              </label>
            </div>

            <div class="col-span-1 md:col-span-2 text-xl mt-20">
              <p>Type:</p>
              <input
                type="text"
                v-model="newPresentoir.presentoirType"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Matériel:</p>
              <input
                type="text"
                v-model="newPresentoir.presentoirMateriel"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Format:</p>
              <input
                type="text"
                v-model="newPresentoir.presentoirFormat"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Pour type de cartes:</p>
              <input
                type="text"
                v-model="newPresentoir.presentoirPourFormat"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Capacité:</p>
              <input
                type="text"
                v-model="newPresentoir.presentoirCapacite"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Prix de vente:</p>
              <input
                type="number"
                v-model="newPresentoir.presentoirPrix"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Prix minimum location:</p>
              <input
                type="number"
                v-model="newPresentoir.presentoirCommandeMinimale"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Note:</p>
              <input
                type="text"
                v-model="newPresentoir.presentoirNote"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
              <p>Nombre en inventaire:</p>
              <input
                type="text"
                v-model="newPresentoir.presentoirStockAvailable"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-full mt-2 mb-4"
              />
            </div>
          </div>
          <div v-if="successEdit" class="mb-10">
            <p class="text-green-500">
              Le nouveau présentoir a été modifié avec succès.
            </p>
          </div>
          <div v-if="errorEdit" class="mb-10">
            <p class="text-red-500">Une erreur est survenue.</p>
          </div>
          <div v-if="manqueChampsUpdate" class="mb-10">
            <p class="text-red-500">
              Verifier que tous les champs sont bien renseignés.
            </p>
          </div>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="saveEdit()"
          >
            SAUVEGARDER LES MODIFICATIONS
          </button>
        </div>

        <div v-if="mode === 'supprimer'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Suppression d'un présentoir</p>
            <div class="relative">
              <select
                v-model="currentPresentoir"
                @change="getIndex()"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option disabled selected value="choose"
                  >Veuillez sélectionner un présentoir à supprimer</option
                >
                <option
                  v-for="(presentoir, index) in store.state.presentoirs"
                  :key="index"
                  :value="presentoir.presentoirId"
                  >{{ presentoir.presentoirType }} -
                  {{
                    presentoir.presentoirPourFormat.charAt(0).toUpperCase() +
                      presentoir.presentoirPourFormat.slice(1)
                  }}
                  - {{ presentoir.presentoirFormat }}</option
                >
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            class="grid grid-cols-1 md:grid-cols-4 my-10"
            v-if="currentPresentoir !== 'choose'"
          >
            <img
              :src="
                store.state.presentoirs[getIndex(currentPresentoir)]
                  .presentoirImage
              "
              class="imgPresentoirToDelete"
            />
            <div class="col-span-3 pl-10 text-xl">
              <p>
                Id :
                {{
                  store.state.presentoirs[getIndex(currentPresentoir)]
                    .presentoirId
                }}
              </p>
              <p>
                Type:
                {{
                  store.state.presentoirs[getIndex(currentPresentoir)]
                    .presentoirType
                }}
              </p>
              <p>
                Matériel:
                {{
                  store.state.presentoirs[getIndex(currentPresentoir)]
                    .presentoirMateriel
                }}
              </p>
              <p>
                Pour type de cartes:
                {{
                  store.state.presentoirs[getIndex(currentPresentoir)]
                    .presentoirPourFormat
                }}
              </p>
              <p>
                Prix :
                {{
                  store.state.presentoirs[
                    getIndex(currentPresentoir)
                  ].presentoirPrix.toFixed(2)
                }}$
              </p>
            </div>
          </div>

          <button
            class=" bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase"
            @click="deletePresentoir(presentoirToDelete)"
          >
            supprimer le présentoir
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

import AdminSideBar from "../../components/AdminSideBar.vue";
import { ref, onMounted, inject } from "vue";

import { ref as refDb, uploadBytes, getDownloadURL } from "firebase/storage";

export default {
  name: "GestionPresentoirAdmin",
  components: { AdminSideBar },
  setup() {
    const firebaseConfig = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: "extranet-fb58d.firebaseapp.com",
      projectId: "extranet-fb58d",
      storageBucket: "extranet-fb58d.appspot.com",
      messagingSenderId: "54012934511",
      appId: "1:54012934511:web:3fa26c12add158e1a2109a",
      measurementId: "G-Z5ELBPXFRY",
    };
    const firebaseApp = initializeApp(firebaseConfig);
    const storage = getStorage(firebaseApp);
    const store = inject("store");
    const mode = ref(null);
    const presentoirToEdit = ref(null);
    const presentoirToAdd = ref(null);
    const currentPresentoir = ref("choose");
    const newImageUrl = ref(null);
    const newPresentoirImage = ref(null);
    const errorFile = ref(false);
    const newUploadedImage = ref(null);
    const error = ref(false);
    const errorDelete = ref(false);
    const success = ref(false);
    const successAjout = ref(false);
    const errorAjout = ref(false);
    const successDelete = ref(false);
    const errorMessage = ref("");
    const errorEdit = ref(false);
    const successEdit = ref(false);
    const manqueInfos = ref(false);
    const manqueChampsUpdate = ref(false);
    const selectedForDelete = ref("");
    const indexToDelete = ref(null);
    const newPresentoir = ref(null);
    const imageUrl1Name = ref(null);
    const imageUrl2Name = ref(null);
    const imageUrl3Name = ref(null);
    const imageUrl1 = ref(null);
    const imageUrl2 = ref(null);
    const imageUrl3 = ref(null);
    const selectedFile = ref(null);

    onMounted(() => {
      if (store.state.presentoirs === null) {
        store.methods.fetchPresentoirs();
      }
    });

    function onFileSelected(event) {
      // VALIDATION DU FORMAT ET DE LA GRANDEUR

      const allowedExtensions = ["jpg", "png", "gif"],
        sizeLimit = 5000000;
      const fileExtension = event.target.files[0].name.split(".").pop();
      const fileSize = event.target.files[0].fileSize;

      if (!allowedExtensions.includes(fileExtension)) {
        alert("Ce type de fichier n'est pas accepté !");
        return;
      } else if (fileSize > sizeLimit) {
        alert("Le fichier est trop volumineux.");
        return;
      }

      selectedFile.value = event.target.files[0];

      if (event.target.id === "imageUrl1") {
        imageUrl1Name.value = [
          event.target.files[0].name.slice(
            0,
            event.target.files[0].name.length - 4
          ) +
            "_" +
            randomString(8),
        ].join("");
        imageUrl1Name.value += `.${fileExtension}`;
        onUploadImage("imageUrl1", imageUrl1Name.value);
      } else if (event.target.id === "imageUrl2") {
        imageUrl2Name.value = [
          event.target.files[0].name.slice(
            0,
            event.target.files[0].name.length - 4
          ) +
            "_" +
            randomString(8),
        ].join("");
        imageUrl2Name.value += `.${fileExtension}`;
        onUploadImage("imageUrl2", imageUrl2Name.value);
      } else if (event.target.id === "imageUrl3") {
        imageUrl3Name.value = [
          event.target.files[0].name.slice(
            0,
            event.target.files[0].name.length - 4
          ) +
            "_" +
            randomString(8),
        ].join("");
        imageUrl3Name.value += `.${fileExtension}`;
        onUploadImage("imageUrl3", imageUrl3Name.value);
      }
    }

    function getIndex(id) {
      let index = store.state.presentoirs.findIndex(
        (x) => x.presentoirId === id
      );
      if (mode.value === "modifier") {
        newPresentoir.value = store.state.presentoirs[index];
      }
      return index;
    }

    function handleFileUpload(event) {
      // VALIDATION DU FORMAT ET DE LA GRANDEUR

      const allowedExtensions = ["jpg", "jpeg", "png", "gif"],
        sizeLimit = 5000000; // 500 k
      const fileExtension = event.target.files[0].name.split(".").pop();
      const fileSize = event.target.files[0].fileSize;

      if (!allowedExtensions.includes(fileExtension)) {
        errorFile.value = true;
        alert("Ce type de fichier n'est pas accepté !");
      } else if (fileSize > sizeLimit) {
        errorFile.value = true;
        alert("Le fichier est trop volumineux.");
      }

      if (!errorFile.value) {
        newImageUrl.value = event.target.files[0];
        uploadNewImage(newImageUrl.value);
      }
    }

    const onUploadImage = (imageType, fileName) => {
      const storageRef = refDb(storage, `/presentoirs/${fileName}`);
      uploadBytes(storageRef, selectedFile.value).then((snapshot) => {
        console.log(
          "Uploaded",
          snapshot,
          getDownloadURL(storageRef).then((url) => {
            if (imageType === "imageUrl1") {
              imageUrl1.value = url;
              if (mode.value === "modifier") {
                newPresentoir.value.presentoirImage = imageUrl1.value;
              }
            } else if (imageType === "imageUrl2") {
              imageUrl2.value = url;
              if (mode.value === "modifier") {
                newPresentoir.value.presentoirImage2 = imageUrl2.value;
              }
            } else if (imageType === "imageUrl3") {
              imageUrl3.value = url;
              if (mode.value === "modifier") {
                newPresentoir.value.presentoirImage3 = imageUrl3.value;
              }
            }
          })
        );
      });
    };

    async function uploadNewPresentoir() {
      successAjout.value = false;
      errorAjout.value = false;
      manqueInfos.value = false;

      newPresentoir.value.presentoirImage = imageUrl1.value;

      if (imageUrl2.value) {
        newPresentoir.value.presentoirImage2 = imageUrl2.value;
      } else {
        newPresentoir.value.presentoirImage2 = null;
      }

      if (imageUrl3.value) {
        newPresentoir.value.presentoirImage3 = imageUrl3.value;
      } else {
        newPresentoir.value.presentoirImage3 = null;
      }

      if (
        newPresentoir.value.presentoirPrix === "" ||
        !newPresentoir.value.presentoirPrix ||
        newPresentoir.value.presentoirType === "" ||
        !newPresentoir.value.presentoirType ||
        newPresentoir.value.presentoirPourFormat === "" ||
        !newPresentoir.value.presentoirPourFormat ||
        newPresentoir.value.presentoirMateriel === "" ||
        !newPresentoir.value.presentoirMateriel ||
        newPresentoir.value.presentoirCapacite === "" ||
        !newPresentoir.value.presentoirCapacite ||
        newPresentoir.value.presentoirFormat === "" ||
        !newPresentoir.value.presentoirFormat ||
        newPresentoir.value.presentoirNote === "" ||
        !newPresentoir.value.presentoirNote ||
        newPresentoir.value.presentoirCommandeMinimale === "" ||
        !newPresentoir.value.presentoirCommandeMinimale ||
        !newPresentoir.value.presentoirImage
      ) {
        manqueInfos.value = true;
        errorMessage.value =
          "Veuillez vérifier que tous les champs du formulaires sont bien remplis.";

        return;
      }

      newPresentoir.value.presentoirPrix = parseFloat(
        newPresentoir.value.presentoirPrix
      ).toFixed(2);
      newPresentoir.value.presentoirCommandeMinimale = parseFloat(
        newPresentoir.value.presentoirCommandeMinimale
      ).toFixed(2);
      newPresentoir.value.presentoirStockAvailable = parseInt(
        newPresentoir.value.presentoirStockAvailable
      );

      try {
        const response = await fetch(store.state.addPresentoirUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify(newPresentoir.value),
        });

        const data = await response.json();

        if (response.ok) {
          if (data.success === 1) {
            successAjout.value = true;
            newPresentoir.value = {};
            imageUrl1.value = null;
            imageUrl2.value = null;
            imageUrl3.value = null;
            store.methods.fetchPresentoirs();
          } else {
            console.error(data);
            errorAjout.value = true;
          }
        } else {
          console.error(data);
          errorAjout.value = true;
        }
      } catch (err) {
        console.error(err);
        errorAjout.value = true;
      }
    }

    function maxDecimal(self, count) {
      if (self.indexOf(".") == -1) {
        return;
      }
      if (self.length - self.indexOf(".") > count) {
        self = parseFloat(self).toFixed(count);
      }
    }

    function deletePresentoir() {
      let indexPresentoir = getIndex(currentPresentoir.value);

      let proceed = confirm(`Voulez-vous vraiment supprimer le présentoir :
            - ${store.state.presentoirs[indexPresentoir].presentoirType}
          `);
      if (proceed) {
        fetch(`${store.state.deletePresentoirUrl}/${currentPresentoir.value}`, {
          credentials: "include",
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success === 1) {
              successDelete.value = true;
              indexToDelete.value = null;
              currentPresentoir.value = "choose";

              store.methods.fetchPresentoirs(); // MET A JOUR LES PRESENTOIRS
            } else {
              error.value = true;
            }
          })
          .catch((err) => {
            console.log(err);
            error.value = true;
          });
      }
    }

    async function saveEdit() {
      success.value = false;
      error.value = false;
      manqueChampsUpdate.value = false;

      if (imageUrl1.value) {
        newPresentoir.value.presentoirImage = imageUrl1.value;
      } else if (imageUrl2.value) {
        newPresentoir.value.presentoirImage2 = imageUrl2.value;
      }

      if (imageUrl3.value) {
        newPresentoir.value.presentoirImage3 = imageUrl3.value;
      }

      if (
        newPresentoir.value.presentoirPrix === "" ||
        !newPresentoir.value.presentoirPrix ||
        newPresentoir.value.presentoirType === "" ||
        !newPresentoir.value.presentoirType ||
        newPresentoir.value.presentoirPourFormat === "" ||
        !newPresentoir.value.presentoirPourFormat ||
        newPresentoir.value.presentoirMateriel === "" ||
        !newPresentoir.value.presentoirMateriel ||
        newPresentoir.value.presentoirCapacite === "" ||
        !newPresentoir.value.presentoirCapacite ||
        newPresentoir.value.presentoirFormat === "" ||
        !newPresentoir.value.presentoirFormat ||
        newPresentoir.value.presentoirNote === "" ||
        !newPresentoir.value.presentoirNote ||
        newPresentoir.value.presentoirCommandeMinimale === "" ||
        !newPresentoir.value.presentoirCommandeMinimale ||
        !newPresentoir.value.presentoirImage
      ) {
        manqueChampsUpdate.value = true;
        return;
      }

      newPresentoir.value.presentoirPrix = parseFloat(
        newPresentoir.value.presentoirPrix
      ).toFixed(2);
      newPresentoir.value.presentoirCommandeMinimale = parseFloat(
        newPresentoir.value.presentoirCommandeMinimale
      ).toFixed(2);
      newPresentoir.value.presentoirStockAvailable = parseInt(
        newPresentoir.value.presentoirStockAvailable
      );

      errorEdit.value = false;
      successEdit.value = false;

      try {
        const res = await fetch(store.state.updatePresentoirUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify(newPresentoir.value),
        });

        const data = await res.json();

        if (data.success === 1) {
          successEdit.value = true;

          currentPresentoir.value = "choose";
          store.methods.fetchPresentoirs(); // MET A JOUR LES PRESENTOIR
        } else {
          console.error(data);
          errorEdit.value = true;
        }
      } catch (error) {
        console.error(error);
        errorEdit.value = true;
      }
    }

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    const randomString = (length) => {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    };

    return {
      mode,
      currentPresentoir,
      errorFile,
      newUploadedImage,
      errorMessage,
      selectedForDelete,
      errorDelete,
      store,
      getIndex,
      newPresentoir,
      handleFileUpload,
      uploadNewPresentoir,
      onFileSelected,
      imageUrl1,
      imageUrl2,
      imageUrl3,
      onUploadImage,
      manqueInfos,
      presentoirToEdit,
      saveEdit,
      deletePresentoir,
      successDelete,
      successAjout,
      maxDecimal,
      presentoirToAdd,
      newPresentoirImage,
      toggleMenu,
      imageUrl2Name,
      imageUrl1Name,
      imageUrl3Name,
      randomString,
      selectedFile,
      errorEdit,
      successEdit,
      errorAjout,
      manqueChampsUpdate,
    };
  },
};
</script>
<style lang="scss">
.image-edit-admin {
  width: 200px;
}

.imageUploaded {
  padding: 20px;
  max-height: 200px;
}

.imgPresentoirToDelete {
  max-height: 200px;
}
</style>
