<template>
  <main>
    <TheNavbar />

    <div class="cartes grid grid-cols-1  lg:grid-cols-4  px-10 pt-16">
      <div class="col-span-4 row-start-1 px-5 flex justify-center items-center">
        <div
          class="w-11/12 h-5/6 md:w-6/12 md:h-3/6 border flex justify-center items-center p-3 min-height-merci"
        >
          <div class="flex flex-col items-center">
            <h1 class="text-3xl mb-4 text-center">
              Merci pour votre commande !
            </h1>
            <p class="text-center">
              Un courriel vous sera envoyé lors du traitement de votre commande.
            </p>
          </div>
        </div>
      </div>
    </div>

    <TheFooter />
  </main>
</template>

<script>
import TheNavbar from "../../components/TheNavbar";
import TheFooter from "../../components/TheFooter.vue";
// import { useRoute } from 'vue-router';
import { onMounted, inject } from "vue";

export default {
  name: "Merci",
  components: { TheNavbar, TheFooter },
  setup() {
    const store = inject("store");

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {
      store,
    };
  },
};
</script>
<style lang="scss">
.cartes {
  max-width: 1445px;
  margin: auto;

  #modeBoutique,
  #modeRapide {
    cursor: pointer;
  }

  .min-height-merci {
    min-height: 400px;
  }
}
</style>
