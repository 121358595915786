<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8 ">
        <div class="col-span-3">
          <h2 class="text-3xl uppercase">Gestion des créateurs</h2>
        </div>
        <div class="w-full mt-10 grid grid-cols-1 md:grid-cols-3">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="mode = 'ajouter'"
          >
            Ajouter un créateur
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="
              mode = 'modifier';
              editCreator = {};
              currentCreatorEdit = 'choose';
            "
          >
            Modifier un créateur
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 col-span-3 lg:col-span-1 uppercase"
            @click="mode = 'supprimer'"
          >
            Supprimer un créateur
          </button>
        </div>

        <div v-if="mode === 'modifier'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Modification un créateur</p>
            <div class="relative">
              <select
                v-model="currentCreatorEdit"
                @change="selectCreator()"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option disabled selected value="choose"
                  >Veuillez sélectionner un créateur à modifier</option
                >
                <option
                  v-for="(creator, index) in store.state.creators"
                  :key="index"
                  :value="creator.creator_id"
                  >{{ creator.creator_firstName }}
                  {{ creator.creator_lastName }}</option
                >
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            v-if="currentCreatorEdit !== 'choose'"
            class="grid grid-cols-4 lg:grid-cols-5 gap-y-2 pt-10"
          >
            <p class="flex items-center">Prénom:</p>
            <input
              type="text"
              v-model="editCreator.creator_firstName"
              id="prenomInputMod"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-9/12 col-span-4"
            />
            <p class="flex items-center">Nom :</p>
            <input
              type="text"
              v-model="editCreator.creator_lastName"
              class="py-2 px-2 text-sm border border-blue-lighter rounded  w-9/12 col-span-4"
            />

            <p class="flex items-center whitespace-nowrap">Pourcentage :</p>
            <span
              ><input
                type="number"
                v-model="editCreator.creator_commission"
                id="commissionInputMod"
                class="py-2 px-2 text-center text-sm border border-blue-lighter rounded w-20 col-span-4"
              />
              <p class="pl-1 inline">%</p></span
            >
          </div>

          <button
            class="mt-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase"
            @click="confirmEditCreator()"
          >
            CONFIRMER LES MODIFICATIONS
          </button>
        </div>

        <div v-if="mode === 'ajouter'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Ajouter un créateur</p>

            <div class="grid grid-cols-4 lg:grid-cols-5 gap-y-2 pt-10">
              <p class="flex items-center">Prénom:</p>
              <input
                type="text"
                v-model="newCreator.creator_firstName"
                id="prenomInputAdd"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-9/12 col-span-4 "
              />
              <p class="flex items-center">Nom :</p>
              <input
                type="text"
                v-model="newCreator.creator_lastName"
                class="py-2 px-2 text-sm border border-blue-lighter rounded  w-9/12 col-span-4"
              />
              <p class="flex items-center whitespace-nowrap">Pourcentage :</p>
              <span
                ><input
                  type="number"
                  v-model="newCreator.creator_commission"
                  id="commissionInputAdd"
                  class="py-2 px-2 text-center text-sm border border-blue-lighter rounded w-20 col-span-4"
                />
                <p class="pl-1 inline">%</p></span
              >
            </div>
          </div>

          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase mt-10"
            @click="confirmAddCreator()"
          >
            Ajouter le créateur
          </button>
        </div>

        <div v-if="mode === 'supprimer'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Supprimer un créateur</p>
            <div class="relative">
              <select
                v-model="currentCreatorDelete"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option disabled selected value="choose"
                  >Veuillez sélectionner un créateur à supprimer</option
                >
                <option
                  v-for="(creator, index) in store.state.creators"
                  :key="index"
                  :value="creator.creator_id"
                  >{{ creator.creator_firstName }}
                  {{ creator.creator_lastName }}</option
                >
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase mt-10"
            @click="deleteCreator()"
          >
            Supprimer le créateur
          </button>
        </div>

        <div class="w-full h-10 mt-10"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { ref, onMounted, inject } from "vue";

export default {
  name: "GestionCreateurAdmin",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");
    const selectForUpdate = ref();
    const currentCreatorDelete = ref("choose");
    const currentCreatorEdit = ref("choose");
    const indexCreatorEdit = ref(null);
    const newCreator = ref({});
    const editCreator = ref({});
    const mode = ref(null);
    const successDelete = ref(false);
    const success = ref(false);
    const error = ref(false);
    // const indexOfCreatorToUpdate = ref('Veuillez sélectionner un format');

    function selectCreator() {
      if (currentCreatorEdit.value !== "choose") {
        indexCreatorEdit.value = store.state.creators.findIndex(
          (x) => x.creator_id === currentCreatorEdit.value
        );
        editCreator.value = store.state.creators[indexCreatorEdit.value];
      }
    }

    function confirmAddCreator() {
      let inputPrenomAdd = document.querySelector("#prenomInputAdd");
      let inputCommissionAdd = document.querySelector("#commissionInputAdd");

      if (!newCreator.value.creator_firstName) {
        if (!inputPrenomAdd.classList.contains("border-red-500")) {
          inputPrenomAdd.classList.add("border-red-500");
        }
        return;
      } else {
        if (inputPrenomAdd.classList.contains("border-red-500")) {
          inputPrenomAdd.classList.remove("border-red-500");
        }

        if (!newCreator.value.creator_commission) {
          if (!inputCommissionAdd.classList.contains("border-red-500")) {
            inputCommissionAdd.classList.add("border-red-500");
          }

          return;
        } else {
          if (inputCommissionAdd.classList.contains("border-red-500")) {
            inputCommissionAdd.classList.remove("border-red-500");
          }
        }
      }

      let proceed = confirm(`Confirmer l'ajout du créateur ?`);
      if (proceed) {
        fetch(store.state.addCreatorUrl, {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newCreator.value),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success === 1) {
              success.value = true;
              store.methods.fetchCreators();
              newCreator.value = {};
            } else {
              error.value = true;
            }
          })
          .catch((err) => {
            console.log(err);
            error.value = true;
          });
      }
    }

    function confirmEditCreator() {
      let inputPrenomMod = document.querySelector("#prenomInputMod");
      let inputCommissionMod = document.querySelector("#commissionInputMod");

      if (!editCreator.value.creator_firstName) {
        if (!inputPrenomMod.classList.contains("border-red-500")) {
          inputPrenomMod.classList.add("border-red-500");
        }
        return;
      } else {
        if (inputPrenomMod.classList.contains("border-red-500")) {
          inputPrenomMod.classList.remove("border-red-500");
        }

        if (!editCreator.value.creator_commission) {
          if (!inputCommissionMod.classList.contains("border-red-500")) {
            inputCommissionMod.classList.add("border-red-500");
          }

          return;
        } else {
          if (inputCommissionMod.classList.contains("border-red-500")) {
            inputCommissionMod.classList.remove("border-red-500");
          }
        }
      }

      let proceed = confirm(`Confirmer les modifications pour le créateur ?`);
      if (proceed) {
        editCreator.value.creator_commission = parseFloat(
          editCreator.value.creator_commission
        ).toFixed(2);

        fetch(store.state.updateCreatorUrl, {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(editCreator.value),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success === 1) {
              success.value = true;
              store.methods.fetchCreators();
              editCreator.value = {};
            } else {
              error.value = true;
            }
          })
          .catch((err) => {
            console.log(err);
            error.value = true;
          });
      }
    }

    function deleteCreator() {
      if (currentCreatorDelete.value !== "choose") {
        let indexCreator = store.state.creators.findIndex(
          (x) => x.creator_id === currentCreatorDelete.value
        );

        let proceed = confirm(
          `Voulez-vous vraiment effacer le créateur : ${store.state.creators[indexCreator].creator_firstName} ${store.state.creators[indexCreator].creator_lastName}`
        );
        if (proceed) {
          fetch(
            `${store.state.deleteCreatorUrl}/${currentCreatorDelete.value}`,
            {
              credentials: "include",
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.success === 1) {
                successDelete.value = true;
                currentCreatorDelete.value = "choose";
                store.methods.fetchCreators(); // MET A JOUR LES PRESENTOIRS
              } else {
                console.log("no success");
                error.value = true;
              }
            })
            .catch((err) => {
              console.log(err);
              error.value = true;
            });
        }
      }
    }

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    onMounted(() => {
      if (store.state.creators === null) {
        store.methods.fetchCreators();
      }
    });

    return {
      store,
      selectForUpdate,
      currentCreatorDelete,
      currentCreatorEdit,
      selectCreator,
      mode,
      deleteCreator,
      toggleMenu,
      successDelete,
      error,
      editCreator,
      newCreator,
      confirmAddCreator,
      success,
      confirmEditCreator,
    };
  },
};
</script>
<style lang="scss">
.image-edit-admin {
  width: 200px;
}

.homeAdmin {
  max-width: 1445px;
  margin: auto;
  display: flex;
  justify-content: flex-start;
}
</style>
