<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8">
        <div class="col-pan-3">
          <div class="text-3xl uppercase">
            ENREGISTREMENT D'UN NOUVEAU CLIENT
          </div>
        </div>
        <div class="w-full mt-10 grid grid-cols-1 lg:grid-cols-3">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="mode = 'complete'"
          >
            Compléter un profil client
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="mode = 'representant'"
          >
            Lien d'enregistrement pour représentant
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 col-span-3 lg:col-span-1 uppercase"
            @click="mode = 'client'"
          >
            Lien pour client
          </button>
        </div>

        <div v-if="mode === 'representant'">
          <p class="text-2xl mt-10">Gestion des liens pour représentant.</p>

          <div class="mt-10">
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 lg:mr-10 col-span-3 lg:col-span-1 uppercase"
              @click="modeRep = 'creer'"
            >
              Créer un lien
            </button>
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 col-span-3 lg:col-span-1 uppercase"
              @click="modeRep = 'revoquer'"
            >
              Révoquer un représentant
            </button>
          </div>

          <div v-if="modeRep === 'creer' && mode === 'representant'">
            <p class="text-2xl my-10">Création d'un lien pour représentant.</p>
            <p class="text my-10">
              Sélectionnez un représentant à associer au lien.
            </p>
            <div class="relative w-6/12" v-if="store.state.representants">
              <select
                v-model="currentRepToLink"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
              >
                <option disabled selected value="null"
                  >Veuillez sélectionner un représentant...</option
                >
                <option
                  v-for="(rep, index) in store.state.representants"
                  :key="index"
                  :value="rep.representative_id"
                  >{{ rep.representative_firstName }}
                  {{ rep.representative_lastName }}</option
                >
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>

            <div class="flex justify-start mt-10">
              <div class="mr-10">
                <p class="pb-6">
                  Courriel du représentant pour envoyer le lien
                </p>

                <input
                  type="email"
                  name="email"
                  v-model="emailRep"
                  class="bg-white border px-2 py-1 rounded w-full"
                  placeholder="Courriel"
                />
              </div>
            </div>
            <p class="mt-4">Ce lien est utilisable de façon illimité.</p>
            <p v-if="succesLinkCLient" class="text-green-500 my-4">
              Le lien a été créé et envoyé avec succès.
            </p>
            <p v-if="errorLinkClient" class="text-red-500 my-4">
              Une erreur est survenue pendant la création du lien, veuillez
              recommencer.
            </p>

            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded my-4"
              @click="createKey('rep', emailRep, currentRepToLink)"
            >
              Envoyer le lien d'inscription par courriel
            </button>
          </div>

          <div v-if="modeRep === 'revoquer' && mode === 'representant'">
            <p class="-mb-14 mt-10">Veuillez sélectionner un représentant</p>
            <div class="relative w-6/12 mt-20" v-if="store.state.representants">
              <select
                v-model="currentRepToRevoke"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
              >
                <option disabled selected value="null"
                  >Veuillez sélectionner un représentant...</option
                >
                <option
                  v-for="(rep, index) in store.state.representants"
                  :key="index"
                  :value="rep.representative_id"
                  >{{ rep.representative_firstName }}
                  {{ rep.representative_lastName }}</option
                >
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
            <p v-if="revokeSuccess" class="text-green-500 my-4">
              Tous les liens pour ce représentant sont désactivés.
            </p>
            <p v-if="revokeError" class="text-red-500 my-4">
              Une erreur est survenue.
            </p>
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded my-4"
              @click="cancelRep()"
            >
              Révoquer les liens pour ce représentant
            </button>
          </div>
        </div>

        <div v-if="mode === 'client'">
          <p class="text-2xl my-10">Envoi d'un lien pour client.</p>
          <p class="text my-10">
            Sélectionnez un représentant à associer au client.
          </p>
          <div class="relative w-6/12" v-if="store.state.representants">
            <select
              v-model="currentRepForClient"
              class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
            >
              <option disabled selected value="null"
                >Veuillez sélectionner un représentant...</option
              >
              <option
                v-for="(rep, index) in store.state.representants"
                :key="index"
                :value="rep.representative_id"
                >{{ rep.representative_firstName }}
                {{ rep.representative_lastName }}</option
              >
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg
                class="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>

          <div class="flex justify-start mt-10">
            <div class="mr-10">
              <p class="pb-6">Courriel du client pour faire l'enregistrement</p>

              <input
                type="email"
                name="email"
                v-model="emailClient"
                class="bg-white border px-2 py-1 rounded w-full"
                placeholder="Courriel"
              />
            </div>
          </div>

          <p v-if="succesLinkCLient" class="text-green-500 my-4">
            Le lien a été créé et envoyé avec succès.
          </p>
          <p v-if="errorLinkClient" class="text-red-500 my-4">
            Une erreur est survenue pendant la création du lien, veuillez
            recommencer.
          </p>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded my-4"
            @click="createKey('client', emailClient, currentRepForClient)"
          >
            Envoyer le lien d'inscription par courriel
          </button>
        </div>

        <div v-if="mode === 'complete'" class="enregistrementClient">
          <p class="text-2xl my-10">Enregistrement d'un nouveau client</p>

          <div class="relative md:w-6/12" v-if="store.state.representants">
            <select
              v-model="currentRep"
              class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 "
            >
              <option disabled selected value="null"
                >Veuillez sélectionner un représentant...</option
              >
              <option
                v-for="(rep, index) in store.state.representants"
                :key="index"
                :value="rep.representative_id"
                >{{ rep.representative_firstName }}
                {{ rep.representative_lastName }}</option
              >
            </select>

            <div class="grid grid-cols-1 wrapperInput w-full selectClient">
              <p class="pt-12 pb-4 text-left">
                Veuillez sélectionner le client dans la liste.
              </p>

              <div class="relative" v-if="clientsListOk">
                <input
                  :disabled="inputClientDisabled"
                  v-model="selectClient"
                  @change="getClientsInfos()"
                  placeholder="Sélectionnez un client"
                  type="text"
                  list="produit"
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                />
                <datalist id="produit">
                  <option
                    v-for="(client, index) in clientsListHarvest"
                    :key="index"
                    >{{ client.name }}</option
                  >
                </datalist>
              </div>

              <div>
                <label class="inline-flex items-center wrapperInput mt-6">
                  <input
                    type="checkbox"
                    class="form-checkbox text-black"
                    v-model="ifClientExist"
                    id="checkifShipping"
                    @click="clearSelectedClient()"
                  />
                  <span class="ml-2 pl-4 mb-4"
                    >Le client ne se trouve pas dans la liste.</span
                  >
                </label>
              </div>
            </div>
            <div class="grid grid-cols-1 wrapperInput">
              <p class="pt-12 pb-4 text-left">
                Veuillez compléter les champs nécessaires afin de compléter
                l'enregistrement.
              </p>

              <input
                class="mt-1 h-10 px-3 mb-2"
                type="text"
                placeholder="Nom du commerce"
                v-model="infosClient.compagnyName"
                maxlength="70"
              />
              <input
                class="mt-1 h-10 px-3 mb-2"
                type="text"
                placeholder="Nom de la personne contact"
                v-model="infosClient.contact"
                maxlength="70"
              />
              <input
                class="mt-1 h-10 px-3 mb-2"
                type="email"
                placeholder="Courriel"
                v-model="infosClient.email"
                maxlength="70"
              />
              <InputMask
                mask="(999) 999-9999? x99999"
                v-model="infosClient.phone"
                type="text"
                placeholder="(999) 999-9999"
                maxlength="20"
                class="mt-1 h-10 px-3 mb-2"
              />
            </div>
            <div class="grid grid-cols-1 wrapperInput mt-6">
              <p class="pb-4 font-weight-700">ADRESSE DE FACTURATION</p>
              <input
                class="mt-1 h-10 px-3 mb-2"
                type="text"
                placeholder="No civique"
                v-model="infosClient.billingAdressNumber"
                maxlength="70"
              />
              <input
                class="mt-1 h-10 px-3 mb-2"
                type="text"
                placeholder="Rue"
                v-model="infosClient.billingStreet"
                maxlength="60"
              />
              <input
                class="mt-1 h-10 px-3 mb-2"
                type="text"
                placeholder="Code postal"
                v-model="infosClient.billingPostalCode"
                maxlength="20"
              />
              <input
                class="mt-1 h-10 px-3 mb-2"
                type="text"
                placeholder="Ville"
                v-model="infosClient.billingCity"
                maxlength="60"
              />
              <input
                class="mt-1 h-10 px-3 mb-2"
                type="text"
                placeholder="Province"
                v-model="infosClient.billingProvince"
                maxlength="70"
              />
              <input
                class="mt-1 h-10 px-3 mb-2"
                type="text"
                placeholder="Pays"
                v-model="infosClient.billingPays"
                maxlength="70"
              />
              <div>
                <label class="inline-flex items-center wrapperInput mt-6">
                  <input
                    type="checkbox"
                    class="form-checkbox text-black"
                    v-model="infosClient.shippingIsBilling"
                    id="checkifShipping"
                  />
                  <span class="ml-2 pl-4 mb-4"
                    >Utiliser l'adresse de facturation comme adresse de
                    livraison</span
                  >
                </label>
              </div>

              <div
                class="grid grid-cols-1 wrapperInput my-6"
                v-if="!infosClient.shippingIsBilling"
              >
                <p class="pb-4 font-weight-700">ADRESSE DE LIVRAISON</p>
                <input
                  class="mt-1 h-10 px-3 mb-2"
                  type="text"
                  placeholder="No civique"
                  v-model="infosClient.shippingAdressNumber"
                  maxlength="70"
                />
                <input
                  class="mt-1 h-10 px-3 mb-2"
                  type="text"
                  placeholder="Rue"
                  v-model="infosClient.shippingStreet"
                  maxlength="60"
                />
                <input
                  class="mt-1 h-10 px-3 mb-2"
                  type="text"
                  placeholder="Code postal"
                  v-model="infosClient.shippingPostalCode"
                  maxlength="20"
                />
                <input
                  class="mt-1 h-10 px-3 mb-2"
                  type="text"
                  placeholder="Ville"
                  v-model="infosClient.shippingCity"
                  maxlength="60"
                />
                <input
                  class="mt-1 h-10 px-3 mb-2"
                  type="text"
                  placeholder="Province"
                  v-model="infosClient.shippingProvince"
                  maxlength="70"
                />
                <input
                  class="mt-1 h-10 px-3 mb-2"
                  type="text"
                  placeholder="Pays"
                  v-model="infosClient.shippingPays"
                  maxlength="70"
                />
              </div>
            </div>

            <div class="wrapperInput grid grid-cols-1 w-full">
              <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded my-4"
                @click="generatePass()"
              >
                Générer un mot de passe
              </button>
              <input
                class="mt-1 h-10 px-3 mb-2"
                @change="checkPassMatch()"
                id="pass1"
                type="password"
                placeholder="Mot de passe"
                v-model="infosClient.password1"
              />
              <input
                class="mt-1 h-10 px-3 mb-2"
                @change="checkPassMatch()"
                id="pass2"
                type="password"
                placeholder="Confirmer mot de passe"
                v-model="infosClient.password2"
              />
              <p v-if="!checkPassMatch()" class="text-red-500 text-center">
                Les mots de passes ne concordent pas.
              </p>
              <p v-if="needMoreCar" class="text-red-500 text-center">
                Le mot de passe a besoin d'un minimum de 7 caracthères.
              </p>
              <p v-if="errorCreate" class="text-red-500 text-center">
                Une erreur est survenue pendant la création du client.
              </p>
              <p v-if="courrielUtilise" class="text-red-500 text-center">
                Ce courriel est déjà été utilisé par un client, veuillez en
                inscrire un autre.
              </p>
              <p v-if="clientExisteHarvest" class="text-red-500 text-center">
                Ce client existe déja dans les clients existants sur harvest..
              </p>
              <p v-if="successAddClient" class="text-green-500 text-center">
                Le client a été créé avec succès.
              </p>

              <button
                :disabled="needInfos"
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded my-4"
                @click="createClient()"
              >
                Enregistrer le client
              </button>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { inject, onMounted, ref, reactive } from "vue";
import InputMask from "primevue/inputmask";

export default {
  name: "EnregistrementAdmin",
  components: { AdminSideBar, InputMask },
  setup() {
    const store = inject("store");
    const currentRep = ref(null);
    const emailClient = ref(null);
    const mode = ref(null);
    const modeRep = ref(null);
    const error = ref(true);
    const needMoreCar = ref(false);
    let infosClient = reactive({
      shippingIsBilling: true,
      billingPays: "Canada",
      shippingPays: "Canada",
    });
    const needInfos = ref(false);
    const minCar = 7;
    const clientsListHarvest = ref([null]);
    const clientsListOk = ref(false);
    const selectClient = ref(null);
    const ifClientExist = ref(false);
    const inputClientDisabled = ref(false);
    const errorCreate = ref(false);
    const successAddClient = ref(false);
    const courrielUtilise = ref(false);
    const clientExisteHarvest = ref(false);
    const emailRep = ref(null);
    const currentRepToLink = ref(null);
    const currentRepForClient = ref(null);
    const currentRepToRevoke = ref(null);
    const revokeSuccess = ref(false);
    const revokeError = ref(false);
    const succesLinkCLient = ref(false);
    const errorLinkClient = ref(false);

    let clientIdHarvest = null;

    function buildPass(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }

    function generatePass() {
      const password = buildPass(7);
      var pass1 = document.getElementById("pass1");
      var pass2 = document.getElementById("pass2");

      pass1.type = "text";
      pass2.type = "text";

      infosClient.password1 = password;
      infosClient.password2 = password;
    }

    onMounted(() => {
      if (!store.state.allClientsInfos) {
        store.methods.getAllClientsInfos();
      }

      if (!store.state.representants) {
        store.methods.fetchRepresentants();
      }

      getClientsHarvest(0);
    });

    async function createClient() {
      store.state.loadingCreateClient = true;
      successAddClient.value = false;
      errorCreate.value = false;
      courrielUtilise.value = false;
      clientExisteHarvest.value = false;
      store.state.tempInfosClientEnregistrement = JSON.parse(
        JSON.stringify(infosClient)
      );
      let clientExist = false;

      const pass = infosClient.password1;
      infosClient.password = pass;

      infosClient.representativeId = currentRep.value;

      if (clientIdHarvest === null && ifClientExist.value === false) {
        alert(
          "Vérifier de sélectionner le client dans la liste ou cocher qu'il n'existe pas."
        );
        return;
      }

      if (
        infosClient.representativeId === null ||
        !infosClient.compagnyName ||
        !infosClient.contact ||
        !infosClient.email ||
        !infosClient.billingAdressNumber ||
        !infosClient.phone ||
        !infosClient.billingStreet ||
        !infosClient.billingPostalCode ||
        !infosClient.billingCity ||
        !infosClient.billingProvince ||
        !infosClient.billingPays
      ) {
        alert("Des informations sont manquantes...");
        return;
      }

      if (!infosClient.shippingIsBilling) {
        if (
          !infosClient.shippingAdressNumber ||
          !infosClient.shippingStreet ||
          !infosClient.shippingPostalCode ||
          !infosClient.shippingCity ||
          !infosClient.shippingProvince ||
          !infosClient.shippingPays
        ) {
          alert("Des informations sont manquantes...");
          return;
        }
      }

      // VERIFICATION DE L'EXISTANCE DU CLIENT

      store.state.allClientsInfos.forEach((client) => {
        // PAR NOM DE COMPANGNIE
        if (
          client.customer_compagnyName
            .toLowerCase()
            .includes(infosClient.compagnyName.toLowerCase())
        ) {
          let proceed = confirm(
            `Un client avec le nom ${client.customer_compagnyName} exite déja, voulez-vous quand-même faire l'ajout du client ?`
          );
          if (!proceed) {
            clientExist = true;
          }
        }

        // PAR COURRIEL
        if (client.customer_email === infosClient.email) {
          clientExist = true;
          alert(
            `Un client ayant déja le même courriel existe déja, veuillez vérifier que le client n'existe déja... `
          );
          return;
        }

        // PAR ID HARVEST
        if (clientIdHarvest) {
          if (client.customer_harvestId == clientIdHarvest) {
            let proceed = confirm(
              `Un ou plusieurs clients sont associé(s) aux compte client que vous avez sélectionné, voulez-vous quand-même faire l'ajout du client ?`
            );
            if (!proceed) {
              clientExist = true;
            }
          }
        }

        // PAR COURRIEL
        if (client.customer_email === infosClient.email) {
          clientExist = true;
          alert(
            `Un client ayant déja le même courriel existe déja, veuillez vérifier que le client n'existe déja... `
          );
          return;
        }
      });

      if (!clientExist) {
        if (clientIdHarvest) {
          infosClient.idHarvest = clientIdHarvest;
          pushClientExtranet();
        } else {
          const adresse = `${infosClient.billingAdressNumber}, ${
            infosClient.billingStreet
          }, \r\n${infosClient.billingCity}, ${
            infosClient.billingProvince
          } \r\n${infosClient.billingPostalCode.toUpperCase()}`;

          const infosForHarvest = {
            name: infosClient.compagnyName,
            currency: "CAD",
            is_active: true,
            address: adresse,
          };

          try {
            const res = await fetch(process.env.VUE_APP_HARVEST_URL, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Harvest-Account-Id": process.env.VUE_APP_HARVEST_ACOUNT_ID,
                Authorization: "Bearer " + process.env.VUE_APP_TOKEN_HARVEST,
              },
              body: JSON.stringify(infosForHarvest),
            });

            if (!res.ok) {
              console.log("error");
              errorCreate.value = true;
            } else {
              let data = await res.json();

              infosClient.idHarvest = data.id;
              pushClientExtranet();
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
    }

    async function cancelRep() {
      let proceed = confirm(
        `Voulez-vous vraiment révoquer le ou les liens pour ce représentant ?`
      );

      if (proceed) {
        try {
          revokeSuccess.value = false;
          revokeError.value = false;

          const res = await fetch(
            `${store.state.revokeRepLinksUrl}/${currentRepToRevoke.value}`,
            {
              method: "get",
              headers: {
                "content-type": "application/json",
              },
              credentials: "include",
            }
          );

          if (!res.ok) {
            console.log("un problème est survenu.");
            revokeError.value = true;
          } else {
            const data = await res.json();

            if (data.success === 1) {
              revokeSuccess.value = true;
            } else {
              revokeError.value = true;
            }
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        return;
      }
    }

    function checkPassMinLength() {
      if (infosClient.password1 && infosClient.password2) {
        if (
          infosClient.password1.length >= minCar &&
          infosClient.password2.length >= minCar
        ) {
          needMoreCar.value = false;
        } else {
          needMoreCar.value = true;
        }
      }
    }

    function clearSelectedClient() {
      selectClient.value = null;

      if (inputClientDisabled.value) {
        inputClientDisabled.value = false;
      } else {
        inputClientDisabled.value = true;
        infosClient.compagnyName = null;
        clientIdHarvest = null;
      }
    }

    function checkPassMatch() {
      if (infosClient.password1 !== infosClient.password2) {
        return false;
      } else {
        checkPassMinLength();
        return true;
      }
    }

    function getClientsInfos() {
      if (selectClient.value) {
        infosClient.compagnyName = selectClient.value;
        let indexOfSelectClient = clientsListHarvest.value.findIndex(
          (client) => client.name === selectClient.value
        );
        if (indexOfSelectClient !== -1) {
          clientIdHarvest = clientsListHarvest.value[indexOfSelectClient].id;
        } else {
          infosClient.compagnyName = null;
        }
      }
    }

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    async function pushClientExtranet() {
      try {
        let response = await fetch(`${store.state.createClientAdminUrl}`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(infosClient),
          credentials: "include",
        });

        if (!response.ok) {
          console.log("Erreur");
        }

        let data = await response.json();

        if (data.success === 1 && data.data !== 80) {
          successAddClient.value = true;
          infosClient = {
            shippingIsBilling: true,
            billingPays: "Canada",
            shippingPays: "Canada",
          };
        } else if (data.success === 1 && data.data == 80) {
          errorCreate.value = true;
          courrielUtilise.value = true;
        } else {
          errorCreate.value = true;
        }
        store.state.loadingCreateClient = false;
        return data;
      } catch (err) {
        console.log(err);
      }
    }

    async function getClientsHarvest(pageCount) {
      let numPageToReq;
      if (pageCount === 0) {
        numPageToReq = "";
      } else {
        numPageToReq = `?page=${pageCount}`;
      }

      try {
        const res = await fetch(
          `${process.env.VUE_APP_HARVEST_URL}${numPageToReq}`,
          {
            method: "GET",
            headers: {
              "Harvest-Account-Id": process.env.VUE_APP_HARVEST_ACOUNT_ID,
              Authorization: "Bearer " + process.env.VUE_APP_TOKEN_HARVEST,
            },
          }
        );
        if (!res.ok) {
          console.log("un problème est survenu.");
        } else {
          const data = await res.json();
          let newListingClient;
          if (pageCount === 0) {
            clientsListHarvest.value = data.clients;
          } else {
            newListingClient = clientsListHarvest.value.concat(data.clients);
            clientsListHarvest.value = newListingClient;
          }

          if (data.page === data.total_pages) {
            // SORT BY NAME ASC
            clientsListHarvest.value.sort((a, b) =>
              a.name > b.name ? 1 : b.name > a.name ? -1 : 0
            );
            clientsListOk.value = true;
          } else {
            getClientsHarvest(pageCount + 1);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    async function createKey(typeKey, email, rep) {
      let url;

      if (!typeKey || !email || rep == null || rep == undefined) {
        // CODE ERREUR
        console.log("erreur");
        return;
      }

      if (typeKey === "rep") {
        url = store.state.getCreateKeyRepUrl;
      } else if (typeKey === "client") {
        url = store.state.getCreateKeyUrl;
      }

      const infos = {
        email: email,
        repId: rep,
      };

      succesLinkCLient.value = false;
      errorLinkClient.value = false;

      try {
        const res = await fetch(`${url}`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(infos),
          credentials: "include",
        });

        if (!res.ok) {
          console.log("un problème est survenu.");
          errorLinkClient.value = true;
        } else {
          const data = await res.json();
          if (data.success === 1) {
            succesLinkCLient.value = true;
          } else {
            errorLinkClient.value = true;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    return {
      store,
      toggleMenu,
      currentRep,
      revokeSuccess,
      revokeError,
      succesLinkCLient,
      errorLinkClient,
      buildPass,
      createKey,
      emailRep,
      currentRepToLink,
      currentRepForClient,
      currentRepToRevoke,
      generatePass,
      emailClient,
      getClientsInfos,
      checkPassMatch,
      getClientsHarvest,
      clearSelectedClient,
      createClient,
      pushClientExtranet,
      cancelRep,
      errorCreate,
      successAddClient,
      courrielUtilise,
      clientExisteHarvest,
      mode,
      modeRep,
      error,
      needMoreCar,
      infosClient,
      needInfos,
      minCar,
      clientsListHarvest,
      clientsListOk,
      selectClient,
      ifClientExist,
      inputClientDisabled,
      clientIdHarvest,
    };
  },
};
</script>
<style scoped lang="scss">
.enregistrementClient {
  display: block;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: rgba(255, 255, 255, 1);
    transition: background-color 5000s ease-in-out 0s;
  }
  input::-webkit-input-placeholder,
  input::-ms-input-placeholder,
  input:-ms-input-placeholder {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
  }

  input {
    color: #7a7a7a;
    border: 1px solid #dedede;
    outline-width: 0;
  }

  button:hover {
    background-color: #bba267;
  }
}

.wrapperInput {
  max-width: 400px;
  min-width: 250px;
}

#checkifShipping {
  transform: translateY(-8px);
  cursor: pointer;
}

.selectClient {
  margin-top: -20px;

  .check {
    cursor: pointer;
    position: relative;
    margin: auto;
    width: 18px;
    height: 18px;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
    p {
      width: 400px;
      transform: translate(28px, 21px);
    }
  }

  .check svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #c8ccd4;
    stroke-width: 1.5;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
  }
  .check svg path {
    stroke-dasharray: 60;
    stroke-dashoffset: 0;
  }
  .check svg polyline {
    stroke-dasharray: 22;
    stroke-dashoffset: 66;
  }
  .check:hover:before {
    opacity: 1;
  }
  .check:hover svg {
    stroke: #222;
  }
  #cbx:checked + .check svg {
    stroke: #222;
  }
  #cbx:checked + .check svg path {
    stroke-dashoffset: 60;
    transition: all 0.3s linear;
  }
  #cbx:checked + .check svg polyline {
    stroke-dashoffset: 42;
    transition: all 0.2s linear;
    transition-delay: 0.15s;
  }
}
</style>
