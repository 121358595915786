<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="/admin" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <!-- DEBUT DU MODAL -->

      <div
        class="hidden overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center "
        id="modal-id"
      >
        <div
          class="relative my-6 mx-auto max-w-6xl w-11/12"
          v-if="selectForUpdate"
        >
          <!--content-->
          <div
            class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
          >
            <!--header-->
            <div
              class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
            >
              <div class="flex flex-col md:flex-row md:justify-between w-10/12">
                <h3 class="font-semibold text-lg">
                  CLIENT:<span class="pl-10">
                    {{ selectForUpdate.customer_compagnyName }} (
                    {{ selectForUpdate.customer_contact }} )</span
                  >
                </h3>
              </div>
              <div class="flex flex-col md:flex-row md:justify-between w-10/12">
                <h4 class="font-semibold text-lg">
                  {{ selectForUpdate.customer_email }}
                </h4>
              </div>

              <button
                class="p-1 ml-auto border-0 text-black opacity-6 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                @click="toggleModal('modal-id')"
              >
                <span
                  class="text-black h-6 w-6 text-2xl block outline-none focus:outline-none"
                >
                  x
                </span>
              </button>
            </div>
            <!--body-->
            <div class="relative p-6 grid md:grid-cols-2">
              <!-- GESTION MOT DE PASSE START HERE -->
              <div v-if="selectForUpdate" class="col-span-3">
                <div class="md:mt-8 grid grid-cols-1 md:grid-cols-2 ">
                  <div class="ml-6">
                    <p class="mt-4">Nouveau mot de passe :</p>
                    <input
                      id="pass1"
                      class="mt-2 h-10 px-3 mb-2  border w-11/12"
                      type="password"
                      placeholder="Nouveau mot de passe"
                      v-model="newPassword.newPassword1"
                    />
                    <p class="mt-4">Confirmer le nouveau mot de passe :</p>
                    <input
                      id="pass2"
                      class="mt-2 h-10 px-3 mb-2  border w-11/12"
                      type="password"
                      placeholder="Confirmer le nouveau mot de passe"
                      v-model="newPassword.newPassword2"
                    />
                  </div>
                </div>
              </div>

              <p class="text-red-400 py-4 text-lg font-bold ml-6" v-if="erreur">
                Une erreur est survenue pendant les modifications.
              </p>
              <p
                class="text-red-400 py-4 text-lg font-bold ml-6"
                v-if="minLength"
              >
                Le mot de passe doit contenir au moins 7 caractères.
              </p>
              <p
                class="text-red-400 py-4 text-lg font-bold ml-6"
                v-if="dontMatch"
              >
                Les mots de passe ne correspondent pas.
              </p>
              <p class="text-red-400 py-4 text-lg font-bold ml-6" v-if="erreur">
                {{ erreur }}
              </p>
            </div>
            <div class="wrapperInput grid grid-cols-1 w-full">
              <button
                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded my-4 ml-12"
                @click="generatePass()"
              >
                Générer un mot de passe
              </button>
            </div>
            <!--footer-->
            <div
              class="flex justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
            >
              <button
                class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="toggleModal('modal-id')"
              >
                Fermer
              </button>
              <button
                class="bg-emerald-500 text-green-400 active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                @click="changePassword()"
              >
                Modifier le mot de passe
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- FIN DU MODAL -->
      <div class="col-span-5 p-8">
        <div class="col-pan-3">
          <div class="text-3xl uppercase">
            Gestion mot de passe client
          </div>
        </div>

        <div
          class="grid grid-cols-10 gap-y-2 pt-10 "
          v-if="store.state.allClientsInfos"
        >
          <input
            v-model="filterClients"
            class="mr-4 mt-4 bg-gray-200 appearance-none border-2 border-gray-200  rounded w-full lg:w-8/12 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 col-span-10"
            id="inline-full-name"
            type="text"
            placeholder="RECHERCHE PAR NOM DE COMPAGNIE / COURRIEL / TEL"
          />

          <div class="col-span-10">
            <p class="text-green-700 py-4 text-lg font-bold" v-if="success">
              Les modifications ont étés effectuées avec succès.
            </p>
          </div>

          <p class="my-6 text-semibold" v-if="nbrResultatsRecherche !== null">
            Résultat<span v-if="nbrResultatsRecherche > 1">s</span>
            {{ nbrResultatsRecherche }}
          </p>

          <div
            class="col-span-10 grid grid-cols-6 py-2 px-4  mt-4 shadow rounded-sm overflow-hidden items_gestion_commande"
            v-for="(client, index) in searchClients()"
            :key="index"
          >
            <div
              class="col-span-2 font-semibold"
              @click="toggleModal('modal-id', client.customer_id)"
            >
              {{ client.customer_compagnyName }}
            </div>
            <div class="col-span-2">
              {{ client.customer_email }}
            </div>
            <div class="col-span-2">
              {{ client.customer_phone }}
            </div>
          </div>
        </div>

        <button
          class="mt-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase"
          @click="confirmEditSettings()"
        >
          CONFIRMER LES MODIFICATIONS
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { inject, onMounted, ref } from "vue";

export default {
  name: "MotDePasse",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");
    const success = ref(false);
    const erreur = ref(false);
    const filterClients = ref(null);
    const nbrResultatsRecherche = ref(null);
    const modalIsOpen = ref(false);
    const selectForUpdate = ref(null);
    const newPassword = ref({});
    const needMoreCar = ref(false);
    const minCar = 7;
    const dontMatch = ref(false);
    const minLength = ref(false);

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    function searchClients() {
      // SEARCH BY COMPAGNY NAME

      let filteredClients = [];
      if (filterClients.value) {
        if (filterClients.value.length > 2) {
          store.state.allClientsInfos.forEach((client) => {
            const filter = filterClients.value.toLowerCase();
            const compagnyName = client.customer_compagnyName.toLowerCase();
            const email = client.customer_email.toLowerCase();
            const tel = client.customer_phone.toLowerCase();
            if (
              compagnyName.includes(filter) ||
              email.includes(filter) ||
              tel.includes(filter)
            ) {
              filteredClients.push(client);
            }
          });
        } else {
          filteredClients = [];
        }
      } else {
        filteredClients = [];
      }

      // COUNT NUMBER OF RESULTS
      if (filterClients.value) {
        if (filterClients.value.length < 3) {
          nbrResultatsRecherche.value = null;
        } else {
          nbrResultatsRecherche.value = filteredClients.length;
        }
      }

      return filteredClients;
    }

    function toggleModal(modalID, customerId) {
      modalIsOpen.value = !modalIsOpen.value;

      if (modalIsOpen.value) {
        erreur.value = false;
        success.value = false;
        newPassword.value = {
          newPassword1: null,
          newPassword2: null,
        };
        let indexClient = store.state.allClientsInfos.findIndex(
          (client) => client.customer_id == customerId
        );

        if (indexClient !== -1) {
          selectForUpdate.value = store.state.allClientsInfos[indexClient];
        }
      } else {
        selectForUpdate.value = null;
      }

      document.getElementById(modalID).classList.toggle("flex");
      document.getElementById(modalID).classList.toggle("hidden");
    }

    function checkPassMatch(pass1, pass2) {
      if (pass1 !== pass2) {
        return false;
      } else {
        checkPassMinLength(pass1, pass2);
        return true;
      }
    }

    function checkPassMinLength(pass1, pass2) {
      if (pass1 && pass2) {
        if (pass1.length >= minCar && pass2.length >= minCar) {
          return true;
        } else {
          return false;
        }
      }
    }

    async function makePasswordChange(pass) {
      erreur.value = false;
      success.value = false;

      const infosChangePass = {
        password: pass,
        user_id: selectForUpdate.value.customer_userId,
      };

      try {
        let response = await fetch(`${store.state.changePasswordOfUserUrl}`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify(infosChangePass),
          credentials: "include",
        });

        if (!response.ok) {
          console.log("Erreur");
        }

        let data = await response.json();

        if (data.success === 1) {
          if (!data.data.error) {
            success.value = true;
            toggleModal("modal-id", null);
          } else {
            erreur.value = data.data.message;
          }
        } else {
          erreur.value = "Un problème est survenue.";
        }
      } catch (err) {
        console.log(err);
      }
    }

    function buildPass(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    }

    function changePassword() {
      minLength.value = false;
      dontMatch.value = false;

      const pass1 = newPassword.value.newPassword1;
      const pass2 = newPassword.value.newPassword2;

      if (checkPassMinLength(pass1, pass2)) {
        if (checkPassMatch(pass1, pass2)) {
          makePasswordChange(pass1);
        } else {
          dontMatch.value = true;
        }
      } else {
        minLength.value = true;
      }
    }

    function generatePass() {
      const password = buildPass(7);
      var pass1 = document.getElementById("pass1");
      var pass2 = document.getElementById("pass2");

      pass1.type = "text";
      pass2.type = "text";

      newPassword.value.newPassword1 = password;
      newPassword.value.newPassword2 = password;
    }

    onMounted(() => {
      if (!store.state.allClientsInfos) {
        store.methods.getAllClientsInfos();
      }
    });

    return {
      store,
      toggleMenu,
      searchClients,
      filterClients,
      nbrResultatsRecherche,
      checkPassMinLength,
      toggleModal,
      modalIsOpen,
      selectForUpdate,
      newPassword,
      checkPassMatch,
      needMoreCar,
      changePassword,
      success,
      erreur,
      makePasswordChange,
      minCar,
      minLength,
      dontMatch,
      buildPass,
      generatePass,
    };
  },
};
</script>
<style lang="scss"></style>
