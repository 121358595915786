<template>
  <div class="home relative hauteurmin">
    <img
      src="@/assets/images/Mimosa-design-logo-small.png"
      alt="Mimosa Design"
      width="274"
      height="65"
      id="logo-mimosa"
    />
    <div class="mt-14 pb-10">
      <p class="uppercase  text-xs link">Réinitialiser votre mot de passe.</p>
    </div>
    <input
      class="h-10 px-3 mb-2"
      type="email"
      placeholder="Courriel"
      v-model="email"
    />

    <button
      class="h-10 px-5 m-2 mb-7 text-white text-sm transition-colors duration-150 bg-black focus:shadow-outline uppercase"
      @click="requestNewPass()"
    >
      Réinitialiser
    </button>

    <div v-if="message">
      <p class="text-red-400">
        Un problème est survenu, veuillez vérifier que votre couriel est valide.
      </p>
    </div>
    <div v-if="messageOk">
      <p>Un lien vous a été envoyé par courriel</p>
    </div>

    <div class="absolute bottom-10 text-sm">
      © {{ store.methods.getCurrentYear() }}. Mimosa Design - Extranet. Tous
      droits réservés.
    </div>
  </div>
</template>

<script>
import { inject, onMounted, ref } from "vue";

export default {
  name: "ResetPassword",
  setup() {
    const email = ref(null);
    const message = ref(false);
    const messageOk = ref(false);
    const store = inject("store");

    async function requestNewPass() {
      if (!email.value) {
        message.value = "Veuillez remplir le champs courriel.";
        return;
      } else {
        message.value = false;
      }

      let formatedData = {
        email: email.value.toLowerCase(),
      };

      try {
        const res = await fetch(`${store.state.forgotPasswordUrl}`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(formatedData),
        });

        // a non-200 response code
        if (!res.ok) {
          message.value = true;
        } else {
          const json = await res.json();

          if (json.success === 1) {
            email.value = null;
            messageOk.value = true;
          } else {
            message.value = true;
          }
        }
      } catch (err) {
        message.value = true;
      }
    }

    onMounted(() => {
      window.scrollTo(0, 0);
    });

    return {
      email,
      requestNewPass,
      message,
      messageOk,
    };
  },
};
</script>
<style lang="scss">
.hauteurmin {
  min-height: 500px;
}

.cartes {
  max-width: 1445px;
  margin: auto;

  #modeBoutique,
  #modeRapide {
    cursor: pointer;
  }
}
</style>
