<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="/admin" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8">
        <div class="col-pan-3">
          <div class="text-3xl uppercase">
            Gestion affichage des prix de reventes
          </div>
        </div>

        <div
          class="grid grid-cols-10 gap-y-2 pt-10"
          v-if="store.state.prixRevente"
        >
          <div class="col-span-10">
            <p class="flex items-center">
              Collection Illustrative naïves > Format régulier (5.25 x 5.25 po)
            </p>
          </div>

          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.carteReg525"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10">
            <p class="flex items-center">
              Collection Illustrative naïves > Format mini (3 x 3 po)
            </p>
          </div>
          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.carteReg3"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10">
            <p class="flex items-center">
              Collection Illustrative naïves > Format allongé ( 3.75 x 9.125 po
              )
            </p>
          </div>

          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.carteReg525"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10">
            <p class="flex items-center">
              Collection Illustrative naïves > Format Géant (8,75 x 11,75 po)
            </p>
          </div>

          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.carteBig"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10">
            <p class="flex items-center">Collection à planter</p>
          </div>
          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.aPlanter"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>
          <div class="col-span-10">
            <p class="flex items-center">Collection une touche de doré</p>
          </div>
          <div class="col-span-10">
            <input
              type="text"
              v-model="store.state.prixRevente.toucheDore"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10 pt-12">
            <p class="flex items-center">Planificateur de repas</p>
          </div>
          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.planificateurRepas"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10">
            <p class="flex items-center">Cahier de notes</p>
          </div>
          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.cahierDeNotes"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10">
            <p class="flex items-center">Bloc-notes</p>
          </div>
          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.blocNotes"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10 pt-8">
            <p class="flex items-center">
              Coffrets Méli-Mélo - Collection Illustrative naïves
            </p>
          </div>
          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.melimeloNaive"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10">
            <p class="flex items-center">
              Coffrets Méli-Mélo - Collection à planter
            </p>
          </div>
          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.melimeloAplanter"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>
          <!-- <div class="col-span-10">
             <p class="flex items-center">
              Coffrets collection ensemencées</p>
          </div>
          <div class="col-span-10 mb-4">
            <input type="text" v-model="store.state.prixRevente.coffretEns"  class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12">
          </div> -->
          <!-- <div class="col-span-10">
             <p class="flex items-center">
              Coffrets collection une touche de doré</p>
          </div>
          <div class="col-span-10 mb-4">
            <input type="text" v-model="store.state.prixRevente.coffretToucheDore"  class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12">
          </div>
            <div class="col-span-10">
             <p class="flex items-center">
              Coffrets cartes régulières</p>
          </div>
          <div class="col-span-10 mb-4">
            <input type="text" v-model="store.state.prixRevente.coffretReg"  class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12">
          </div> -->

          <!-- AFFICHES -->
          <div class="col-span-10 pt-8">
            <p class="flex items-center">Affiches</p>
          </div>

          <div class="col-span-10">
            <p class="flex items-center">Affiche - Format 5" X 7"</p>
          </div>
          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.affiche5X7"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10">
            <p class="flex items-center">Affiche - Format 9" X 9"</p>
          </div>
          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.affiche9X9"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10">
            <p class="flex items-center">Affiche - Format 8" X 10"</p>
          </div>
          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.affiche8X10"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10">
            <p class="flex items-center">Affiche - Format 12" X 16"</p>
          </div>
          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.affiche12X16"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>
          <div class="col-span-10">
            <p class="flex items-center">Affiche - Format 16" X 20"</p>
          </div>
          <div class="col-span-10 mb-4">
            <input
              type="text"
              v-model="store.state.prixRevente.affiche16X20"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>
        </div>
        <div>
          <p class="text-green-700 py-4 text-lg font-bold" v-if="success">
            Les modifications ont étés effectuées avec succès.
          </p>
          <p class="text-red-400 py-4 text-lg font-bold" v-if="erreur">
            Une erreur est survenue pendant les modifications.
          </p>
        </div>

        <button
          class="mt-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase"
          @click="confirmEditSettings()"
        >
          CONFIRMER LES MODIFICATIONS
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { inject, onMounted, ref } from "vue";

export default {
  name: "GestionPrixRevente",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");
    const success = ref(false);
    const erreur = ref(false);

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    async function confirmEditSettings() {
      try {
        success.value = false;
        erreur.value = false;
        const res = await fetch(`${store.state.updatePrixReventeUrl}`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(store.state.prixRevente),
        });
        // a non-200 response code
        if (!res.ok) {
          erreur.value = true;
          const error = new Error(res);
          error.json = res.json();
          throw error;
        } else {
          success.value = true;
        }
      } catch (err) {
        console.log(err);
      }
    }

    onMounted(() => {
      store.methods.getPrixRevente();
    });

    return {
      store,
      toggleMenu,
      confirmEditSettings,
      success,
      erreur,
    };
  },
};
</script>
<style lang="scss"></style>
