<template>
  <div class="relative min-h-screen md:flex">
    <!-- Mobile Menu Bar -->
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- Logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- Mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <!-- Sidebar -->
    <AdminSideBar
      class="sidebar md:translate-x-0 -translate-x-full transition-transform duration-300 ease-in-out"
    />

    <!-- Content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8">
        <div>
          <p class="text-3xl mb-10 uppercase">Gestion des Catégories</p>
          <div v-if="!loading && dataCats && dataCats.data">
            <div class="grid-wrapper">
              <div
                v-for="category in sortedCategories"
                :key="category.id"
                class="category-card"
                @click="toggleCheckbox(category.id)"
              >
                <div class="image-container">
                  <img
                    v-if="category.image"
                    :src="category.image"
                    :alt="category.name"
                    class="category-image"
                  />
                  <div v-else class="placeholder">
                    <span>Pas d'image</span>
                  </div>
                </div>
                <div class="category-info">
                  <span class="category-name">{{ category.name }}</span>
                  <input
                    type="checkbox"
                    :checked="isChecked(category.id)"
                    class="checkbox-input"
                  />
                  <!-- Icon to open modal -->
                  <svg
                    class="info-icon h-5 w-5 ml-2 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    @click.stop="openModal(category.prixSugg, category.id)"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13 16h-1v-4h-1m1-4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p>Loading categories...</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="showModal" class="modal-backdrop">
      <div class="modal-content">
        <h2 class="text-2xl mb-4">Prix Suggérés</h2>
        <div
          v-if="editingPrixSugg.products && editingPrixSugg.products.length > 0"
          class="container-modal-infos"
        >
          <div
            v-for="(product, index) in editingPrixSugg.products"
            :key="index"
            class="mb-4"
          >
            <div class="">
              <label class="">Nom du Produit:</label>
              <input
                type="text"
                v-model="product.name"
                class="input-box"
                placeholder="Nom du produit"
              />
            </div>
            <div class="prix-container">
              <div class="">
                <label class="">Votre Prix:</label>
                <input
                  type="text"
                  v-model="product.votre_prix"
                  class="input-box"
                  placeholder="Votre prix"
                />
              </div>
              <div class="">
                <label class="">Prix Suggéré:</label>
                <input
                  type="text"
                  v-model="product.prix_suggere"
                  class="input-box"
                  placeholder="Prix suggéré"
                />
              </div>
            </div>
            <button
              @click="removeProduct(index)"
              class="mt-2 bg-red-500 text-white px-1 py-1 rounded"
            >
              Supprimer
            </button>
          </div>
        </div>
        <div v-else>
          <p>Aucune donnée disponible.</p>
        </div>
        <button
          @click="addProduct"
          class="mt-4 bg-blue-500 text-white px-2 py-2 rounded mx-1"
        >
          Ajouter un produit
        </button>
        <button
          @click="savePrixSugg"
          class="mt-4 bg-green-500 text-white px-2 py-2 rounded mx-1"
        >
          Sauvegarder
        </button>
        <button @click="closeModal" class="btn-close mx-1">Fermer</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { onMounted, ref, computed } from "vue";

const loading = ref(false);
const dataCats = ref(null);
const checkedCategories = ref({}); // Copie des catégories cochées
const showModal = ref(false);
const selectedCategoryId = ref(null);
const editingPrixSugg = ref({ products: [] });

async function getAllCats() {
  loading.value = true;
  try {
    const response = await fetch(
      `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/getAllCats`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );

    dataCats.value = await response.json();

    // Créer une copie des états des cases cochées
    dataCats.value.data.apiCategories.forEach((cat) => {
      checkedCategories.value[cat.id] = dataCats.value.data.dbCats.some(
        (dbCat) => dbCat.cat_id === cat.id && dbCat.handleCat === 1
      );
    });
  } catch (err) {
    console.log(err);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  getAllCats();
});

function isChecked(categoryId) {
  // Vérifier si la catégorie existe dans checkedCategories
  return !!checkedCategories.value[categoryId];
}

async function toggleCheckbox(categoryId) {
  // Vérifier si la catégorie existe dans checkedCategories
  if (!(categoryId in checkedCategories.value)) {
    // Initialiser la clé si elle n'existe pas
    checkedCategories.value[categoryId] = false;
  }

  // Inverser l'état localement
  checkedCategories.value[categoryId] = !checkedCategories.value[categoryId];

  try {
    // Synchroniser avec la base de données
    const handleCat = checkedCategories.value[categoryId] ? 1 : 0;
    const response = await fetch(
      `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updateHandleCat`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ cat_id: categoryId, handleCat }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update category");
    }

    console.log(`Category ${categoryId} updated to handleCat = ${handleCat}`);
  } catch (err) {
    // Si la requête échoue, revenir à l'état précédent
    checkedCategories.value[categoryId] = !checkedCategories.value[categoryId];
    console.error(`Failed to update category ${categoryId}:`, err);
  }
}

function openModal(prixSugg, categoryId) {
  selectedCategoryId.value = categoryId;
  if (typeof prixSugg === "string") {
    try {
      editingPrixSugg.value = JSON.parse(prixSugg) || { products: [] };
    } catch (error) {
      console.error("Erreur lors de l'analyse JSON:", error);
      editingPrixSugg.value = { products: [] };
    }
  } else if (typeof prixSugg === "object" && prixSugg !== null) {
    editingPrixSugg.value = prixSugg || { products: [] };
  } else {
    editingPrixSugg.value = { products: [] };
  }
  showModal.value = true;
}

function closeModal() {
  showModal.value = false;
  selectedCategoryId.value = null;
  editingPrixSugg.value = { products: [] };
}

function addProduct() {
  editingPrixSugg.value.products.push({
    name: "",
    votre_prix: "",
    prix_suggere: "",
  });
}

function removeProduct(index) {
  editingPrixSugg.value.products.splice(index, 1);
}

async function savePrixSugg() {
  try {
    const response = await fetch(
      `${process.env.VUE_APP_BASE_URL}/api/inventory/admin/updatePrixRevente`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          cat_id: selectedCategoryId.value,
          prixSugg: JSON.stringify(editingPrixSugg.value),
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to save prixSugg");
    }

    const updatedCategories = dataCats.value.data.dbCats.map((cat) =>
      cat.cat_id === selectedCategoryId.value
        ? { ...cat, prixSugg: JSON.stringify(editingPrixSugg.value) }
        : cat
    );
    dataCats.value.data.dbCats = updatedCategories;

    closeModal();
  } catch (error) {
    console.error("Erreur lors de la sauvegarde des prix suggérés:", error);
  }
}

const sortedCategories = computed(() => {
  if (!dataCats.value?.data?.apiCategories || !dataCats.value?.data?.dbCats)
    return [];

  const dbCatsMap = new Map(
    dataCats.value.data.dbCats.map((cat) => [cat.cat_id, cat])
  );

  const mergedCategories = dataCats.value.data.apiCategories.map((apiCat) => {
    const dbCat = dbCatsMap.get(apiCat.id);
    return dbCat
      ? {
          ...apiCat,
          handleCat: dbCat.handleCat,
          prixSugg: dbCat.prixSugg ? JSON.parse(dbCat.prixSugg) : null,
        }
      : { ...apiCat, handleCat: 0, prixSugg: null };
  });

  return mergedCategories.sort((a, b) => {
    if (!a.image) return 1;
    if (!b.image) return -1;
    return 0;
  });
});
</script>

<style lang="scss">
/* Modal Styling */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 80vw;
  width: 100%;
  text-align: center;
}

.input-box {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5rem;
  width: 100%;
}

.btn-close {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #e53e3e;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-close:hover {
  background-color: #c53030;
}

/* Info Icon Styling */
.info-icon {
  color: #000;
  cursor: pointer;
  transition: color 0.3s ease;
}

.info-icon:hover {
  color: #3182ce;
}

/* Other styles */
.grid-wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.category-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.category-card:hover {
  transform: translateY(-5px);
}

.image-container {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.category-image,
.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  color: #666;
  font-weight: bold;
  font-size: 1rem;
}

.category-info {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-name {
  font-weight: bold;
  font-size: 1rem;
}

.checkbox-input {
  width: 18px;
  height: 18px;
  pointer-events: none;
}

.prix-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.container-modal-infos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 75vw;
  margin: 0 auto;
  gap: 1rem;
}

@media (min-width: 640px) {
  .grid-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .grid-wrapper {
    grid-template-columns: repeat(6, 1fr);
  }
}
</style>
