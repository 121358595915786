<template>
  <section class="filterCategories md:mt-48">
    <!-- GRANDEUR PRODUITS -->
    <h6 class="titlesChoices">OPTIONS</h6>

    <div>
      <ul>
        <li class="lineCategorie" @click="selectOption('infos')">
          <div class="textCategorie">
            <img src="@/assets/images/chevron-droit.png" class="chevron" />
            <p class="categorie_item_options" id="infos">Mes infos</p>
          </div>
        </li>
      </ul>
    </div>

    <div>
      <ul>
        <li class="lineCategorie" @click="selectOption('commandes')">
          <div class="textCategorie">
            <img src="@/assets/images/chevron-droit.png" class="chevron" />
            <p class="categorie_item_options" id="commandes">Mes commandes</p>
          </div>
        </li>
      </ul>
    </div>

    <div v-if="isAdmin">
      <ul>
        <li class="lineCategorie" @click="selectOption('password')">
          <div class="textCategorie">
            <img src="@/assets/images/chevron-droit.png" class="chevron" />
            <p class="categorie_item_options" id="password">Mot de passe</p>
          </div>
        </li>
      </ul>
    </div>

    <div>
      <ul>
        <li class="lineCategorie" @click="selectOption('telechargement')">
          <div class="textCategorie">
            <img src="@/assets/images/chevron-droit.png" class="chevron" />
            <p class="categorie_item_options" id="telechargement">
              Images des produits
            </p>
          </div>
        </li>
      </ul>
    </div>

    <div>
      <ul>
        <li class="lineCategorie" @click="logout()">
          <div class="textCategorie">
            <img src="@/assets/images/chevron-droit.png" class="chevron" />
            <p class="categorie_item_options" id="telechargement">
              Se déconnecter
            </p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { inject, onMounted, ref } from "vue";
// import { useRouter } from "vue-router";
export default {
  name: "MenuProfil",

  setup() {
    const store = inject("store");
    // const router = useRouter();
    const isAdmin = ref(false);

    onMounted(() => {
      document.querySelector("#commandes").style.fontWeight = "500";
    });

    function selectOption(option) {
      // SELECTIONNE BONNE OPTION ET LA MET EN GRAS

      store.state.currentModeProfil = option;
      const otherElement = document.querySelectorAll(".categorie_item_options");
      for (var i = 0; i < otherElement.length; i++) {
        otherElement[i].style.fontWeight = "normal";
      }
      document.querySelector(`#${option}`).style.fontWeight = "500";
    }

    async function logout() {
      try {
        const res = await fetch(store.state.logoutUrl, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });

        if (!res.ok) {
          console.log("ERREUR");
        }

        const json = await res.json();
        // set the response data

        if (json.success === 1) {
          // router.push({ path: "/login" });
          window.location.href = '/';
        }
      } catch (err) {
        console.error(err);
      }
    }

    async function isItAdmin() {
      const res = await fetch(
        `${store.state.isAdminUrl}/${store.state.infosCustomer.customer_userId}`,
        {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        }
      );

      if (!res.ok) {
        const error = new Error(res.statusText);
        error.json = res.json();
        throw error;
      } else {
        const data = await res.json();

        isAdmin.value = data.data.isAdmin;
      }
    }

    onMounted(() => {
      document.querySelector("#commandes").style.fontWeight = "500";
      isItAdmin();
    });

    return {
      store,
      selectOption,
      isItAdmin,
      logout,
      isAdmin,
    };
  },
};
</script>

<style lang="scss">
.filterCategories {
  max-width: 250px;

  h6 {
    padding-bottom: 15px;
  }
}
</style>
