<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="/admin" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8">
        <div class="col-pan-3">
          <div class="text-3xl uppercase">
            Autres paramètres
          </div>
        </div>

        <div
          v-if="store.state.gotSettingsDb && newSettings"
          class="grid grid-cols-10 gap-y-2 pt-10"
        >
          <div class="col-span-10">
            <p class="flex items-center">Montant TPS:</p>
          </div>

          <div class="col-span-10">
            <input
              type="number"
              v-model="newSettings.tps"
              id="prenomInputMod"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10">
            <p class="flex items-center">Montant TVQ:</p>
          </div>

          <div class="col-span-10">
            <input
              type="number"
              v-model="newSettings.tvq"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>

          <div class="col-span-10">
            <p class="flex items-center">Prix expédition:</p>
          </div>

          <div class="col-span-10">
            <input
              type="number"
              v-model="newSettings.prixExpedition"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>
          <div class="col-span-10">
            <p class="flex items-center">Montant commande minimale:</p>
          </div>

          <div class="col-span-10">
            <input
              type="number"
              v-model="newSettings.commandeMinimale"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-6/12"
            />
          </div>
        </div>
        <div>
          <p class="text-green-700 py-4 text-lg font-bold" v-if="success">
            Les modifications ont étés effectuées avec succès.
          </p>
          <p class="text-red-400 py-4 text-lg font-bold" v-if="erreur">
            Une erreur est survenue pendant les modifications.
          </p>
        </div>

        <button
          class="mt-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase"
          @click="confirmEditSettings()"
        >
          CONFIRMER LES MODIFICATIONS
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { inject, onMounted, ref } from "vue";

export default {
  name: "GestionAdmin",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");
    const newSettings = ref({});
    const success = ref(false);
    const erreur = ref(false);

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    async function confirmEditSettings() {
      let error = false;

      if (isNaN(newSettings.value.tps)) {
        error = true;
      }
      if (isNaN(newSettings.value.tvq)) {
        error = true;
      }
      if (isNaN(newSettings.value.prixExpedition)) {
        error = true;
      }
      if (isNaN(newSettings.value.commandeMinimale)) {
        error = true;
      }

      if (error) {
        alert("Vérifiez tous les champs du formulaire.");
        return;
      }

      let proceed = confirm(`Voulez-vous confirmer les modifications ?`);

      if (proceed) {
        success.value = false;
        erreur.value = false;

        try {
          const res = await fetch(`${store.state.updateSettingsExtranet}`, {
            method: "post",
            headers: {
              "content-type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(newSettings.value),
          });
          // a non-200 response code
          if (!res.ok) {
            erreur.value = true;
            const error = new Error(res);
            error.json = res.json();
            throw error;
          } else {
            success.value = true;
          }
        } catch (err) {
          console.log(err);
        }
      }
    }

    onMounted(() => {
      if (!store.state.gotSettingsDb) {
        (async () => {
          await store.methods.fetchSettingsExtranet();
          newSettings.value = {
            tps: store.state.tps,
            tvq: store.state.tvq,
            prixExpedition: store.state.prixExpedition,
            commandeMinimale: store.state.commandeMinimale,
            grosseurCoffret: store.state.grosseurCoffret,
            categoriesToRemove: store.state.categoriesToRemove,
            tagsToRemove: store.state.tagsToRemove,
            formatsToRemove: store.state.formatsToRemove,
            prixCoffretReg: store.state.prixCoffretReg,
            prixCoffretToucheDore: store.state.prixCoffretToucheDore,
            prixCoffretEns: store.state.prixCoffretEns,
          };
        })();
      } else {
        newSettings.value = {
          tps: store.state.tps,
          tvq: store.state.tvq,
          prixExpedition: store.state.prixExpedition,
          commandeMinimale: store.state.commandeMinimale,
          grosseurCoffret: store.state.grosseurCoffret,
          categoriesToRemove: store.state.categoriesToRemove,
          tagsToRemove: store.state.tagsToRemove,
          formatsToRemove: store.state.formatsToRemove,
          prixCoffretReg: store.state.prixCoffretReg,
          prixCoffretToucheDore: store.state.prixCoffretToucheDore,
          prixCoffretEns: store.state.prixCoffretEns,
        };
      }
    });

    return {
      store,
      toggleMenu,
      newSettings,
      confirmEditSettings,
    };
  },
};
</script>
<style lang="scss"></style>
