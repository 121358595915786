<template>
  <div></div>
</template>

<script>
import { onMounted } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "HomeAdmin",
  setup() {
    const router = useRouter();

    onMounted(() => {
      router.push({ path: "/admin/produits" });
    });

    return {
      router,
    };
  },
};
</script>
<style lang="scss"></style>
