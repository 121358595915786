<template>
  <section
    class="filterTags pt-10"
    v-if="
      !store.state.loading &&
        (store.state.tagsCoffToucheDore ||
          store.state.tagsCoffEns ||
          store.state.tagsCoffReg)
    "
  >
    <h6 class="titlesChoices">ETIQUETTES PRODUITS</h6>

    <div
      v-if="store.state.tagsCoffReg && store.state.typeCoffrets === 'regulier'"
    >
      <div
        v-for="(tag, index) in store.state.tagsCoffReg"
        :key="index"
        class="mx-5"
      >
        <button
          class="btnFilter"
          :id="`tag${tag.id}`"
          @click="handleTags(tag.id)"
        >
          <span v-html="tag.name"></span>
        </button>
      </div>
    </div>

    <div
      v-if="
        store.state.tagsCoffEns && store.state.typeCoffrets === 'ensemencees'
      "
    >
      <div
        v-for="(tag, index) in store.state.tagsCoffEns"
        :key="index"
        class="mx-5"
      >
        <button
          class="btnFilter"
          :id="`tag${tag.id}`"
          @click="handleTags(tag.id)"
        >
          <span v-html="tag.name"></span>
        </button>
      </div>
    </div>

    <div
      v-if="
        store.state.tagsCoffToucheDore &&
          store.state.typeCoffrets === 'touchedore'
      "
    >
      <div
        v-for="(tag, index) in store.state.tagsCoffToucheDore"
        :key="index"
        class="mx-5"
      >
        <button
          class="btnFilter"
          :id="`tag${tag.id}`"
          @click="handleTags(tag.id)"
        >
          <span v-html="tag.name"></span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { inject, ref, onMounted } from "vue";
export default {
  name: "SelectTagCoffret",

  setup() {
    const store = inject("store");
    const tagsList = ref([]);

    onMounted(() => {
      store.methods.tagsEnsList();
      store.methods.tagsRegList();
      store.methods.tagsToucheDoreList();
    });

    function handleTags(tag) {
      if (tagsList.value.length > 0) {
        if (tagsList.value.includes(tag)) {
          // SI TAG EST DEJA DANS LA LISTE
          let tagIndex = tagsList.value.indexOf(tag);
          tagsList.value.splice(tagIndex, 1);
          document.querySelector(`#tag${tag}`).classList.remove("tag_selected");
        } else {
          tagsList.value.push(tag);
          document.querySelector(`#tag${tag}`).classList.add("tag_selected");
        }
      } else {
        tagsList.value.push(tag);
        document.querySelector(`#tag${tag}`).classList.add("tag_selected");
      }

      if (tagsList.value.length > 0) {
        store.state.currentFilterTags = tagsList.value;
      } else {
        store.state.currentFilterTags = "all";
      }
    }

    return {
      store,
      handleTags,
      tagsList,
    };
  },
};
</script>

<style lang="scss"></style>
