<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="/admin" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8">
        <div class="col-pan-3">
          <div class="text-3xl uppercase">
            GESTION DES CODES PROMOS
          </div>
        </div>
        <div class="w-full mt-10 grid grid-cols-1 md:grid-cols-3">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="
              mode = 'ajouter';
              newPromoCode = {};
            "
          >
            Ajouter un nouveau code
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="
              mode = 'modifier';
              currentPromo = null;
            "
          >
            Désactiver / Activer un code
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 col-span-3 lg:col-span-1 uppercase"
            @click="
              mode = 'supprimer';
              currentPromo = null;
            "
          >
            Supprimer un code
          </button>
        </div>
        <div v-if="mode === 'ajouter'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Ajout d'un code promo</p>
          </div>
          <div class="grid grid-cols-1 lg:grid-cols-4 my-10 gap-y-4">
            <p class="col-span-1 flex items-center">Code promo:</p>
            <input
              type="text"
              v-model="newPromoCode.codePromo"
              class="py-2 px-2 text-sm border border-blue-lighter rounded col-span-3 lg:w-6/12"
            />
            <p class="col-span-1 flex items-center">Nom:</p>
            <input
              type="text"
              v-model="newPromoCode.codePromoName"
              class="py-2 px-2 text-sm border border-blue-lighter rounded col-span-3 lg:w-6/12"
            />
            <p class="col-span-1 flex items-center">Description:</p>
            <input
              type="text"
              v-model="newPromoCode.codePromoDescription"
              class="py-2 px-2 text-sm border border-blue-lighter rounded col-span-3 lg:w-6/12"
            />

            <div class="col-span-4 mt-8">
              <div class="mb-4">
                <label for="uneFois" class="flex items-center cursor-pointer">
                  <!-- toggle -->
                  <div class="relative">
                    <!-- input -->
                    <input
                      id="uneFois"
                      type="checkbox"
                      class="sr-only"
                      v-model="newPromoCode.codePromoOnce"
                    />
                    <!-- line -->
                    <div
                      class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"
                    ></div>
                    <!-- dot -->
                    <div
                      class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"
                    ></div>
                  </div>
                  <!-- label -->
                  <div class="ml-3 text-gray-700 font-small">
                    Utilisable une seule fois par client.
                  </div>
                </label>
              </div>

              <div class="mb-4">
                <label for="unClient" class="flex items-center cursor-pointer">
                  <!-- toggle -->
                  <div class="relative">
                    <!-- input -->
                    <input
                      id="unClient"
                      type="checkbox"
                      class="sr-only"
                      v-model="newPromoCode.onlyUser"
                    />
                    <!-- line -->
                    <div
                      class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"
                    ></div>
                    <!-- dot -->
                    <div
                      class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"
                    ></div>
                  </div>
                  <!-- label -->
                  <div class="ml-3 text-gray-700 font-small">
                    Utilisable seulement pour un client en particulier.
                  </div>
                </label>
                <!-- LISTING DES CLIENT POUR PROMO  -->
                <div v-if="newPromoCode.onlyUser && clients" class="my-4">
                  <div>
                    <div class="relative">
                      <select
                        v-model="currentClient"
                        class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:w-6/12"
                        @change="updateClient()"
                      >
                        <option disabled selected value="null"
                          >Veuillez sélectionner un client</option
                        >
                        <option
                          v-for="(client, indexClient) in clients"
                          :key="indexClient"
                          :value="client.customer_id"
                          >{{ client.customer_compagnyName }}</option
                        >
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 lg:w-7/12"
                      >
                        <svg
                          class="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- FIN DU LISTING -->
              </div>

              <div class="mb-4">
                <label for="codeActif" class="flex items-center cursor-pointer">
                  <!-- toggle -->
                  <div class="relative">
                    <!-- input -->
                    <input
                      id="codeActif"
                      type="checkbox"
                      class="sr-only"
                      v-model="newPromoCode.codePromoIsActive"
                      checked
                    />
                    <!-- line -->
                    <div
                      class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"
                    ></div>
                    <!-- dot -->
                    <div
                      class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"
                    ></div>
                  </div>
                  <!-- label -->
                  <div class="ml-3 text-gray-700 font-small">
                    Code actif
                  </div>
                </label>
              </div>

              <div class="mb-4">
                <label
                  for="montantMinimal"
                  class="flex items-center cursor-pointer"
                >
                  <!-- toggle -->
                  <div class="relative">
                    <!-- input -->
                    <input
                      id="montantMinimal"
                      type="checkbox"
                      class="sr-only"
                      v-model="newPromoCode.needMontantMin"
                    />
                    <!-- line -->
                    <div
                      class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"
                    ></div>
                    <!-- dot -->
                    <div
                      class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"
                    ></div>
                  </div>
                  <!-- label -->
                  <div class="ml-3 text-gray-700 font-small">
                    Montant minimal d'achat
                  </div>
                </label>

                <div class="mt-8">
                  <span class="text-gray-700">Type de promotion</span>
                  <div class="mt-2">
                    <label class="inline-flex items-center cursor-pointer">
                      <input
                        type="radio"
                        class="form-radio"
                        name="promoType"
                        value="tag"
                        v-model="newPromoCode.promoType"
                      />
                      <span class="ml-2">Étiquette</span>
                    </label>
                    <label class="inline-flex items-center ml-4 cursor-pointer">
                      <input
                        type="radio"
                        class="form-radio"
                        name="promoType"
                        value="cat"
                        v-model="newPromoCode.promoType"
                      />
                      <span class="ml-2">Catégorie</span>
                    </label>
                    <label class="inline-flex items-center ml-4 cursor-pointer">
                      <input
                        type="radio"
                        class="form-radio"
                        name="PromoType"
                        value="none"
                        v-model="newPromoCode.promoType"
                        checked
                      />
                      <span class="ml-2">Aucune restriction</span>
                    </label>
                  </div>
                </div>

                <!--  LISTING CHOIX DES TAGS POUR PROMO  TAGS-->

                <div v-if="newPromoCode.promoType === 'tag'" class="mt-4">
                  <div v-if="store.state.allTagsDispo">
                    <div>
                      <div class="relative">
                        <select
                          v-model="currentTag"
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:w-6/12"
                          @change="updateEtiquette()"
                        >
                          <option disabled selected value="null"
                            >Veuillez sélectionner un étiquette</option
                          >
                          <option
                            v-for="(tag, indexTag) in store.state.allTagsDispo"
                            :key="indexTag"
                            :value="tag.id"
                            ><span v-html="tag.name"></span> -
                            {{ tag.id }}</option
                          >
                        </select>
                        <div
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 lg:w-7/12"
                        >
                          <svg
                            class="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- FIN  DES TAGS POUR PROMO -->

                <!--  LISTING CHOIX DES CAT POUR PROMO  CATEGORIES-->

                <div v-if="newPromoCode.promoType === 'cat'" class="mt-4">
                  <div v-if="store.state.allCatDispo">
                    <div>
                      <div class="relative">
                        <select
                          v-model="currentCat"
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:w-6/12 "
                          @change="updateCat()"
                        >
                          <option disabled selected value="null"
                            >Veuillez sélectionner une catégorie</option
                          >
                          <option
                            v-for="(cat, indexCat) in store.state.allCatDispo"
                            :key="indexCat"
                            :value="cat.cats_id"
                            ><span v-html="cat.cat_name"></span> -
                            {{ cat.cats_id }}</option
                          >
                        </select>
                        <div
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 lg:w-7/12"
                        >
                          <svg
                            class="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path
                              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- FIN  DES CAT POUR PROMO -->

                <div class="mt-8">
                  <span class="text-gray-700">Type de rabais</span>
                  <div class="mt-2">
                    <label class="inline-flex items-center cursor-pointer">
                      <input
                        type="radio"
                        class="form-radio"
                        name="rabaisType"
                        value="pourcentage"
                        v-model="newPromoCode.typeRabais"
                      />
                      <span class="ml-2">Pourcentage</span>
                    </label>
                    <label class="inline-flex items-center ml-4 cursor-pointer">
                      <input
                        type="radio"
                        class="form-radio"
                        name="rabaisType"
                        value="montant"
                        v-model="newPromoCode.typeRabais"
                      />
                      <span class="ml-2">Montant</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <p
              class="col-span-1 flex items-center"
              v-if="newPromoCode.needMontantMin === true"
            >
              Montant minimal:
            </p>
            <input
              type="number"
              v-model="newPromoCode.codePromoMontantMinimale"
              class="py-2 px-2 text-sm border border-blue-lighter rounded col-span-3 w-full md:w-6/12"
              v-if="newPromoCode.needMontantMin === true"
            />

            <p
              class="col-span-1 flex items-center"
              v-if="newPromoCode.typeRabais === 'montant'"
            >
              Montant de rabais:
            </p>
            <input
              type="number"
              v-model="newPromoCode.codePromoMontant"
              class="py-2 px-2 text-sm border border-blue-lighter rounded col-span-3 w-full md:w-6/12"
              v-if="newPromoCode.typeRabais === 'montant'"
            />
            <p
              class="col-span-1 flex items-center"
              v-if="newPromoCode.typeRabais === 'pourcentage'"
            >
              Pourcentage de rabais:
            </p>
            <input
              type="number"
              v-model="newPromoCode.codePromoMontant"
              class="py-2 px-2 text-sm border border-blue-lighter rounded col-span-3 w-full md:w-6/12"
              v-if="newPromoCode.typeRabais === 'pourcentage'"
            />

            <div class="flex justify-start mt-10">
              <div class="mr-10">
                <p class="pb-6">Date de départ</p>
                <v-date-picker v-model="startDate" :masks="masks">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="bg-white border px-2 py-1 rounded"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </v-date-picker>
              </div>
              <div>
                <p class="pb-6">Date de fin</p>
                <v-date-picker v-model="endDate" :masks="masks">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="bg-white border px-2 py-1 rounded"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </v-date-picker>
              </div>
            </div>
          </div>
          <div v-if="error">
            <p class="text-red-400 font-semibold">
              {{ errorMessage }}
            </p>
          </div>
          <div v-if="success">
            <p class="text-green-500 font-semibold">
              Le code promo a été créé avec succès !
            </p>
          </div>
          <button
            class="mt-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase"
            @click="createCode()"
          >
            CRÉER LE CODE PROMO
          </button>
          <div></div>
        </div>
        <div v-if="mode === 'modifier'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Désactiver un code promo</p>
            <div class="relative">
              <select
                v-model="currentPromoToEdit"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 lg:w-6/12"
              >
                <option disabled selected value="null"
                  >Veuillez sélectionner un code promo à désactiver</option
                >
                <option
                  v-for="(promo, indexPromo) in store.state.codesPromo"
                  :key="indexPromo"
                  :value="indexPromo"
                  >{{ promo.codePromoName }} - (
                  {{ promo.codePromo.toUpperCase() }} )</option
                >
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 lg:w-7/12"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            class="grid grid-cols-1 md:grid-cols-4 my-10 gap-y-2"
            v-if="currentPromoToEdit !== null"
          >
            <div class="md:col-span-4">
              <label for="codeActif" class="flex items-center cursor-pointer ">
                <!-- toggle -->
                <div class="relative">
                  <!-- input -->
                  <input
                    id="codeActif"
                    type="checkbox"
                    class="sr-only"
                    v-model="
                      store.state.codesPromo[currentPromoToEdit]
                        .codePromoIsActive
                    "
                    :checked="
                      store.state.codesPromo[currentPromoToEdit]
                        .codePromoIsActive == 1
                        ? (store.state.codesPromo[
                            currentPromoToEdit
                          ].codePromoIsActive = true)
                        : (store.state.codesPromo[
                            currentPromoToEdit
                          ].codePromoIsActive = false)
                    "
                  />
                  <!-- line -->
                  <div
                    class="w-10 h-4 bg-gray-400 rounded-full shadow-inner"
                  ></div>
                  <!-- dot -->
                  <div
                    class="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"
                  ></div>
                </div>
                <!-- label -->
                <div class="ml-3 text-gray-700 font-small">
                  Code actif
                </div>
              </label>
            </div>

            <div v-if="error">
              <p class="text-red-400 font-semibold">
                {{ errorMessage }}
              </p>
            </div>
            <div v-if="success">
              <p class="text-green-500 font-semibold">
                Le code promo a été modifié avec succès !
              </p>
            </div>
            <button
              class="mt-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase"
              @click="updateCodePromo()"
            >
              MODIFIER LE CODE
            </button>
          </div>
        </div>
        <div v-if="mode === 'supprimer'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Suppression d'un code promo</p>
            <div class="relative">
              <select
                v-model="currentPromo"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option disabled selected value="null"
                  >Veuillez sélectionner un code promo à supprimer</option
                >
                <option
                  v-for="(promo, indexPromo) in store.state.codesPromo"
                  :key="indexPromo"
                  :value="indexPromo"
                  >{{ promo.codePromoName }} - (
                  {{ promo.codePromo.toUpperCase() }} )</option
                >
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mr-10 uppercase"
            @click="
              deleteCode(
                store.state.codesPromo[currentPromo].codePromoId,
                store.state.codesPromo[currentPromo].codePromoName,
                store.state.codesPromo[currentPromo].codePromo
              )
            "
          >
            Supprimer le code
          </button>
        </div>
      </div>
    </div>

    <!-- </div> -->
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { onMounted, inject, ref } from "vue";

export default {
  name: "GestionCodesPromo",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");
    const currentPromo = ref(null);
    const mode = ref(null);
    const newPromoCode = ref({ promoType: "none", codePromoIsActive: true });
    const startDate = ref(new Date());
    const endDate = ref(null);
    const clients = ref(null);
    const currentClient = ref(null);
    const currentTag = ref(null);
    const currentCat = ref(null);
    const error = ref(false);
    const errorMessage = ref(null);
    const success = ref(false);
    const currentPromoToEdit = ref(null);

    const masks = {
      input: "DD-MM-YYYY",
    };

    function getAllCatDispo() {
      // A VERIFIER ASYNC

      if (store.state.data) {
        let totalCat = [];
        store.state.data.forEach((product) => {
          product.product_categories.forEach((cat) => {
            totalCat.push({ cats_id: cat.id, cat_name: cat.name });
          });
        });
        // ENLEVE DUPLICATE ET MET EN ORDRE ALPHABETIQUE DE CAT
        let uniqueCat = store.methods.removeDuplicates(
          totalCat,
          (item) => item.cats_id
        );
        store.state.allCatDispo = uniqueCat.sort(
          store.methods.dynamicSort("cat_name")
        ); // SORT BY ALPHABETIC ORDER
      }
    }

    function updateCat() {
      newPromoCode.value.cat = currentCat.value;
    }

    function updateEtiquette() {
      newPromoCode.value.tag = currentTag.value;
    }

    function updateClient() {
      newPromoCode.value.client = currentClient.value;
    }

    onMounted(() => {
      if (!store.state.codesPromo) {
        store.methods.getCodesPromos();
      }

      if (!store.state.data) {
        (async () => {
          await store.methods.fetchAllProducts();
          getAllCatDispo();
          store.methods.showAllTags(store.state.data);
        })();
      } else {
        getAllCatDispo();
        store.methods.showAllTags(store.state.data);
      }

      if (!clients.value) {
        getAllCustomers();
      }
    });

    async function getAllCustomers() {
      try {
        const res = await fetch(`${store.state.getAllCustomersUrl}`, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });

        if (!res.ok) {
          const error = new Error(res.statusText);
          error.json = res.json();
          throw error;
        }
        const json = await res.json();
        // set the response data

        clients.value = json.data;
        return "success";
      } catch (err) {
        console.error(err);
      }
    }

    function deleteCode(codePromoId, codePromoName, codePromo) {
      if (currentPromo.value === null) {
        return;
      }

      let proceed = confirm(
        `Voulez-vous vraiment le code promo ${codePromo} - ${codePromoName}?`
      );

      if (proceed) {
        (async () => {
          await store.methods.deleteCodePromo(codePromoId);
          store.methods.getCodesPromos();
          currentPromo.value = null;
        })();
      }
    }

    async function updateCodePromo() {
      let proceed = confirm(
        `Voulez-vous vraiment désactiver le code promo ${store.state.codesPromo[currentPromoToEdit]}`
      );

      if (proceed) {
        const toSend = {
          codePromoId: store.state.codesPromo[currentPromoToEdit].codePromoId,
          isActive:
            store.state.codesPromo[currentPromoToEdit].codePromoIsActive,
        };

        try {
          const res = await fetch(`${store.state.updateCodePromoUrl}`, {
            method: "post",
            headers: {
              "content-type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(toSend),
          });

          if (!res.ok) {
            errorMessage.value =
              "Une erreur est survenue pendant la modification du code promo.";
            error.value = true;
          }
          const json = await res.json();
          if (json.success == 1) {
            store.methods.getCodesPromos();
            success.value = true; // INDIQUE MESSAGE DE SUCCES
          } else {
            errorMessage.value =
              "Une erreur est survenue pendant la modification du code promo.";
            error.value = true;
          }
        } catch (err) {
          console.error(err);
        }
      }
    }

    async function checkIfCodeAlereadyExist(promoCode) {
      try {
        const res = await fetch(
          `${store.state.getCheckIfCodePromoAlereadyExistUrl}/${promoCode}`,
          {
            method: "get",
            headers: {
              "content-type": "application/json",
            },
            credentials: "include",
          }
        );

        if (!res.ok) {
          const error = new Error(res.statusText);
          error.json = res.json();
          throw error;
        }
        const json = await res.json();

        if (json.data === true) {
          // SI LE CODE EXISTE DEJA

          error.value = true;
          errorMessage.value =
            "Le nom du code promo existe déja, veuillez en sélectionner un autre.";

          return;
        } else {
          // FAIRE ACTION AVEC OK CODE EXISTE PAS
          error.value = false;
          sendPromoCode();
        }
      } catch (err) {
        console.error(err);
      }
    }

    async function sendPromoCode() {
      try {
        const res = await fetch(`${store.state.createPromoCodeUrl}`, {
          method: "post",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(newPromoCode.value),
        });

        if (!res.ok) {
          errorMessage.value =
            "Une erreur est survenue pendant la création du code promo.";
          error.value = true;
        }
        const json = await res.json();
        if (json.success == 1) {
          success.value = true; // INDIQUE MESSAGE DE SUCCES
          newPromoCode.value = { promoType: "none", codePromoIsActive: true };
          currentClient.value = null;
          currentTag.value = null;
          currentCat.value = null;
          store.methods.getCodesPromos();
        } else {
          errorMessage.value =
            "Une erreur est survenue pendant la création du code promo.";
          error.value = true;
        }
      } catch (err) {
        console.error(err);
      }
    }

    function createCode() {
      // VERIFICATION DES DATA
      let err = false;

      if (!newPromoCode.value.codePromoMontantMinimale) {
        newPromoCode.value.codePromoMontantMinimale = 0;
      }

      if (
        (newPromoCode.value.promoType !== "tag" &&
          newPromoCode.value.promoType !== "cat") ||
        !newPromoCode.value.promoType
      ) {
        newPromoCode.value.promoType = "none";
      }

      if (newPromoCode.value.promoType === "cat") {
        if (!newPromoCode.value.cat) {
          err = true;
        }
      }
      if (newPromoCode.value.promoType === "tag") {
        if (!newPromoCode.value.tag) {
          err = true;
        }
      }

      if (newPromoCode.value.onlyUser) {
        if (newPromoCode.value.client === null || "") {
          err = true;
        }
      }

      if (!newPromoCode.value.typeRabais) {
        err = true;
      }

      if (!newPromoCode.value.typeRabais) {
        err = true;
      }
      if (
        !newPromoCode.value.typeRabais === "montant" ||
        newPromoCode.value.typeRabais === "pourcentage"
      ) {
        if (!newPromoCode.value.codePromoMontant) {
          err = true;
        }
        if (newPromoCode.value.codePromoMontant < 0) {
          err = true;
        }
      }
      if (newPromoCode.value.onlyUser) {
        if (!newPromoCode.value.client) {
          err = true;
        }
      }

      if (!newPromoCode.value.codePromoName) {
        err = true;
      }

      if (!newPromoCode.value.codePromoDescription) {
        err = true;
      }

      if (!newPromoCode.value.codePromo) {
        err = true;
      } else {
        newPromoCode.value.codePromo = newPromoCode.value.codePromo.toLowerCase();
      }

      if (newPromoCode.value.codePromoIsActive === false) {
        newPromoCode.value.codePromoIsActive = false;
      } else {
        newPromoCode.value.codePromoIsActive = true;
      }

      checkDate();

      if (!newPromoCode.value.endDate) {
        err = true;
      }
      if (!newPromoCode.value.startDate) {
        err = true;
      }

      if (err) {
        error.value = true;
        success.value = false;
        errorMessage.value =
          "Des champs sont manquants, veillez vérifier que tous les champs sont bien renseignés.";
        return;
      } else {
        error.value = false;
      }

      checkIfCodeAlereadyExist(newPromoCode.value.codePromo);
    }

    function checkDate() {
      if (endDate.value <= startDate.value) {
        alert("La date de début doit précéder la date de fin du rapport.");
        return;
      } else {
        let ddS = String(startDate.value.getDate()).padStart(2, "0");
        let mmS = String(startDate.value.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyyS = startDate.value.getFullYear();

        let goodStartDate = yyyyS + "-" + mmS + "-" + ddS;

        let ddE = String(endDate.value.getDate()).padStart(2, "0");
        let mmE = String(endDate.value.getMonth() + 1).padStart(2, "0"); //January is 0!
        let yyyyE = endDate.value.getFullYear();

        let goodEndDate = yyyyE + "-" + mmE + "-" + ddE;

        newPromoCode.value.startDate = goodStartDate;
        newPromoCode.value.endDate = goodEndDate;

        return goodStartDate, goodEndDate;
      }
    }

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    return {
      store,
      currentPromo,
      toggleMenu,
      startDate,
      newPromoCode,
      mode,
      endDate,
      masks,
      deleteCode,
      createCode,
      checkDate,
      getAllCatDispo,
      clients,
      currentClient,
      currentTag,
      currentCat,
      error,
      errorMessage,
      success,
      updateCat,
      updateEtiquette,
      updateClient,
      checkIfCodeAlereadyExist,
      sendPromoCode,
      currentPromoToEdit,
      updateCodePromo,
    };
  },
};
</script>
<style lang="scss">
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
</style>
