<template>
  <section
    class="selectStock"
    v-if="
      !store.state.loading &&
        store.state.currentTag !== store.state.idCatAffiches
    "
  >
    <input
      type="checkbox"
      id="cbx"
      style="display: none;"
      class="checkbox inline-block"
      v-model="ifChecked"
      @click="handleChecked()"
    />
    <label for="cbx" class="check inline-table">
      <p>En inventaire seulement</p>
      <svg width="18px" height="18px" viewBox="0 0 18 18">
        <path
          d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"
        ></path>
        <polyline points="1 9 7 14 15 4"></polyline>
      </svg>
    </label>
  </section>
</template>

<script>
import { inject, ref, onMounted } from "vue";
export default {
  name: "SelectStock",

  setup() {
    const store = inject("store");
    const ifChecked = ref(false);

    function handleChecked() {
      if (store.state.ifStock === 0) {
        store.state.ifStock = 1;
      } else {
        store.state.ifStock = 0;
      }
    }

    onMounted(() => {
      // REFRESH LE FILTRE D'INVETAIRE
      store.state.ifStock = 0;
    });

    return {
      store,
      ifChecked,
      handleChecked,
    };
  },
};
</script>

<style lang="scss">
.selectStock {
  margin-top: -20px;

  .check {
    cursor: pointer;
    position: relative;
    margin: auto;
    width: 18px;
    height: 18px;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
    p {
      width: 200px;
      transform: translate(28px, 21px);
    }
  }

  .check svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #c8ccd4;
    stroke-width: 1.5;
    transform: translate3d(0, 0, 0);
    transition: all 0.2s ease;
  }
  .check svg path {
    stroke-dasharray: 60;
    stroke-dashoffset: 0;
  }
  .check svg polyline {
    stroke-dasharray: 22;
    stroke-dashoffset: 66;
  }
  .check:hover:before {
    opacity: 1;
  }
  .check:hover svg {
    stroke: #222;
  }
  #cbx:checked + .check svg {
    stroke: #222;
  }
  #cbx:checked + .check svg path {
    stroke-dashoffset: 60;
    transition: all 0.3s linear;
  }
  #cbx:checked + .check svg polyline {
    stroke-dashoffset: 42;
    transition: all 0.2s linear;
    transition-delay: 0.15s;
  }
}
</style>
