<template>
  <div>
    <TheNavbar />

    <main class="produit pt-16 " id="confirm-commande-container">
      <div class="grid grid-cols-1 lg:grid-cols-2">
        <div class="m-2 lg:m-10">
          <table v-if="store.state.currentTaxes && store.state.currentOrder">
            <thead>
              <tr>
                <th scope="col" colspan="2">Produits</th>
                <th scope="col">Quantité</th>
                <th scope="col">Prix</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in store.state.currentOrder"
                :key="index"
              >
                <td colspan="2">
                  <strong class="product-title">{{ item.name }}</strong>
                  <span
                    class="text-offset"
                    v-if="item.format && item.format.length !== 0"
                    >{{ item.format }}</span
                  >
                  <ul
                    v-for="(itemInCoffret, index) in item.insideCoffret"
                    :key="index"
                    class="pt-2"
                  >
                    <li class="pl-4">- {{ itemInCoffret }}</li>
                  </ul>
                </td>

                <td class="item-qty">{{ item.quantity }}</td>
                <td class="item-price">
                  {{ (item.price * item.quantity).toFixed(2) }} $
                  <span
                    v-if="item.quantity > 1"
                    class="text-offset item-multiple"
                    >{{ item.price.toFixed(2) }}$ &#215;
                    {{ item.quantity }}</span
                  >
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr class="text-offset">
                <td colspan="3">Sous-total</td>
                <td>{{ store.state.currentTaxes.soustotal.toFixed(2) }}$</td>
              </tr>
              <tr class="text-offset">
                <td colspan="3">TPS</td>
                <td>{{ store.state.currentTaxes.tps.toFixed(2) }}$</td>
              </tr>
              <tr class="text-offset">
                <td colspan="3">TVQ</td>
                <td>{{ store.state.currentTaxes.tvq.toFixed(2) }}$</td>
              </tr>
              <tr class="text-offset">
                <td colspan="3">Total</td>
                <td>{{ store.state.currentTaxes.total.toFixed(2) }}$</td>
              </tr>
            </tfoot>
          </table>
        </div>

        <!-- FACTURATION -->
        <div class="m-2 lg:m-10" v-if="store.state.infosCustomer">
          <table class="billing_adress">
            <thead>
              <tr>
                <th scope="col" colspan="4">facturation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="4">
                  <strong class="product-title">{{
                    store.state.infosCustomer.customer_compagnyName
                  }}</strong>
                  <span
                    class="text-offset"
                    v-if="store.state.infosCustomer.customer_contact"
                    >( {{ store.state.infosCustomer.customer_contact }} )</span
                  >
                  <strong class="product-title">{{
                    formatPhoneNumber(store.state.infosCustomer.customer_phone)
                  }}</strong>
                  <strong class="product-title">{{
                    store.state.infosCustomer.customer_email
                  }}</strong>

                  <strong class="product-title pt-10"
                    >{{
                      store.state.infosCustomer.customer_billingAdressNumber
                    }}, {{ store.state.infosCustomer.customer_billingStreet }}
                  </strong>
                  <strong class="product-title">{{
                    store.state.infosCustomer.customer_billingCity
                  }}</strong>
                  <strong class="product-title">{{
                    store.state.infosCustomer.customer_billingPostalCode
                  }}</strong>
                  <strong class="product-title"
                    >{{ store.state.infosCustomer.customer_billingProvince }},
                    {{ store.state.infosCustomer.customer_billingPays }}
                  </strong>
                </td>
              </tr>
              <tr>
                <td colspan="4" class="if_same">
                  <input
                    type="checkbox"
                    id="cbx"
                    style="display: none;"
                    class="checkbox inline-block"
                    v-model="
                      store.state.infosCustomer.customer_shippingIsBilling
                    "
                    @click="handleChecked()"
                  />

                  <label for="cbx" class="check inline-table">
                    <p>
                      Utiliser adresse de livraison comme adresse de facturation
                    </p>
                    <svg width="18px" height="18px" viewBox="0 0 18 18">
                      <path
                        d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"
                      ></path>
                      <polyline points="1 9 7 14 15 4"></polyline>
                    </svg>
                  </label>
                </td>
              </tr>

              <tr v-if="!store.state.infosCustomer.customer_shippingIsBilling">
                <td colspan="4">
                  <span>Adresse de livraison :</span>
                  <input
                    type="text"
                    class="form-input mt-1 block w-full p-2 border border-gray font-bold	"
                    id="shippingAdressNumber"
                    v-model="
                      store.state.infosCustomer.customer_shippingAdressNumber
                    "
                    placeholder="Numéro civique"
                  />
                  <input
                    type="text"
                    class="form-input mt-1 block w-full p-2 border border-gray font-bold	"
                    id="shippingStreet"
                    v-model="store.state.infosCustomer.customer_shippingStreet"
                    placeholder="Rue"
                  />
                  <input
                    type="text"
                    class="form-input mt-1 block w-full p-2 border border-gray font-bold	"
                    id="shippingCity"
                    v-model="store.state.infosCustomer.customer_shippingCity"
                    placeholder="Ville"
                  />
                  <input
                    type="text"
                    class="form-input mt-1 block w-full p-2 border border-gray font-bold	"
                    id="shippingPostalCode"
                    v-model="
                      store.state.infosCustomer.customer_shippingPostalCode
                    "
                    placeholder="Code postal"
                  />
                  <input
                    type="text"
                    class="form-input mt-1 block w-full p-2 border border-gray font-bold	"
                    id="shippingProvince"
                    v-model="
                      store.state.infosCustomer.customer_shippingProvince
                    "
                    placeholder="Province"
                  />
                  <input
                    type="text"
                    class="form-input mt-1 block w-full p-2 border border-gray font-bold	"
                    id="shippingPays"
                    v-model="store.state.infosCustomer.customer_shippingPays"
                    placeholder="Pays"
                  />
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td>
                  <div class="flex justify-center">
                    <button
                      class="btnValider uppercase my-2"
                      @click="confirmCommand()"
                      id="btn-confirm-command"
                    >
                      <div class="flex justify-between items-center  mx-2">
                        CONFIRMER LA COMMANDE<span
                          class="ml-2"
                          v-if="creatingCommande"
                          ><img
                            id="loadingLogo"
                            src="../../assets/images/spinner_button.gif"
                        /></span>
                      </div>
                    </button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </main>

    <TheFooter />
  </div>
</template>

<script>
import TheNavbar from "../../components/TheNavbar";
import TheFooter from "../../components/TheFooter";

import { inject, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "ConfirmOrder",
  components: { TheNavbar, TheFooter },
  setup() {
    const store = inject("store");
    const router = useRouter();
    const typePaiement = ref();
    const creatingCommande = ref(false);

    let numCommande = null;

    function handleChecked() {
      !store.state.infosCustomer.customer_shippingIsBilling;
    }

    async function goOrder() {
      try {
        const res = await fetch(store.state.postOrder, {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(
            // ENVOIE LE PANIER ET LES INFOS DU CLIENT ET TYPE PAIEMENT
            [store.state.cart, store.state.infosCustomer, typePaiement.value]
          ),
        });

        const json = await res.json();

        if (!res.ok) {
          console.error(json);
        } else {
          if (json.success === 1) {
            // REDIRECT PAGE SUCCESS

            const numCommande = parseInt(json.data.number);

            const key = {
              client_key: json.data.client_key,
              number: numCommande,
            };

            const customerId = store.state.infosCustomer.customer_id;
            store.methods.addNumCommandeUpdateLocalStorage(
              numCommande,
              customerId
            ); // STOCKER NUM COMMANDE

            updateCommandeWithHarvestId(key);
          } else {
            console.error(json);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }

    async function updateCommandeWithHarvestId(key) {
      // POUSSER LE CODE CLIENT ID DE HARVEST DANS LA DB

      try {
        const res = await fetch(
          `${store.state.updateCommandeHarvestNumUrl}/${key.client_key}/${key.number}`,
          {
            method: "get",
            headers: {
              "content-type": "application/json",
            },
            credentials: "include",
          }
        );
        const data = await res.json();
        if (!res.ok) {
          console.error(data);
        } else {
          if (data.success === 1) {
            store.methods.clearCart();
            router.push({ path: "/panier/merci" });
          } else {
            console.error(data);
          }
        }
      } catch (err) {
        console.error(err);
      }
    }

    // FORMAT PHONE NUMBER
    function formatPhoneNumber(phoneNumberString) {
      let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }
      return null;
    }

    // VALIDATION DES CHAMPS A REMPLIR
    function confirmCommand() {
      if (!creatingCommande.value) {
        creatingCommande.value = true;

        if (!store.state.infosCustomer.customer_shippingIsBilling) {
          let allOk = true;

          if (!store.state.infosCustomer.customer_shippingAdressNumber) {
            document
              .querySelector("#shippingAdressNumber")
              .classList.add("empty");
            allOk = false;
          } else {
            document
              .querySelector("#shippingAdressNumber")
              .classList.remove("empty");
          }

          if (!store.state.infosCustomer.customer_shippingStreet) {
            document.querySelector("#shippingStreet").classList.add("empty");
            allOk = false;
          } else {
            document.querySelector("#shippingStreet").classList.remove("empty");
          }

          if (!store.state.infosCustomer.customer_shippingCity) {
            document.querySelector("#shippingCity").classList.add("empty");
            allOk = false;
          } else {
            document.querySelector("#shippingCity").classList.remove("empty");
          }

          if (!store.state.infosCustomer.customer_shippingPostalCode) {
            document
              .querySelector("#shippingPostalCode")
              .classList.add("empty");
            allOk = false;
          } else {
            document
              .querySelector("#shippingPostalCode")
              .classList.remove("empty");
          }

          if (!store.state.infosCustomer.customer_shippingProvince) {
            document.querySelector("#shippingProvince").classList.add("empty");
            allOk = false;
          } else {
            document
              .querySelector("#shippingProvince")
              .classList.remove("empty");
          }

          if (!store.state.infosCustomer.customer_shippingPays) {
            document.querySelector("#shippingPays").classList.add("empty");
            allOk = false;
          } else {
            document.querySelector("#shippingPays").classList.remove("empty");
          }

          if (!allOk) {
            return;
          } else {
            // SI TOUT EST OK CONTINUER

            goOrder();
          }
        } else {
          goOrder();
        }
      }
    }

    onMounted(() => {
      if (!store.state.ifConfirmCartCliked) {
        // SI CLIENT NA PAS PASSER PAR LE PANIER  REDIRECT PANIER

        router.push({ path: "/panier" });
      }
      if (!store.state.currentOrder || !store.state.currentTaxes) {
        // SI PAS DE COMMANDE OU CALCUL TAXES REDIRECT PANIER
        router.push({ path: "/panier" });
      }

      window.scrollTo(0, 0);

      if (!store.state.infosCustomer) {
        store.methods.fetchInfosCustomer();
      } else {
        if (store.state.infosCustomer.customer_shippingIsBilling === 1) {
          store.state.infosCustomer.customer_shippingIsBilling = true;
        } else {
          store.state.infosCustomer.customer_shippingIsBilling = false;
        }
      }
    });

    return {
      store,
      formatPhoneNumber,
      handleChecked,
      typePaiement,
      confirmCommand,
      creatingCommande,
      goOrder,
      numCommande,
      updateCommandeWithHarvestId,
    };
  },
};
</script>
<style lang="scss">
$borderGray: #dedede;
$goldColor: #b7a36f;

@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&display=swap");

.empty {
  border: 1px solid red !important;
}

#confirm-commande-container {
  max-width: 1445px !important;
  margin: auto !important;
}

// #btn-confirm-command,
// #btn-confirm-command-disable {

// }

#btn-confirm-command-disable {
  border: 1px solid #fff;
  background-color: #ffffff;
}

.text-checked {
  color: blue;
}

.if_same {
  .check {
    cursor: pointer;
    position: relative;
    margin: auto;
    width: 18px;
    height: 18px;
    -webkit-tap-highlight-color: transparent;
    transform: translate3d(0, 0, 0);
    p {
      min-width: 300px;
      transform: translate(38px, 21px);
      margin-bottom: 20px;
    }
  }

  .check svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #c8ccd4;
    stroke-width: 1.5;
    transform: translate3d(0, -20px, 0);
    transition: all 0.2s ease;
  }
  .check svg path {
    stroke-dasharray: 60;
    stroke-dashoffset: 0;
  }
  .check svg polyline {
    stroke-dasharray: 22;
    stroke-dashoffset: 66;
  }
  .check:hover:before {
    opacity: 1;
  }
  .check:hover svg {
    stroke: #222;
  }
  #cbx:checked + .check svg {
    stroke: #222;
  }
  #cbx:checked + .check svg path {
    stroke-dashoffset: 60;
    transition: all 0.3s linear;
  }
  #cbx:checked + .check svg polyline {
    stroke-dashoffset: 42;
    transition: all 0.2s linear;
    transition-delay: 0.15s;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  color: #4a4a4d;
  min-width: 100%;
}

th,
td {
  padding: 10px 15px;
  vertical-align: middle;
}

thead {
  background: rgba(34, 34, 34, 1);
  background: linear-gradient(rgba(34, 34, 34, 1), #000000);
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  border: 1px solid #000;
}

th:first-child {
  text-align: left;
}

th:last-child {
  border-top-right-radius: 3px;
}

tbody tr:nth-child(even) {
  background: #eeeeee;
}

td {
  border-bottom: 1px solid #7a7a7a;
  border-right: 1px solid #7a7a7a;
}

td:first-child {
  border-left: 1px solid #7a7a7a;
}

.product-title {
  color: rgba(34, 34, 34, 1);
  display: block;
}

.text-offset {
  color: #4a4a4d;
  font-size: 14px;
}

.item-stock,
.item-qty {
  text-align: center;
}

.item-price {
  text-align: right;
}

.item-multiple {
  display: block;
}

tfoot {
  text-align: right;
}

tfoot tr:last-child {
  background: #eeeeee;
  color: rgba(34, 34, 34, 1);
  font-weight: bold;
}

tfoot tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}
tfoot tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}
</style>
