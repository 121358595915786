import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";

// ADRESSES CLIENTS
import Home from "../views/Home.vue";
import Accueil from "../views/clients/Accueil.vue";
// import Cartes from "../views/clients/Cartes.vue";
import Coffrets from "../views/clients/Coffrets.vue";
import Panier from "../views/clients/Panier.vue";
import Produit from "../views/clients/Produit.vue";
import LoginAdmin from "../views/admin/LoginAdmin.vue";
import HomeAdmin from "../views/admin/HomeAdmin.vue";
import ProduitCoffret from "../views/clients/ProduitCoffret.vue";
import Presentoirs from "../views/clients/Presentoirs.vue";
// import Papeterie from "../views/clients/Papeterie.vue";
import ConfirmOrder from "../views/clients/ConfirmOrder.vue";
import Enregistrement from "../views/clients/Enregistrement.vue";
import Promotions from "../views/clients/Promotions.vue";
import Profil from "../views/clients/Profil.vue";
import Oups from "../views/clients/Oups.vue";
import Merci from "../views/clients/Merci.vue";
import Erreur from "../views/clients/Erreur.vue";
import ResetPassword from "../views/clients/ResetPassword.vue";
import Reinitialisation from "../views/clients/Reinitialistion.vue";
import Demande from "../views/clients/Demande.vue";
// import Affiches from "../views/clients/Affiches.vue";
import Categorie from "../views/clients/Categorie.vue";

// ADRESSES ADMIN
import CoffretsMeliMeliAdmin from "../views/admin/CoffretsMeliMeloAdmin.vue";
import CoffretsRegAdmin from "../views/admin/CoffretsRegAdmin.vue";
import GestionRepresentantAdmin from "../views/admin/GestionRepresentantAdmin.vue";
import GestionPresentoirAdmin from "../views/admin/GestionPresentoirAdmin.vue";
import GestionCreateurAdmin from "../views/admin/GestionCreateurAdmin.vue";
import GestionProduitsAdmin from "../views/admin/GestionProduitsAdmin.vue";
import GestionCommandesAmdin from "../views/admin/GestionCommandesAdmin.vue";
import RapportsCreateursAdmin from "../views/admin/RapportsCreateursAdmin.vue";
import RapportsRepresentantsAdmin from "../views/admin/RapportsRepresentantsAdmin.vue";
import EnregistrementAdmin from "../views/admin/EnregistrementAdmin.vue";
import GestionCodesPromo from "../views/admin/GestionCodesPromo.vue";
import GestionMockups from "../views/admin/GestionMockupAdmin.vue";
import GestionPromotions from "../views/admin/GestionPromotions.vue";
import GestionAdmin from "../views/admin/GestionAdmin.vue";
import OupsAdmin from "../views/admin/OupsAdmin.vue";
import DesactiverClientAdmin from "../views/admin/DesactiverClientAdmin.vue";
import GestionAdministrateur from "../views/admin/GestionAdministrateur.vue";
import GestionClients from "../views/admin/GestionClients.vue";
import GestionPrixRevente from "../views/admin/GestionPrixRenvente.vue";
import MotDePasse from "../views/admin/MotDePasse.vue";
import GestionCategories from "../views/admin/GestionCategories.vue";
import StatsAdmin from "../views/admin/StatsAdmin.vue";

const authGuardClient = (to, from, next) => {
  axios
    .get(process.env.VUE_APP_CHECKAUTH, { withCredentials: true })

    .then(function (response) {
      if (response.data.data.isAuth) {
        next();
      } else {
        next("/");
      }
    })
    .catch(function (error) {
      console.log(error);
      next("/");
    });
};

const authGuardAdmin = (to, from, next) => {
  axios
    .get(process.env.VUE_APP_CHECKADMIN, { withCredentials: true })

    .then(function (response) {
      if (response.data.data.isAuth && response.data.data.isAdmin) {
        next();
      } else {
        next("/admin/login");
      }
    })
    .catch(function (error) {
      console.log(error);
      next("/");
    });
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/accueil",
    name: "Accueil",
    component: Accueil,
    beforeEnter: authGuardClient,
  },
  // {
  //   path: "/cartes",
  //   name: "Cartes",
  //   component: Cartes,
  //   beforeEnter: authGuardClient,
  // },
  // {
  //   path: "/affiches",
  //   name: "Affiches",
  //   component: Affiches,
  //   beforeEnter: authGuardClient,
  // },
  {
    path: "/categorie/coffrets",
    name: "Coffrets",
    component: Coffrets,
    beforeEnter: authGuardClient,
  },
  {
    path: "/panier",
    name: "Panier",
    component: Panier,
    beforeEnter: authGuardClient,
  },
  {
    path: "/produit/:produit",
    name: "Produit",
    component: Produit,
    beforeEnter: authGuardClient,
  },
  {
    path: "/categorie/:categorie",
    name: "Categorie",
    component: Categorie,
    beforeEnter: authGuardClient,
  },
  {
    path: "/produit/coffret/:coffret",
    name: "ProduitCoffret",
    component: ProduitCoffret,
    beforeEnter: authGuardClient,
  },
  {
    path: "/resetpassword",
    name: "  ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/categorie/presentoirs",
    name: "Presentoirs",
    component: Presentoirs,
    beforeEnter: authGuardClient,
  },
  // {
  //   path: "/papeterie",
  //   name: "Papeterie",
  //   component: Papeterie,
  //   beforeEnter: authGuardClient,
  // },
  {
    path: "/confirmation",
    name: "ConfirmOrder",
    component: ConfirmOrder,
    beforeEnter: authGuardClient,
  },
  {
    path: "/enregistrement/:key",
    name: "Enregistrement",
    component: Enregistrement,
  },

  {
    path: "/admin/login/",
    name: "LoginAdmin",
    component: LoginAdmin,
  },
  {
    path: "/admin/",
    name: "HomeAdmin",
    component: HomeAdmin,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/coffretsmelimelo",
    name: "coffretsmelimelo",
    component: CoffretsMeliMeliAdmin,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/demande",
    name: "Demande",
    component: Demande,
  },
  {
    path: "/admin/representants",
    name: "GestionRepresentantAdmin",
    component: GestionRepresentantAdmin,
    beforeEnter: authGuardAdmin,
  },

  {
    path: "/admin/categories",
    name: "GestionCategories",
    component: GestionCategories,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/presentoirs",
    name: "GestionPresentoirAdmin",
    component: GestionPresentoirAdmin,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/createurs",
    name: "GestionCreateurAdmin",
    component: GestionCreateurAdmin,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/produits",
    name: "GestionProduitsAdmin",
    component: GestionProduitsAdmin,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/coffrets",
    name: "CoffretsRegAdmin",
    component: CoffretsRegAdmin,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/commandes",
    name: "GestionCommandesAmdin",
    component: GestionCommandesAmdin,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/desactiver",
    name: "DesactiverClientAdmin",
    component: DesactiverClientAdmin,
    beforeEnter: authGuardAdmin,
  },

  {
    path: "/admin/rapportsrepresentants",
    name: "RapportsRepresentantsAdmin",
    component: RapportsRepresentantsAdmin,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/rapportscreateurs",
    name: "RapportsCreateursAdmin",
    component: RapportsCreateursAdmin,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/enregistrement",
    name: "EnregistrementAdmin",
    component: EnregistrementAdmin,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/codespromo",
    name: "GestionCodesPromo",
    component: GestionCodesPromo,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/gestionadministrateur",
    name: "GestionAdministrateur",
    component: GestionAdministrateur,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/mockups",
    name: "GestionMockups",
    component: GestionMockups,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/gestionpromotions",
    name: "GestionPromotions",
    component: GestionPromotions,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/gestionprixrevente",
    name: "GestionPrixRevente",
    component: GestionPrixRevente,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/profil",
    name: "Profil",
    component: Profil,
    beforeEnter: authGuardClient,
  },
  {
    path: "/promotions",
    name: "Promotions",
    component: Promotions,
    beforeEnter: authGuardClient,
  },
  {
    path: "/admin/gestion",
    name: "GestionAdmin",
    component: GestionAdmin,
    beforeEnter: authGuardAdmin,
  },

  {
    path: "/panier/merci/",
    name: "Merci",
    component: Merci,
    beforeEnter: authGuardClient,
  },
  {
    path: "/panier/erreur",
    name: "Erreur",
    component: Erreur,
    beforeEnter: authGuardClient,
  },
  {
    path: "/admin/gestionclients",
    name: "GestionClients",
    component: GestionClients,
    beforeEnter: authGuardAdmin,
  },
  {
    path: "/admin/motdepasse",
    name: "MotDePasse",
    component: MotDePasse,
    beforeEnter: authGuardAdmin,
  },

  {
    path: "/admin/statistiques",
    name: "StatsAdmin",
    component: StatsAdmin,
    beforeEnter: authGuardAdmin,
  },

  {
    path: "/admin/:catchAll(.*)",
    name: "OupsAdmin",
    component: OupsAdmin,
    beforeEnter: authGuardAdmin,
  },

  {
    path: "/:catchAll(.*)",
    name: "Oups",
    component: Oups,
    beforeEnter: authGuardClient,
  },

  {
    path: "/reinitialisation/:resetLink",
    name: "Reinitialisation",
    component: Reinitialisation,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
