<template>
  <section class="showPresentoirs">
    <vue-easy-lightbox
      :visible="visible"
      :imgs="currentImage"
      :index="indexImage"
      @hide="handleHide"
      v-if="store.state.presentoirs"
    ></vue-easy-lightbox>

    <!-- SHOW PRESENTOIRS   -->
    <div
      v-if="store.state.presentoirs && !store.state.loadingPresentoirs"
      class="col-span-1 w-full grid grid-cols-2 mt-12"
    >
      <transition-group name="slide">
        <div
          v-for="(presentoir, index) in store.state.presentoirs"
          :key="index"
          class="col-span-2 grid grid-cols-2 sm:grid-cols-4 w-11/12 mb-8"
        >
          <div class="col-span-2 pb-10">
            <div class="flex justify-center">
              <img
                :src="presentoir.presentoirImage"
                class="image_presentoirs cursor-pointer"
                @click="
                  showImage(
                    presentoir.presentoirImage,
                    presentoir.presentoirImage2,
                    presentoir.presentoirImage3,
                    0
                  )
                "
              />
            </div>
            <div
              v-if="presentoir.presentoirImage2 || presentoir.presentoirImage3"
              class="w-full flex justify-start mt-6"
            >
              <div
                v-if="presentoir.presentoirImage2"
                class="ml-32 cursor-pointer"
                @click="
                  showImage(
                    presentoir.presentoirImage,
                    presentoir.presentoirImage2,
                    presentoir.presentoirImage3,
                    1
                  )
                "
              >
                <img
                  :src="presentoir.presentoirImage2"
                  class="image_presentoirsMini"
                />
              </div>
              <div
                v-if="presentoir.presentoirImage3"
                class="ml-10 cursor-pointer"
                @click="
                  showImage(
                    presentoir.presentoirImage,
                    presentoir.presentoirImage2,
                    presentoir.presentoirImage3,
                    2
                  )
                "
              >
                <img
                  :src="presentoir.presentoirImage3"
                  class="image_presentoirsMini"
                />
              </div>
            </div>
          </div>

          <div
            class="col-span-2 flex flex-col justify-center pb-10 pl-5 sm:pl-10 md:pl-0"
          >
            <p>Pour {{ presentoir.presentoirPourFormat }}</p>
            <p>Matériel: {{ presentoir.presentoirMateriel }}</p>
            <p>Format: {{ presentoir.presentoirFormat }}</p>
            <p>Capacité: {{ presentoir.presentoirCapacite }}</p>
            <p>Prix d'achat {{ presentoir.presentoirPrix.toFixed(2) }} $</p>
            <br />
            <p v-if="presentoir.presentoirStockAvailable === 0">
              ( Rupture d'inventaire )
            </p>
            <p v-else>( {{ presentoir.presentoirStockAvailable }} en stock )</p>
            <br />
            <p>
              * Location gratuite avec une commande minimale de
              {{ presentoir.presentoirCommandeMinimale }} $
            </p>
            <div class="w-full flex justify-left pt-5">
              <button
                @click="addToCart(presentoir.presentoirId)"
                class="btnValider"
                v-if="presentoir.presentoirStockAvailable > 0"
              >
                Ajouter au panier
              </button>
              <button
                @click="addToCart(presentoir.presentoirId)"
                disabled
                class="btnValiderDisabled"
                v-else
              >
                Ajouter au panier
              </button>
            </div>
          </div>
        </div>
      </transition-group>
    </div>

    <div v-if="store.state.loadingPresentoirs" class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p v-if="store.state.errorPresentoirs">
      {{ store.state.errorPresentoirs }}
    </p>
  </section>
</template>
<script>
import { inject, reactive, ref } from "vue";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "showPresentoirs",
  components: { VueEasyLightbox },
  setup() {
    const store = inject("store");
    const currentImage = ref(null);
    const tempCart = reactive([]);
    const visible = ref(false);
    const indexImage = ref(null);

    function increment(variation) {
      if (
        !isNaN(
          store.state.tempCartQty[
            `card-${variation.parentId}-${variation.variation}`
          ]
        )
      ) {
        store.state.tempCartQty[
          `card-${variation.parentId}-${variation.variation}`
        ]++;
      } else {
        store.state.tempCartQty[
          `card-${variation.parentId}-${variation.variation}`
        ] = 1;
      }
    }

    function decrement(variation) {
      if (
        !isNaN(
          store.state.tempCartQty[
            `card-${variation.parentId}-${variation.variation}`
          ]
        )
      ) {
        if (
          store.state.tempCartQty[
            `card-${variation.parentId}-${variation.variation}`
          ] > 0
        ) {
          store.state.tempCartQty[
            `card-${variation.parentId}-${variation.variation}`
          ]--;
        }
      }
    }

    function addToCart(presentoirId) {
      let index = store.state.presentoirs.findIndex(
        (x) => x.presentoirId === presentoirId
      ); // TROUVER INDEX DE ITEM
      let itemToAdd = {
        parentId: 888888,
        variation: store.state.presentoirs[index].presentoirId,
        price: store.state.presentoirs[index].presentoirPrix,
        commandeMinimale:
          store.state.presentoirs[index].presentoirCommandeMinimale,
        imageVariation: store.state.presentoirs[index].presentoirImage,
        format: store.state.presentoirs[index].presentoirFormat,
      };
      store.methods.addToCart([
        itemToAdd,
        1,
        store.state.presentoirs[index].presentoirType,
        [false, false],
        true,
      ]);
    }

    function handleHide() {
      visible.value = false;
    }

    function showImage(imageUrl1, imageUrl2, imageUrl3, index) {
      indexImage.value = index;
      if (imageUrl1 && !imageUrl2 && !imageUrl3) {
        currentImage.value = [imageUrl1, imageUrl2];
      } else if (imageUrl1 && imageUrl2 && !imageUrl3) {
        currentImage.value = [imageUrl1, imageUrl2];
      } else {
        currentImage.value = [imageUrl1, imageUrl2, imageUrl3];
      }

      visible.value = true;
    }

    return {
      store,
      addToCart,
      tempCart,
      visible,
      handleHide,
      increment,
      decrement,
      currentImage,
      showImage,
      indexImage,
    };
  },
};
</script>

<style lang="scss">
// TRANSITION DE PRODUITS

.slide-enter-from {
  opacity: 0;
  transform: scale(0.95);
}
.slide-enter-to {
  transform: scale(1);
  opacity: 1;
}
.slide-enter-active {
  transition: all 0.4s ease;
}
.slide-leave-from {
  opacity: 1;
  transform: scale(1);
}
.slide-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.image_presentoirs {
  max-height: 270px;
}

.image_presentoirsMini {
  max-height: 100px;
}

.rapide-product-holder {
  // LOADER
  .lds-roller {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-40px);
    transform: translateX(-40px);
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #000;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
