<template>
  <!-- sidebar -->
  <div
    class="sidebar bg-gray-300 text-gray-100 w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out"
  >
    <!-- logo -->
    <a href="#" class="text-white flex items-center space-x-2 px-4">
      <img
        src="@/assets/images/Mimosa-design-logo-small.png"
        alt="Mimosa Design"
        width="274"
        height="65"
        id="logo-mimosa"
      />
    </a>

    <!-- nav -->
    <nav>
      <router-link
        to="/admin/commandes"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white mt-10"
      >
        Commandes
      </router-link>

      <router-link
        to="/admin/produits"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        <div class="flex justify-start">
          Gestion produits
          <span v-if="store.state.gotNewProducts">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="20"
              height="20"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g
                fill="none"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style="mix-blend-mode: normal"
              >
                <path d="M0,172v-172h172v172z" fill="none"></path>
                <g fill="#e67e22">
                  <path
                    d="M70.83802,17.2l2.46354,91.73333h25.30729l2.55312,-91.73333zM86.04479,126.13333c-9.41986,0 -15.29635,5.46853 -15.29635,14.37812c0,8.82934 5.88222,14.28854 15.29635,14.28854c9.41413,0 15.21797,-5.45921 15.21797,-14.28854c0,-8.90959 -5.7981,-14.37812 -15.21797,-14.37812z"
                  ></path>
                </g>
              </g>
            </svg>
          </span>
        </div>
      </router-link>

      <router-link
        to="/admin/coffretsmelimelo"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Coffrets Méli Mélo
      </router-link>
      <router-link
        to="/admin/coffrets"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Coffrets même visuel
      </router-link>
      <router-link
        to="/admin/presentoirs"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Présentoirs
      </router-link>
      <router-link
        to="/admin/representants"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Représentants
      </router-link>
      <router-link
        to="/admin/createurs"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Créateurs
      </router-link>
      <router-link
        to="/admin/gestionpromotions"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Gestion promotions
      </router-link>
      <router-link
        to="/admin/codespromo"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Codes promo
      </router-link>

      <router-link
        to="/admin/mockups"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Gestion mockups
      </router-link>

      <router-link
        to="/admin/categories"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Gestion catégories
      </router-link>

      <router-link
        to="/admin/gestion"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Autres paramètres
      </router-link>

      <router-link
        to="/admin/enregistrement"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white mt-10"
      >
        Enregistrement clients
      </router-link>

      <router-link
        to="/admin/desactiver"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Activation clients
      </router-link>
      <router-link
        to="/admin/gestionclients"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Gestion clients
      </router-link>
      <router-link
        to="/admin/motdepasse"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Mot de passe clients
      </router-link>

      <router-link
        to="/admin/statistiques"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white mt-10"
      >
        Statistiques de ventes
      </router-link>

      <router-link
        to="/admin/rapportscreateurs"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Rapport(s) créateurs
      </router-link>
      <router-link
        to="/admin/rapportsrepresentants"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Rapport(s) représentants
      </router-link>
      <router-link
        to="/admin/gestionadministrateur"
        class="block py-2.5 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white"
      >
        Gestion administrateurs
      </router-link>

      <p
        class="block py-2.5 mt-4 px-4 rounded transition duration-200 text-gray-800 hover:bg-gray-400 hover:text-white cursor-pointer"
        @click="logout()"
      >
        Se déconnecter
      </p>
    </nav>
  </div>
</template>

<script>
import { inject } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "AdminSideBar",

  setup() {
    const router = useRouter();
    const store = inject("store");
    async function logout() {
      try {
        const res = await fetch(store.state.logoutUrl, {
          method: "get",
          headers: {
            "content-type": "application/json",
          },
          credentials: "include",
        });

        if (!res.ok) {
          console.log("ERREUR");
        }

        const json = await res.json();
        // set the response data

        if (json.success === 1) {
          router.push({ path: "/admin/login" });
        }
      } catch (err) {
        console.error(err);
      }
    }

    return {
      store,
      logout,
    };
  },
};
</script>

<style lang="scss">
.AdminSidebar {
  height: 100vh;
  border-right: 1px solid rgb(107, 106, 106);
  box-shadow: 1px 1px 1px rgb(207, 207, 207);
  width: 200px;
}
</style>
