<template>
  <main>
    <TheNavbar />

    <div class="cartes grid grid-cols-1  lg:grid-cols-4  px-4 md:px-10 pt-16 ">
      <div class="col-span-3 row-start-1 px-5">
        <h1 class="titleSection uppercase pb-2">PROMOTIONS</h1>
      </div>

      <div
        class="col-span-5 h-full flex justify-center items-center inner_promotion"
        v-if="
          store.state.activesPromotions.length === 0 &&
            store.state.loadActivesPromotions !== true
        "
      >
        <div
          class="w-11/12 h-5/6 md:w-6/12 md:h-2/6 border flex justify-center items-center p-3 mb-32 min_message_promotion"
        >
          <div class="flex flex-col items-center">
            <h2 class="text-2xl text-center">
              Aucune promotion n'est en cours actuellement.
            </h2>
          </div>
        </div>
      </div>

      <div
        class="col-span-3 px-2 inner_promotion ql-container ql-snow "
        v-else-if="
          store.state.loadActivesPromotions !== true &&
            store.state.activesPromotions.length > 0
        "
      >
        <div class="ql-editor w-full">
          <div
            v-for="(promo, promotionIndex) in store.state.activesPromotions"
            :key="promotionIndex"
            class="mt-20 mb-56 w-full"
          >
            <span v-html="promo.promotion_content"></span>
          </div>
        </div>
      </div>
    </div>

    <TheFooter />
  </main>
</template>

<script>
import TheNavbar from "../../components/TheNavbar";
import TheFooter from "../../components/TheFooter.vue";

import { inject, onMounted } from "vue";

export default {
  name: "Promotions",
  components: { TheNavbar, TheFooter },
  setup() {
    const store = inject("store");

    onMounted(() => {
      window.scrollTo(0, 0);

      if (!store.state.data) {
        (async () => {
          await store.methods.fetchAllProducts();
          store.methods.checkIfCart();
          store.methods.calculTaxes();
          if (!store.state.coffretsMeliMelo) {
            store.methods.fetchCoffretMeliMelo();
          }
          if (!store.state.presentoirs) {
            store.methods.fetchPresentoirs();
          }
        })();
      } else {
        // VERIFIE SI LE CLIENT A DEJA UN CART
        store.methods.checkIfCart();

        if (!store.state.coffretsMeliMelo) {
          store.methods.fetchCoffretMeliMelo();
        }
        if (!store.state.presentoirs) {
          store.methods.fetchPresentoirs();
        }
      }
    });

    return {
      store,
    };
  },
};
</script>
<style scoped lang="scss">
.ql-container {
  border: none !important;
  width: 90vw !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.cartes {
  max-width: 1445px;
  margin: auto;

  .inner_promotion {
    min-height: 50vh;

    .min_message_promotion {
      min-height: 300px;
    }
  }

  #modeBoutique,
  #modeRapide {
    cursor: pointer;
  }
}
</style>
