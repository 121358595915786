<template>
  <main>
    <TheNavbar class="navbar" />
  </main>
</template>

<script>
import TheNavbar from "../../components/TheNavbar";
import { onMounted, inject } from "vue";
// import { useRouter } from 'vue-router';

export default {
  name: "Accueil",
  components: { TheNavbar },
  setup() {
    const store = inject("store");
    // const router = useRouter();

    onMounted(() => {
      window.scrollTo(0, 0);

      if (!store.state.infosCustomer && !store.state.loadingInfosCustomer) {
        store.methods.fetchInfosCustomer();
      }

      handleScroll();
    });

    const handleScroll = () => {
      window.addEventListener("scroll", () => {
        let scroll = window.scrollY;

        if (scroll >= 200) {
          document.querySelector(".navbar").classList.add("scrolled");
        } else {
          document.querySelector(".navbar").classList.remove("scrolled");
        }
      });
    };

    return {
      store,
      handleScroll,
    };
  },
};
</script>

<style lang="scss">
.navbar {
  position: relative;
  transition: all 2.8s ease-in-out;
}
.navbar.unscrolled .navbar.scrolled {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 1s ease-in-out;
}
</style>
