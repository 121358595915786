<template>
  <section class="searchGroup" v-show="!store.state.loading">
    <h6 class="titlesChoices">RECHERCHE</h6>
    <input
      type="search"
      class="search-field"
      placeholder="Recherche de produits…"
      id="searchInput"
    />
  </section>
</template>

<script>
import { inject, onMounted } from "vue";
export default {
  name: "Search",

  setup() {
    const store = inject("store");
    function handleSearch(search) {
      let searchWords;
      if (search.length < 2 || search == "") {
        // MINUMUM 2 CARACTHERES RECHERCHE
        store.state.currentFilterSearch = "none";
      } else {
        searchWords = search.trim().toLowerCase();
        store.state.currentFilterSearch = searchWords;
      }
    }
    // GERER LE X DANS SEARCH
    onMounted(() => {
      document.getElementById("searchInput").addEventListener("input", (e) => {
        handleSearch(e.currentTarget.value);
      });
    });

    return {
      store,
      handleSearch,
    };
  },
};
</script>

<style lang="scss">
.searchGroup {
  max-width: 250px;

  h6 {
    padding-bottom: 10px;
  }
  .search-field {
    border: 2px solid #eeeeee;
    color: #7a7a7a;
    height: 48px;
    width: 100%;
    display: block;
    font-size: 13px;
    margin-bottom: 20px;
    padding-left: 8px;
    font-family: inherit;
  }
  input:focus {
    outline: none !important;
  }
}
</style>
