<template>
  <div>
    <TheNavbar />
    <main class="panier">
      <div class="commentaire-panier w-11/12 lg:w-10/12 py-10 mx-auto">
        <h2 class="font-semibold tracking-wide text-black pb-7">
          DÉLAI DE LIVRAISON
        </h2>
        <p v-if="store.state.errorInventoryMessage" class="my-6 font-bold">
          {{ store.state.errorInventoryMessage }}
        </p>

        <p>
          Commande totale minimum de {{ store.state.commandeMinimale }}$. Frais
          de livraison fixe à {{ store.state.prixExpedition }}$ pour le Québec.
          Délai d'assemblage entre 3 et 5 jours ouvrables. Délai de livraison
          selon Purolator.
        </p>
      </div>
      <div
        v-if="!store.state.loading && !loadingCart"
        class="panier-resume w-11/12 lg:w-10/12 panier-container"
      >
        <!-- ENTENTE -->
        <div class="titles-panier grid grid-cols-6 lg:grid-cols-12 py-5">
          <div class="tracking-wide col-span-1 hidden lg:block"></div>
          <div
            class="tracking-wide font-semibold col-span-6 lg:col-span-5 mx-auto lg:pr-20"
          >
            Produit<span v-if="store.state.cartQty > 1">s</span>
          </div>
          <div class="tracking-wide font-semibold col-span-2 hidden lg:block">
            Prix
          </div>
          <div class="tracking-wide font-semibold col-span-2 hidden lg:block">
            Quantité
          </div>
          <div class="tracking-wide font-semibold col-span-2 hidden lg:block">
            Sous-total
          </div>
        </div>
        <!-- LOOP PRODUITS PANIER -->
        <transition-group name="slide">
          <div v-for="(product, index) in store.state.cart" :key="index">
            <div class="grid grid-cols-6 lg:grid-cols-12 py-5">
              <div
                class="x_delete_panier bloc-item col-span-1 pl-5"
                @click="
                  store.methods.removeFromCart(
                    index,
                    product.parentId,
                    product.variationId,
                    product.isCoffret,
                    product.isMeliMelo,
                    product.isPresentoir,
                    store.state.cart[
                      showIndex(product.variationId, product.isCoffret)
                    ].quantity
                  )
                "
              >
                x
              </div>
              <div class="bloc-item col-span-5 pr-5 ">
                <img
                  :src="product.image"
                  alt="{{product.name}}"
                  class="image-produit-panier pr-5"
                  v-if="
                    product.parentId !== 777777 &&
                      product.variantId !== 777777 &&
                      product.parentId !== 999998
                  "
                />
                <img
                  src="../../assets/images/blank_space.png"
                  :alt="product.name"
                  class="image-produit-panier pr-5"
                  v-else
                />

                <div
                  v-if="
                    !product.isCoffret &&
                      product.parentId !== 777777 &&
                      product.variantId !== 777777
                  "
                >
                  <p class="tracking-wide">
                    {{ product.name }}
                    <span v-if="product.format.length > 0"
                      >- {{ product.format }}</span
                    >
                  </p>
                </div>

                <div v-else-if="product.isCoffret && !product.isMeliMelo">
                  <p class="tracking-wide">
                    Coffrets de 6 - {{ product.name }}
                  </p>
                </div>
                <div v-else-if="product.isCoffret && product.isMeliMelo">
                  <p class="tracking-wide">Coffrets - {{ product.name }}</p>
                </div>
                <div v-else>
                  <p class="tracking-wide">
                    {{ product.name }}
                  </p>
                </div>
              </div>
              <div class="col-span-1 block lg:hidden"></div>
              <div
                class="bloc-item tracking-wide col-span-1 lg:col-span-2 w-24"
              >
                <span
                  v-if="
                    product.parentId !== 777777 &&
                      product.variantId !== 777777 &&
                      product.parentId !== 999998
                  "
                  >{{ product.price.toFixed(2) }} $</span
                >
              </div>
              <div
                class="bloc-item col-span-2 flex justify-end lg:justify-start"
              >
                <div
                  class="num-block skin-5"
                  v-if="
                    product.parentId !== 777777 &&
                      product.variantId !== 777777 &&
                      product.parentId !== 999998
                  "
                >
                  <!-- RETOURNE INDEX POUR CHANGER VALEUR DIRECT DANS LE CART -->
                  <div class="num-in">
                    <span
                      class="minus dis"
                      @click="
                        decrement(
                          product.parentId,
                          product.variationId,
                          product.isCoffret,
                          product.isMeliMelo,
                          product.isPresentoir
                        )
                      "
                      >-</span
                    >
                    <input
                      class="in-num tracking-wide"
                      readonly
                      type="number"
                      v-model.number="
                        store.state.cart[
                          showIndex(product.variationId, product.isCoffret)
                        ].quantity
                      "
                    />
                    <span
                      class="plus"
                      @click="
                        increment(
                          product.parentId,
                          product.variationId,
                          product.isCoffret,
                          product.isMeliMelo,
                          product.isPresentoir
                        )
                      "
                      >+</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="bloc-item col-span-2 sous-total-inside-cart  flex justify-center lg:justify-start"
              >
                <p class="tracking-wide text-black">
                  {{ (product.quantity * product.price).toFixed(2) }} $
                </p>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
      <div v-else class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <div
        class="code-promo w-11/12 lg:w-10/12 py-10 px-5"
        v-if="!store.state.loading && !loadingCart"
      >
        <div class="grid grid-cols-5 w-full">
          <div
            v-if="codeinvalide"
            class="flex items-center justify-center lg:justify-end lg:mr-4 col-span-5  order-2 lg:order-3 "
          >
            <p class="text-red-400 lg:mr-4 lg:mt-2">Code promo invalide.</p>
          </div>

          <div
            class="col-span-5 lg:col-span-4 order-1 flex justify-center lg:justify-end lg:mr-4 mb-1 lg:mb-0"
          >
            <input
              type="text"
              v-model="codePromo"
              class="codePromoInput w-10/12 lg:w-56"
              placeholder="Code promo"
              @keyup.enter="checkCodePromo(codePromo)"
            />
          </div>

          <div
            class="order-3 lg:order-2 col-span-5 lg:col-span-1 flex justify-center lg:justify-end mt-1 lg:mt-0"
          >
            <button
              class="boutonappliquer w-10/12 lg:w-56 "
              @click="checkCodePromo(codePromo)"
            >
              APPLIQUER LE CODE PROMO
            </button>
          </div>
        </div>
      </div>
      <transition name="slide">
        <div
          class="code-promo w-11/12 lg:w-10/12 py-10 px-5"
          v-if="
            store.state.promoPresentoir !== null &&
              !store.methods.promoPresentoirUnique() &&
              !store.state.gotFreePresentoir
          "
        >
          <div class="grid grid-cols-5  w-full">
            <div
              class="col-span-6 lg:col-span-4 flex justify-center items-center"
            >
              <h2 class="w-11/12 lg:w-full lg:text-right lg:mr-4">
                Vous avez droit à la location gratuite de :
                {{ store.state.cart[store.state.promoPresentoir].name }}
              </h2>
            </div>

            <div
              class="col-span-6 lg:col-span-1 flex justify-center lg:justify-end mt-3 lg:mt-0"
            >
              <button
                class="boutonappliquer w-10/12 lg:w-56"
                @click="store.methods.addPromoPresentoir()"
              >
                Appliquer l'offre
              </button>
            </div>
          </div>
        </div>
      </transition>

      <!-- MONTANT TOTAL ET RABAIS -->

      <div
        class="panier-final w-11/12 lg:w-10/12 mx-auto pt-10"
        v-if="
          store.state.cart.length > 0 && !store.state.loading && !loadingCart
        "
      >
        <div
          class="inside-panier-final w-11/12 lg:w-6/12 grid grid-col-10 gap-y-8 mx-auto lg:mx-0"
        >
          <transition name="slide">
            <div class="col-span-10" v-if="!orderIsActive()">
              <h2 class="font-medium text-black text-lg tracking-wider">
                * Important, la commande minimale est de
                {{ store.state.commandeMinimale.toFixed(2) }} $
              </h2>
            </div>
          </transition>

          <div class="col-span-10">
            <h2 class="font-medium tracking-wider">TOTAL PANIER</h2>
          </div>
          <div class="col-span-10 flex justify-between ">
            <p class="tracking-wide">Sous-total</p>
            <p class="tracking-wide">
              {{ store.methods.calculSousTotalCart() }} $
            </p>
          </div>
          <div class="col-span-10 flex justify-between ">
            <p class="tracking-wide">Expédition</p>
            <p class="tracking-wide">
              {{ store.state.prixExpedition.toFixed(2) }} $
            </p>
          </div>
          <div
            v-if="store.state.rabaisExpedition"
            class="col-span-10 flex justify-between"
          >
            <p class="tracking-wide">( Rabais expédition )</p>
            <p class="tracking-wide">
              - {{ store.state.montantRabaisExpedition.toFixed(2) }} $
            </p>
          </div>

          <div
            v-if="store.state.rabais"
            class="col-span-10 flex justify-between"
          >
            <p class="tracking-wide">( Rabais )</p>
            <p class="tracking-wide">- {{ store.state.montantRabais }} $</p>
          </div>
          <div class="col-span-10 flex justify-between">
            <p class="tracking-wide">TPS</p>
            <p class="tracking-wide">{{ store.state.montantTps }} $</p>
          </div>
          <div class="col-span-10 flex justify-between">
            <p class="tracking-wide">TVQ</p>
            <p class="tracking-wide">{{ store.state.montantTvq }} $</p>
          </div>
          <div class="col-span-10 flex justify-between">
            <p class="tracking-wide">Total</p>
            <p class="tracking-wide">{{ store.state.totalFinal }} $</p>
          </div>
          <div
            class="flex justify-center  w-full lg:w-11/12 lg:w-30 col-span-10 lg:col-span-10"
          >
            <button
              class="btnValider "
              v-if="orderIsActive()"
              @click="placeOrder()"
            >
              <div class="flex justify-between items-center ml-2">
                VALIDER LA COMMANDE<span class="ml-2" v-if="loadingCommande"
                  ><img
                    id="loadingLogo"
                    src="../../assets/images/spinner_button.gif"
                /></span>
              </div>
            </button>

            <button class="btnValiderDisabled  w-10/12 lg:w-56" v-else>
              VALIDER LA COMMANDE
            </button>
          </div>
        </div>
      </div>
    </main>
    <TheFooter />
  </div>
</template>

<script>
import TheNavbar from "../../components/TheNavbar";
import TheFooter from "../../components/TheFooter";

import { inject, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

export default {
  name: "Cartes",
  components: { TheNavbar, TheFooter },
  setup() {
    const store = inject("store");
    const codePromo = ref(null);
    const router = useRouter();
    const isSubmited = ref(false);
    const codeinvalide = ref(false);
    const needRefreshInventory = ref(false);
    const loadingCart = ref(false);
    const loadingCommande = ref(false);

    onMounted(() => {
      window.scrollTo(0, 0);
      store.state.currentOrder = null; // RESET ORDER
      if (!store.state.infosCutomer) {
        // SI PAS DINFO CUSTOIMER AVOIR LE CLIENT ID POUR FETCHER
        store.methods.checkUserId();
        store.methods.fetchInfosCustomer();
      }

      // REFRESH DATA
      store.state.inventoryBeenUpdated = false;

      if (!store.state.data) {
        loadingCart.value = true;
        store.methods.fetchPresentoirs();

        (async () => {
          await store.methods.fetchAllProducts();
          store.methods.checkIfCart();
          store.methods.calculTaxes();
          loadingCart.value = false;
        })();
      } else {
        store.methods.checkIfCart();
        store.methods.calculTaxes();
        loadingCart.value = false;
      }
    });

    function showIndex(variationId, isCoffret) {
      return store.state.cart.findIndex(
        (x) => x.variationId === variationId && x.isCoffret === isCoffret
      ); // A VERIFIER
    }

    function increment(
      parentId,
      variationId,
      isCoffret,
      isMeliMelo,
      isPresentoir
    ) {
      // AJUSTEMENT D'INVENTAIRE ET BLOCAGE SI INVENTAIRE AU MAX

      if (isPresentoir) {
        let indexPresentoir = store.state.presentoirs.findIndex(
          (x) => x.presentoirId === variationId
        );

        if (
          store.state.presentoirs[indexPresentoir].presentoirStockAvailable ===
          0
        ) {
          alert(
            `Vous ne pouvez ajouter plus d'item que l'inventaire diponible`
          );
          return;
        } else {
          store.state.presentoirs[indexPresentoir].presentoirStockAvailable--;
        }
      } else if (isCoffret && !isMeliMelo) {
        // AJUSTEMENT INVENTAIRE LIVE
        let indexProduct = store.state.data.findIndex(
          (product) => product.product_id === parentId
        );
        let indexVariation = store.state.data[
          indexProduct
        ].product_priceVariations.findIndex(
          (variation) => variation.variation === variationId
        );
        if (
          store.state.data[indexProduct].product_priceVariations[indexVariation]
            .stockAvailable -
            6 <
          0
        ) {
          alert(
            `Vous ne pouvez ajouter plus d'item que l'inventaire diponible`
          );
          return;
        } else {
          store.state.data[indexProduct].product_priceVariations[
            indexVariation
          ].stockAvailable -= 6;
        }
      } else if (isMeliMelo) {
        // // VERIFIER INVENTAIRE DISPONIBLE DU COFFRET

        // FORMATAGE POUR VERICIATION INVENTAIRE

        let coffret = {
          coffret_varId: [
            variationId[0],
            variationId[1],
            variationId[2],
            variationId[3],
            variationId[4],
            variationId[5],
          ],
        };

        if (store.methods.getInventoryCoffret(coffret) === 0) {
          alert(
            `Vous ne pouvez ajouter plus d'item que l'inventaire diponible`
          );
          return;
        } else {
          store.methods.updateMeliMeloInventary(variationId, 1);
        }
      } else {
        // SI PRODUIT REG
        let indexProduct = store.state.data.findIndex(
          (product) => product.product_id === parentId
        );
        let indexVariation = store.state.data[
          indexProduct
        ].product_priceVariations.findIndex(
          (variation) => variation.variation === variationId
        );
        if (
          store.state.data[indexProduct].product_priceVariations[indexVariation]
            .stockAvailable === 0
        ) {
          alert(
            `Vous ne pouvez ajouter plus d'item que l'inventaire diponible`
          );
          return;
        } else {
          store.state.data[indexProduct].product_priceVariations[indexVariation]
            .stockAvailable--;
        }
      }

      let newQuantity =
        store.state.cart[showIndex(variationId, isCoffret)].quantity + 1;
      store.methods.updateCart({
        variationId: variationId,
        quantity: newQuantity,
        isCoffret: isCoffret,
        isMeliMelo: isMeliMelo,
        isPresentoir: isPresentoir,
      });
    }

    function decrement(
      parentId,
      variationId,
      isCoffret,
      isMeliMelo,
      isPresentoir
    ) {
      if (store.state.cart[showIndex(variationId, isCoffret)].quantity > 1) {
        // ENLEVER LE CODE PROMO POUR UN FUTUR AJOUT ET RECALCUL

        let listOfIndexPromo = [];
        store.state.cart.forEach((item, index) => {
          if (item.parentId === 999998 && item.variationId === 999998) {
            listOfIndexPromo.push(index);
          }
        });

        if (listOfIndexPromo.length) {
          listOfIndexPromo.reverse().forEach((indexToRemove) => {
            store.state.cart.splice(indexToRemove, 1); // ENLEVE LA PROMO
          });
        }

        // AJUSTEMENT D'INVENTAIRE POUR PRESENTOIR

        if (isPresentoir) {
          let indexPresentoir = store.state.presentoirs.findIndex(
            (x) => x.presentoirId === variationId
          );
          store.state.presentoirs[indexPresentoir].presentoirStockAvailable++;
        } else if (isCoffret && !isMeliMelo) {
          // AJUSTEMENT INVENTAIRE LIVE
          let indexProduct = store.state.data.findIndex(
            (product) => product.product_id === parentId
          );
          let indexVariation = store.state.data[
            indexProduct
          ].product_priceVariations.findIndex(
            (variation) => variation.variation === variationId
          );
          store.state.data[indexProduct].product_priceVariations[
            indexVariation
          ].stockAvailable -= 6;
        } else if (isMeliMelo) {
          // // VERIFIER INVENTAIRE DISPONIBLE DU COFFRET

          store.methods.updateMeliMeloInventary(variationId, -1);
        } else {
          // SI PRODUIT REG

          let indexProduct = store.state.data.findIndex(
            (product) => product.product_id === parentId
          );
          let indexVariation = store.state.data[
            indexProduct
          ].product_priceVariations.findIndex(
            (variation) => variation.variation === variationId
          );
          store.state.data[indexProduct].product_priceVariations[indexVariation]
            .stockAvailable++;
        }

        let newQuantity =
          store.state.cart[showIndex(variationId, isCoffret)].quantity - 1;
        store.methods.updateCart({
          variationId: variationId,
          quantity: newQuantity,
          isCoffret: isCoffret,
          isMeliMelo: isMeliMelo,
          isPresentoir: isPresentoir,
        });
      }
    }

    // VERIFIE SUR LE LA COMMANDE EST AU DESSUS DU MIMIMUM REQUIS
    function orderIsActive() {
      if (
        store.state.sousTotalAvecRabaisSansExpedition >=
        store.state.commandeMinimale
      ) {
        return true;
      } else {
        return false;
      }
    }

    function placeOrder() {
      // PLACER LA COMMANDE DE PRODUITS

      if (!loadingCommande.value) {
        isSubmited.value = true;
        store.state.ifConfirmCartCliked = true;
        loadingCommande.value = true;

        fetch(store.state.processOrderUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          body: JSON.stringify(store.state.cart),
        })
          .then((res) => res.json())
          .then((data) => {
            store.state.currentOrder = data.data.factureClient;
            store.state.currentTaxes = data.data.taxesTotal;
            if (data.success === 1) {
              router.push({ path: "/confirmation" });
            } else {
              console.log("error");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    function pushcodePromoInCart(codePromo) {
      let dejaPromo = false;

      // VERIFIE QUE LE CODE N'EST PAS DEJA DANS LE CART
      store.state.cart.forEach((item) => {
        if (item.format === store.state.myCodePromo.codePromoName) {
          dejaPromo = true;
        }
      });

      if (store.state.myCodePromo.codePromo === codePromo && !dejaPromo) {
        // SI LE CODE PROMO EST APPLICABLE
        let price = 0;

        codeinvalide.value = false;

        // SI MONTANT MINIMUM ET RABAIS DE MONTANT

        if (store.state.myCodePromo.codePromoType === "montant") {
          // SI C'EST UNE PROMO MONTANT
          if (
            store.methods.calculSousTotalCart() >=
            store.state.myCodePromo.codePromoMontantMin
          ) {
            // SI LE CART EQUIVAUT AU MINIMUM

            price = -store.state.myCodePromo.codePromoMontant;
          } else {
            // SI NE RENCONTRE PAS LE MINIMUM
            codeinvalide.value = true;
          }
        } else {
          // SI POURCENTAGE

          if (
            store.methods.calculSousTotalCart() >=
            store.state.myCodePromo.codePromoMontantMin
          ) {
            // SI MONTANT MINIMUM EST OK

            if (store.state.myCodePromo.codePromoTag) {
              // SI PROMO TAG

              let indexOfTag = [];
              let indexOfCoffretMeliMelo = [];

              store.state.cart.forEach((item, indexItem) => {
                if (!item.isMeliMelo && !item.isPresentoir) {
                  // SI C'EST UN PRODUIT REG OU COFFRET REG

                  let foundIndex = store.state.data.findIndex(
                    (product) => product.product_id === item.parentId
                  );
                  if (foundIndex !== -1) {
                    let foundTagIndex = store.state.data[
                      foundIndex
                    ].product_tags.findIndex(
                      (tag) => tag.id === store.state.myCodePromo.codePromoTag
                    );
                    if (foundTagIndex !== -1) {
                      indexOfTag.push(indexItem);
                    }
                  }
                } else if (item.isCoffret && item.isMeliMelo) {
                  // SI C'EST UN COFFRET MELIMELO

                  let numberOfProductInCoffret = 0;
                  store.state.data.forEach((productInData, indexInData) => {
                    // CHACUN DES PRODUITS

                    item.variationId.forEach((variation) => {
                      // CHACUNE DES VARIATION A L'INTERIEUR DU COFFRET

                      let indexIfInCoffret = productInData.product_priceVariations.findIndex(
                        (product) => product.variation === variation
                      );
                      if (indexIfInCoffret !== -1) {
                        // SI COMPRIS DANS LE COFFRET
                        let indexOfGoodTag = store.state.data[
                          indexInData
                        ].product_tags.findIndex(
                          (insideProduct) =>
                            insideProduct.id ===
                            store.state.myCodePromo.codePromoTag
                        );
                        if (indexOfGoodTag !== -1) {
                          numberOfProductInCoffret++;
                        }
                      }
                    });
                  });

                  if (numberOfProductInCoffret > 0) {
                    indexOfCoffretMeliMelo.push({
                      indexOfCoffretInCart: indexItem,
                      numberOfProductCorresponding: numberOfProductInCoffret,
                      priceOfCoffret: item.price,
                      quantity: item.quantity,
                    });
                  }
                }
              });

              if (!indexOfTag.length && !indexOfCoffretMeliMelo.length) {
                // SI AUCUN RESULTATS CODE INVALIDE

                codeinvalide.value = true;
              } else {
                if (indexOfTag.length) {
                  // SI DES RESULTAT DANS INDEX OF TAG
                  indexOfTag.forEach((indexItem) => {
                    price -=
                      store.state.cart[indexItem].price *
                      store.state.cart[indexItem].quantity *
                      store.state.myCodePromo.codePromoPourcentage;
                  });
                }
                if (indexOfCoffretMeliMelo.length) {
                  // SI DES CARTES DANS COFFRETS ON CONCORDE AVEC LA PROMO

                  indexOfCoffretMeliMelo.forEach((item) => {
                    price -= (
                      item.priceOfCoffret *
                      (item.numberOfProductCorresponding / 6) *
                      item.quantity *
                      store.state.myCodePromo.codePromoPourcentage
                    ).toFixed(2);
                  });
                }
              }
            } else if (store.state.myCodePromo.codePromoCat) {
              // SI PROMO CAT

              let indexOfCat = [];
              let indexOfCoffretMeliMelo = [];

              store.state.cart.forEach((item, indexItem) => {
                if (!item.isMeliMelo && !item.isPresentoir) {
                  // SI C'EST UN PRODUIT REG OU COFFRET REG

                  let foundIndex = store.state.data.findIndex(
                    (product) => product.product_id === item.parentId
                  );
                  if (foundIndex !== -1) {
                    let foundTagIndex = store.state.data[
                      foundIndex
                    ].product_categories.findIndex(
                      (cat) => cat.id === store.state.myCodePromo.codePromoCat
                    );
                    if (foundTagIndex !== -1) {
                      indexOfCat.push(indexItem);
                    }
                  }
                } else if (item.isCoffret && item.isMeliMelo) {
                  // SI C'EST UN COFFRET MELIMELO

                  let numberOfProductInCoffret = 0;
                  store.state.data.forEach((productInData, indexInData) => {
                    // CHACUN DES PRODUITS

                    item.variationId.forEach((variation) => {
                      // CHACUNE DES VARIATION A L'INTERIEUR DU COFFRET

                      let indexIfInCoffret = productInData.product_priceVariations.findIndex(
                        (product) => product.variation === variation
                      );
                      if (indexIfInCoffret !== -1) {
                        // SI COMPRIS DANS LE COFFRET
                        let indexOfGoodCat = store.state.data[
                          indexInData
                        ].product_categories.findIndex(
                          (insideProduct) =>
                            insideProduct.id ===
                            store.state.myCodePromo.codePromoCat
                        );
                        if (indexOfGoodCat !== -1) {
                          numberOfProductInCoffret++;
                        }
                      }
                    });
                  });

                  if (numberOfProductInCoffret > 0) {
                    indexOfCoffretMeliMelo.push({
                      indexOfCoffretInCart: indexItem,
                      numberOfProductCorresponding: numberOfProductInCoffret,
                      priceOfCoffret: item.price,
                      quantity: item.quantity,
                    });
                  }
                }
              });

              if (!indexOfCat.length && !indexOfCoffretMeliMelo.length) {
                // SI AUCUN RESULTATS CODE INVALIDE

                codeinvalide.value = true;
              } else {
                if (indexOfCat.length) {
                  // SI DES RESULTAT DANS INDEX OF TAG
                  indexOfCat.forEach((indexItem) => {
                    price -=
                      store.state.cart[indexItem].price *
                      store.state.cart[indexItem].quantity *
                      store.state.myCodePromo.codePromoPourcentage;
                  });
                }
                if (indexOfCoffretMeliMelo.length) {
                  // SI DES CARTES DANS COFFRETS ON CONCORDE AVEC LA PROMO

                  indexOfCoffretMeliMelo.forEach((item) => {
                    price -= (
                      item.priceOfCoffret *
                      (item.numberOfProductCorresponding / 6) *
                      item.quantity *
                      store.state.myCodePromo.codePromoPourcentage
                    ).toFixed(2);
                  });
                }
              }
            } else {
              // SI PROMO REG
              price -= (
                store.methods.calculSousTotalCart() *
                store.state.myCodePromo.codePromoPourcentage
              ).toFixed(2);
            }
          } else {
            // SI LE PRIX MINIMUM N'EST PAS ATTEINT
            codeinvalide.value = true;
          }
        }

        let namePromo =
          "RABAIS " + store.state.myCodePromo.codePromo.toUpperCase();

        let promoToAdd = {
          variation: 999998,
          parentId: 999998,
          price: price,
          imageVariation: null,
          format: store.state.myCodePromo.codePromoName,
        };

        if (!codeinvalide.value) {
          // POUSSER LE PRODUIT DANS LE PANIER SI LE CODE EST VALIDE
          store.methods.addToCart([
            promoToAdd,
            1,
            namePromo,
            [false, false],
            false,
          ]);
          // RECALCUCLER LE SOUS-TOTAL QUI A ETE MODIFIER 14 oct
          store.methods.calculSousTotalCart();
          store.methods.calculTaxes(); 

        }
      } else {
        codeinvalide.value = true;
      }
    }

    function checkCodePromo(codePromo) {
      // SYNC LES REQUETES

      if (codePromo) {
        // SI LE CODE PROMO N'EST PAS NUl

        (async () => {
          await store.methods.getCodePromo(codePromo.toLowerCase());

          if (!store.state.customerId) {
            (async () => {
              await store.methods.fetchInfosCustomer();
              pushcodePromoInCart(codePromo);
            })();
          } else {
            pushcodePromoInCart(codePromo);
          }
        })();
      }
    }

    return {
      store,
      codePromo,
      orderIsActive,
      placeOrder,
      checkCodePromo,
      needRefreshInventory,
      loadingCommande,
      showIndex,
      increment,
      decrement,
      isSubmited,
      codeinvalide,
      pushcodePromoInCart,
      loadingCart,
    };
  },
};
</script>
<style lang="scss">
$borderGray: #dedede;
$goldColor: #b7a36f;

.slide-enter-from {
  opacity: 0;
  transform: scale(0.95);
}
.slide-enter-to {
  transform: scale(1);
  opacity: 1;
}
.slide-enter-active {
  transition: all 0.4s ease;
}
.slide-leave-from {
  opacity: 1;
  transform: scale(1);
}
.slide-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

.boutonappliquer {
  border: 2px solid black;
  font-size: 12px;
  letter-spacing: 0.9px;
  font-weight: 600;
  padding: 10px;
  transition: all 0.3s;
  outline: none !important;
  text-transform: uppercase;
}

.boutonappliquer:hover {
  border: 2px solid $goldColor;
  color: $goldColor;
}

.btnValider {
  font-size: 14px !important;
  color: white;
  border-radius: 0px;
  letter-spacing: 0.9px;
  padding: 10px 10px;
  display: block;
  background-color: black;
  transition: all 0.3s;
  outline: none !important;
}
.btnValider:active {
  outline: none !important;
}

.btnValider:hover {
  color: white;
  background-color: #b7a36f;
}

.btnValiderDisabled {
  cursor: not-allowed;
  font-size: 14px !important;
  color: #a2a2a2 !important;
  border-radius: 0px;
  letter-spacing: 0.9px;
  padding: 10px 10px;
  display: block;
  background-color: rgb(236, 236, 236);
  transition: all 0.3s;
  outline: none !important;
}
.btnValiderDisabled:active {
  outline: none !important;
}

.panier {
  max-width: 1445px;
  margin: auto;
  color: #555;

  .code-promo {
    border-bottom: 1px solid $borderGray;
    border-left: 1px solid $borderGray;
    border-right: 1px solid $borderGray;
    margin: auto;
    display: flex;
    justify-content: flex-end;

    .codePromoInput {
      border: 1px solid $borderGray;
      padding: 10px;
    }

    input:focus {
      outline: none;
    }
  }

  .panier-resume {
    border: 1px solid $borderGray;
    margin: auto;
    .titles-panier {
      border-bottom: 1px solid $borderGray;
      color: #555;
    }

    .image-produit-panier {
      max-height: 80px;
      width: 90px;
    }

    .x_delete_panier {
      cursor: pointer;
    }

    .bloc-item {
      height: 70px;
      display: flex;
      align-items: center;
    }

    .sous-total-inside-cart {
      font-weight: 600;
    }

    z-index: 100;
    /* skin 5 */
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    input:focus {
      outline: none !important;
    }

    .skin-5 .num-in {
      width: 88px;
      float: left;
      vertical-align: middle;
      position: relative;
      border: 1px solid #eeeff1;
      border-radius: 3px;
    }

    .skin-5 .num-in:hover {
      border: 1px solid #eeeff7;
      box-shadow: 0px 0px 7px 0px #f3f3f3;
    }

    .skin-5 .num-in span {
      font-size: 16px;
      width: 20px;
      display: block;
      line-height: 41px;
    }

    .skin-5 .num-in span.minus {
      cursor: pointer;
      float: left;
      text-align: right;
      font-size: 18px;
    }

    .skin-5 .num-in input {
      border: none;
      height: 41px;
      width: 46px;
      float: left;
      text-align: center;
      font-size: 16px;

      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
    }

    .skin-5 .num-in span.plus {
      cursor: pointer;
      float: right;
      text-align: left;
      font-size: 18px;
    }

    /* / skin 5 */
  }
}
</style>
