<template>
  <div class="relative min-h-screen md:flex">
    <div class="bg-gray-600 text-gray-100 flex justify-between md:hidden">
      <!-- logo -->
      <a href="#" class="block p-4 text-white font-bold"
        >Mimosa Design Extranet</a
      >

      <!-- mobile menu button -->
      <button
        class="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700"
        @click="toggleMenu()"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </button>
    </div>

    <AdminSideBar />

    <!-- content -->
    <div class="flex-1 md:p-10">
      <div class="col-span-5 p-8 ">
        <div class="col-span-3">
          <p class="text-3xl uppercase">
            Gestion des représentants
          </p>
        </div>
        <div class="w-full mt-10 grid grid-cols-1 md:grid-cols-3">
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="mode = 'ajouter'"
          >
            Ajouter un représentant
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 lg:mr-10 col-span-3 lg:col-span-1 uppercase"
            @click="
              mode = 'modifier';
              editRepresentant = {};
              currentRepresentantEdit = 'choose';
            "
          >
            Modifier un représentant
          </button>
          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded mt-4 lg:mt-0 col-span-3 lg:col-span-1 uppercase"
            @click="mode = 'supprimer'"
          >
            Supprimer un représentant
          </button>
        </div>

        <div v-if="mode === 'modifier'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Modification un représentant</p>
            <div class="relative">
              <select
                v-model="currentRepresentantEdit"
                @change="selectRepresentant()"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option disabled selected value="choose"
                  >Veuillez sélectionner un représentant à modifier</option
                >
                <option
                  v-for="(representant, index) in store.state.representants"
                  :key="index"
                  :value="representant.representative_id"
                  >{{ representant.representative_firstName }}
                  {{ representant.representative_lastName }}</option
                >
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div
            v-if="currentRepresentantEdit !== 'choose'"
            class="grid grid-cols-4 lg:grid-cols-5 gap-y-2 pt-10"
          >
            <p class="flex items-center">Prénom:</p>
            <input
              type="text"
              v-model="editRepresentant.representative_firstName"
              class="py-2 px-2 text-sm border border-blue-lighter rounded w-9/12 col-span-4"
            />
            <p class="flex items-center">Nom :</p>
            <input
              type="text"
              v-model="editRepresentant.representative_lastName"
              class="py-2 px-2 text-sm border border-blue-lighter rounded  w-9/12 col-span-4"
            />
            <p class="flex items-center">Telephone :</p>
            <input
              type="text"
              v-model="editRepresentant.representative_phone"
              class="py-2 px-2 text-sm border border-blue-lighter rounded  w-9/12 col-span-4"
            />
            <p class="flex items-center">Courriel :</p>
            <input
              type="email"
              name="email"
              v-model="editRepresentant.representative_email"
              class="py-2 px-2 text-sm border border-blue-lighter rounded  w-9/12 col-span-4"
            />
            <p class="flex items-center whitespace-nowrap">Pourcentage :</p>
            <span
              ><input
                type="number"
                v-model="editRepresentant.representative_commission"
                class="py-2 px-2 text-center text-sm border border-blue-lighter rounded w-20 col-span-4 poucentage"
              />
              <p class="pl-1 inline">%</p></span
            >
          </div>

          <button
            class="mt-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase"
            @click="confirmEditRepresentant()"
          >
            CONFIRMER LES MODIFICATIONS
          </button>
        </div>

        <div v-if="mode === 'ajouter'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Ajouter un représentant</p>

            <div class="grid grid-cols-4 lg:grid-cols-5 gap-y-2 pt-10">
              <p class="flex items-center">Prénom:</p>
              <input
                type="text"
                v-model="newRepresentant.representative_firstName"
                class="py-2 px-2 text-sm border border-blue-lighter rounded w-9/12 col-span-4"
              />
              <p class="flex items-center">Nom :</p>
              <input
                type="text"
                v-model="newRepresentant.representative_lastName"
                class="py-2 px-2 text-sm border border-blue-lighter rounded  w-9/12 col-span-4"
              />
              <p class="flex items-center">Telephone :</p>
              <input
                type="text"
                v-model="newRepresentant.representative_phone"
                class="py-2 px-2 text-sm border border-blue-lighter rounded  w-9/12 col-span-4"
              />
              <p class="flex items-center">Courriel :</p>
              <input
                type="email"
                name="email"
                v-model="newRepresentant.representative_email"
                class="py-2 px-2 text-sm border border-blue-lighter rounded  w-9/12 col-span-4"
              />

              <p class="flex items-center">Pourcentage :</p>
              <span
                ><input
                  type="number"
                  v-model="newRepresentant.representative_commission"
                  class=" py-2 px-2 text-center text-sm border border-blue-lighter rounded w-20 col-span-4 poucentage"
                />
                <p class="pl-1 inline">%</p></span
              >
            </div>
          </div>

          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase mt-10"
            @click="confirmAddRepresentant()"
          >
            Ajouter le représentant
          </button>
        </div>

        <div v-if="mode === 'supprimer'">
          <div class="mb-4 mt-14">
            <p class="text-2xl my-10">Supprimer un représentant</p>
            <div class="relative">
              <select
                v-model="currentRepresentantDelete"
                class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option disabled selected value="choose"
                  >Veuillez sélectionner un représentant à supprimer</option
                >
                <option
                  v-for="(representant, index) in store.state.representants"
                  :key="index"
                  :value="representant.representative_id"
                  >{{ representant.representative_firstName }}
                  {{ representant.representative_lastName }}</option
                >
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>

          <button
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 border border-blue-700 rounded uppercase mt-10"
            @click="deleteRepresentant()"
          >
            Supprimer le représentant
          </button>
        </div>

        <div class="w-full h-10 mt-10"></div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminSideBar from "../../components/AdminSideBar.vue";
import { ref, onMounted, inject } from "vue";

export default {
  name: "GestionRepresentantAdmin",
  components: { AdminSideBar },
  setup() {
    const store = inject("store");
    const selectForUpdate = ref();
    const currentRepresentantDelete = ref("choose");
    const currentRepresentantEdit = ref("choose");
    const indexRepresentantEdit = ref(null);
    const newRepresentant = ref({});
    const editRepresentant = ref({});
    const mode = ref(null);
    const successDelete = ref(false);
    const success = ref(false);
    const error = ref(false);
    // const indexOfCreatorToUpdate = ref('Veuillez sélectionner un format');

    function selectRepresentant() {
      if (currentRepresentantEdit.value !== "choose") {
        indexRepresentantEdit.value = store.state.representants.findIndex(
          (x) => x.representative_id === currentRepresentantEdit.value
        );
        editRepresentant.value =
          store.state.representants[indexRepresentantEdit.value];
      }
    }

    function confirmAddRepresentant() {
      if (!newRepresentant.value.representative_firstName) {
        return;
      }
      if (!newRepresentant.value.representative_phone) {
        return;
      }
      if (!newRepresentant.value.representative_email) {
        return;
      }
      if (!newRepresentant.value.representative_commission) {
        return;
      }

      let proceed = confirm(`Confirmer l'ajout du créateur ?`);

      if (proceed) {
        fetch(store.state.addRepresentantUrl, {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(newRepresentant.value),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success === 1) {
              success.value = true;
              store.methods.fetchRepresentants();
              newRepresentant.value = {};
            } else {
              error.value = true;
            }
          })
          .catch((err) => {
            console.log(err);
            error.value = true;
          });
      } else {
        console.log("deletepas");
      }
    }

    function confirmEditRepresentant() {
      if (!editRepresentant.value.representative_firstName) {
        return;
      }
      if (!editRepresentant.value.representative_phone) {
        return;
      }
      if (!editRepresentant.value.representative_email) {
        return;
      }
      if (!editRepresentant.value.representative_commission) {
        return;
      }

      let proceed = confirm(
        `Confirmer les modifications pour le représentant ?`
      );
      if (proceed) {
        fetch(store.state.updateRepresentantUrl, {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(editRepresentant.value),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.success === 1) {
              success.value = true;
              store.methods.fetchRepresentants();
              editRepresentant.value = {};
            } else {
              error.value = true;
            }
          })
          .catch((err) => {
            console.log(err);
            error.value = true;
          });
      } else {
        console.log("deletepas");
      }
    }

    function deleteRepresentant() {
      if (currentRepresentantDelete.value !== "choose") {
        let indexRepresentant = store.state.representants.findIndex(
          (x) => x.representative_id === currentRepresentantDelete.value
        );

        let proceed = confirm(
          `Voulez-vous vraiment effacer le représentant : ${store.state.representants[indexRepresentant].representative_firstName} ${store.state.representants[indexRepresentant].representative_lastName}`
        );
        if (proceed) {
          fetch(
            `${store.state.deleteRepresentantUrl}/${currentRepresentantDelete.value}`,
            {
              credentials: "include",
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.success === 1) {
                successDelete.value = true;
                currentRepresentantDelete.value = "choose";
                store.methods.fetchRepresentants(); // MET A JOUR LES PRESENTOIRS
              } else {
                console.log("no success");
                error.value = true;
              }
            })
            .catch((err) => {
              console.log(err);
              error.value = true;
            });
        } else {
          console.log("deletepas");
        }
      }
    }

    function toggleMenu() {
      const sidebar = document.querySelector(".sidebar");
      sidebar.classList.toggle("-translate-x-full");
    }

    onMounted(() => {
      if (store.state.representants === null) {
        store.methods.fetchRepresentants();
      }
    });

    return {
      store,
      selectForUpdate,
      currentRepresentantDelete,
      currentRepresentantEdit,
      selectRepresentant,
      mode,
      deleteRepresentant,
      toggleMenu,
      successDelete,
      error,
      editRepresentant,
      newRepresentant,
      confirmAddRepresentant,
      success,
      confirmEditRepresentant,
      indexRepresentantEdit,
    };
  },
};
</script>
<style lang="scss">
.image-edit-admin {
  width: 200px;
}

.poucentage {
  min-width: 70px;
}
</style>
